import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from '../general/Link';
import { useCommonStyles } from '../commonStyles';
import { Spacing, FontSizes, Colors, FontWeights } from '@ateams/components';

const useStyles = createUseStyles({
  builderSectionVerticalLinks: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.xsmall,
  },
  builderSectionVerticalLinksMore: {
    fontSize: FontSizes.small,
    color: Colors.secondaryDark,
    cursor: 'pointer',
    fontWeight: FontWeights.medium,
  },
});

export interface BuilderSectionVerticalLinksProps {
  title: string;
  maxRows: number;
  links: {
    label: string;
    href: string;
  }[];
}

export const BuilderSectionVerticalLinks = ({
  title,
  maxRows,
  links,
}: BuilderSectionVerticalLinksProps) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const [showMore, setShowMore] = useState(false);

  const handleMoreClick = () => {
    setShowMore(!showMore);
  };

  return (
    <div className={styles.builderSectionVerticalLinks}>
      <div className={commonStyles.title}>{title}</div>
      {links.slice(0, showMore ? links.length : maxRows).map((link) => (
        <Link key={link.href} href={link.href} showIcon text={link.label} />
      ))}
      {links.length > maxRows && (
        <div
          className={styles.builderSectionVerticalLinksMore}
          onClick={handleMoreClick}
        >
          {showMore
            ? 'Collapse the list'
            : `See ${links.length - maxRows} more`}
        </div>
      )}
    </div>
  );
};
