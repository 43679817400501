import { ServiceAuth, ServiceEndpoint } from './utils';

export enum TranscriptType {
  Evaluation = 'evaluation',
  Interview = 'interview',
  DiscoverInterview = 'discover-interview',
}

export enum TranscriptSpeakerType {
  Interviewer = 'interviewer',
  Interviewee = 'interviewee',
  Unknown = 'unknown',
}

export interface TranscriptLine {
  start: number;
  speakerType: TranscriptSpeakerType;
  speakerName: string;
  text: string;
}

export interface TranscriptUser {
  name: string;
  email: string;
  pictureURL: string;
}

export interface TranscriptData {
  type: TranscriptType;
  interviewDate: string;
  organizer: TranscriptUser;
  attendee: TranscriptUser;
  lines: TranscriptLine[];
  summary: string;
  duration: number;
  recordingUrl: string;
  companyName?: string;
  missionName?: string;
  roleName?: string;
}

export const BasePath = '/transcripts';

export default class TranscriptsEndpoint extends ServiceEndpoint {
  public getTranscript(
    auth: ServiceAuth,
    jobId: string,
  ): Promise<TranscriptData> {
    return this.fetch(auth, BasePath + `/${jobId}`);
  }
}
