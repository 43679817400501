import React from 'react';
import { useCommonStyles } from '../commonStyles';
import {
  Colors,
  FontSizes,
  Select,
  SelectOption,
  Spacing,
} from '@ateams/components';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { components } from 'react-select';

const useStyles = createUseStyles({
  select: {
    margin: 0,
  },
  tagList: {
    marginTop: Spacing.small,
    '& > div': {
      borderRadius: 4,
      fontSize: FontSizes.xsmall,
      fontWeight: 400,
      color: Colors.dark,
    },
  },
  badge: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    background: Colors.backgroundDark,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: FontSizes.xsmall,
    fontWeight: 400,
    color: Colors.dark,
  },
});

export interface FieldDropdownProps {
  label: string;
  options: SelectOption[];
  value: SelectOption[] | SelectOption | undefined;
  onChange?: (value: SelectOption | null) => void;
  placeholder?: string;
  className?: string;
  labelClassName?: string;
  isMulti?: boolean;
}

export const FieldDropdown = ({
  label,
  options,
  value,
  onChange,
  placeholder,
  className,
  labelClassName,
  isMulti,
}: FieldDropdownProps) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();

  return (
    <div className={cn(commonStyles.field, className)}>
      <div className={cn(commonStyles.title, labelClassName)}>{label}</div>
      <Select
        options={options}
        value={value}
        onChange={(value) => onChange?.(value as SelectOption | null)}
        placeholder={placeholder}
        className={styles.select}
        overrideStyles={{
          control: (base) => ({
            ...base,
            height: '32px !important',
          }),
          input: (base) => ({
            ...base,
            flexGrow: 1,
            padding: 0,
            margin: 0,
          }),
          valueContainer: (base) => ({
            ...base,
            fontSize: 12,
            padding: 0,
            paddingLeft: 10,
          }),
        }}
        components={{
          ValueContainer: (props) => {
            const selectedOptionsCount = props.getValue().length;

            return (
              <components.ValueContainer {...props}>
                {props.children}
                {isMulti && selectedOptionsCount > 0 && (
                  <span className={styles.badge}>{selectedOptionsCount}</span>
                )}
              </components.ValueContainer>
            );
          },
        }}
        isMulti={isMulti}
        isSearchable
        tagListClassName={styles.tagList}
      />
    </div>
  );
};
