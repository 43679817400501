import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import UserAvatar from '@src/components/UserAvatar';
import { NotificationType } from '@a_team/models/dist/NotificationObject';
import CompanyLogo from '@src/components/CompanyLogo';
import { DateISOString } from '@a_team/models/dist/misc';
import { formatTimeAgo } from '@src/helpers/formatDate';
import CompanyLogoWithOwner from '@src/components/CompanyLogoWithOwner';
import starIcon from './star.svg';
import UserAvatarWithCompanyLogo from '@src/components/UserAvatarWithCompanyLogo';

interface NotificationProps {
  notificationType:
    | NotificationType.CompanyLikedYou
    | NotificationType.ConnectionApproved
    | NotificationType.ConnectionRequest
    | NotificationType.CollaboratorRequest
    | NotificationType.ApplicationStatus
    | NotificationType.TeamUpRequest
    | NotificationType.TeamUpRequestAccepted
    | NotificationType.EvaluationInterviewScheduled
    | NotificationType.BuilderRatings
    | NotificationType.EvaluationInterviewWaitingSchedule;
  ctaName?: string;
  ctaAction: () => void;
  onMarkAsRead?: () => void;
  isRead?: boolean;
  title: string;
  description: React.ReactNode;
  imageUrl?: string | null;
  profilePictureURL?: string | null;
  companyLogoUrl?: string | null;
  dataTestingId?: string;
  status?: string;
  color?: string;
  createdAt: DateISOString;
}

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    color: '#000000',
    display: 'flex',
    gap: 16,
    padding: 16,
    borderBottom: '1px solid #DADADC',
  },
  title: {
    fontWeight: 500,
  },
  desc: {
    color: '#818388',
    fontSize: 14,
  },
  imgContainer: {
    position: 'relative',
    height: 'fit-content',
  },
  starIconContainer: {
    position: 'absolute',
    right: '-2px',
    bottom: '-10px',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    flex: 1,
  },
  btn: {
    fontWeight: 500,
    fontSize: 14,
    border: 'none',
    background: 'none',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
    outline: 'none',
  },
  ctaBtn: {
    color: '#6D00D7',
  },
  markAsReadBtn: {
    color: '#818388',
  },
  btnsContainer: {
    display: 'flex',
    gap: 16,
  },
  purpleIndicator: {
    position: 'absolute',
    background: '#6D00D7',
    width: 8,
    height: 8,
    borderRadius: '50%',
    left: 10,
    top: 10,
  },
  titleTimeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 12,
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    margin: '8px 0',
    gap: 8,
  },
  time: {
    color: '#818388',
    fontSize: 14,
    whiteSpace: 'nowrap',
  },
});

const Notification = ({
  ctaName,
  ctaAction,
  title,
  description,
  imageUrl,
  profilePictureURL,
  onMarkAsRead,
  notificationType,
  createdAt,
  isRead,
  status,
  color,
  dataTestingId,
  companyLogoUrl,
}: NotificationProps): JSX.Element => {
  const styles = useStyles();

  const timeAgo = formatTimeAgo(createdAt);

  return (
    <div className={styles.container}>
      {!isRead && <div className={styles.purpleIndicator} />}

      <div className={styles.imgContainer}>
        {[
          NotificationType.CompanyLikedYou,
          NotificationType.ApplicationStatus,
          NotificationType.EvaluationInterviewScheduled,
          NotificationType.EvaluationInterviewWaitingSchedule,
          NotificationType.BuilderRatings,
        ].includes(notificationType) ? (
          <CompanyLogo logoUrl={imageUrl} />
        ) : notificationType === NotificationType.CollaboratorRequest ? (
          <CompanyLogoWithOwner
            logoUrl={imageUrl}
            profilePictureURL={profilePictureURL}
          />
        ) : [
            NotificationType.TeamUpRequest,
            NotificationType.TeamUpRequestAccepted,
          ].includes(notificationType) ? (
          <UserAvatarWithCompanyLogo
            logoUrl={companyLogoUrl}
            profilePictureURL={profilePictureURL}
          />
        ) : (
          <UserAvatar
            shouldResizeUploadCareImages={false}
            size={56}
            src={imageUrl || undefined}
            alt={'user avatar'}
          />
        )}
        {notificationType === NotificationType.CompanyLikedYou && (
          <div className={styles.starIconContainer}>
            <img src={starIcon} alt="star icon" />
          </div>
        )}
      </div>
      <div className={styles.wrapper}>
        <div>
          <div className={styles.titleTimeContainer}>
            <div className={styles.title}>{title}</div>
            <div className={styles.time}>{timeAgo}</div>
          </div>
          {notificationType === NotificationType.ApplicationStatus && (
            <div className={styles.status}>
              <span
                style={{
                  height: 12,
                  width: 12,
                  borderRadius: 2,
                  background: color,
                }}
              />
              {status}
            </div>
          )}
          <div className={styles.desc}>{description}</div>
        </div>
        <div className={styles.btnsContainer}>
          {ctaName && (
            <button
              data-testing-id={dataTestingId}
              onClick={ctaAction}
              className={cx(styles.btn, styles.ctaBtn)}
            >
              {ctaName}
            </button>
          )}

          {!isRead && onMarkAsRead && (
            <button
              onClick={onMarkAsRead}
              className={cx(styles.btn, styles.markAsReadBtn)}
            >
              Mark as read
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notification;
