import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { AdminNotesMissionApplication } from '@a_team/models/dist/AdminNotesObject';
import { AdminVettingProcessWithAudit } from '@a_team/models/dist/vetting-processes/vetting-processes';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import Profile from '@src/stores/Profile/Profile';
import {
  OnEvaluationFollowupInviteSubmit,
  OnEvaluationInviteSubmit,
  EvaluationInviteModal,
} from '@src/components/Modal/EvaluationInviteModal';
import { VettingProcessSelector } from './vetting-process-selector';
import { VettingProcessSummary } from './vetting-process-summary';
import { NoVettingProcessesCard } from './no-processes-card';
import { VettingProcessActivity } from './activity';
import { Button } from '@src/views/VettingFeedbackForm/components/button';
import useToggle from '@src/hooks/useToggle';
import { Colors, Spacing } from '@ateams/components';
import { Size } from '@src/views/VettingFeedbackForm/components/typography';
import { useStores } from '@src/stores';
import cx from 'classnames';

export interface VettingTabProps {
  vettingProcesses: AdminVettingProcessWithAudit[];
  applications: AdminNotesMissionApplication[];
  profile: Profile;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  tabSection: {
    marginBottom: '24px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  button: {
    width: '170px',
    height: '32px',
    padding: '10px 16px',
    fontSize: Size.Small,
    background: Colors.primary,
    marginTop: Spacing.xsmall,
    marginLeft: '16px',
  },
  buttonDisabled: {
    background: Colors.primaryLight,
  },
  vettingProcessSelectorContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxWidth: '790px',
  },
});

function getDefaultSelectedProcess(
  vettingProcesses: AdminVettingProcessWithAudit[],
): AdminVettingProcessWithAudit | null {
  return vettingProcesses.length
    ? vettingProcesses.slice().sort((a, b) => {
        const aTime = a.createdAt ? new Date(a.createdAt).getTime() : 0;
        const bTime = b.createdAt ? new Date(b.createdAt).getTime() : 0;

        return aTime - bTime;
      })[0]
    : null;
}

/**
 * Figma: {@link https://www.figma.com/file/L1g2wFUmUk2Y7QNzrOxIfk/Profile-page?node-id=3830-25328&t=2mUIpalyFvgbaFtY-0}
 */
export const VettingTab: React.FC<VettingTabProps> = (props) => {
  const styles = useStyles();
  const { auth, vetters } = useStores();
  const { profile, vettingProcesses } = props;
  const [selectedVettingProcess, setSelectedVettingProcess] = useState(() =>
    getDefaultSelectedProcess(vettingProcesses),
  );
  const [loading, setLoading] = useLoadingState();
  const [evaluationInviteModalOpen, toggleEvaluationInviteModalOpen] =
    useToggle();

  const onEvaluationInvite: OnEvaluationInviteSubmit = (payload) => {
    setLoading(
      (async () => {
        // Creates a new vetting process
        await profile.startVettingProcess({
          category: payload.category,
          vettingType: payload.vettingType,
          contactOwner: payload.contactOwner,
          selectedVetterIds: payload.selectedVetterIds,
          vettingEmailTemplateParameters: payload.sendEvaluationEmail
            ? {
                subject: payload.emailSubject,
                template: payload.emailBody,
                templateType: payload.templateType,
                templateVariables: {},
                calendarUrlType: payload.calendarUrlType,
                sendInThread: payload.sendInThread,
              }
            : undefined,
        });

        // Refreshes data to fetch the new vetting process
        await profile.fetchAdminNotes();
        toggleEvaluationInviteModalOpen();
      })(),
      'Started Vetting Process',
    );
  };

  const onEvaluationFollowupInvite: OnEvaluationFollowupInviteSubmit = (
    id,
    payload,
  ) => {
    setLoading(
      (async () => {
        await profile.sendEvaluationInviteFollowup({
          id,
          category: payload.category,
          vettingType: payload.vettingType,
          contactOwner: payload.contactOwner,
          selectedVetterIds: payload.selectedVetterIds,
          vettingEmailTemplateParameters: {
            subject: payload.emailSubject,
            template: payload.emailBody,
            templateType: payload.templateType,
            templateVariables: {},
            calendarUrlType: payload.calendarUrlType,
            sendInThread: payload.sendInThread,
          },
        });

        // Refreshes data to fetch the new vetting process
        await profile.fetchAdminNotes();
      })(),
      'Sent evaluation invite followup',
    );
  };

  useEffect(() => {
    // Relevant after starting a new process - we want to select the new process
    if (!selectedVettingProcess) {
      setSelectedVettingProcess(getDefaultSelectedProcess(vettingProcesses));
    } else {
      // Relevant after applying a change to a vetting process and refreshing (E.g. sending evaluation invitation)
      setSelectedVettingProcess(
        vettingProcesses.find((vp) => vp.id === selectedVettingProcess.id) ||
          null,
      );
    }
  }, [vettingProcesses]);

  const builderRejected = useMemo(() => {
    return profile.adminNotes?.data.markedAsRejectedAt != null;
  }, [profile.adminNotes?.data.markedAsRejectedAt]);

  const onOpenScheduleVettingModal = async () => {
    if (auth.isVetter && !vetters.vetterConfiguration) {
      await vetters.getVettingConfiguration();
    }

    toggleEvaluationInviteModalOpen();
  };

  if (!vettingProcesses.length || !selectedVettingProcess) {
    return (
      <>
        <NoVettingProcessesCard
          mainTalentCategoryId={
            profile.data.talentProfile?.mainTalentCategoryId
          }
          onEvaluationInvite={onEvaluationInvite}
        />
        <LoadingIndicator loading={loading} />
      </>
    );
  }

  return (
    <div className={styles.container}>
      {evaluationInviteModalOpen && (
        <EvaluationInviteModal
          open
          defaultEvaluationType={vetters.vetterConfiguration?.type}
          defaultCategory={profile.data.talentProfile?.mainTalentCategoryId}
          onClose={toggleEvaluationInviteModalOpen}
          onSubmit={onEvaluationInvite}
        />
      )}
      <div className={styles.vettingProcessSelectorContainer}>
        <VettingProcessSelector
          className={styles.tabSection}
          vettingProcesses={vettingProcesses}
          selectedVettingProcess={selectedVettingProcess.id}
          onChange={(vettingProcess) =>
            setSelectedVettingProcess(vettingProcess)
          }
        />

        <Button
          className={
            builderRejected
              ? cx(styles.button, styles.buttonDisabled)
              : styles.button
          }
          rounded
          onClick={onOpenScheduleVettingModal}
          disabled={builderRejected}
          title={
            builderRejected
              ? 'You need to uncheck rejected in order to start evaluation'
              : undefined
          }
        >
          Start Evaluation
        </Button>
      </div>

      <VettingProcessSummary
        profile={profile}
        className={styles.tabSection}
        vettingProcess={selectedVettingProcess}
        onEvaluationInvite={onEvaluationFollowupInvite}
      />

      <VettingProcessActivity
        className={styles.tabSection}
        vettingProcessAudit={selectedVettingProcess.audit}
      />

      <LoadingIndicator loading={loading} />
    </div>
  );
};
