import React from 'react';
import {
  CompanySizeLabels,
  PrimaryExpertiseLabels,
  ProductTypeLabels,
  TypicalTeamSizeLabels,
  VettingProcessProductManagementPreVettingForm,
} from '@a_team/models/dist/vetting-processes/pre-vetting';
import { InputLabel, MultipleValuesField, useCommonStyles } from '../common';
import { ValueTag } from '@src/views/VettingFeedbackForm/components/value-tag';

function renderBooleanValue(bool: boolean) {
  return bool ? 'Yes' : 'No';
}

export interface PreVettingProductManagementAnswersProps {
  answers: VettingProcessProductManagementPreVettingForm;
}

export const PreVettingProductManagementAnswers: React.FC<
  PreVettingProductManagementAnswersProps
> = ({ answers }) => {
  const commonStyles = useCommonStyles();

  return (
    <>
      <div className={commonStyles.row}>
        <InputLabel>Primary expertise</InputLabel>
        <MultipleValuesField
          answers={answers.primaryExpertise.map(
            (pe) => PrimaryExpertiseLabels[pe] as string,
          )}
        />
      </div>

      <div className={commonStyles.row}>
        <InputLabel>Company sizes</InputLabel>
        <MultipleValuesField
          answers={answers.companySize.map(
            (cs) => CompanySizeLabels[cs] as string,
          )}
        />
      </div>

      <div className={commonStyles.row}>
        <InputLabel>Product types</InputLabel>
        <MultipleValuesField
          answers={answers.productType.map(
            (cs) => ProductTypeLabels[cs] as string,
          )}
        />
      </div>

      <div className={commonStyles.row}>
        <InputLabel>Has experience working on a product</InputLabel>
        <ValueTag>
          {renderBooleanValue(answers.hasExperienceWorkingOnAProduct)}
        </ValueTag>
      </div>

      <div className={commonStyles.row}>
        <InputLabel>Typical team size</InputLabel>
        <ValueTag>{TypicalTeamSizeLabels[answers.typicalTeamSize]}</ValueTag>
      </div>

      <div className={commonStyles.row}>
        <InputLabel>Has product designer on team</InputLabel>
        <ValueTag>
          {renderBooleanValue(answers.hadProductDesignerOnTeam)}
        </ValueTag>
      </div>

      {typeof answers.hasProfitAndLossResponsibility === 'boolean' && (
        <div className={commonStyles.row}>
          <InputLabel>Has profit & loss responsibility</InputLabel>
          <ValueTag>
            {renderBooleanValue(answers.hasProfitAndLossResponsibility)}
          </ValueTag>
        </div>
      )}

      <div className={commonStyles.row}>
        <InputLabel>Has technical background</InputLabel>
        <ValueTag>
          {renderBooleanValue(answers.hasTechnicalBackground)}
        </ValueTag>
      </div>
    </>
  );
};
