import React, { useMemo } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { theme } from '@a_team/ui-components';
import {
  AdminVettingProcessWithAudit,
  vettingTypeToStatusMap,
} from '@a_team/models/dist/vetting-processes/vetting-processes';
import {
  AccentScoreLabels,
  EnglishLevelLabels,
  ExpertiseScoreLabels,
  InteractionExperienceLabels,
} from '@a_team/models/dist/AdminNotesObject';
import { Card } from '@src/views/VettingFeedbackForm/components/card';
import {
  Size,
  Text,
} from '@src/views/VettingFeedbackForm/components/typography';
import { ValueTag } from '@src/views/VettingFeedbackForm/components/value-tag';
import { LinkIcon } from '@src/views/VettingFeedbackForm/components/icons/link';
import UserAvatar from '@src/components/UserAvatar';
import { DateInput } from '@src/views/VettingFeedbackForm/components/date-input';
import { Select } from '@src/views/VettingFeedbackForm/components/select';
import { Spacing } from '@ateams/components';
import { getScoreColor } from '@src/views/VettingFeedbackForm/form/main-info';
import { Separator } from '@src/views/VettingFeedbackForm/components/separator';
import { VettingProcessSummaryActions } from './actions';
import { VettingProcessSummarySkills } from './skills';
import { ComposeEmailBox } from '../compose-email-card/index';
import { OnEvaluationFollowupInviteSubmit } from '@src/components/Modal/EvaluationInviteModal';
import { VettingProcessStatus } from '@a_team/models/dist/vetting-processes/status';
import { VettingProcessSummaryAdditionalMaterials } from './additional-materials';
import {
  TranscriptLocation,
  VettingDashboardRecordingLocation,
} from '@src/locations';
import Profile from '@src/stores/Profile/Profile';

export interface VettingProcessSummaryProps {
  profile: Profile;
  vettingProcess: AdminVettingProcessWithAudit;
  onEvaluationInvite: OnEvaluationFollowupInviteSubmit;
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    maxWidth: '790px',
    borderRadius: '8px',
    padding: '24px',
  },
  columnsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  fieldsColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  inputRow: {
    display: 'flex',
    flexDireciton: 'row',
    alignItems: 'center',
    height: '32px',
  },
  inputLabel: {
    width: '132px',
    marginRight: '16px',
  },
  inputScoreLabel: {
    width: '132px',
  },
  marginBottom: {
    marginBottom: '16px',
  },
  interviewerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  interviewerUserAvatar: {
    marginRight: '4px',
  },
  purpleTag: {
    background: theme.colors.Hannibal[100],
    color: theme.colors.Hannibal[600],
  },
  rowValues: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    flexWrap: 'wrap',
  },
});

export const VettingProcessSummary: React.FC<VettingProcessSummaryProps> = (
  props,
) => {
  const styles = useStyles();
  const { profile, vettingProcess } = props;

  const shouldShowComposeEmailBox =
    vettingProcess.status !== VettingProcessStatus.Completed;

  const recordingPageUrls = useMemo(() => {
    if (!vettingProcess.recordingDownloadLinks) return [];
    const urls = vettingProcess.recordingDownloadLinks.filter((url) => url);
    const recordingPageUrls = urls.map(
      (url) =>
        `${VettingDashboardRecordingLocation}?recordingUrl=${encodeURIComponent(
          url,
        )}`,
    );
    return recordingPageUrls;
  }, [vettingProcess]);

  return (
    <Card className={cx(styles.container, props.className)}>
      {shouldShowComposeEmailBox && (
        <ComposeEmailBox
          rejected={profile.adminNotes?.isMarkedAsRejected}
          vettingProcess={vettingProcess}
          onEvaluationInvite={props.onEvaluationInvite}
          className={styles.marginBottom}
        />
      )}

      <div className={cx(styles.columnsContainer, styles.marginBottom)}>
        <div className={styles.fieldsColumn}>
          <Text
            size={Size.Medium}
            className={cx(styles.inputRow, styles.marginBottom)}
          >
            Interview Info
          </Text>
          <div className={cx(styles.inputRow, styles.marginBottom)}>
            <Text size={Size.Small} className={styles.inputLabel}>
              Interviewer
            </Text>
            {vettingProcess.vetter ? (
              <div className={styles.interviewerContainer}>
                <UserAvatar
                  containerClassName={styles.interviewerUserAvatar}
                  src={vettingProcess.vetter.user.profilePictureURL}
                  size={24}
                />
                <Text>{vettingProcess.vetter.user.fullName}</Text>
              </div>
            ) : (
              <EmptyValue />
            )}
          </div>
          <div className={cx(styles.inputRow, styles.marginBottom)}>
            <Text size={Size.Small} className={styles.inputLabel}>
              Interview Date
            </Text>
            {vettingProcess.interviewDate ? (
              <DateInput
                selected={new Date(vettingProcess.interviewDate)}
                isClearable={false}
                isDisabled
              />
            ) : (
              <EmptyValue />
            )}
          </div>
          <div className={cx(styles.inputRow, styles.marginBottom)}>
            <Text size={Size.Small} className={styles.inputLabel}>
              Evaluation Type
            </Text>
            <Select
              value={{
                label: vettingTypeToStatusMap[vettingProcess.vettingType],
                value: '_',
              }}
              isClearable={false}
              isDisabled
              color={'#EFEFF0'}
              components={{ DropdownIndicator: null }}
            />
          </div>
          <div className={cx(styles.inputRow, styles.marginBottom)}>
            <Text size={Size.Small} className={styles.inputLabel}>
              Role Category
            </Text>
            {vettingProcess.category ? (
              <Select
                value={{ value: '_', label: vettingProcess.category.name }}
                isClearable={false}
                isDisabled
                color={'#EFEFF0'}
                components={{ DropdownIndicator: null }}
              />
            ) : (
              <EmptyValue />
            )}
          </div>

          <div className={cx(styles.inputRow, styles.marginBottom)}>
            <Text size={Size.Small} className={styles.inputLabel}>
              Call Recording
            </Text>
            <div className={styles.rowValues}>
              {recordingPageUrls?.map((recordingPageUrl, idx) => (
                <a
                  key={recordingPageUrl}
                  href={recordingPageUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ValueTag
                    className={styles.purpleTag}
                    startAdornment={<LinkIcon />}
                  >
                    Recording link {idx + 1}
                  </ValueTag>
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.fieldsColumn}>
          <Text
            size={Size.Medium}
            className={cx(styles.inputRow, styles.marginBottom)}
          >
            Interview Scores
          </Text>
          <div className={cx(styles.inputRow, styles.marginBottom)}>
            <Text
              size={Size.Small}
              className={cx(styles.inputLabel, styles.inputScoreLabel)}
            >
              Expertise Level
            </Text>
            {typeof vettingProcess.feedback?.answers.scores.expertise ===
            'number' ? (
              <Select
                value={{
                  value: '_',
                  label: ExpertiseScoreLabels[
                    vettingProcess.feedback.answers.scores.expertise
                  ] as string,
                }}
                isClearable={false}
                isDisabled
                color={getScoreColor(
                  vettingProcess.feedback.answers.scores.expertise,
                )}
                components={{ DropdownIndicator: null }}
              />
            ) : (
              <EmptyValue />
            )}
          </div>
          <div className={cx(styles.inputRow, styles.marginBottom)}>
            <Text
              size={Size.Small}
              className={cx(styles.inputLabel, styles.inputScoreLabel)}
            >
              Interpersonal Skills
            </Text>
            {typeof vettingProcess.feedback?.answers.scores
              .interactionExperience === 'number' ? (
              <Select
                value={{
                  value: '_',
                  label: InteractionExperienceLabels[
                    vettingProcess.feedback.answers.scores.interactionExperience
                  ] as string,
                }}
                isClearable={false}
                isDisabled
                color={getScoreColor(
                  vettingProcess.feedback.answers.scores.interactionExperience,
                )}
                components={{ DropdownIndicator: null }}
              />
            ) : (
              <EmptyValue />
            )}
          </div>
          <div className={cx(styles.inputRow, styles.marginBottom)}>
            <Text
              size={Size.Small}
              className={cx(styles.inputLabel, styles.inputScoreLabel)}
            >
              English Proficiency
            </Text>
            {typeof vettingProcess.feedback?.answers.scores.englishLevel ===
            'number' ? (
              <Select
                value={{
                  value: '_',
                  label: EnglishLevelLabels[
                    vettingProcess.feedback.answers.scores.englishLevel
                  ] as string,
                }}
                isClearable={false}
                isDisabled
                color={getScoreColor(
                  vettingProcess.feedback.answers.scores.englishLevel,
                )}
                components={{ DropdownIndicator: null }}
              />
            ) : (
              <EmptyValue />
            )}
          </div>
          <div className={cx(styles.inputRow, styles.marginBottom)}>
            <Text
              size={Size.Small}
              className={cx(styles.inputLabel, styles.inputScoreLabel)}
            >
              English Pronunciation
            </Text>
            {typeof vettingProcess.feedback?.answers.scores.accent ===
            'number' ? (
              <Select
                value={{
                  value: '_',
                  label: AccentScoreLabels[
                    vettingProcess.feedback.answers.scores.accent
                  ] as string,
                }}
                isClearable={false}
                isDisabled
                color={getScoreColor(
                  vettingProcess.feedback.answers.scores.accent,
                )}
                components={{ DropdownIndicator: null }}
              />
            ) : (
              <EmptyValue />
            )}
          </div>

          <div className={cx(styles.inputRow, styles.marginBottom)}>
            <Text size={Size.Small} className={styles.inputLabel}>
              Transcripts
            </Text>
            <div className={styles.rowValues}>
              {vettingProcess.transcriptJobIds?.map((jobId, idx) => {
                const transcriptUrl = `${TranscriptLocation(jobId)}`;
                return (
                  <a
                    key={jobId}
                    href={transcriptUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ValueTag
                      className={styles.purpleTag}
                      startAdornment={<LinkIcon />}
                    >
                      Transcript link {idx + 1}
                    </ValueTag>
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {vettingProcess.feedback?.answers.skills.length ? (
        <VettingProcessSummarySkills
          className={styles.marginBottom}
          skills={vettingProcess.feedback.answers.skills}
        />
      ) : null}

      {vettingProcess.feedbackAdditionalMaterials && (
        <VettingProcessSummaryAdditionalMaterials
          className={styles.marginBottom}
          additionalMaterials={vettingProcess.feedbackAdditionalMaterials}
        />
      )}

      <Separator direction={'horizontal'} space={Spacing.medium} />

      <VettingProcessSummaryActions vettingProcess={vettingProcess} />
    </Card>
  );
};

const useEmptyValueStyles = createUseStyles({
  emptyValue: {
    color: '#818388',
  },
});

const EmptyValue = () => {
  const styles = useEmptyValueStyles();
  return (
    <Text size={Size.Small} className={styles.emptyValue}>
      N/A
    </Text>
  );
};
