import { AccentScoreLabels } from '@a_team/models/dist/AdminNotesObject';
import React from 'react';
import { BasicVettingProcessColumnProps } from '..';

export const AccentField: React.FC<BasicVettingProcessColumnProps> = ({
  vettingProcess,
}) => {
  if (typeof vettingProcess.feedback?.answers.scores.accent !== 'number') {
    return null;
  }

  return (
    <>{AccentScoreLabels[vettingProcess.feedback.answers.scores.accent]}</>
  );
};
