import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Icon, IconType, ToggleSwitch } from '@ateams/components';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { Button } from '@a_team/ui-components';
import cx from 'classnames';
import { useCommonStyles } from './commonStyles';
import {
  useGetUserCalendar,
  useMutationUpdateCalendarPreferences,
} from '@src/rq/profile';
import { NoCalendarReasonsModal } from './ReasonsModal';
import { goToManageCalendarClick, goToWorkingHoursClick } from './helpers';
import { useStores } from '@src/stores';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { ConnectCalendarButton } from './ConnectCalendarButton';

const InterviewSettingsView = () => {
  const styles = useCommonStyles();
  const { auth } = useStores();
  const analytics = useAnalytics();
  const { data: calendarData, isLoading: isCalendarLoading } =
    useGetUserCalendar();

  const { isLoading: isUpdating, mutate: updatePreferences } =
    useMutationUpdateCalendarPreferences();

  const [showCustomModal, setShowCustomModal] = useState<boolean>(false);

  const calcomCalendar = calendarData?.calcom?.selectedCalendar;
  const hasAvailibility = calendarData?.calcom?.hasAvailability;
  const userId = auth.uid ?? '';
  const autoRecord = !!calendarData?.calcom?.autoRecord;
  const isUsingSharedCalendar = useMemo(
    () => hasAvailibility && !calcomCalendar,
    [hasAvailibility, calcomCalendar],
  );

  const handleCalendarClick = () => {
    if (!calcomCalendar) {
      setShowCustomModal(true);
      return;
    }

    goToManageCalendarClick(auth.withNewCalendarLoginFlow);
    analytics.trackManageCalendarClick(userId);
  };

  const renderHeadline = () => {
    if (isUsingSharedCalendar) {
      return 'Interview scheduling';
    }

    return calcomCalendar ? `Connected calendar` : `Schedule interviews faster`;
  };

  const renderCopy = () => {
    if (hasAvailibility && !calcomCalendar) {
      return 'Companies can schedule interviews with you during your working hours.';
    }

    return calcomCalendar
      ? `Companies can now schedule interviews with you directly.`
      : `Connect your Calendar to allow companies to schedule interviews with you directly.`;
  };

  const renderLabel = () => {
    if (!calcomCalendar && !hasAvailibility) {
      return 'Interviewing not enabled';
    }

    if (calcomCalendar) {
      return 'Interviewing enabled';
    }

    return 'Interviewing partially enabled';
  };

  return (
    <div>
      <div className={styles.section}>
        <div className={styles.headline}>Interviews</div>
        <div className={styles.mainCopy}>
          Enable companies to schedule interviews with you directly.
        </div>
      </div>
      {!isCalendarLoading && (
        <div className={styles.section}>
          <div className={styles.subHeadline}>{renderHeadline()}</div>
          <div className={styles.copy}>{renderCopy()}</div>
          <div className={styles.cardsContainer}>
            <div className={cx(styles.card, 'nogap')}>
              <div className={styles.calendarMainContainer}>
                <div className={styles.calendarWrap}>
                  <Icon
                    type={
                      !calcomCalendar && hasAvailibility
                        ? IconType.ErrorCalendar
                        : IconType.PurpleCalendar
                    }
                    size="large"
                  />
                  <div>
                    <div
                      className={
                        calcomCalendar || hasAvailibility
                          ? styles.calendarInstalledTitle
                          : styles.noCalendarInstalledTitle
                      }
                    >
                      {renderLabel()}
                    </div>
                    {!calcomCalendar && hasAvailibility ? (
                      <div className={styles.calendarInstalledSubtitle}>
                        Calendar not connected
                      </div>
                    ) : calcomCalendar ? (
                      <div className={styles.calendarInstalledSubtitle}>
                        Calendar connected
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <ConnectCalendarButton
                  variant={isUsingSharedCalendar ? 'ghost' : undefined}
                  triggerTeaser={!isUsingSharedCalendar}
                  onCalendarClick={handleCalendarClick}
                  hasCalendar={!!calcomCalendar}
                />
              </div>
              {isUsingSharedCalendar && (
                <div className={styles.optoutWarning}>
                  <div className={styles.calendarInstalledTitle}>
                    Clients can't discover your profile in search, you may be
                    missing out on missions.
                  </div>
                  <div className={styles.calendarInstalledSubtitle}>
                    To get discovered and allow clients to book interviews
                    directly, connect your calendar.
                  </div>
                </div>
              )}
            </div>
            {(hasAvailibility || calcomCalendar) && (
              <div className={styles.card}>
                <div className={styles.subHeadline}>Working hours</div>
                <div className={styles.copy}>
                  Configure days and times that interviews can be booked
                </div>
                <div className={styles.calendarMainContainer}>
                  <div>
                    <div className={styles.calendarInstalledTitle}>
                      Manage your working hours
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      className={styles.calendarButton}
                      onClick={() => {
                        goToWorkingHoursClick(
                          calendarData.calcom?.schedule?.id,
                          auth.withNewCalendarLoginFlow,
                        );
                        analytics.trackManageWorkingHoursClicked(userId);
                      }}
                      variant="ghost"
                    >
                      Adjust working hours
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {(hasAvailibility || calcomCalendar) && (
              <div className={styles.card}>
                <div className={styles.subHeadline}>
                  Interview Recording & Feedback
                </div>
                <div className={styles.copy}>
                  When enabled, interviews you schedule with A.Team will be
                  recorded, transcribed and analyzed to give you feedback, help
                  improve your profile and more.
                </div>
                <div className={styles.calendarMainContainer}>
                  <div>
                    <div className={styles.calendarInstalledTitle}>
                      <ToggleSwitch
                        color="purple"
                        size={'small'}
                        checked={autoRecord}
                        onChange={() =>
                          updatePreferences({
                            autoRecord: !autoRecord,
                          })
                        }
                        disabled={isUpdating || isCalendarLoading}
                        label={`Interview recordings ${
                          autoRecord ? 'enabled' : 'disabled'
                        }`}
                      />{' '}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <NoCalendarReasonsModal
        open={showCustomModal}
        onClose={() => setShowCustomModal(false)}
        fullScreen
      />
      <LoadingIndicator
        loading={isCalendarLoading}
        successMsg="Settings loaded"
      />
    </div>
  );
};

export default observer(InterviewSettingsView);
