import React from 'react';
import { ModalPages } from '.';
import MissionCodeOfConduct from './MissionCodeOfConduct';
import PlatformCodeOfConduct from './PlatformCodeOfConduct';
import QuickOnboardingChecklist from './QuickOnboardingChecklist';

interface BodyProps {
  page: ModalPages;
}

const Body = ({ page }: BodyProps): JSX.Element => {
  return (
    <div>
      {page === ModalPages.PlatformCodeOfConduct && <PlatformCodeOfConduct />}
      {page === ModalPages.MissionCodeOfConduct && <MissionCodeOfConduct />}

      {page === ModalPages.QuickOnboardingChecklist && (
        <QuickOnboardingChecklist />
      )}
    </div>
  );
};

export default Body;
