import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import ReactSelect, {
  Props as ReactSelectProps,
  SelectComponentsConfig,
  OptionTypeBase,
} from 'react-select';
import cx from 'classnames';
import { customStyles, selectThemeColors, selectThemeStyles } from './styles';
import { MultiValueList } from './MultiValueList';
import { MultiValueContainer as ValueContainer } from './MultiValueContainer';
import { DropdownIndicator } from './DropdownIndicator';

import { createUseStyles } from 'react-jss';
import { Widths, Margins } from '../theme/styles';
import { InputLabel } from './InputLabel';
import { Option } from './Option';
import { RegularSelectInput } from './RegularSelectInput';
import { OptionWithDescription } from './OptionWithDescription';

export interface SelectProps<
  IsMulti extends boolean = false,
  T extends SelectOption = SelectOption,
> extends ReactSelectProps<T, IsMulti> {
  error?: boolean;
  label?: string;
  required?: boolean;
  width?: keyof typeof Widths;
  margin?: keyof typeof Margins;
  hideTags?: boolean;
  showItemCount?: boolean;
  itemCountPrefix?: string;
  onRemoveOption?: (option: SelectOption) => void;
  selectRef?: React.LegacyRef<ReactSelect<T, IsMulti>>;
  tagListClassName?: string;
  overrideStyles?: SelectProps<IsMulti, T>['styles'];
  /**
   * Replaces the default Input component from react-select with RegularSelectInput
   * This is used to avoid performance issues when we have many react-select instances
   * {@link https://buildateam.atlassian.net/browse/NEXUS-646}
   */
  useRegularSelectInput?: boolean;
  disableLocalValue?: boolean;
  isOptionWithDescription?: boolean;
  customMultiValueListValues?: (value: T[]) => T[];
}

const useStyles = createUseStyles<SelectProps>({
  root: {
    width: (props: SelectProps) => Widths[props.width || 'default'],
    margin: (props: SelectProps) => Margins[props.margin || 'default'],
  },
});

export const IndicatorSeparator = (): ReactElement => {
  return <span />;
};

// See complete API:
// https://react-select.com/props
const Select = <
  IsMulti extends boolean = false,
  T extends SelectOption = SelectOption,
>(
  props: SelectProps<IsMulti, T>,
): ReactElement => {
  const styles = useStyles({ width: props.width, margin: props.margin });
  const {
    error,
    onChange,
    value,
    label,
    required,
    className,
    hideTags,
    showItemCount,
    itemCountPrefix,
    styles: propsStyles,
    overrideStyles,
    components,
    onRemoveOption,
    useRegularSelectInput = false,
    tagListClassName,
    selectRef,
    disableLocalValue,
    customMultiValueListValues = (values: T[]) => values,
    ...rest
  } = props;
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    !disableLocalValue && setLocalValue(value);
  }, [value]);

  const selectStyles = useMemo(() => {
    return { ...(propsStyles || customStyles()), ...overrideStyles };
  }, [propsStyles, overrideStyles]);

  const removeOption = (removedOption: SelectOption) => {
    setLocalValue(
      (localValue as T[]).filter(
        (option) => option.value !== removedOption.value,
      ),
    );
    onRemoveOption && onRemoveOption(removedOption);
  };

  const selectComponents = useMemo(() => {
    const selectComponents: SelectComponentsConfig<OptionTypeBase, IsMulti> = {
      DropdownIndicator,
      IndicatorSeparator,
    };

    if (props.isMulti) {
      selectComponents['Option'] = Option;
    } else if (props.isOptionWithDescription) {
      selectComponents['Option'] = OptionWithDescription;
    }

    if (props.isMulti && props.isSearchable && props.showItemCount) {
      selectComponents['ValueContainer'] = ValueContainer;
    }

    if (useRegularSelectInput) {
      selectComponents['Input'] = RegularSelectInput;
    }

    return {
      ...selectComponents,
      ...components,
    };
  }, [
    props.isMulti,
    props.isSearchable,
    props.showItemCount,
    useRegularSelectInput,
    components,
  ]);

  const multiValueListValues = useMemo(() => {
    return customMultiValueListValues(
      (!disableLocalValue ? (localValue as T[]) : (value as T[])) || [],
    );
  }, [disableLocalValue, localValue, value]);

  return (
    <div
      className={cx(styles.root, className)}
      data-testing-id={props['data-testing-id']}
    >
      {label && <InputLabel required={required}>{label}</InputLabel>}
      <ReactSelect
        ref={selectRef}
        value={disableLocalValue ? value : localValue}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            ...selectThemeColors(error),
          },
          ...selectThemeStyles,
        })}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        components={selectComponents}
        placeholder={
          props.isMulti && props.showItemCount && (localValue as T[])?.length
            ? null
            : undefined
        }
        closeMenuOnSelect={!props.isMulti}
        controlShouldRenderValue={!props.isMulti}
        hideSelectedOptions={!props.isMulti}
        onChange={(value, action) => {
          !disableLocalValue && setLocalValue(value);
          onChange && onChange(value, action);
        }}
        isDisabled={!!props.disabled}
        isClearable={false}
        styles={selectStyles}
        selectProps={{ itemCountPrefix }}
        {...rest}
      />
      {!hideTags && props.isMulti && (
        <MultiValueList
          onRemove={removeOption}
          values={multiValueListValues}
          className={tagListClassName}
        />
      )}
    </div>
  );
};

export default Select;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SelectOption = { label: string; value: string; [k: string]: any };
