import Image from '@src/components/Image';
import React from 'react';
import { createUseStyles } from 'react-jss';
import ReserveCard from './ReserveCard';

const useStyles = createUseStyles({
  container: {
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
    alignItems: 'center',
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
    fontWeight: '600',
    margin: 0,
  },
  imgContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    maxWidth: '680px',
    width: '100%',
    height: 'auto',
  },
  desc: {
    color: '#818388',
    textAlign: 'center',
    fontSize: 15,
    margin: 0,
  },
  head: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  reserveCardsContainer: {
    display: 'flex',
    gap: 8,
    flexDirection: 'column',
    alignItems: 'center',
  },
});

const Reserve = (): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div className={styles.head}>
        <h4 className={styles.title}>
          You’ll be notified about relevant missions via email
        </h4>
        <p className={styles.desc}>
          We’ll reach out about missions that align with your skills
        </p>
      </div>
      <div className={styles.imgContainer}>
        <Image
          className={styles.image}
          aspectRatio={17 / 6}
          src="https://ucarecdn.com/bff04b42-b2ce-472b-b082-8c76193848a7/-/preview/1000x352/"
          alt="Reserve"
        />
      </div>
      <div className={styles.reserveCardsContainer}>
        <ReserveCard
          title="Curated Missions"
          description="You have access to select missions that align closely with your expertise. You’ll be notified via email when relevant roles become available."
        />
        <ReserveCard
          title="Get Noticed"
          description="To expedite the process of being invited to apply, we recommend maintaining a high quality profile. Highlighting your skills and experience in detail increases your visibility for mission reach outs."
        />
      </div>
    </div>
  );
};

export default Reserve;
