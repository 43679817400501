import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import dotSvg from './dot.svg';
import linkSvg from './link.svg';
import { Expertise } from '@src/stores/Profile/models';
import { Breakpoints } from '@ateams/components';
import CompanyLogoFallback from '@src/components/CompanyLogoFallback';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';

interface HeaderProps {
  logoUrl?: string;
  title?: string;
  companyName?: string;
  websiteUrl?: string;
  /** @deprecated */
  industry?: string;
  industries?: string[];
  allIndustries?: Expertise[];
  projectUrl?: string;
}

const useStyles = createUseStyles({
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    marginTop: 16,
    marginBottom: 0,
    display: 'flex',
    justifyContent: 'center',
    fontSize: 24,
    fontWeight: 600,
  },
  header: {
    marginTop: 8,
    display: 'flex',
    gap: 8,
    justifyContent: 'center',
    fontWeight: 400,
  },
  link: {
    display: 'flex',
    alignContent: 'center',
    color: '#6D00D7',
    gap: 8,
  },
  industry: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 150,
    textOverflow: 'ellipsis',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    industry: {
      maxWidth: 'none',
    },
  },
});

const Header = ({
  logoUrl,
  title,
  companyName,
  websiteUrl,
  industry,
  industries,
  allIndustries,
  projectUrl,
}: HeaderProps): JSX.Element => {
  const styles = useStyles();
  const { auth } = useStores();

  const industriesString = useMemo(() => {
    if (auth.withMultipleIndustriesForExperiences && industries?.length) {
      return industries
        ?.map((projectIndustry) => {
          return allIndustries?.find((item) => item.id === projectIndustry)
            ?.name;
        })
        .join(', ');
    }

    if (!auth.withMultipleIndustriesForExperiences) {
      return allIndustries?.find((item) => item.id === industry)?.name;
    }

    return '';
  }, [industry, industries, allIndustries]);

  const company = useMemo(() => {
    const elements: JSX.Element[] = [];
    if (companyName) {
      if (websiteUrl) {
        elements.push(
          <div>
            <a
              href={websiteUrl}
              className={styles.link}
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkSvg} alt="Link" /> {companyName}
            </a>
          </div>,
        );
      } else {
        elements.push(<div>{companyName}</div>);
      }
    }

    return elements;
  }, [companyName, websiteUrl]);

  const project = useMemo(() => {
    if (!projectUrl) {
      return null;
    }

    return (
      <div>
        <a
          href={projectUrl}
          className={styles.link}
          target="_blank"
          rel="noreferrer"
        >
          <img src={linkSvg} alt="Link" />
          Project link
        </a>
      </div>
    );
  }, [projectUrl]);

  return (
    <div>
      <div className={styles.logoWrapper}>
        <CompanyLogoFallback size={72} logoUrl={logoUrl} />
      </div>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.header}>
        {company}
        {company.length > 0 && !!industriesString && (
          <img src={dotSvg} alt="Dot" />
        )}
        <div className={styles.industry}>{industriesString}</div>
        {!!industriesString && project !== null && (
          <img src={dotSvg} alt="Dot" />
        )}
        {project}
      </div>
    </div>
  );
};

export default observer(Header);
