import {
  Spacing,
  Colors,
  BorderRadius,
  FontSizes,
  FontWeights,
} from '@ateams/components';
import { FieldCheckbox } from '../general/FieldCheckbox';
import { FieldTextarea } from '../general/FieldTextarea';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
    backgroundColor: Colors.backgroundLight,
    borderRadius: BorderRadius.xLarge,
    padding: Spacing.medium,
  },
  scaleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.small,
  },
  checkbox: {
    marginTop: Spacing.xsmall,
  },
  checkboxText: {
    fontSize: FontSizes.small,
    fontWeight: FontWeights.medium,
    color: Colors.dark,
  },
});

export interface MostProudOfSectionProps {
  mostProudOf: string;
  onMostProudOfChange: (value: string) => void;
  workOnProductThatScaled: boolean;
  onWorkOnProductThatScaledChange: (value: boolean) => void;
  scaleNotes: string;
  onScaleNotesChange: (value: string) => void;
}

export const MostProudOfSection = ({
  mostProudOf,
  onMostProudOfChange,
  workOnProductThatScaled,
  onWorkOnProductThatScaledChange,
  scaleNotes,
  onScaleNotesChange,
}: MostProudOfSectionProps) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <FieldTextarea
        label="What is the builder most proud of?"
        placeholder="E.g., Helped mentor junior employees..."
        value={mostProudOf ?? ''}
        onChange={onMostProudOfChange}
        minLength={40}
      />
      <div className={styles.scaleContainer}>
        <FieldCheckbox
          text="Did this builder work on a product that scaled?"
          textClassName={styles.checkboxText}
          value={workOnProductThatScaled}
          onChange={onWorkOnProductThatScaledChange}
          className={styles.checkbox}
        />
        {workOnProductThatScaled && (
          <FieldTextarea
            placeholder="What is the scale? Ex. “saved $10M a year”"
            value={scaleNotes ?? ''}
            onChange={onScaleNotesChange}
            minLength={20}
          />
        )}
      </div>
    </div>
  );
};
