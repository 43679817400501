import React from 'react';
import { createUseStyles } from 'react-jss';
import { WeeklyHours } from '@a_team/models/dist/vetting-processes/pre-vetting';
import { Spacing } from '@ateams/components';
import { formatMinutesToTime } from '@src/helpers/time';
import { calcTotalMinutes } from '@src/components/TimeField/utils';
import { RightArrow } from '@src/views/VettingFeedbackForm/components/icons/arrows';
import { ValueTag } from '@src/views/VettingFeedbackForm/components/value-tag';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  dayContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Spacing.small,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  rightArrow: {
    flexShrink: 0,
    margin: '0 8px',
  },
  dayHoursContainer: {
    display: 'inline-flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: Spacing.xsmall,
  },
});

function indexToDay(index: number) {
  switch (index) {
    case 0:
      return 'Sunday';
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    default:
      return 'Saturday';
  }
}

export const WeeklyHoursAnswers = (props: {
  weeklyHours: WeeklyHours['hoursRanges'];
}) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      {props.weeklyHours
        .map((dailyHours, i) => ({ dailyHours, day: indexToDay(i) }))
        .filter(({ dailyHours }) => dailyHours && dailyHours.length)
        .map(({ dailyHours, day }) => (
          <div key={day} className={styles.dayContainer}>
            <ValueTag>{day}</ValueTag>

            <RightArrow className={styles.rightArrow} />

            <div className={styles.dayHoursContainer}>
              {dailyHours?.map((hours) => {
                if (hours[0] === null || hours[1] === null) return null;

                const start = formatMinutesToTime(
                  calcTotalMinutes(hours[0].hours, hours[0].minutes),
                );
                const end = formatMinutesToTime(
                  calcTotalMinutes(hours[1].hours, hours[1].minutes),
                );

                return (
                  <ValueTag
                    key={`${start}-${end}`}
                  >{`${start}-${end}`}</ValueTag>
                );
              })}
            </div>
          </div>
        ))}
    </div>
  );
};
