import React, { FC, ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { Card } from '@ateams/components';
import TextButton from '@src/components/TextButton';
import { format } from 'date-fns';
import {
  BasicContractObject,
  ContractId,
  ContractPartyType,
} from '@a_team/models/dist/ContractObject';
import { ContractStatusDisplay } from '@src/views/Mission/Documents/contractsTable/ContractStatusDisplay';
import { useScreenClass } from 'react-grid-system';
import { MobileContractsTable } from '@src/views/Mission/Documents/contractsTable/MobileContractsTable';
import { isFunction } from 'lodash';

export interface Props {
  contracts: BasicContractObject[];
  newContract?: BasicContractObject;
  onContractView(sid: ContractId, signed: boolean): void;
  getContractDeleteCallback(
    contract: BasicContractObject,
  ): CallableFunction | undefined;
}

const useStyles = createUseStyles({
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    border: '1px solid #C0C0C0',
    borderLeft: 'none',
    borderTop: 'none',
    fontSize: 15,
    fontWeight: 300,
    color: '#62646A',
    padding: '21px 24px 22px',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  tr: {
    borderBottom: '1px solid #C0C0C0',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  td: {
    padding: '21px 24px 22px',
    textAlign: 'center',
    borderRight: '1px solid #C0C0C0',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  smallDivider: {
    borderRight: '1px solid #C0C0C0',
    height: '16px',
    margin: '0 6px',
  },
});

const ContractActions: FC<
  Pick<Props, 'getContractDeleteCallback' | 'onContractView'> & {
    contract: BasicContractObject;
  }
> = (props): ReactElement => {
  const { onContractView, getContractDeleteCallback, contract } = props;
  const styles = useStyles();

  const handleContractDelete = getContractDeleteCallback(contract);

  return (
    <>
      <TextButton
        highlight
        onClick={() =>
          onContractView(
            contract.sid,
            !!contract.parties.find(
              ({ type, signedAt }) =>
                contract.custom ||
                (type === ContractPartyType.MissionRole && !!signedAt),
            ),
          )
        }
        disabled={!contract.downloadURL}
      >
        View
      </TextButton>

      {isFunction(handleContractDelete) && (
        <>
          <span className={styles.smallDivider} />
          <TextButton highlight onClick={handleContractDelete}>
            Remove
          </TextButton>
        </>
      )}
    </>
  );
};

const ContractsTable = (props: Props): ReactElement => {
  const { contracts, newContract } = props;
  const styles = useStyles();

  const screenClass = useScreenClass();

  if (screenClass === 'xs') {
    return <MobileContractsTable {...props} />;
  }

  return (
    <Card style={{ padding: 0 }}>
      <table className={styles.table}>
        <tbody>
          <tr>
            <th className={styles.th}>Date</th>
            <th className={styles.th}>Builder Name</th>
            <th className={styles.th}>Builder Status</th>
            <th className={styles.th}>Client Status</th>
            <th className={styles.th}>Actions</th>
          </tr>
          {newContract && (
            <tr
              style={{ backgroundColor: '#FBFFF7' }}
              className={styles.tr}
              key={`${newContract.sid}-${newContract.createdAt}`}
            >
              <td className={styles.td}>
                {format(new Date(newContract.createdAt), 'PP')}
              </td>
              <td className={styles.td}>
                {
                  newContract.parties.find(
                    (party) => party.type === ContractPartyType.MissionRole,
                  )?.user?.fullName
                }
              </td>
              <td className={styles.td}>
                <ContractStatusDisplay
                  custom={newContract.custom}
                  party={newContract.parties.find(
                    ({ type }) => type === ContractPartyType.MissionRole,
                  )}
                />
              </td>
              <td className={styles.td}>
                <ContractStatusDisplay
                  custom={newContract.custom}
                  party={newContract.parties.find(
                    ({ type }) => type === ContractPartyType.BillingCustomer,
                  )}
                />
              </td>
              <td className={styles.td}>
                <ContractActions contract={newContract} {...props} />
              </td>
            </tr>
          )}
          {contracts.map((contract) => (
            <tr
              className={styles.tr}
              key={`${contract.sid}-${contract.createdAt}`}
            >
              <td className={styles.td}>
                {format(new Date(contract.createdAt), 'PP')}
              </td>
              <td className={styles.td}>
                {
                  contract.parties.find(
                    (party) => party.type === ContractPartyType.MissionRole,
                  )?.user?.fullName
                }
              </td>
              <td className={styles.td}>
                <ContractStatusDisplay
                  custom={contract.custom}
                  party={contract.parties.find(
                    ({ type }) => type === ContractPartyType.MissionRole,
                  )}
                />
              </td>
              <td className={styles.td}>
                <ContractStatusDisplay
                  custom={contract.custom}
                  party={contract.parties.find(
                    ({ type }) => type === ContractPartyType.BillingCustomer,
                  )}
                />
              </td>
              <td className={styles.td}>
                <ContractActions {...props} contract={contract} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
};

export default ContractsTable;
