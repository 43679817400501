import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { uniqBy } from 'lodash';
import { observer } from 'mobx-react';
import SectionHeading from '@src/components/SectionHeading';
import InvoicesTable, {
  currencies,
  PaymentCycleInvoice,
} from '@src/views/Mission/Documents/InvoicesTable';
import MissionLayout from '@src/layouts/Mission';
import { loadMission, MissionMatch } from '@src/url-loaders/loadMission';
import { useStores } from '@src/stores';
import { Redirect } from 'react-router-dom';
import { MissionPageLocation, MissionControlBase } from '@src/locations';
import { LoadMoreLink } from '@ateams/components';
import PaymentDetailsModal from '@src/components/Modal/PaymentDetailsModal';
import {
  BasicInvoiceObject,
  InvoiceType,
} from '@a_team/models/dist/InvoiceObject';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { MemberContractModal } from '@src/components/Modal/ContractModal/MemberContractModal';
import ContractObject, {
  BasicContractObject,
  ContractId,
  ContractPartyType,
  ContractStatus,
  ContractType,
} from '@a_team/models/dist/ContractObject';
import useToggle from '@src/hooks/useToggle';
import { ClientContractModal } from '@src/components/Modal/ContractModal/ClientContractModal';
import ContractsTable from '@src/views/Mission/Documents/contractsTable';
import TagsFilter from '@src/components/Filters/TagsFilter';
import { SignedContractModal } from '@src/components/Modal/ContractModal/SignedContractModal';
import { PendingContractsCard } from './PendingContractsCard';
import { InvoiceMarkPaidModal } from '@src/components/Modal/InvoiceMarkPaidModal';
import { MarkAsPaidData } from '@ateams/api/dist/endpoints/Invoices';
import { UpdateAvailabilityModal } from '@src/components/Modal/UpdateAvailabilityModal';
import { AvailabilityData } from '@a_team/models/dist/AvailabilityObject';
import { StillAvailableModal } from '@src/components/Modal/StillAvailableModal';
import { MissionApplicationId } from '@a_team/models/src/MissionApplicationObject';
import { InvoiceCancelModal } from '@src/components/Modal/InvoiceCancelModal';
import { PaymentCycleReopenRequest } from '@ateams/api/dist/endpoints/Missions';
import BuilderContractCards from './builderContractCards';
import { UserId } from '@a_team/models/dist/UserObject';
import { MissionRoleId } from '@a_team/models/dist/MissionRole';
import { ContractCard } from './ContractCard';
import { createUseStyles } from 'react-jss';
import ContractUploader from './ContractUploader';
import { Colors } from '@ateams/components';
import ConfirmModal from '@src/components/Modal/ConfirmModal';
import { format } from 'date-fns';
import { useAnalytics, UserEvent } from '@ateams/analytics/dist/platform';
import { PaymentMethodBanner } from './PaymentMethodBanner';

interface Props {
  match: MissionMatch;
  adminView?: boolean;
}

export const missionDocumentsViewLoader = loadMission;

const useStyles = createUseStyles({
  sectionHeading: {
    fontSize: 20,
    marginBottom: 9,
  },
  inlineCta: {
    marginLeft: 8,
    color: Colors.primary,
    fontWeight: 300,
    display: 'inline',
    fontSize: 15,
    lineHeight: '24px',

    '& div': {
      display: 'inline',
    },
  },
  cardsWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: 6,
    marginBottom: 22,
  },
  inlineFilter: {
    marginLeft: 32,
    display: 'inline-flex',
    alignItems: 'flex-start',
    fontWeight: 'normal',
    fontSize: '1rem',
    marginBottom: -10,
  },
  invoicesTable: {
    marginTop: 20,
  },
});

const MissionDocumentsView = (props: Props): ReactElement => {
  const { match, adminView } = props;
  const styles = useStyles();

  const stores = useStores();
  const [setupModalOpen, setSetupModalOpen] = useState(false);
  const [payInvoice, setPayInvoice] = useState<BasicInvoiceObject | null>(null);
  const [cancelInvoice, setCancelInvoice] =
    useState<PaymentCycleInvoice | null>(null);
  const [currentContract, setCurrentContract] = useState<ContractObject>();
  const [clientContracts, setClientContracts] = useState<ContractObject[]>();
  const [contractModalOpen, toggleContractModal] = useToggle(false);
  const [updateAvailabilityModalOpen, toggleUpdateAvailablityModal] =
    useToggle(false);
  const [stillAvailableModalOpen, toggleStillAvailableModal] = useToggle(false);
  const [signedContractModalOpen, toggleSignedContractModal] = useToggle(false);
  const [approvedIds, setApprovedIds] = useState<ContractId[]>([]);
  const [filterInvoiceTypes, setFilterInvoiceTypes] = useState<string[]>([]);
  const [loading, setLoading] = useLoadingState();
  const [deleteModal, toggleDelete] = useToggle();
  const [deleteContract, setDeleteContract] = useState<BasicContractObject>();
  const [newContract, setNewContract] = useState<
    BasicContractObject | undefined
  >();
  const analytics = useAnalytics();

  const { missions, auth, missionControl, payments } = stores;
  const { currentMission } = missions;

  const eventProps = {
    uid: auth.uid ?? undefined,
    mid: currentMission?.mid ?? undefined,
  };

  useEffect(() => {
    if (!currentMission) return;
    currentMission?.getInvoices(adminView);
  }, [currentMission?.mid]);

  useEffect(() => {
    if (!newContract || !currentMission) return;

    setTimeout(() => {
      const newContracts = [
        newContract as ContractObject,
        ...currentMission.contracts,
      ];
      currentMission.setContracts(newContracts);
      setNewContract(undefined);
    }, 1500);
  }, [newContract]);

  useEffect(() => {
    const ids: ContractId[] = [];
    clientContracts?.forEach(
      (contract) => contract.signedAt && ids.push(contract.sid),
    );
    setApprovedIds(ids);
  }, [clientContracts]);

  useEffect(() => {
    if (!currentContract) return;

    const { type, custom } = currentContract;

    analytics.track(UserEvent.ContractViewed, {
      type,
      custom: custom || false,
      ...eventProps,
    });
  }, [currentContract]);

  const invoiceTypes = useMemo(
    () =>
      uniqBy(
        (currentMission?.invoices || []).map((item) => ({
          id: item.type,
          label:
            item.type === InvoiceType.MissionRolePlatformFee
              ? 'Fees'
              : 'Payments',
        })),
        'id',
      ),
    [currentMission?.invoices],
  );

  const deleteContractConfirmationDescription = useMemo<string>(() => {
    if (!deleteContract) return '';

    const { type, createdAt } = deleteContract;

    const date = format(new Date(createdAt), 'PP');
    const chunks = ['Proceeding will hide this'];

    if (type === ContractType.MissionAgreement) {
      const party = deleteContract.parties.find(
        ({ type }) => type === ContractPartyType.MissionRole,
      );

      chunks.push(`agreement with ${party?.user?.fullName}`);
    } else {
      chunks.push(`Platform Agreement`);
    }

    chunks.push(`from ${date}.`);

    return chunks.join(' ');
  }, [deleteContract]);

  let notAllowed =
    (props.adminView && !auth.isAdmin) ||
    (!currentMission?.currentUserRole && !currentMission?.isMissionManager);

  auth.isAdmin && (notAllowed = false);

  const setContract = (sid: ContractId, signed?: boolean) => {
    if (!currentMission) return;

    auth.isAdmin || signed
      ? toggleSignedContractModal(true)
      : toggleContractModal(true);
    setLoading(
      currentMission
        .getSingleContract(sid)
        .then((res) => setCurrentContract(res)),
      null,
    );
  };

  const setContracts = async () => {
    if (!currentMission) return;
    toggleContractModal(true);
    setLoading(
      getContracts().then((contracts) => {
        setClientContracts(contracts);
      }),
      null,
    );
  };

  const getContracts = async () => {
    if (!currentMission) return;
    return await Promise.all(
      currentMission.serviceAgreementContracts.map((contract) =>
        currentMission.getSingleContract(contract.sid),
      ),
    );
  };

  const handlePayInvoice = (data: MarkAsPaidData): void => {
    if (!payInvoice || !currentMission) return;

    setPayInvoice(null);
    setLoading(
      currentMission.markInvoiceAsPaid(payInvoice.iid, data),
      'Success!',
    );
  };

  const handleCancelInvoice = (data: PaymentCycleReopenRequest): void => {
    if (!cancelInvoice || !currentMission) return;

    setCancelInvoice(null);
    setLoading(
      currentMission.reopenPaymentCycle(cancelInvoice.paymentCycle, data),
      'Success!',
    );
  };

  const handleUploadContract = async (
    ...args: [uid: UserId, rid: MissionRoleId, downloadURL: string]
  ) => {
    if (!currentMission) return;

    const contract = await currentMission.uploadCustomMissionAgreement(...args);
    setNewContract(contract);
  };

  const handleLoadMoreInvoices = (): void => {
    if (!currentMission) return;

    setLoading(currentMission.getInvoices(adminView), null);
  };

  const onContractAgree = (ids: ContractId[]) => {
    if (!currentMission) return;
    setLoading(
      (async () => {
        await currentMission?.signContracts(ids);
        toggleContractModal();

        if (!currentMission.isMissionManager) {
          toggleUpdateAvailablityModal();
        }
      })(),
      null,
    );
  };

  const handleContractDelete = (sid: ContractId) => {
    if (currentMission) {
      setLoading(currentMission?.deleteCustomContract(sid), null);
      toggleDelete();

      if (deleteContract) {
        const { type } = deleteContract;

        analytics.track(UserEvent.ContractDeleted, {
          ...eventProps,
          type,
          mid: currentMission.mid,
        });

        setDeleteContract(undefined);
      }
    }
  };

  const contractDeleteCallback = (contract: BasicContractObject) => {
    setDeleteContract(contract);
    toggleDelete();
  };

  const getContractDeleteCallback = (
    contract: BasicContractObject,
  ): undefined | CallableFunction => {
    if (auth.isAdmin && contract.custom) {
      return () => contractDeleteCallback(contract);
    }
    return;
  };

  const onUpdateAvailability = (availabilityData: AvailabilityData) => {
    setLoading(
      (async () => {
        await auth.updateAvailability(availabilityData);
        toggleUpdateAvailablityModal();

        await missionControl.loadPendingMissionApplications();

        if (missionControl.pending?.items.length) {
          toggleStillAvailableModal();
        }
      })(),
      null,
    );
  };

  const onSetMissionApplicationsUnavailable = (
    removedApplications: MissionApplicationId[],
  ) => {
    setLoading(
      (async () => {
        if (removedApplications.length) {
          await missionControl.setMissionApplicationsUnavailable({
            applicationIds: removedApplications,
          });
        }

        toggleStillAvailableModal();
      })(),
      null,
    );
  };

  const filteredUnsignedClientContracts = useMemo(() => {
    return clientContracts?.filter(
      (contract) =>
        !contract.signedAt && contract.status !== ContractStatus.Completed,
    );
  }, [clientContracts]);

  const handleUploadCustomAgreement = async (file: string) => {
    if (file && currentMission) {
      const contract = await currentMission.uploadCustomPlatformAgreement(file);

      if (contract) {
        const contracts = [contract, ...currentMission.contracts];
        currentMission.setContracts(contracts);
      }
    }
  };

  if (notAllowed) {
    return (
      <Redirect
        to={
          match.params.mid
            ? MissionPageLocation(match.params.mid)
            : MissionControlBase
        }
      />
    );
  }

  return (
    <MissionLayout
      match={match}
      title={`${currentMission?.data.title || 'Loading...'} | Documents`}
    >
      <>
        <SignedContractModal
          open={signedContractModalOpen}
          onClose={toggleSignedContractModal}
          contract={currentContract}
          roles={currentMission?.data.roles}
          isMissionManager={currentMission?.isMissionManager}
          isAdmin={auth.isAdmin}
        />
        {currentMission?.isMissionManager ? (
          <ClientContractModal
            contracts={filteredUnsignedClientContracts}
            roles={currentMission.data.roles}
            signedContracts={approvedIds}
            onAccept={onContractAgree}
            open={contractModalOpen}
            onClose={() => toggleContractModal(false)}
          />
        ) : (
          <>
            <MemberContractModal
              isAdmin={auth.isAdmin}
              contract={currentContract}
              open={contractModalOpen}
              onClose={() => toggleContractModal(false)}
              onAccept={onContractAgree}
              roles={currentMission?.data.roles}
            />
            <UpdateAvailabilityModal
              currentAvailability={auth.user?.availability}
              open={updateAvailabilityModalOpen}
              onClose={() => toggleUpdateAvailablityModal(false)}
              onSubmit={onUpdateAvailability}
            />
            <StillAvailableModal
              open={stillAvailableModalOpen}
              onClose={() => toggleStillAvailableModal(false)}
              missionApplications={missionControl.pending}
              loadMore={missionControl.loadPendingMissionApplications}
              onSubmit={onSetMissionApplicationsUnavailable}
            />
          </>
        )}
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {currentMission?.showClientContractCard && (
            <PendingContractsCard
              pendingContracts={currentMission.pendingContracts.length}
              onCardClick={setContracts}
            />
          )}
        </div>

        {!props.adminView && payments.setupDetails && (
          <>
            <PaymentDetailsModal
              iframeUrl={payments.setupDetails.setupIFrameURL}
              open={setupModalOpen}
              onClose={() => setSetupModalOpen(false)}
            />
            <PaymentMethodBanner onOpen={() => setSetupModalOpen(true)} />
          </>
        )}

        {currentMission?.showCustomContractCards ? (
          <>
            <SectionHeading className={styles.sectionHeading} isFirst>
              Platform Agreement
              <div className={styles.inlineCta}>
                <ContractUploader onUploaded={handleUploadCustomAgreement}>
                  <span>Upload</span>
                </ContractUploader>
              </div>
            </SectionHeading>
            <div className={styles.cardsWrap}>
              {currentMission.customPlatformAgreements.length > 0 ? (
                currentMission.customPlatformAgreements.map((contract) => (
                  <ContractCard
                    key={contract.sid}
                    onDeleteClick={() => contractDeleteCallback(contract)}
                    onCardClick={(sid) => setContract(sid, true)}
                    contract={contract}
                  />
                ))
              ) : (
                <p style={{ margin: '0 0 41px' }}>No service agreements yet.</p>
              )}
            </div>

            <SectionHeading isFirst className={styles.sectionHeading}>
              Builder Agreements
            </SectionHeading>
            <BuilderContractCards
              canUpload={!!currentMission.accountId}
              onCreateContract={handleUploadContract}
              mission={currentMission?.data}
            />
          </>
        ) : (
          <SectionHeading className={styles.sectionHeading} isFirst>
            Agreements
          </SectionHeading>
        )}
        {currentMission?.builderContracts.length === 0 ? (
          'No contracts yet'
        ) : (
          <>
            <ContractsTable
              getContractDeleteCallback={getContractDeleteCallback}
              newContract={newContract}
              contracts={currentMission?.builderContracts || []}
              onContractView={setContract}
            />
            <ConfirmModal
              actionLabel="Delete"
              title="Hide Agreement?"
              description={deleteContractConfirmationDescription}
              onConfirm={() =>
                deleteContract && handleContractDelete(deleteContract.sid)
              }
              open={deleteModal}
              onClose={toggleDelete}
            />
          </>
        )}
      </>
      <SectionHeading className={styles.sectionHeading}>
        Invoices
        {!props.adminView && !!currentMission?.invoices.length && (
          <div className={styles.inlineFilter}>
            <TagsFilter
              label="Filter by"
              inline
              items={invoiceTypes}
              selected={filterInvoiceTypes}
              onChange={setFilterInvoiceTypes}
            />
          </div>
        )}
      </SectionHeading>

      {payInvoice && (
        <InvoiceMarkPaidModal
          createdAt={payInvoice.createdAt}
          currency={currencies[payInvoice.currency]}
          totalAmount={payInvoice.totalAmount}
          onConfirm={handlePayInvoice}
          onClose={() => setPayInvoice(null)}
          open
        />
      )}
      {cancelInvoice && (
        <InvoiceCancelModal
          createdAt={cancelInvoice.createdAt}
          currency={currencies[cancelInvoice.currency]}
          totalAmount={cancelInvoice.totalAmount}
          onConfirm={handleCancelInvoice}
          onClose={() => setCancelInvoice(null)}
          open
        />
      )}
      <LoadingIndicator loading={loading} />

      {currentMission?.invoices && currentMission.invoices.length > 0 ? (
        <>
          <InvoicesTable
            data-testing-id="invoices-table"
            className={styles.invoicesTable}
            invoices={currentMission.invoices.filter(
              (item) =>
                !filterInvoiceTypes.length ||
                filterInvoiceTypes.includes(item.type),
            )}
            paymentCycles={currentMission.paymentCycles}
            onMarkInvoiceAsPaid={
              props.adminView && loading !== true ? setPayInvoice : undefined
            }
            onCancelInvoice={
              props.adminView && loading !== true ? setCancelInvoice : undefined
            }
          />

          {currentMission?.nextTokens.invoices && (
            <LoadMoreLink
              onLoadMore={() => handleLoadMoreInvoices()}
              buttonText={'Load more Invoices'}
              withIcon
            />
          )}
        </>
      ) : (
        <p>No invoices yet</p>
      )}
    </MissionLayout>
  );
};

export default observer(MissionDocumentsView);
