import React from 'react';
import { Project } from '.';
import { createUseStyles } from 'react-jss';
import {
  BorderColors,
  BorderRadius,
  Colors,
  FontSizes,
  FontWeights,
  Spacing,
  Tag,
  TagList,
} from '@ateams/components';
import { Link } from '../general/Link';
import { FieldTextarea } from '../general/FieldTextarea';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.backgroundLight,
    borderRadius: BorderRadius.xLarge,
    paddingBottom: Spacing.large,
  },
  internalContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.small,
    border: `1px solid ${BorderColors.light}`,
    borderRadius: BorderRadius.xLarge,
    backgroundColor: Colors.backgroundWhite,
    padding: Spacing.large,
  },
  nameAndJobRoleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.xxsmall,
  },
  name: {
    fontSize: FontSizes.regular,
    fontWeight: FontWeights.medium,
    color: Colors.dark,
  },
  jobRole: {
    fontSize: FontSizes.small,
    fontWeight: FontWeights.regular,
    color: Colors.dark,
  },
  companyContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.xsmall,
    alignItems: 'center',
  },
  companyLogo: {
    width: Spacing.large,
    height: Spacing.large,
    borderRadius: BorderRadius.small,
  },
  company: {
    fontSize: FontSizes.small,
    fontWeight: FontWeights.regular,
    color: Colors.dark,
  },
  separator: {
    margin: `${Spacing.xsmall}px 0`,
  },
  skillsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.xxsmall,
  },
  skillsTitle: {
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.medium,
    color: Colors.dark,
  },
  skills: {
    flexWrap: 'wrap',
    columnGap: Spacing.xsmall,
    alignItems: 'center',
  },
  skill: {
    padding: Spacing.small,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: BorderRadius.medium,
  },
  skillText: {
    fontSize: FontSizes.small,
    color: Colors.dark,
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    gap: Spacing.xsmall,
  },
  impressionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.large,
    padding: Spacing.large,
  },
});

export interface ProjectCardProps {
  project: Project;
  onImpressionsChange: (projectId: string, impressions: string) => void;
}

export const ProjectCard = ({
  project,
  onImpressionsChange,
}: ProjectCardProps) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div className={styles.internalContainer}>
        <div className={styles.nameAndJobRoleContainer}>
          <div className={styles.name}>{project.name}</div>
          <div className={styles.jobRole}>{project.jobRole}</div>
        </div>
        <div className={styles.companyContainer}>
          <img
            src={project.companyLogoUrl}
            alt={project.company}
            className={styles.companyLogo}
          />
          <div className={styles.company}>{project.company}</div>
          <div>·</div>
          <Link href={project.projectUrl} text="Project Link" showIcon />
        </div>
        <hr className={styles.separator} />
        <div className={styles.skillsContainer}>
          <div className={styles.skillsTitle}>Linked evaluation skills</div>
          <TagList className={styles.skills}>
            {project.skills.map((skill) => (
              <Tag className={styles.skill} key={skill.name}>
                <div className={styles.skillText}>
                  <div>{skill.name}</div>
                </div>
              </Tag>
            ))}
          </TagList>
        </div>
      </div>
      <div className={styles.impressionsContainer}>
        <FieldTextarea
          label="Add your impressions"
          placeholder="Add additional context to the project"
          value={project.impressions ?? ''}
          onChange={(value) => onImpressionsChange(project.id, value)}
          minLength={40}
        />
      </div>
    </div>
  );
};
