import { Select, SelectOption, SelectProps } from '@ateams/components';
import * as googleMaps from '@googlemaps/google-maps-services-js';
import useDebounceState from '@src/hooks/useDebounceState';
import { useGetPlaceById, useGetPlacesSuggestions } from '@src/rq/places';
import React, { CSSProperties, ReactElement, useMemo, useState } from 'react';
import { Theme } from 'react-select';
import { customStyles, selectThemeColors, selectThemeStyles } from './styles';

const DEBOUNCE_TIME = 200; // milliseconds

export interface LocationSelectOption {
  option: SelectOption;
  prediction?: googleMaps.PlaceAutocompleteResult;
  countryShortName?: string;
}

export type OnLocationChange = (
  option: null | LocationSelectOption | LocationSelectOption[],
) => void;

export interface LocationAutocompleteV2Props
  extends Omit<SelectProps, 'onChange'> {
  onChange?: OnLocationChange;
  isOutlined?: boolean;
}

const DropdownIndicator = (): ReactElement => {
  return <span />;
};

const IndicatorSeparator = (): ReactElement => {
  return <span />;
};

const dashedStyles = {
  ...customStyles,
  control: (provided: CSSProperties): CSSProperties => ({
    ...provided,
    border: 'none',
    boxShadow: 'none', // Removes the border when selected
    borderBottom: '1px dashed #C0C0C0',
  }),
  valueContainer: (provided: CSSProperties): CSSProperties => ({
    ...provided,
    minHeight: 42,
    padding: 0,
  }),
};

export const LocationAutocompleteV2 = ({
  onChange,
  isOutlined,
  error,
  ...rest
}: LocationAutocompleteV2Props): ReactElement => {
  const [, triggerSearch] = useDebounceState<string>(
    '',
    (value) => setSearchTerm(value),
    DEBOUNCE_TIME,
  );

  const [searchTerm, setSearchTerm] = useState('');

  const { data: placePredictions, isLoading } = useGetPlacesSuggestions({
    searchTerm,
  });

  const getPlaceById = useGetPlaceById();

  const selectOptions = useMemo(
    () =>
      placePredictions?.map((prediction) => ({
        value: prediction.place_id,
        label: prediction.description,
      })) ?? [],
    [placePredictions],
  );

  const getOptionDetails = async (
    option: SelectOption,
  ): Promise<LocationSelectOption> => {
    if (option.shortName) {
      return { option, countryShortName: option.shortName };
    }

    const placeDetails = await fetchPlaceDetails(option.value);
    return {
      option,
      prediction: placeDetails.prediction,
      countryShortName: placeDetails.countryShortName,
    };
  };

  const fetchPlaceDetails = async (
    placeId: string,
  ): Promise<{
    prediction?: googleMaps.PlaceAutocompleteResult;
    countryShortName: string;
  }> => {
    const prediction = placePredictions?.find(
      (place) => place.place_id === placeId,
    );
    const countryShortName = await fetchCountryShortName(placeId);
    return { prediction, countryShortName };
  };

  const fetchCountryShortName = async (placeId: string): Promise<string> => {
    const placeDetails = await getPlaceById(placeId);
    if (!placeDetails || !placeDetails.address_components) return '';

    const countryComponent = placeDetails.address_components.find((component) =>
      component.types.includes(googleMaps.AddressType.country),
    );
    return countryComponent ? countryComponent.short_name : '';
  };

  const handleSelectChange = async (
    selectedItem: SelectOption[] | SelectOption | null,
  ) => {
    if (!onChange) return;
    if (!selectedItem) {
      onChange(null);
      return;
    }

    if (Array.isArray(selectedItem)) {
      const results = await Promise.all(selectedItem.map(getOptionDetails));
      onChange(results);
    } else {
      const result = await getOptionDetails(selectedItem);
      onChange(result);
    }
  };

  return (
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    <Select
      cacheOptions={false}
      options={selectOptions}
      onInputChange={(value) => triggerSearch(value)}
      isLoading={isLoading}
      onChange={handleSelectChange}
      components={{ DropdownIndicator, IndicatorSeparator }}
      theme={(theme: Theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          ...selectThemeColors(error),
        },
        ...selectThemeStyles,
      })}
      placeholder={'Search location...'}
      {...(isOutlined ? {} : { styles: dashedStyles })}
      {...rest}
    />
  );
};
