import { Avatar } from '@a_team/ui-components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { BuilderSectionHorizontalLinks } from './BuilderSectionHorizontalLinks';
import { BuilderSectionVerticalLinks } from './BuilderSectionVerticalLinks';
import FileUploadSection from './FileUploadSection';
import {
  BorderColors,
  BorderRadius,
  Colors,
  FontSizes,
  FontWeights,
  Spacing,
} from '@ateams/components';
import { BuilderEvaluationInfo } from './BuilderEvaluationInfo';

const useStyles = createUseStyles({
  builderSection: {
    padding: Spacing.large,
    border: 'none',
    borderRadius: BorderRadius.xLarge,
    background: `linear-gradient(${Colors.backgroundLight}, ${Colors.backgroundWhite})`,
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
  },
  builderSectionHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.medium,
  },
  builderSectionHeader: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.xsmall,
    width: '100%',
  },
  builderSectionAvatar: {
    width: '72px !important',
    height: '72px !important',
    borderRadius: BorderRadius.xLarge,
    flexShrink: 0,
  },
  builderSectionFullName: {
    fontSize: FontSizes.large,
    fontWeight: FontWeights.semiBold,
    lineHeight: '26px',
    textAlign: 'left',
    color: Colors.dark,
  },
  builderSectionEmailAndLocation: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.small,
    alignItems: 'center',
    fontSize: FontSizes.xsmall,
    color: Colors.dark,
  },
  builderSectionSubtitleItem: {
    fontSize: FontSizes.small,
    color: Colors.dark,
  },
  builderSectionVerticalLinksContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: Spacing.medium,
    paddingBottom: Spacing.medium,
    borderBottom: `1px solid ${BorderColors.lighter}`,
  },
});

export interface BuilderData {
  name: string;
  email: string;
  location: string;
  avatarUrl: string;
  linkedinUrl: string;
  resumeUrl: string;
  portfolioUrl: string;
  portfolioPassword?: string;
  evaluationCallLinks: {
    label: string;
    href: string;
  }[];
  missionsBuilderAppliedTo: {
    label: string;
    href: string;
  }[];
  uploadedMaterials: {
    label: string;
    href: string;
  }[];
}

export interface BuilderSectionProps {
  builderData: BuilderData;
  uploadedFiles: File[];
  setUploadedFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

export const BuilderSection = ({
  builderData,
  uploadedFiles,
  setUploadedFiles,
}: BuilderSectionProps) => {
  const styles = useStyles();

  return (
    <div className={styles.builderSection}>
      <div className={styles.builderSectionHeaderContainer}>
        <Avatar
          className={styles.builderSectionAvatar}
          src={builderData.avatarUrl}
        />
        <div className={styles.builderSectionHeader}>
          <div className={styles.builderSectionFullName}>
            {builderData.name}
          </div>
          <div className={styles.builderSectionEmailAndLocation}>
            <div className={styles.builderSectionSubtitleItem}>
              {builderData.email}
            </div>
            <div className={styles.builderSectionSubtitleItem}>·</div>
            <div className={styles.builderSectionSubtitleItem}>
              {builderData.location}
            </div>
          </div>
          <BuilderSectionHorizontalLinks
            linkedinUrl={builderData.linkedinUrl}
            resumeUrl={builderData.resumeUrl}
            portfolioUrl={builderData.portfolioUrl}
            portfolioPassword={builderData.portfolioPassword}
          />
        </div>
      </div>
      <div className={styles.builderSectionVerticalLinksContainer}>
        <BuilderSectionVerticalLinks
          title="Evaluation call links"
          maxRows={2}
          links={builderData.evaluationCallLinks}
        />
        <BuilderSectionVerticalLinks
          title="Missions builder applied to"
          maxRows={2}
          links={builderData.missionsBuilderAppliedTo}
        />
        <BuilderSectionVerticalLinks
          title="Builder uploaded materials"
          maxRows={2}
          links={builderData.uploadedMaterials}
        />
      </div>
      <FileUploadSection
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
      />
      <BuilderEvaluationInfo
        evaluationType="Selection team"
        roleCategory="Developer"
        admin="Tanya H."
        interviewer="Linas B."
      />
    </div>
  );
};
