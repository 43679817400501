import React, { ReactElement, useEffect, useState } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { Button, Icon, Modal } from '@a_team/ui-components';
import {
  Company,
  CompanyStatus,
  EnrichableCompany,
} from '@a_team/models/dist/Company';
import { CompanyV2Picker } from '.';
import { NewCompanyId } from '../CompanyPicker';
import { Colors } from '@ateams/components';
import CompanyAvatar from '../CompanyAvatar/CompanyAvatar';
import TextButton from '../TextButton';
import { isValidUrl } from '@src/helpers/urls';
import { useMutationCreateCompany } from '@src/rq/companiesV2Search';

const useStyles = createUseStyles({
  container: {
    padding: 24,
  },
  header: {
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 24,
    marginTop: 0,
  },
  inputContainer: {
    marginTop: 8,
    '& span[class^="companyAvatarsContainer"]': {
      display: 'none',
    },
  },
  label: {
    fontSize: 15,
    fontWeight: 500,
    marginBottom: 12,
  },
  input: {
    zIndex: 10,
    '&:focus': {
      borderColor: '#DADADC !important',
      backgroundColor: '#FFF !important',
    },
  },
  suggestionSpacing: {
    position: 'relative',
    minHeight: 48,
    padding: 8,
    paddingTop: 18,
    top: -10,
  },
  suggestion: {
    borderRadius: 8,
    background: Colors.backgroundLight,
    display: 'flex',
    justifyContent: 'space-between',
  },
  nameSuggestion: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: 8,
  },
  textButton: {
    fontWeight: 500,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const unverifiedCompany: Pick<
  Company,
  'id' | 'logoUrl' | 'status' | 'talentIndustryIds'
> = {
  id: NewCompanyId,
  logoUrl: undefined,
  status: CompanyStatus.Unverified,
  talentIndustryIds: [],
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  companyName: string;
  onSelectNewCompany: (company: Company & { url?: string }) => void;
}

const enrichableCompanyToCompanyWithName = (
  name: string,
  data: EnrichableCompany,
): Company & { url: string } => {
  return {
    name: name,
    id: data.cid,
    url: data.url,
    status: data.verified ? CompanyStatus.Verified : CompanyStatus.Unverified,
    logoUrl: data.logoUrl,
    talentIndustryIds: data.industries ?? [],
  };
};

export const AddCompanyModal = ({
  isOpen,
  onClose,
  onSelectNewCompany,
  companyName: initialCompanyName,
}: Props): ReactElement => {
  const styles = useStyles();
  const [requestUrl, setRequestUrl] = useState(false);
  const [blurredName, setBlurredName] = useState(false);
  const [suggestedName, setSuggestedName] = useState('');
  const [urlError, setUrlError] = useState('');

  // ensure refresh when setting search term from parent component
  const [nameInputKey, setNameInputKey] = useState(Date.now());
  const [urlInputKey, setUrlInputKey] = useState(Date.now());

  const [companyNameSearchTerm, setCompanyNameSearchTerm] = useState('');
  const [companyUrlSearchTerm, setCompanyUrlSearchTerm] = useState('');
  const [selectedCompany, setSelectedCompany] = useState<
    Company & { url?: string }
  >({ ...unverifiedCompany, name: initialCompanyName });

  const { mutate: createCompany, isLoading } = useMutationCreateCompany({
    onSuccess: (data) => {
      const company = enrichableCompanyToCompanyWithName(
        selectedCompany.name,
        data,
      );
      onSelectNewCompany(company);
    },
    onError: () => onSelectNewCompany(selectedCompany),
  });

  const updateCompanyName = (name: string) => {
    setSelectedCompany({ ...selectedCompany, name });
    setSuggestedName('');
  };

  const updateCompanyUrl = (url: string | undefined) => {
    setSelectedCompany({ ...selectedCompany, ...unverifiedCompany, url });
    setSuggestedName('');
    setRequestUrl(!url);
    setUrlError('');
  };

  const setCompanyFromName = ([company]: Array<Company & { url?: string }>) => {
    if (!company) return;

    setSelectedCompany(company);
    setSuggestedName('');
    setCompanyNameSearchTerm(company?.name || '');

    if (company?.url) {
      setCompanyUrlSearchTerm(company?.url);
      setUrlInputKey(Date.now());
    }
  };

  const setCompanyFromUrlAndSuggestName = ([company]: Array<
    Company & { url?: string }
  >) => {
    if (!company) return;

    setSelectedCompany({ ...company, name: selectedCompany.name || '' });
    setCompanyUrlSearchTerm(company.url || '');

    if (selectedCompany.name !== company.name) {
      setSuggestedName(company.name || '');
    }
  };

  const acceptNameSuggestion = (name: string) => {
    setNameInputKey(Date.now());
    setCompanyFromName([{ ...selectedCompany, name: suggestedName }]);
  };

  useEffect(() => {
    updateCompanyName(initialCompanyName);
    setCompanyNameSearchTerm(initialCompanyName);
    setCompanyUrlSearchTerm('');
    setSuggestedName('');

    setNameInputKey(Date.now());
    setUrlInputKey(Date.now());
  }, [initialCompanyName]);

  useEffect(() => {
    if (blurredName) {
      setBlurredName(false);
      setRequestUrl(!!selectedCompany.name && !selectedCompany.url);
    }
  }, [blurredName]);

  const onSave = () => {
    if (selectedCompany.url && !isValidUrl(selectedCompany.url)) {
      setUrlError('Please provide a valid link.');
      return;
    }

    createCompany(selectedCompany as Company & { url: string });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      canClose={false}
      shouldHideGradientStroke
      innerContentStyle={{ overflow: 'visible' }}
    >
      <div className={styles.container}>
        <h2 className={styles.header}>Add a new company</h2>
        <div className={styles.inputContainer}>
          <label className={styles.label}>Company name</label>
          <CompanyV2Picker
            key={`${companyNameSearchTerm}-${nameInputKey}`}
            onSelectCompanies={setCompanyFromName}
            className={styles.input}
            placeholder={'Enter name'}
            onlySearchVerified
            suppressIcons
            autoFocus
            required
            onChangeProps={{
              initialSearchTerm: companyNameSearchTerm,
              onInputChange: updateCompanyName,
              onBlur: () => setBlurredName(true),
            }}
          />
          {suggestedName ? (
            <div className={cx(styles.suggestion, styles.suggestionSpacing)}>
              <div className={styles.nameSuggestion}>
                <CompanyAvatar
                  src={selectedCompany.logoUrl}
                  size={24}
                  className={styles.avatar}
                />
                <span>{suggestedName}</span>
              </div>
              <TextButton
                className={styles.textButton}
                onClick={() => acceptNameSuggestion(suggestedName)}
                color="secondaryDark"
              >
                Use existing company
              </TextButton>
            </div>
          ) : (
            <div className={styles.suggestionSpacing} />
          )}
        </div>

        <div className={styles.inputContainer}>
          <label className={styles.label}>Company link</label>
          <CompanyV2Picker
            key={`${companyUrlSearchTerm}-${urlInputKey}}`}
            className={styles.input}
            onSelectCompanies={setCompanyFromUrlAndSuggestName}
            placeholder={'Enter link'}
            onlySearchVerified
            suppressIcons
            searchUrl
            onChangeProps={{
              initialSearchTerm: companyUrlSearchTerm,
              onInputChange: updateCompanyUrl,
              onBlur: () => {
                if (selectedCompany.url && !isValidUrl(selectedCompany.url)) {
                  setUrlError('');
                }
              },
            }}
          />
          {urlError ? (
            <div className={styles.suggestionSpacing}>
              <span style={{ fontSize: '12px', color: 'red' }}>{urlError}</span>
            </div>
          ) : requestUrl ? (
            <div className={cx(styles.suggestion, styles.suggestionSpacing)}>
              <span style={{ fontSize: '12px' }}>
                Add a link to be matched with this or similar companies on
                A.Team.
              </span>
            </div>
          ) : (
            <div className={styles.suggestionSpacing} />
          )}
        </div>
      </div>

      <div className={styles.buttonsContainer}>
        <Button onClick={() => onClose()} variant="secondary">
          Back
        </Button>
        <Button
          variant="main"
          disabled={!selectedCompany.name}
          onClick={onSave}
        >
          {isLoading ? <Icon name="loading" size="lg" /> : 'Save'}
        </Button>
      </div>
    </Modal>
  );
};
