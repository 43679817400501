import { ExpertiseScoreLabels } from '@a_team/models/dist/AdminNotesObject';
import React from 'react';
import { BasicVettingProcessColumnProps } from '..';

export const ExpertiseField: React.FC<BasicVettingProcessColumnProps> = ({
  vettingProcess,
}) => {
  if (typeof vettingProcess.feedback?.answers.scores.expertise !== 'number') {
    return null;
  }

  return (
    <>
      {ExpertiseScoreLabels[vettingProcess.feedback.answers.scores.expertise]}
    </>
  );
};
