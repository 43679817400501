import React from 'react';
import { useCommonStyles } from '../commonStyles';
import { createUseStyles } from 'react-jss';
import { FontSizes, FontWeights, Colors } from '@ateams/components';

const useStyles = createUseStyles({
  fieldReadOnlyValue: {
    fontSize: FontSizes.small,
    fontWeight: FontWeights.regular,
    color: Colors.dark,
  },
});

export interface FieldReadOnlyProps {
  label: string;
  value: string;
}

export const FieldReadOnly = ({ label, value }: FieldReadOnlyProps) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  return (
    <div>
      <div className={commonStyles.title}>{label}</div>
      <div className={styles.fieldReadOnlyValue}>{value}</div>
    </div>
  );
};
