import React, { useState } from 'react';
import { useCommonStyles } from '../commonStyles';
import { createUseStyles } from 'react-jss';
import { ProjectCard } from './ProjectCard';
import {
  Icon,
  IconType,
  Colors,
  FontSizes,
  FontWeights,
  Spacing,
} from '@ateams/components';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
  },
  seeAllButton: {
    fontSize: FontSizes.small,
    color: Colors.secondary,
    cursor: 'pointer',
    fontWeight: FontWeights.medium,
    display: 'flex',
    alignItems: 'center',
    gap: Spacing.small,
    userSelect: 'none',
  },
});

export interface ProjectSkill {
  id: string;
  name: string;
}

export interface Project {
  id: string;
  name: string;
  jobRole: string;
  company: string;
  companyLogoUrl: string;
  projectUrl: string;
  skills: ProjectSkill[];
  impressions: string;
}

export interface ProjectsSectionProps {
  projects: Project[];
  setProjects: React.Dispatch<React.SetStateAction<Project[]>>;
  maxRows: number;
}

export const ProjectsSection = ({
  projects,
  setProjects,
  maxRows,
}: ProjectsSectionProps) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const [showAllProjects, setShowAllProjects] = useState(false);

  const onImpressionsChange = (projectId: string, impressions: string) => {
    setProjects((prev) =>
      prev.map((project) =>
        project.id === projectId ? { ...project, impressions } : project,
      ),
    );
  };

  return (
    <div className={commonStyles.section}>
      <div className={commonStyles.sectionTitle}>Projects</div>
      <div className={commonStyles.sectionDescription}>
        Understand the scale and scope of the builder's projects, including
        their contribution, leadership experience and similar. Interviewer must
        add impressions to at least 1 project.
      </div>
      <div className={styles.container}>
        {projects
          .slice(0, showAllProjects ? projects.length : 2)
          .map((project) => (
            <ProjectCard
              key={project.id}
              project={project}
              onImpressionsChange={onImpressionsChange}
            />
          ))}
      </div>
      {projects.length > maxRows && (
        <div
          className={styles.seeAllButton}
          onClick={() => setShowAllProjects(!showAllProjects)}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              setShowAllProjects(!showAllProjects);
            }
          }}
        >
          <span>
            {showAllProjects ? 'Collapse the list' : 'See all projects'}
          </span>
          <Icon type={IconType.ArrowDownSecondarySmall} size={'xsmall'} />
        </div>
      )}
    </div>
  );
};
