import React, { forwardRef } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { format } from 'date-fns';
import copy from 'copy-to-clipboard';
import { theme } from '@a_team/ui-components';
import { VettingProcessPreVettingFormOld } from '@a_team/models/dist/vetting-processes/pre-vetting';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { Text } from '@src/views/VettingFeedbackForm/components/typography';
import { ValueTag } from '@src/views/VettingFeedbackForm/components/value-tag';
import { InboxIcon } from '@src/views/VettingFeedbackForm/components/icons/inbox';
import { LinkIcon } from '@src/views/VettingFeedbackForm/components/icons/link';
import { Spacing } from '@ateams/components';
import { getGMTFromMinutesOffset } from '@ateams/react-utils/dist/helpers/timezone';
import { WeeklyHoursAnswers } from './weekly-hours';

export const useCommonStyles = createUseStyles({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '16px',
    marginBottom: Spacing.medium,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  purpleTag: {
    background: theme.colors.Hannibal[100],
    color: theme.colors.Hannibal[600],
  },
  emailTag: {
    cursor: 'pointer',
  },
});

export interface PreVettingCommonAnswersProps {
  answers: VettingProcessPreVettingFormOld;
  builderEmail?: string;
}

export const PreVettingCommonAnswers: React.FC<
  PreVettingCommonAnswersProps
> = ({ answers, builderEmail }) => {
  const styles = useCommonStyles();
  const [loading, setLoading] = useLoadingState();

  const onClickOnEmail = () => {
    if (!builderEmail) return;

    copy(builderEmail);
    setLoading(Promise.resolve(new Date()), 'Email copied to clipboard');
  };

  return (
    <>
      {answers.calendarUrl && (
        <div className={styles.row}>
          <InputLabel>Calendar URL</InputLabel>
          <div>
            <a href={answers.calendarUrl} target={'_blank'} rel="noreferrer">
              <ValueTag
                className={styles.purpleTag}
                startAdornment={<LinkIcon />}
              >
                {answers.calendarUrl}
              </ValueTag>
            </a>
          </div>
        </div>
      )}

      {answers.weeklyHours && (
        <>
          <div className={styles.row}>
            <InputLabel>Time zone</InputLabel>

            <ValueTag>
              {answers.weeklyHours.timezone.name} -{' '}
              {getGMTFromMinutesOffset(answers.weeklyHours.timezone.utcOffset)}
            </ValueTag>
          </div>

          <div className={styles.row}>
            <InputLabel>Weekly hours</InputLabel>

            <WeeklyHoursAnswers weeklyHours={answers.weeklyHours.hoursRanges} />
          </div>
        </>
      )}

      {builderEmail && (
        <div className={styles.row}>
          <InputLabel>Email address</InputLabel>

          <ValueTag
            className={cx(styles.purpleTag, styles.emailTag)}
            startAdornment={<InboxIcon />}
            onClick={onClickOnEmail}
          >
            {builderEmail}
          </ValueTag>
        </div>
      )}

      <div className={styles.row}>
        <InputLabel>Form submitted</InputLabel>
        <ValueTag>
          {format(new Date(answers.submitDate), 'LLLL d, yyyy')}
        </ValueTag>
      </div>

      <LoadingIndicator key={new Date().toISOString()} loading={loading} />
    </>
  );
};

interface MultipleValuesFieldProps {
  answers: string[];
}

const useMultipleValuesFieldStyles = createUseStyles({
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '4px',
  },
});

export const MultipleValuesField: React.FC<MultipleValuesFieldProps> = ({
  answers,
}) => {
  const styles = useMultipleValuesFieldStyles();

  return (
    <div className={styles.container}>
      {answers.map((answer) => (
        <ValueTag key={answer}>{answer}</ValueTag>
      ))}
    </div>
  );
};

const useInputLabelStyles = createUseStyles({
  text: {
    fontWeight: 500,
    width: '280px',
    marginRight: Spacing.medium,
    flexShrink: 0,
  },
});

export const InputLabel: typeof Text = forwardRef((props, ref) => {
  const styles = useInputLabelStyles();

  return (
    <Text {...props} ref={ref} className={cx(props.className, styles.text)} />
  );
});
