import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import checkedIcon from './icons/checked.svg';
import loaderIcon from './icons/loader.svg';
import websiteIcon from './icons/website.svg';
import compassIcon from './icons/compass.svg';
import detailsIcon from './icons/details.svg';
import gettingStartedIcon from './icons/gettingStarted.svg';
import profileIcon from './icons/profile.svg';
import starIcon from './icons/star.svg';
import commentIcon from './icons/comment.svg';

export enum StepIconType {
  NUMBER = 'NUMBER',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  FINAL = 'FINAL',
  WEBSITE = 'WEBSITE',
  DETAILS = 'DETAILS',
  COMPASS = 'COMPASS',
  PROFILE = 'PROFILE',
  STAR = 'STAR',
  GETTING_STARTED = 'GETTING_STARTED',
  COMMENT = 'COMMENT',
}

interface StepIconProps {
  type: StepIconType;
  value?: string;
}

export const useStyles = createUseStyles({
  stepNumber: {
    width: 40,
    height: 40,
    backgroundColor: '#EFEFF0',
    borderRadius: 11,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
  },
  loader: {
    position: 'absolute',
    width: 16,
    height: 16,
    animation: '$loading 1s linear infinite',
  },
  '@keyframes loading': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  highlighted: {
    backgroundColor: '#FCFAFF',
  },
  highlightedStrong: {
    backgroundColor: '#EDE0FF',
  },
});

function StepIcon({ type, value }: StepIconProps) {
  const styles = useStyles();

  if (type === StepIconType.COMMENT) {
    return (
      <div className={cx(styles.stepNumber)}>
        <img src={commentIcon} alt="Comment" width={16} height={16} />
      </div>
    );
  }

  if (type === StepIconType.COMPLETED) {
    return (
      <div className={cx(styles.stepNumber, styles.highlighted)}>
        <img src={checkedIcon} alt="Completed" width={12} height={12} />
      </div>
    );
  }

  if (type === StepIconType.WEBSITE) {
    return (
      <div className={cx(styles.stepNumber, styles.highlightedStrong)}>
        <img src={websiteIcon} alt="Website" width={16} height={16} />
      </div>
    );
  }

  if (type === StepIconType.DETAILS) {
    return (
      <div className={cx(styles.stepNumber)}>
        <img src={detailsIcon} alt="Details" width={16} height={16} />
      </div>
    );
  }

  if (type === StepIconType.COMPASS) {
    return (
      <div className={cx(styles.stepNumber)}>
        <img src={compassIcon} alt="Curated missions" width={16} height={16} />
      </div>
    );
  }

  if (type === StepIconType.GETTING_STARTED) {
    return (
      <div className={cx(styles.stepNumber)}>
        <img
          src={gettingStartedIcon}
          alt="Getting started"
          width={16}
          height={16}
        />
      </div>
    );
  }

  if (type === StepIconType.PROFILE) {
    return (
      <div className={cx(styles.stepNumber)}>
        <img src={profileIcon} alt="Profile" width={16} height={16} />
      </div>
    );
  }

  if (type === StepIconType.STAR) {
    return (
      <div className={cx(styles.stepNumber)}>
        <img src={starIcon} alt="Star" width={16} height={16} />
      </div>
    );
  }

  if (type === StepIconType.IN_PROGRESS) {
    return (
      <div className={cx(styles.stepNumber, styles.highlighted)}>
        <img
          src={loaderIcon}
          alt="Loading"
          className={styles.loader}
          width={12}
          height={12}
        />
      </div>
    );
  }

  if (type === StepIconType.FINAL) {
    return (
      <div className={cx(styles.stepNumber, styles.highlightedStrong)}>🎉</div>
    );
  }

  return <div className={styles.stepNumber}>{value}</div>;
}

export default StepIcon;
