import React, { useMemo, useState } from 'react';
import { Icon, IconType } from '@ateams/components';
import { Button } from '@a_team/ui-components';
import Dropdown, { DropdownItem } from '@src/components/Dropdown';
import { useCommonStyles } from './commonStyles';
import {
  goToAddAppleCalendarClick,
  goToAddGoogleCalendarClick,
  goToAddOutlookCalendarClick,
} from './helpers';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { useStores } from '@src/stores';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

interface ConnectCalendarButtonProps {
  triggerTeaser?: boolean;
  hasCalendar?: boolean;
  topAligned?: boolean;
  variant?: 'main' | 'ghost';
  onCalendarClick?: () => void;
}

export const ConnectCalendarButton = ({
  hasCalendar,
  onCalendarClick,
  topAligned,
  triggerTeaser,
  variant,
}: ConnectCalendarButtonProps) => {
  const styles = useCommonStyles();
  const analytics = useAnalytics();
  const { auth } = useStores();
  const queryClient = useQueryClient();
  const location = useLocation();
  const [showPickCalendar, setShowPickCalendar] = useState<boolean>(false);
  const userId = auth.uid ?? '';

  const handleCalendarClick = () => {
    if (triggerTeaser && onCalendarClick) {
      onCalendarClick();
      return;
    }
    if (!hasCalendar) {
      setShowPickCalendar(!showPickCalendar);
      return;
    }

    onCalendarClick && onCalendarClick();
  };

  const connectGoogleCalendar = () => {
    goToAddGoogleCalendarClick(auth, queryClient, location.pathname);
    analytics.trackConnectGoogleCalendarClick(userId);
    setShowPickCalendar(false);
  };

  const connectAppleCalendar = () => {
    goToAddAppleCalendarClick(auth, queryClient, location.pathname);
    analytics.trackConnectAppleCalendarClick(userId);
    setShowPickCalendar(false);
  };

  const connectOutlookCalendar = () => {
    goToAddOutlookCalendarClick(auth, queryClient, location.pathname);
    analytics.trackConnectOutlookCalendarClick(userId);
    setShowPickCalendar(false);
  };

  const position: { top: number | undefined; bottom: number | undefined } = {
    top: 0,
    bottom: 0,
  };

  if (topAligned) {
    position.bottom = 50;
    position.top = undefined;
  } else {
    position.top = 50;
    position.bottom = undefined;
  }
  const buttonVariant = useMemo(() => {
    if (variant) {
      return variant;
    }

    return hasCalendar ? 'ghost' : 'main';
  }, [hasCalendar, variant]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <Button
        className={styles.calendarButton}
        variant={buttonVariant}
        onClick={handleCalendarClick}
      >
        {hasCalendar ? 'Edit' : 'Connect calendar'}
        {!hasCalendar && !triggerTeaser && (
          <Icon
            type={
              buttonVariant === 'ghost'
                ? IconType.ArrowDownBlack
                : IconType.DownArrowWhite
            }
            size={buttonVariant === 'ghost' ? 'xsmall' : 'small'}
          />
        )}
      </Button>
      {!hasCalendar && (
        <Dropdown
          className={styles.pickCalendar}
          open={showPickCalendar}
          onClose={() => setShowPickCalendar(false)}
          style={{ ...position }}
        >
          <DropdownItem
            className={styles.calendarItem}
            onClick={connectGoogleCalendar}
          >
            <Icon type={IconType.Google} size="small" /> Google calendar
          </DropdownItem>
          <DropdownItem
            className={styles.calendarItem}
            onClick={connectAppleCalendar}
          >
            <Icon type={IconType.AppleCalendar} size="small" /> Apple calendar
          </DropdownItem>
          <DropdownItem
            className={styles.calendarItem}
            onClick={connectOutlookCalendar}
          >
            <Icon type={IconType.OutlookCalendar} size="small" /> Outlook
            calendar
          </DropdownItem>
        </Dropdown>
      )}
    </div>
  );
};
