import { ServiceAuth, ServiceEndpoint } from './utils';
import {
  CalendarOptoutObject,
  CalendarSettings,
  CurrentUserObject,
  CustomCalendarObject,
  DefaultRates,
  RegisteredUserObject,
  UserCardObject,
} from '@a_team/models/dist/UserObject';
import LocationObject from '@a_team/models/dist/LocationObject';
import ServiceObject, { ServiceData } from '@a_team/models/dist/ServiceObject';
import { QueryNextToken, QueryResult } from '@a_team/models/dist/misc';
import UserQuestionnaire from '@a_team/models/dist/UserQuestionnaire';
import {
  AvailabilityData,
  AvailabilityObject,
  AvailabilitySummaryObject,
} from '@a_team/models/dist/AvailabilityObject';
import {
  RoleWithStatus,
  UserTalentSkillAssignmentData,
} from '@a_team/models/dist/TalentCategories';
import { TalentSpecializationId } from '@a_team/models/dist/TalentCategories';
import { UserTalentIndustryExperience } from '@a_team/models/dist/TalentIndustry';
import TimezoneObject from '@a_team/models/src/TimezoneObject';
import { UserReferral } from '@a_team/models/dist/UserObject';
import { MissionCardObject, MissionId } from '@a_team/models/src/MissionObject';
import { AppliedMissionCardObject } from '@a_team/models/src/MissionApplicationObject';
import { WorkingHoursSchema } from '@a_team/models/dist/WorkingHoursObject';
import { MissionApplicationLifecycleStage } from '@a_team/models/dist/MissionApplicationObject';
import PortfolioObject from '@a_team/models/src/PortfolioObject';
import { DesignerPortfolioResponse } from './Registration';
import * as googleMaps from '@googlemaps/google-maps-services-js';
import {
  EvaluationCallDetailsResponse,
  RequestEvaluationCallResponse,
} from '@a_team/models/dist/vetter';
import { RequestEvaluationCallPayload } from './vetting-process';

export const BasePath = '/user';

export interface AppearanceData {
  profilePictureURL?: string;
}

export interface PersonalInfoData {
  aboutMe?: string;
  websites?: string[];
  dribbbleUsername?: string;
  behanceUsername?: string;
  linkedinUsername?: string;
  githubUsername?: string;
  location?: LocationObject;
  timezone?: TimezoneObject;
  workingHours?: WorkingHoursSchema;
  phoneNumber?: string;
  isNewOnboarding?: boolean;
  cvURL?: string;
}

export interface UserExperienceData {
  yearsOfExperience?: number;
  expertise: string[];
  industries: string[];
}

export interface ChangePasswordData {
  oldPassword: string;
  password: string;
}

export interface UserSpecializationAssignmentData {
  mainSpecializationId?: TalentSpecializationId;
  additionalSpecializationIds?: TalentSpecializationId[];
}

export interface UserTalentIndustriesAssignmentData {
  experiences: UserTalentIndustryExperience[];
}

export interface AppliedMissionQueryParameters {
  filterByLifecycleStage: MissionApplicationLifecycleStage[];
}

type AppliedMissionsTotals = {
  total: number;
  [MissionApplicationLifecycleStage.Active]: number;
  [MissionApplicationLifecycleStage.Concluded]: number;
  [MissionApplicationLifecycleStage.LowChance]: number;
};

export type AppliedMissionsResult = QueryResult<AppliedMissionCardObject> & {
  totals: AppliedMissionsTotals;
};

/**
 * Refer friends to A.Team.
 */
export interface UserReferralData {
  referrals: UserReferral[];
}

/**
 * "How did you hear about A.Team?"
 */
export enum UserReferralSourceType {
  ATeamMember = 'TeamMember',
  Client = 'Client',
  ArticleOrBlogPost = 'ArticleOrBlogPost',
  SearchEngine = 'SearchEngine',
  SocialMedia = 'SocialMedia',
  JobPost = 'JobPost',
  Other = 'Other',
}

export interface UserReferralSourceData {
  source?: UserReferralSourceType;
  sourceName?: string;
  sourceEmail?: string;
  referralCode?: string;
  otherText?: string;
}
export interface UserMissionNotInterestedData {
  missionId: MissionId;
  reason?: string;
}

export interface UpdateCVData {
  cvURL?: string;
}

export interface ProfilePictureAnalysisResponse {
  issues: string[];
}

export interface GetProfilePictureAnalysisData {
  pictureUrl: string;
  uid: string;
}

// exported functions

export default class UserEndpoint extends ServiceEndpoint {
  public getCurrentUser(
    auth: ServiceAuth,
  ): Promise<CurrentUserObject | RegisteredUserObject> {
    return this.fetch(auth, BasePath);
  }

  public updateAppearance(
    auth: ServiceAuth,
    data: AppearanceData,
  ): Promise<CurrentUserObject> {
    return this.fetch(auth, BasePath + '/appearance', null, 'put', data);
  }

  public updatePersonalInfo(
    auth: ServiceAuth,
    data: PersonalInfoData,
  ): Promise<CurrentUserObject> {
    return this.fetch(auth, BasePath + '/personal-info', null, 'put', data);
  }

  public updateDesignerPortfolio(
    auth: ServiceAuth,
    data: PortfolioObject,
  ): Promise<DesignerPortfolioResponse> {
    return this.fetch(
      auth,
      BasePath + '/designer-portfolio',
      null,
      'put',
      data,
    );
  }

  public updateExperience(
    auth: ServiceAuth,
    data: UserExperienceData,
  ): Promise<CurrentUserObject> {
    return this.fetch(auth, BasePath + '/experience', null, 'put', data);
  }

  public updateQuestionnaire(
    auth: ServiceAuth,
    data: UserQuestionnaire,
  ): Promise<CurrentUserObject> {
    return this.fetch(auth, BasePath + '/questionnaire', null, 'put', data);
  }

  public changePassword(
    auth: ServiceAuth,
    data: ChangePasswordData,
  ): Promise<CurrentUserObject> {
    return this.fetch(auth, BasePath + '/password', null, 'put', data);
  }

  public createService(
    auth: ServiceAuth,
    data: ServiceData,
  ): Promise<ServiceObject> {
    return this.fetch(auth, BasePath + '/services', null, 'post', data);
  }

  public activeConnectionUsers(
    auth: ServiceAuth,
    next?: QueryNextToken,
  ): Promise<QueryResult<UserCardObject>> {
    return this.fetch(auth, BasePath + '/connections/users', { next });
  }

  public acceptTOS(auth: ServiceAuth): Promise<void> {
    return this.fetch(
      auth,
      BasePath + '/terms-of-service/accept',
      null,
      'post',
    );
  }

  public dismissWelcomePage(auth: ServiceAuth): Promise<void> {
    return this.fetch(auth, BasePath + '/dismiss-welcome-page', null, 'put');
  }

  public acceptPlatformCodeOfConduct(auth: ServiceAuth): Promise<void> {
    return this.fetch(
      auth,
      BasePath + '/platform-code-of-conduct/accept',
      null,
      'post',
    );
  }

  public setWhatsNewModalAsDisplayed(auth: ServiceAuth): Promise<void> {
    return this.fetch(
      auth,
      BasePath + '/whats-new-modal/displayed',
      null,
      'post',
    );
  }

  public setWhatsNewBannerAsDisplayed(auth: ServiceAuth): Promise<void> {
    return this.fetch(
      auth,
      BasePath + '/whats-new-banner/displayed',
      null,
      'post',
    );
  }

  public handleProfileCompleteness(
    auth: ServiceAuth,
    data: {
      haveNotHadThreeJobs?: boolean;
      dismissed?: boolean;
    },
  ): Promise<void> {
    return this.fetch(
      auth,
      BasePath + '/profile-completeness',
      null,
      'post',
      data,
    );
  }

  public acceptMissionCodeOfConduct(auth: ServiceAuth): Promise<void> {
    return this.fetch(
      auth,
      BasePath + '/mission-code-of-conduct/accept',
      null,
      'post',
    );
  }

  public getAvailability(
    auth: ServiceAuth,
  ): Promise<AvailabilitySummaryObject> {
    return this.fetch(auth, BasePath + '/availability', null, 'get');
  }

  public updateAvailability(
    auth: ServiceAuth,
    availability: AvailabilityData,
  ): Promise<AvailabilitySummaryObject> {
    return this.fetch(
      auth,
      BasePath + '/availability',
      null,
      'post',
      availability,
    );
  }

  public updateTalentSkills(
    auth: ServiceAuth,
    data: UserTalentSkillAssignmentData,
  ): Promise<CurrentUserObject> {
    return this.fetch(auth, BasePath + '/talent-skills', null, 'put', data);
  }

  public updateTalentSpecializations(
    auth: ServiceAuth,
    data: UserSpecializationAssignmentData,
  ): Promise<CurrentUserObject> {
    return this.fetch(
      auth,
      BasePath + '/talent-specializations',
      null,
      'put',
      data,
    );
  }

  public updateTalentIndustries(
    auth: ServiceAuth,
    data: UserTalentIndustriesAssignmentData,
  ): Promise<CurrentUserObject> {
    return this.fetch(auth, BasePath + '/talent-industries', null, 'put', data);
  }

  public createReferral(
    auth: ServiceAuth,
    data: UserReferralData,
  ): Promise<CurrentUserObject> {
    return this.fetch(auth, BasePath + '/referrals', null, 'post', data);
  }

  public getReferrals(auth: ServiceAuth): Promise<UserReferralData> {
    return this.fetch(auth, BasePath + '/referrals', null, 'get');
  }

  public updateReferralSource(
    auth: ServiceAuth,
    data: UserReferralSourceData,
  ): Promise<void> {
    return this.fetch(auth, BasePath + '/referral-source', null, 'post', data);
  }

  public getReferralSource(auth: ServiceAuth): Promise<UserReferralSourceData> {
    return this.fetch(auth, BasePath + '/referral-source', null, 'get');
  }

  public getNotInterestedMissions(
    auth: ServiceAuth,
  ): Promise<QueryResult<MissionCardObject>> {
    return this.fetch(auth, `${BasePath}/not-interested-missions`, null, 'get');
  }

  public canScheduleCall(auth: ServiceAuth): Promise<boolean> {
    return this.fetch(auth, `${BasePath}/can-schedule-call`, null, 'get');
  }

  public restoreNotInterestedMission(
    auth: ServiceAuth,
    data: Pick<UserMissionNotInterestedData, 'missionId'>,
  ): Promise<void> {
    return this.fetch(
      auth,
      BasePath + '/restore-not-interested-mission',
      null,
      'post',
      data,
    );
  }

  public updateCurrentUserNotInterestedMissions(
    auth: ServiceAuth,
    data: UserMissionNotInterestedData,
  ): Promise<void> {
    return this.fetch(
      auth,
      BasePath + '/not-interested-missions',
      null,
      'post',
      data,
    );
  }

  public getAppliedMissions(
    auth: ServiceAuth,
    query: AppliedMissionQueryParameters,
    next?: QueryNextToken,
  ): Promise<AppliedMissionsResult> {
    return this.fetch(auth, `${BasePath}/applied-missions`, { ...query, next });
  }

  public getPendingMissionApplications(
    auth: ServiceAuth,
    next?: QueryNextToken,
  ): Promise<QueryResult<AppliedMissionCardObject>> {
    return this.fetch(auth, `${BasePath}/pending-mission-applications`, {
      next,
    });
  }

  public confirmAvailabilitySettings(
    token?: string,
    auth?: ServiceAuth,
  ): Promise<AvailabilityObject> {
    return this.fetch(
      auth || null,
      `${BasePath}/confirm-availability`,
      { token },
      'post',
      {},
    );
  }

  public setOptOutClientDiscovery(
    auth: ServiceAuth,
    optOut: boolean,
  ): Promise<boolean> {
    return this.fetch(
      auth,
      BasePath + '/set-opt-out-client-discovery',
      null,
      'PATCH',
      { optOut },
    );
  }

  public setOptOutBuilderDiscovery(
    auth: ServiceAuth,
    optOut: boolean,
  ): Promise<boolean> {
    return this.fetch(
      auth,
      BasePath + '/set-opt-out-builder-discovery',
      null,
      'PATCH',
      { optOut },
    );
  }

  public getCompletedMissions(
    auth: ServiceAuth,
  ): Promise<QueryResult<MissionCardObject>> {
    return this.fetch(auth, `${BasePath}/completed-missions`, null, 'get');
  }

  public confirmUpdatesSubmission(
    auth: ServiceAuth,
  ): Promise<CurrentUserObject | undefined> {
    return this.fetch(
      auth,
      `${BasePath}/confirm-updates-submission`,
      null,
      'put',
      null,
    );
  }

  public updateCV(auth: ServiceAuth, data: UpdateCVData): Promise<void> {
    return this.fetch(auth, `${BasePath}/update-cv`, null, 'put', data);
  }

  public getUserCalendar(auth: ServiceAuth): Promise<CalendarSettings> {
    return this.fetch(auth, BasePath + `/calendar`, null, 'get');
  }

  public saveCustomCalendar(
    auth: ServiceAuth,
    data: CustomCalendarObject,
  ): Promise<CalendarSettings> {
    return this.fetch(
      auth,
      BasePath + `/save-custom-calendar`,
      null,
      'post',
      data,
    );
  }

  public updateCalendarPreferences(
    auth: ServiceAuth,
    data: { autoRecord: boolean },
  ): Promise<CalendarSettings> {
    return this.fetch(
      auth,
      BasePath + `/update-calendar-preferences`,
      null,
      'put',
      data,
    );
  }

  public getPlacesSuggestions(
    searchTerm: string,
  ): Promise<googleMaps.PlaceAutocompleteResult[]> {
    return this.fetch(
      null,
      BasePath + `/places/suggestions/${searchTerm}`,
      null,
      'get',
    );
  }

  public getPlaceById(placeId: string): Promise<Partial<googleMaps.PlaceData>> {
    return this.fetch(null, BasePath + `/places/${placeId}`, null, 'get');
  }

  public updateUserUnsupportedCalendarName(
    auth: ServiceAuth,
    calendarName: string,
  ): Promise<CurrentUserObject> {
    return this.fetch(
      auth,
      BasePath + '/request-unsupported-calendar',
      null,
      'put',
      {
        name: calendarName,
      },
    );
  }

  public updateDefaultRates(
    auth: ServiceAuth,
    data: DefaultRates,
  ): Promise<CurrentUserObject> {
    return this.fetch(
      auth,
      BasePath + '/update-default-rates',
      null,
      'put',
      data,
    );
  }

  public dismissTimesheetInitiativesGuidance(auth: ServiceAuth): Promise<void> {
    return this.fetch(
      auth,
      BasePath + `/timesheet-initiatives-guidance/dismiss`,
      null,
      'post',
    );
  }

  public calendarOptout(
    auth: ServiceAuth,
    data: CalendarOptoutObject,
  ): Promise<CalendarSettings> {
    return this.fetch(auth, BasePath + `/calendar-optout`, null, 'post', data);
  }

  public getProfilePictureAnalysis(
    auth: ServiceAuth,
    data: GetProfilePictureAnalysisData,
  ): Promise<ProfilePictureAnalysisResponse> {
    return this.fetch(
      auth,
      BasePath + `/profile-picture-analysis`,
      null,
      'post',
      data,
    );
  }

  public requestProfileReview(auth: ServiceAuth): Promise<CurrentUserObject> {
    return this.fetch(auth, `${BasePath}/request-profile-review`, null, 'put');
  }

  public requestEvaluationCall(
    auth: ServiceAuth,
    request: RequestEvaluationCallPayload,
  ): Promise<RequestEvaluationCallResponse> {
    return this.fetch(
      auth,
      `${BasePath}/request-evaluation-call`,
      null,
      'put',
      request,
    );
  }

  public getEvaluationCallDetails(
    auth: ServiceAuth,
  ): Promise<EvaluationCallDetailsResponse> {
    return this.fetch(auth, `${BasePath}/evaluation-call-details`, null, 'get');
  }

  public getUserRolesWithStatus(auth: ServiceAuth): Promise<RoleWithStatus[]> {
    return this.fetch(auth, `${BasePath}/roles-with-status`, null, 'get');
  }

  public markOnboardingCompleted(
    auth: ServiceAuth,
  ): Promise<CurrentUserObject> {
    return this.fetch(
      auth,
      `${BasePath}/mark-onboarding-completed`,
      null,
      'put',
    );
  }

  public setCalendarConnected(auth: ServiceAuth): Promise<CurrentUserObject> {
    return this.fetch(auth, `${BasePath}/calendar-connected`, null, 'put');
  }

  public getProfileCompletion(auth: ServiceAuth): Promise<boolean> {
    return this.fetch(auth, `${BasePath}/profile-completion`, null, 'get');
  }

  public updateOnboardingStage(auth: ServiceAuth): Promise<CurrentUserObject> {
    return this.fetch(auth, `${BasePath}/onboarding-stage`, null, 'put');
  }
}
