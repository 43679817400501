import { createUseStyles } from 'react-jss';
import React, { ReactElement, useMemo, useState } from 'react';
import {
  BorderColors,
  BorderRadius,
  CollapsibleContainer,
  Colors,
  FontSizes,
  FontWeights,
  Icon,
  IconType,
  Spacing,
  Tag,
  TagList,
  TextColors,
} from '@ateams/components';
import {
  MatchedValue,
  StructuredExplanation,
  StructuredExplanationItem,
} from './MatchesDetailsModal';
import { SkillTargeterUserObject } from '@a_team/models/dist/TeamGraphObject';
import cx from 'classnames';
import { useQueryCustomTags } from '@src/rq/customTags';
import { useGetMissionApplicationMatchesOnData } from '@src/rq/missionApplications';
import { useStores } from '@src/stores';
import { useQueriesGetCompanies } from '@src/rq/companies';
import { Company } from '@a_team/models/dist/Company';
import { useQueryTalentIndustries } from '@src/rq/industries';
import _ from 'lodash';
import { useGetExperiencesMatchesOnData } from '@src/rq/experiences';
import { useGetMissionMatchesOnData } from '@src/rq/missions';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
  },
  bottomMargin: {
    marginBottom: 10,
  },
  explanationContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  explanationHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 180,
    marginRight: 10,
    fontSize: 16,
    fontWeight: 500,
  },
  explanationIcon: {
    marginRight: 10,
    marginBottom: 5,
  },
  explanationContent: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    margin: 0,
    listStyleType: 'none',
    paddingInlineStart: 0,
    gap: 8,
  },
  itemParentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.xsmall,
  },
  itemTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 8,
  },
  itemSubtitleText: {
    marginLeft: 29,
  },
  debugHeader: {
    marginTop: 5,
    marginBottom: 5,
  },
  debugTitle: {
    marginTop: 5,
    marginBottom: 5,
    color: 'gray',
    fontWeight: 'normal!important',
  },
  horizontalRule: {
    marginTop: 10,
    marginBottom: 10,
  },
  preformattedText: {
    display: 'flex',
    marginBottom: 15,
  },
  semanticMatchHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  semanticMatchSeparator: {
    width: 20,
    height: 1,
    backgroundColor: BorderColors.lighter,
  },
  semanticMatchQuery: {
    color: TextColors.lighter,
  },
  highlightedText: {
    backgroundColor: Colors.backgroundPurple,
    borderRadius: BorderRadius.default,
    padding: '2px 4px',
    fontWeight: FontWeights.medium,
  },
  explanationTitle: {
    whiteSpace: 'nowrap',
  },
  linkIcon: {
    cursor: 'pointer',
    width: 16,
    height: 16,
    marginTop: Spacing.xxsmall,
  },
  tags: {
    flexWrap: 'wrap',
  },
  tag: {
    padding: 10,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
  },
  buttonPurple: {
    color: Colors.secondaryDark,
    fontWeight: FontWeights.semiBold,
    fontSize: FontSizes.medium,
    cursor: 'pointer',
    paddingTop: Spacing.small,
    paddingBottom: Spacing.small,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: Spacing.small,
  },
  buttonIcon: {
    cursor: 'pointer',
  },
  itemHeader: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.small,
    marginBottom: Spacing.xsmall,
  },
  itemHeaderText: {
    fontWeight: FontWeights.regular,
    fontSize: FontSizes.medium,
    color: TextColors.itemHeader,
  },
});

enum StructuredExplanationTitle {
  AboutMe = 'About Me',
  Projects = 'Projects',
  Jobs = 'Jobs',
  Missions = 'Missions',
  DeclaredIndustries = 'Declared Industries',
  CustomTags = 'Custom Tags',
  Applications = 'Applications',
  AdminNotes = 'Admin Notes',
  EvalFeedback = 'Eval Feedback',
  TimesheetSummaries = 'Timesheet Summaries',
  Blurbs = 'Blurbs',
  Transcripts = 'Transcripts',
}

const icons: Record<string, ReactElement> = {
  [StructuredExplanationTitle.AboutMe]: (
    <Icon type={IconType.AboutMe} size={'exact'} />
  ),
  [StructuredExplanationTitle.Projects]: (
    <Icon type={IconType.Projects} size={'exact'} />
  ),
  [StructuredExplanationTitle.Jobs]: (
    <Icon type={IconType.Jobs} size={'exact'} />
  ),
  [StructuredExplanationTitle.Missions]: (
    <Icon type={IconType.Missions} size={'exact'} />
  ),
  [StructuredExplanationTitle.DeclaredIndustries]: (
    <Icon type={IconType.DeclaredIndustries} size={'exact'} />
  ),
  [StructuredExplanationTitle.CustomTags]: (
    <Icon type={IconType.CustomTags} size={'exact'} />
  ),
  [StructuredExplanationTitle.Applications]: (
    <Icon type={IconType.Applications} size={'exact'} />
  ),
  [StructuredExplanationTitle.AdminNotes]: (
    <Icon type={IconType.AdminNotes} size={'exact'} />
  ),
  [StructuredExplanationTitle.EvalFeedback]: (
    <Icon type={IconType.EvaluationFeedbackForm} size={'exact'} />
  ),
  [StructuredExplanationTitle.TimesheetSummaries]: (
    <Icon type={IconType.TimesheetSummary} size={'exact'} />
  ),
  [StructuredExplanationTitle.Blurbs]: (
    <Icon type={IconType.ProposalBlurbs} size={'exact'} />
  ),
  [StructuredExplanationTitle.Transcripts]: (
    <Icon type={IconType.Transcripts} size={'exact'} />
  ),
  default: <Icon type={IconType.Edit} size={'exact'} />,
};

const titles: Record<string, string> = {
  [StructuredExplanationTitle.AboutMe]: 'About Me',
  [StructuredExplanationTitle.Projects]: 'Projects',
  [StructuredExplanationTitle.Jobs]: 'Jobs',
  [StructuredExplanationTitle.Missions]: 'Missions',
  [StructuredExplanationTitle.DeclaredIndustries]: 'Declared industries',
  [StructuredExplanationTitle.CustomTags]: 'Custom tags',
  [StructuredExplanationTitle.Applications]: 'Applications',
  [StructuredExplanationTitle.AdminNotes]: 'Admin notes',
  [StructuredExplanationTitle.EvalFeedback]: 'Evaluation feedback form',
  [StructuredExplanationTitle.TimesheetSummaries]: 'Timesheet summary',
  [StructuredExplanationTitle.Blurbs]: 'Proposal blurbs',
  [StructuredExplanationTitle.Transcripts]: 'Transcripts',
};

const structuredExplanationOrderTitles: Record<string, number> = {
  [StructuredExplanationTitle.AboutMe]: 0,
  [StructuredExplanationTitle.Projects]: 1,
  [StructuredExplanationTitle.Jobs]: 2,
  [StructuredExplanationTitle.Missions]: 3,
  [StructuredExplanationTitle.DeclaredIndustries]: 4,
  [StructuredExplanationTitle.CustomTags]: 5,
  [StructuredExplanationTitle.Applications]: 6,
  [StructuredExplanationTitle.AdminNotes]: 7,
  [StructuredExplanationTitle.EvalFeedback]: 8,
  [StructuredExplanationTitle.TimesheetSummaries]: 9,
  [StructuredExplanationTitle.Blurbs]: 10,
  [StructuredExplanationTitle.Transcripts]: 11,
};

const MAX_EXPANSION_ITEMS = 5;

interface MissionApplicationPitchMetadata {
  applicationId: string;
  missionId: string;
  roleId: string;
}

interface ProjectMetadata {
  experienceId: string;
  companyId: string;
  endDate: string;
  completenessScore: number;
  description: string;
  jobRole: string;
  title: string;
  startDate: string;
}

interface BlurbMetadata {
  missionId: string;
  proposalId: string;
  text: string;
}

export function MatchingDetailsModalBody(props: {
  user: SkillTargeterUserObject;
  semanticQuery?: string;
  matchesOn?: string;
  matchedValues?: MatchedValue[];
  structuredExplanations?: StructuredExplanation;
  esParsedExplanations?: unknown[];
  esSearchHighlight?: string[];
  matched?: boolean;
  error?: string;
}) {
  const {
    user,
    matchesOn,
    semanticQuery,
    matchedValues,
    structuredExplanations,
    esParsedExplanations,
    esSearchHighlight,
    error,
  } = props;
  const { auth } = useStores();
  const { data: allCustomTags } = useQueryCustomTags();
  const pids = useMemo(() => {
    return structuredExplanations
      ?.filter((exp) => exp.title === StructuredExplanationTitle.Blurbs)
      ?.map((exp) =>
        exp.items?.map(
          (item) => (item.metadata?.blurb as BlurbMetadata)?.proposalId,
        ),
      )
      .flat()
      .filter((aid): aid is string => !!aid);
  }, [structuredExplanations]);
  const aids = useMemo(() => {
    return structuredExplanations
      ?.filter((exp) => exp.title === StructuredExplanationTitle.Applications)
      ?.map((exp) =>
        exp.items?.map(
          (item) =>
            (
              item.metadata
                ?.missionApplicationPitch as MissionApplicationPitchMetadata
            ).applicationId,
        ),
      )
      .flat()
      .filter((aid): aid is string => !!aid);
  }, [structuredExplanations]);
  const mids = useMemo(() => {
    return structuredExplanations
      ?.filter((exp) => exp.title === StructuredExplanationTitle.Missions)
      ?.map((exp) =>
        exp.items?.map(
          (item) =>
            item.metadata?.referenceId ||
            (item.metadata?.project as ProjectMetadata)?.experienceId,
        ),
      )
      .flat()
      .filter((eid): eid is string => !!eid);
  }, [structuredExplanations]);
  const eids = useMemo(() => {
    return structuredExplanations
      ?.filter(
        (exp) =>
          exp.title === StructuredExplanationTitle.Projects ||
          exp.title === StructuredExplanationTitle.Jobs ||
          exp.title === StructuredExplanationTitle.Missions,
      )
      ?.map((exp) =>
        exp.items?.map(
          (item) =>
            item.metadata?.referenceId ||
            (item.metadata?.project as ProjectMetadata)?.experienceId,
        ),
      )
      .flat()
      .filter((eid): eid is string => !!eid);
  }, [structuredExplanations]);
  const cids = useMemo(() => {
    return structuredExplanations
      ?.filter((exp) => exp.title === StructuredExplanationTitle.Projects)
      ?.map((exp) =>
        exp.items?.map(
          (item) => (item.metadata?.project as ProjectMetadata)?.companyId,
        ),
      )
      .flat()
      .filter((cid): cid is string => !!cid);
  }, [structuredExplanations]);
  const iids = useMemo(() => {
    const allIIds = structuredExplanations
      ?.filter(
        (exp) =>
          exp.title === StructuredExplanationTitle.DeclaredIndustries ||
          exp.title === StructuredExplanationTitle.Missions ||
          exp.title === StructuredExplanationTitle.Projects ||
          exp.title === StructuredExplanationTitle.Jobs,
      )
      ?.flatMap((exp) =>
        exp.items?.map((item) => {
          if (
            exp.title === StructuredExplanationTitle.Missions ||
            exp.title === StructuredExplanationTitle.DeclaredIndustries ||
            item.metadata?.type === 'ProjectExperience' ||
            item.metadata?.type === 'JobExperience'
          ) {
            return item.title;
          }
          return null;
        }),
      )
      .filter((industryId): industryId is string => !!industryId);

    return [...new Set(allIIds)];
  }, [structuredExplanations]);
  const { data: missions } = useGetMissionMatchesOnData(
    auth,
    user.uid,
    mids ?? [],
  );
  const { data: missionApplications } = useGetMissionApplicationMatchesOnData(
    auth,
    pids ?? [],
    aids ?? [],
  );
  const [companies, setCompanies] = useState<Company[]>([]);
  const { data: allIndustries } = useQueryTalentIndustries({
    id: iids ?? [],
  });

  const { data: experiences } = useGetExperiencesMatchesOnData(
    auth,
    eids ?? [],
  );

  useQueriesGetCompanies({
    enabled: true,
    companyIds: cids ?? [],
    onSuccess: (data) => {
      setCompanies((current) => [...(current || []), data]);
    },
  });

  const sortedStructuredExplanations = useMemo(() => {
    const sortedExplanations = [
      ..._.cloneDeep(structuredExplanations ?? []),
      {
        title: StructuredExplanationTitle.CustomTags,
        items: [],
      },
    ]?.sort((a, b) => {
      return (
        structuredExplanationOrderTitles[a.title] -
        structuredExplanationOrderTitles[b.title]
      );
    });

    const defaultIfEmptyOrNull = (
      value: string | undefined | null,
      defaultText: string,
    ) =>
      value === undefined || value === null || value === ''
        ? defaultText
        : value;

    sortedExplanations.forEach((exp) => {
      exp.items = exp.items?.map((item) => {
        return {
          ...item,
          partialText: true,
        };
      });

      switch (exp.title) {
        case StructuredExplanationTitle.Applications:
          exp.items = exp.items?.map((item) => {
            const missionApplicationPitch: MissionApplicationPitchMetadata =
              item.metadata
                ?.missionApplicationPitch as MissionApplicationPitchMetadata;
            const applicationId: string =
              missionApplicationPitch?.applicationId;
            const missionApplication = missionApplications?.find(
              (m) => m.aid === applicationId,
            );
            const role = missionApplication?.role;

            return {
              ...item,
              header: `${role?.roleName}, ${missionApplication?.missionName}, ${missionApplication?.companyName}`,
            };
          });
          break;

        case StructuredExplanationTitle.Missions:
          exp.items = exp.items?.map((item) => {
            if (item.metadata?.type === 'MissionRole') {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const referenceId: string = (item.metadata as any)?.referenceId;

              const industryId = item.title;
              const industry = allIndustries?.find((i) => i.id === industryId);

              const mission = missions?.find((e) => e.mid === referenceId);
              const role = mission?.roles.find((r) => r.uid === user.uid);

              return {
                ...item,
                header: `${defaultIfEmptyOrNull(
                  mission?.missionName,
                  '[no mission]',
                )}, ${defaultIfEmptyOrNull(role?.role, '[no role]')}`,
                title: `${
                  role?.yearsOfExperience === -1
                    ? '[unknown] years'
                    : role?.yearsOfExperience === 0
                    ? 'Less than 1 year'
                    : `${role?.yearsOfExperience} years`
                } of experience in <span class="search_service_highlighted">${
                  industry?.name
                }</span>`,
                partialText: false,
              };
            } else {
              const projectMetadata: ProjectMetadata = item.metadata
                ?.project as ProjectMetadata;

              const company = companies?.find(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (c: any) => c.id === projectMetadata?.companyId,
              );
              const experience = experiences?.find(
                (e) => e.eid === projectMetadata?.experienceId,
              );
              return {
                ...item,
                header: `${defaultIfEmptyOrNull(
                  company?.name || experience?.companyName,
                  '[no company]',
                )}, ${defaultIfEmptyOrNull(
                  projectMetadata?.title,
                  '[no title]',
                )}`,
                title: '',
                partialText: true,
              };
            }
          });
          break;

        case StructuredExplanationTitle.Projects:
        case StructuredExplanationTitle.Jobs:
          exp.items = exp.items?.map((item) => {
            if (
              item.metadata?.type === 'ProjectExperience' ||
              item.metadata?.type === 'JobExperience'
            ) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const referenceId: string = (item.metadata as any)?.referenceId;

              const industryId = item.title;
              const industry = allIndustries?.find((i) => i.id === industryId);

              const experience = experiences?.find(
                (e) => e.eid === referenceId,
              );
              return {
                ...item,
                header: `${defaultIfEmptyOrNull(
                  experience?.companyName,
                  '[no company]',
                )}, ${defaultIfEmptyOrNull(experience?.role, '[no role]')}`,
                title: `${
                  experience?.yearsOfExperience === -1
                    ? '[unknown] years'
                    : experience?.yearsOfExperience === 0
                    ? 'Less than 1 year'
                    : `${experience?.yearsOfExperience} years`
                } of experience in <span class="search_service_highlighted">${
                  industry?.name
                }</span>`,
                partialText: false,
              };
            } else {
              const projectMetadata: ProjectMetadata = item.metadata
                ?.project as ProjectMetadata;

              const company = companies?.find(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (c: any) => c.id === projectMetadata?.companyId,
              );
              const experience = experiences?.find(
                (e) => e.eid === projectMetadata?.experienceId,
              );
              return {
                ...item,
                header: `${defaultIfEmptyOrNull(
                  company?.name || experience?.companyName,
                  '[no company]',
                )}, ${defaultIfEmptyOrNull(
                  projectMetadata?.title,
                  '[no title]',
                )}`,
                title: '',
                partialText: true,
              };
            }
          });
          break;

        case StructuredExplanationTitle.Blurbs:
          exp.items = exp.items?.map((item) => {
            const blurbMetadata: BlurbMetadata = item.metadata
              ?.blurb as BlurbMetadata;

            const missionApplication = missionApplications?.find(
              (m) =>
                m.mid === blurbMetadata?.missionId &&
                m.pid === blurbMetadata?.proposalId,
            );
            const role = missionApplication?.role;
            return {
              ...item,
              header: `${role?.roleName}, ${missionApplication?.missionName}, ${missionApplication?.companyName}`,
            };
          });
          break;

        case StructuredExplanationTitle.DeclaredIndustries:
          exp.items = exp.items?.map((item) => {
            const industryId = item.title;
            const industry = allIndustries?.find((i) => i.id === industryId);
            return {
              ...item,
              header: defaultIfEmptyOrNull(industry?.name, '[no industry]'),
            };
          });
          break;
      }
    });

    return sortedExplanations;
  }, [
    structuredExplanations,
    companies,
    missionApplications,
    allIndustries,
    experiences,
    missions,
  ]);

  const [expandedSections, setExpandedSections] = useState<
    Record<string, boolean>
  >({});

  const toggleSection = (title: string) => {
    setExpandedSections((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
  };

  const styles = useStyles();

  if (error) return <div>Error: {error}</div>;

  const highlightText = (text: string, partialText?: boolean) => {
    return (
      (partialText ? '...' : '') +
      text.replace(
        /<span class="search_service_highlighted">(.*?)<\/span>/g,
        `<span class="${styles.highlightedText}">$1</span>`,
      ) +
      (partialText ? '...' : '')
    );
  };

  const shouldRenderExplanation = (
    title: string,
    matchedValues?: MatchedValue[],
  ) => {
    if (title !== StructuredExplanationTitle.CustomTags) return true;
    return (matchedValues?.length ?? 0) > 0;
  };

  const renderExplanationHeader = (title: string) => (
    <div className={styles.explanationHeader}>
      <div className={styles.explanationIcon}>
        {icons[title] ?? icons['default']}
      </div>
      <div
        className={styles.explanationTitle}
        dangerouslySetInnerHTML={{ __html: titles[title] ?? title }}
      />
    </div>
  );

  const renderCustomTags = () => {
    const customTags = matchedValues
      ?.filter((mv) => mv.type === 'customTag')
      .map((mv) => {
        const tagName = allCustomTags?.find(
          (tag) => tag._id === mv.value,
        )?.name;
        return (
          <Tag className={styles.tag} key={mv.value} color="backgroundLight">
            {tagName || 'Unknown Tag'}
          </Tag>
        );
      });
    return <TagList className={styles.tags}>{customTags}</TagList>;
  };

  const renderDeclaredIndustries = (items: StructuredExplanationItem[]) => {
    const declaredIndustries = items.map((item) => {
      const tagName = allIndustries?.find(
        (industry) => industry.id === item.title,
      )?.name;
      return (
        <Tag className={styles.tag} key={item.title} color="backgroundLight">
          {tagName || 'Unknown Tag'}
        </Tag>
      );
    });
    return <TagList className={styles.tags}>{declaredIndustries}</TagList>;
  };

  const renderRegularItems = (
    title: string,
    items: StructuredExplanationItem[],
  ) => {
    const isExpanded = expandedSections[title];
    const displayedItems = isExpanded
      ? items
      : items.slice(0, MAX_EXPANSION_ITEMS);

    return (
      <div className={styles.itemParentContainer}>
        {displayedItems?.map((item, ii) => {
          const {
            header,
            title,
            highlightedMatchedText,
            platformLink,
            partialText,
          } = item ?? {};
          return (
            <div key={ii} className={styles.itemContainer}>
              <div className={styles.itemTitle}>
                {platformLink && (
                  <Icon
                    type={IconType.LinkSecondary}
                    className={styles.linkIcon}
                    onClick={() => {
                      window.open(platformLink, '_blank', 'noreferrer');
                    }}
                  />
                )}
                <div className={styles.itemHeader}>
                  {header && (
                    <div className={styles.itemHeaderText}>{header}</div>
                  )}
                  {title && (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: highlightText(title, partialText),
                      }}
                    />
                  )}
                </div>
              </div>
              {highlightedMatchedText && (
                <div
                  className={styles.itemSubtitleText}
                  dangerouslySetInnerHTML={{
                    __html: highlightText(highlightedMatchedText ?? '', true),
                  }}
                />
              )}
            </div>
          );
        })}
        {items.length > MAX_EXPANSION_ITEMS && (
          <div
            onClick={() => toggleSection(title)}
            className={styles.buttonPurple}
          >
            {isExpanded ? 'Collapse matches' : 'View all'}
            <Icon
              type={
                isExpanded
                  ? IconType.ArrowUpSecondary
                  : IconType.ArrowDownSecondarySmall
              }
              size={'exact'}
              className={styles.buttonIcon}
            />
          </div>
        )}
      </div>
    );
  };

  const renderExplanationContent = (
    title: string,
    items: StructuredExplanationItem[],
  ) => {
    return (
      <div className={styles.explanationContent}>
        {title === StructuredExplanationTitle.CustomTags
          ? renderCustomTags()
          : title === StructuredExplanationTitle.DeclaredIndustries
          ? renderDeclaredIndustries(items)
          : renderRegularItems(title, items)}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {!sortedStructuredExplanations?.length &&
        !user.matchedOnSemanticQuery &&
        !matchesOn && <div>No match details found</div>}
      {!!user.matchedOnSemanticQuery && (
        <div className={styles.bottomMargin}>
          <div className={cx(styles.bottomMargin, styles.semanticMatchHeader)}>
            <strong>Semantic match</strong>
            <div className={styles.semanticMatchSeparator}></div>
            {!!semanticQuery && (
              <span className={styles.semanticMatchQuery}>
                "{semanticQuery}"
              </span>
            )}
          </div>
          {user.matchedOnSemanticQuery}
        </div>
      )}
      {sortedStructuredExplanations?.map((exp, ei) => {
        const { title, items } = exp ?? {};

        if (!shouldRenderExplanation(title, matchedValues)) return null;

        return (
          <div key={ei} className={styles.explanationContainer}>
            {renderExplanationHeader(title)}
            {renderExplanationContent(title, items ?? [])}
          </div>
        );
      })}
      <hr className={styles.horizontalRule} />
      <CollapsibleContainer
        title={`Developer debug information`}
        openDefault={false}
        headerClassName={styles.debugHeader}
        titleClassName={styles.debugTitle}
      >
        <pre className={styles.preformattedText}>
          Parsed Elasticsearch Explanation:{' '}
          {JSON.stringify(esParsedExplanations, null, 2)}
        </pre>
        <hr className={styles.horizontalRule} />
        <pre className={styles.preformattedText}>
          Elasticsearch Highlight: {JSON.stringify(esSearchHighlight, null, 2)}
        </pre>
      </CollapsibleContainer>
    </div>
  );
}
