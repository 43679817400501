import { createUseStyles } from 'react-jss';
import { Breakpoints, Colors, TextColors } from '@ateams/components';

interface Props {
  fullWidth?: boolean;
  descriptionLines?: number;
  hideBadgeOnHover?: boolean;
}

export const useStyles = createUseStyles({
  listItem: {
    listStyle: 'none',
    margin: '0 0 1.5em 0',
    cursor: 'pointer',
    '&.lowCompetitiveness': {
      marginTop: 80,
      '& $badgeContainer': {
        position: 'absolute',
        height: 80,
        width: '100%',
        top: -74,
        gridArea: 'none',
        justifySelf: 'unset',
        minWidth: 'fit-content',
      },
    },
  },
  disabled: {
    pointerEvents: 'none',
    cursor: 'default',
  },
  card: {
    border: '1px solid #DADADC',
    background: 'transparent',
    boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.12)',
    transition: 'all 0.3s ease-in-out',
    padding: 0,
    margin: 0,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: 8,
  },
  teamUpSectionContainer: {
    padding: '0 15px 30px 15px',
    background: Colors.backgroundWhite,
  },
  cardLink: {
    height: '100%',
    background: Colors.backgroundWhite,
    padding: '16px',
    color: '#222222',
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gridTemplateRows: 'min-content',
    gridTemplateAreas: `
      "header badge"
      "description description"
      "details details"
      "matches matches"
      "video video"
      "bottom bottom"
    `,
    alignItems: 'start',
    borderRadius: 8,
  },
  container: {
    '& > *': {
      marginTop: 0,
      marginBottom: '0',
    },
  },
  headerContainer: {
    gridArea: 'header',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'start',
    marginBottom: '8px',
  },
  logo: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'start',
  },
  logoInner: {
    marginRight: '8px',
  },
  tooltip: {
    fontSize: '80%',
    padding: '4px',
    '& > *': {
      margin: '0 auto',
    },
  },
  companyName: {
    color: TextColors.regularLight,
    margin: '0',
  },
  title: {
    fontWeight: 500,
    marginTop: 0,
    marginBottom: '4px',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  badgeContainer: {
    gridArea: 'badge',
    justifySelf: 'end',
    minWidth: 'max-content',
    opacity: 1,
    transition: 'all 0.3s ease-in-out',
  },
  applyEditCTA: {
    padding: 0,
    width: '78px',
    height: '40px',
  },
  editActionButtonContainer: {
    width: '100%',
    gridColumn: 'span 2',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 16px 16px 16px',
    background: Colors.backgroundWhite,
  },
  flagContainer: {
    position: 'absolute',
    right: '16px',
    top: 0,
    opacity: 1,
    transition: 'all 0.3s ease-in-out',
  },
  descriptionContainer: {
    gridArea: 'description',
  },
  description: {
    marginBottom: '4px',
    display: '-webkit-box',
    '-webkit-line-clamp': (props: Props) => props.descriptionLines || 4,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  detailsContainer: {
    gridArea: 'details',
    paddingTop: '12px',
  },
  matchesContainer: {
    gridArea: 'matches',
  },
  videoContainer: {
    gridArea: 'video',
    margin: '0 -1em',
    display: 'flex',
    justifyContent: 'stretch',
    alignItems: 'stretch',

    '& > *': {
      flexDirection: 'column',
      flexGrow: 1,
      width: '100%',
    },
  },
  dateContainer: {
    gridArea: 'bottom',
    background: Colors.backgroundMedium,
    margin: '0 -16px -16px -16px',
    padding: '9.5px 16px',
    alignSelf: 'flex-end',
  },
  hiddenButtonContainer: {
    extend: 'dateContainer',
    display: 'flex',
    justifyContent: 'flex-end',
    alignSelf: 'center',
  },
  bold: {
    fontWeight: 500,
  },
  cardWithVideo: {
    extend: 'card',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    listItem: {
      minHeight: (props: Props) => (props.fullWidth ? 'auto' : '300px'),
      marginBottom: (props: Props) => (props.fullWidth ? '1.5em' : '0'),
      '&.lowCompetitiveness': {
        marginTop: 0,
        '& $badgeContainer': {
          height: 'auto',
          justifySelf: 'end',
          minWidth: 'max-content',
        },
      },
    },
    hiddenButtonContainer: {
      gridArea: 'badge',
      background: 'none',
      margin: 0,
      padding: 0,
      justifySelf: 'flex-end',
      alignSelf: 'flex-start',
      alignItems: 'flex-start',
      transition: 'all 0.3s ease-in-out',
      opacity: 0,

      '&:focus-within': {
        opacity: 1,
      },
    },
    card: {
      height: '100%',
      marginTop: 0,
      boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0)',
      gridTemplateColumns: '3fr 1fr',
      borderRadius: 8,
      gridTemplateAreas: `
        "header badge"
        "description description"
        "details details"
        "bottom bottom"
      `,

      '&:hover, &:focus, &:focus-within': {
        boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.12)',
      },

      '&:hover $hiddenButtonContainer': {
        opacity: 1,
      },
      '&:hover $flagContainer': {
        opacity: 0,
      },
      '&:hover .hideOnHover': {
        opacity: 0,
      },
    },
    badgeContainer: {
      position: 'static !important',
      gridArea: 'badge !important',
      justifySelf: 'end',
      minWidth: 'max-content',
    },
    cardWithVideo: {
      height: '100%',
      boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0)',
      gridTemplateColumns: '3fr 1fr',
      gridTemplateAreas: `
        "header badge"
        "description video"
        "details video"
        "bottom bottom"
      `,

      '&:hover, &:focus, &:focus-within': {
        boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.12)',
      },

      '&:hover $hiddenButtonContainer': {
        opacity: 1,
      },
      '&:hover $flagContainer': {
        opacity: 0,
      },
    },
    videoContainer: {
      display: (props: Props) => (props.fullWidth ? 'flex' : 'none'),
      alignSelf: 'center',
      justifySelf: 'flex-end',
      margin: '0 0 1em 1em',
      height: '135px',

      '& > *': {
        flexGrow: 0,
        width: '240px',
        height: '135px',
      },
    },
    matchesContainer: {
      extend: 'dateContainer',
    },
  },
});
