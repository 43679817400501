import {
  ExperienceMember,
  ExperienceUserMember,
} from '@a_team/models/dist/ExperienceObject';
import { Button } from '@a_team/ui-components';
import { Breakpoints, Icon, IconType } from '@ateams/components';
import JobModal from '@src/components/Modal/JobModal';
import useToggle from '@src/hooks/useToggle';
import Profile from '@src/stores/Profile/Profile';
import {
  ExistingJob,
  NewJob,
  ProfileViewMode,
} from '@src/stores/Profile/models';
import React from 'react';
import { createUseStyles } from 'react-jss';
import Job from './Job';
import { DateISOString } from '@a_team/models/dist/misc';
import { useDeleteUserExperienceMutation } from '@src/rq/experiences';
import JobModalView from '@src/components/Modal/JobModalView';
import SuggestedJobs from './SuggestedJobs';
import AuthStore from '@src/stores/Auth';
import DeleteJobConfirmationModal from '@src/components/Modal/DeleteJobConfirmationModal';
import { useHistory, useLocation } from 'react-router-dom';

interface JobsProps {
  profile: Profile;
  auth: AuthStore;
}

const useStyles = createUseStyles({
  container: {
    maxWidth: 830,
    width: '100%',
  },
  jobsContainer: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
  },
  addBtn: {
    height: '40px !important',
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    container: {
      width: 'calc(100vw - 660px)',
    },
  },
});

export function isExistingJob(job: ExistingJob | NewJob): job is ExistingJob {
  return 'eid' in job;
}

function isExperienceUserMember(
  member: ExperienceMember,
): member is ExperienceUserMember {
  return 'username' in member;
}

function formatDateRange({
  startDate,
  endDate,
}: {
  startDate?: DateISOString;
  endDate?: DateISOString;
}) {
  if (!startDate) return 'No date';

  const start = new Date(startDate);
  const startMonth = start.toLocaleString('default', { month: 'short' });
  const startYear = start.getFullYear();

  if (!endDate) {
    return `${startMonth} ${startYear} - Present`;
  }

  const end = new Date(endDate);
  const endMonth = end.toLocaleString('default', { month: 'short' });
  const endYear = end.getFullYear();

  return `${startMonth} ${startYear} - ${endMonth} ${endYear}`;
}

const Jobs = ({ profile, auth }: JobsProps): JSX.Element | null => {
  const styles = useStyles();
  const [open, toggle] = useToggle(false);
  const [openJobViewModal, toggleJobViewModal] = useToggle(false);
  const [deleteJobModalOpen, toggleDeleteJobModal] = useToggle(false);
  const { mutate: deleteExperience, isLoading: isDeletionLoading } =
    useDeleteUserExperienceMutation();
  const [jobIdToDelete, setJobIdToDelete] = React.useState<string | null>(null);

  const [selectedJob, setSelectedJob] = React.useState<
    ExistingJob | NewJob | null
  >(null);

  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const jobId = searchParams.get('job');

  React.useEffect(() => {
    if (jobId) {
      const job = profile.jobs.find(
        (job) => isExistingJob(job) && job.eid === jobId,
      );
      if (job) {
        setSelectedJob(job);
        toggleJobViewModal();
      }
    }
  }, [jobId, profile.jobs]);

  const handleJobClick = (job: ExistingJob) => {
    setSelectedJob(job);

    searchParams.delete('project');
    searchParams.set('job', job.eid);
    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    history.push(newUrl);
  };

  const handleAddNewJob = () => {
    setSelectedJob(null);
    toggle();
  };

  if (!profile) {
    return null;
  }

  const isEditMode = profile.mode === ProfileViewMode.Edit;

  const isSelectedJobExisting = selectedJob && isExistingJob(selectedJob);

  const onUpdateJob = (job: ExistingJob | NewJob) => {
    setSelectedJob(job);
    toggle();
  };

  const onDeleteJob = (job: ExistingJob) => {
    toggleDeleteJobModal();
    setJobIdToDelete(job.eid);
  };

  const adminOnly = auth.isAdmin && auth.currentUser?.uid !== profile?.data.uid;
  const userId = adminOnly ? profile?.data.uid : auth.currentUser?.uid;

  return (
    <div className={styles.container}>
      {!isEditMode && profile.jobs.length === 0 ? null : <h3>Jobs</h3>}

      {isEditMode && (
        <Button
          onClick={handleAddNewJob}
          variant="secondary"
          className={styles.addBtn}
        >
          <Icon
            type={IconType.PlusBlack}
            style={{ marginRight: 8 }}
            size="xsmall"
          />
          Add a job
        </Button>
      )}

      <div className={styles.jobsContainer}>
        {userId && (
          <SuggestedJobs
            auth={auth}
            userId={userId}
            adminOnly={adminOnly}
            onAccept={onUpdateJob}
          />
        )}
        {profile.jobs.map((job) => {
          const member = job.members.find((member) => {
            if (isExperienceUserMember(member)) {
              return member.uid === profile.data.uid;
            }
            return false;
          });

          if (!member) {
            return null;
          }

          if (isExistingJob(job)) {
            const companyName = job.companyName || job?.companyData?.name;
            const companyLogo = job.logoURL || job?.companyData?.logoUrl;

            return (
              <Job
                auth={auth}
                key={job.eid}
                isSuggested={job.isSuggested}
                jobTitle={member.memberTitle}
                companyName={companyName}
                companyLogo={companyLogo}
                description={job.descriptionHTML}
                period={formatDateRange({
                  startDate: job.startDate,
                  endDate: job.endDate,
                })}
                relatedProjects={job.relatedProjects}
                onOpenViewModal={() => handleJobClick(job)}
                onUpdateClick={isEditMode ? () => onUpdateJob(job) : undefined}
                onDeleteClick={isEditMode ? () => onDeleteJob(job) : undefined}
                totalSkills={job.applicableTalentSkills?.length}
              />
            );
          }

          return (
            <Job
              auth={auth}
              key={job._id}
              isSuggested={job.isSuggested}
              jobTitle={member.memberTitle}
              companyName={job.companyName}
              companyLogo={job.logoURL}
              description={job.descriptionHTML}
              period={formatDateRange({
                startDate: job.startDate,
                endDate: job.endDate,
              })}
              onOpenViewModal={() => {
                setSelectedJob(job);
                toggleJobViewModal();
              }}
              onUpdateClick={isEditMode ? () => onUpdateJob(job) : undefined}
              totalSkills={job.applicableTalentSkills?.length}
            />
          );
        })}
      </div>
      {open && (
        <JobModal
          auth={auth}
          profile={profile}
          job={selectedJob || undefined}
          open={open}
          handleModalClose={() => {
            setSelectedJob(null);
            toggle();
          }}
        />
      )}

      <DeleteJobConfirmationModal
        open={deleteJobModalOpen}
        onClose={toggleDeleteJobModal}
        isDeletionLoading={isDeletionLoading}
        onDelete={() => {
          if (jobIdToDelete) {
            deleteExperience(jobIdToDelete, {
              onSuccess: () => {
                toggleDeleteJobModal();
                profile.filterOutDeletedJob(jobIdToDelete);
                setJobIdToDelete(null);
              },
              onError: () => {
                toggleDeleteJobModal();
                setJobIdToDelete(null);
              },
            });
          }
        }}
      />

      {isSelectedJobExisting && (
        <JobModalView
          auth={auth}
          open={openJobViewModal}
          profile={profile}
          onClose={() => {
            setSelectedJob(null);
            toggleJobViewModal();
          }}
          job={selectedJob}
        />
      )}
    </div>
  );
};

export default Jobs;
