import React from 'react';
import { createUseStyles } from 'react-jss';
import Modal, { ModalBasicProps } from '..';
import { BorderRadius, Spacing, TextColors } from '@ateams/components';
import {
  VettingProcessPreVettingFormOld,
  VettingProcessSoftwareEngineeringPreVettingForm,
} from '@a_team/models/dist/vetting-processes/pre-vetting';
import { VettingFormVariant } from '@a_team/models/dist/vetting-processes/vetting-processes';
import { PreVettingProjectManagementAnswers } from './variant-answers/project-management';
import { PreVettingProductManagementAnswers } from './variant-answers/product-management';
import { PreVettingDesignAnswers } from './variant-answers/design';
import { PreVettingSoftwareEngineeringAnswers } from './variant-answers/software-engineering';
import { PreVettingCommonAnswers } from './common';
import { Button } from '@src/views/VettingFeedbackForm/components/button';
import { Text } from '@src/views/VettingFeedbackForm/components/typography';
import { VettingProcessAuditUser } from '@a_team/models/dist/vetting-process-audit';
import UserAvatar from '@src/components/UserAvatar';

export interface PreVettingOldAnswersModalProps extends ModalBasicProps {
  preVettingAnswersOld?: VettingProcessPreVettingFormOld;
  user?: VettingProcessAuditUser;
}

const useStyles = createUseStyles({
  container: {
    overflow: 'auto',
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
  },
  builderAvatarContainer: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Spacing.medium,
  },
  builderAvatar: {
    marginRight: Spacing.small,
  },
  builderName: {
    color: TextColors.regular,
    fontSize: '15px',
  },
  titleText: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '30px',
    textAlign: 'center',
    marginBottom: '40px',
  },
  closeButton: {
    width: '200px',
    height: '40px',
    borderRadius: BorderRadius.medium,
    alignSelf: 'center',
  },
});

export const PreVettingOldAnswersModal: React.FC<
  PreVettingOldAnswersModalProps
> = (props) => {
  const styles = useStyles();
  const { open, onClose, preVettingAnswersOld, user } = props;

  const renderVariantAnswers = () => {
    if (!preVettingAnswersOld) {
      return null;
    }

    if (preVettingAnswersOld.variant === VettingFormVariant.ProjectManagement) {
      return (
        <PreVettingProjectManagementAnswers answers={preVettingAnswersOld} />
      );
    }

    if (
      preVettingAnswersOld.variant === VettingFormVariant.ProductManagement ||
      preVettingAnswersOld.variant === VettingFormVariant.Marketing ||
      preVettingAnswersOld.variant === VettingFormVariant.Operations
    ) {
      return (
        <PreVettingProductManagementAnswers answers={preVettingAnswersOld} />
      );
    }

    if (preVettingAnswersOld.variant === VettingFormVariant.Design) {
      return <PreVettingDesignAnswers answers={preVettingAnswersOld} />;
    }

    return (
      <PreVettingSoftwareEngineeringAnswers
        answers={
          preVettingAnswersOld as VettingProcessSoftwareEngineeringPreVettingForm
        }
      />
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{ padding: 0, minWidth: 672, borderRadius: 0 }}
    >
      <div className={styles.container}>
        {user && (
          <a
            href={user.profileURL}
            target={'_blank'}
            rel={'noreferrer'}
            className={styles.builderAvatarContainer}
          >
            <UserAvatar
              src={user.profilePictureURL}
              size={24}
              containerClassName={styles.builderAvatar}
            />

            <div className={styles.builderName}>{user.fullName}</div>
          </a>
        )}

        <Text className={styles.titleText}>
          Answers from pre-evaluation form
        </Text>

        {preVettingAnswersOld && renderVariantAnswers()}

        {preVettingAnswersOld && (
          <PreVettingCommonAnswers
            answers={preVettingAnswersOld}
            builderEmail={user?.email}
          />
        )}

        <br />

        <Button onClick={onClose} className={styles.closeButton}>
          Close
        </Button>
      </div>
    </Modal>
  );
};
