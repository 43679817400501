import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import SectionHeading from '@src/components/SectionHeading';
import { Breakpoints } from '@ateams/components';
import calendar from './icon.svg';
import Modal from '@src/components/Modal';
import { NoCalendarReasonsModal } from '@src/views/InterviewSettingsView/ReasonsModal';
import { NoCalendarReasonsModal as NoCalendarReasonsModalV2 } from '@src/views/InterviewSettingsViewV2/ReasonsModal';
import { useStores } from '@src/stores';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { useHistory } from 'react-router-dom';
import { MissionLocation } from '@src/locations';
import { MissionId } from '@a_team/models/dist/MissionObject';
import { ConnectCalendarButton } from '@src/views/InterviewSettingsViewV2/ConnectCalendarButton';

const useStyles = createUseStyles({
  modal: {
    zIndex: 10003,
    textAlign: 'center',
    width: '680px !important',
    padding: '40px !important',
  },
  heading: {
    marginTop: '40px',
    maxWidth: '200px',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  copy1: {
    padding: '0 15px',
    color: '#818388',
  },
  actionWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '32px',
    paddingLeft: '12px',
    paddingRight: '12px',
    gap: 12,
  },
  [`@media (max-width: ${Breakpoints.sm}px)`]: {
    modal: {
      width: '95% !important',
      padding: '20px !important',
    },
    heading: {
      maxWidth: 'auto',
    },
    copy1: {
      padding: '0 10px',
      color: '#818388',
    },
    actionWrap: {
      flexDirection: 'column',
    },
  },
});

interface NoCalendarModalProps {
  missionId: MissionId;
}

export default function NoCalendarModal(props: NoCalendarModalProps) {
  const styles = useStyles();
  const [showReasons, setShowReasons] = useState(false);
  const { auth } = useStores();
  const analytics = useAnalytics();
  const history = useHistory();

  const handleDismiss = () => {
    history.push(`${MissionLocation}/${props.missionId}`);
  };

  if (showReasons) {
    return auth.withSharedCalendarFlow ? (
      <NoCalendarReasonsModalV2
        fullScreen
        open
        onClose={() => setShowReasons(false)}
      />
    ) : (
      <NoCalendarReasonsModal
        fullScreen
        open
        onClose={() => setShowReasons(false)}
      />
    );
  }

  return (
    <Modal onClose={handleDismiss} open className={styles.modal}>
      <div style={{ color: 'black' }}>
        <img src={calendar} style={{ width: 80 }} alt={'Calendar'} />
        <SectionHeading className={styles.heading}>
          Enable interview scheduling to apply
        </SectionHeading>
        <p className={styles.copy1}>
          Connect your Google, Apple or Outlook Calendar to apply to missions
          and allow companies to schedule interviews with you directly.
        </p>
        <div className={styles.actionWrap}>
          <ConnectCalendarButton
            triggerTeaser
            topAligned
            onCalendarClick={() => {
              setShowReasons(true);
              analytics.trackMissionAddCustomCalendarClicked(auth.uid ?? '');
            }}
          />
        </div>
      </div>
    </Modal>
  );
}
