import { CompanyV2Picker } from '@src/components/CompanyV2Picker';
import { ExistingJob, NewJob } from '@src/stores/Profile/models';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import { FormValues } from '.';

interface CompanyNameProps {
  register: UseFormRegister<FormValues>;
  setValue: UseFormSetValue<FormValues>;
  trigger: UseFormTrigger<FormValues>;
  watch: UseFormWatch<FormValues>;
  job?: NewJob | ExistingJob;
}

const useStyles = createUseStyles({
  label: {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  companyPicker: {
    height: '40px',
    border: '1px solid #DADADC !important',
  },
  companyNameWrapper: {
    '& span[class^="companyAvatarsContainer"]': {
      display: 'none',
    },
    '& input[class^="companyPickerInput"] + div + span': {
      display: 'none',
    },
    '& > span': {
      display: 'none',
    },
  },
});

const CompanyName = ({ job, setValue }: CompanyNameProps): JSX.Element => {
  const styles = useStyles();
  const [companyError] = useState('');
  const isOwner = true;

  const companyData = job && 'companyData' in job ? job.companyData : undefined;
  // company data uses companies V2
  const companyName = companyData?.name || job?.companyName;

  return (
    <div>
      <div className={styles.companyNameWrapper}>
        <label className={styles.label}>Company name</label>
        <CompanyV2Picker
          className={styles.companyPicker}
          onSelectCompanies={(companies) => {
            const company = companies[0] || null;

            setValue('companyName', company?.name ?? undefined, {
              shouldDirty: true,
            });
            setValue('companyUrl', company?.url ?? undefined);
            setValue('companyV2Id', company?.id ?? undefined, {
              shouldDirty: true,
            });
            setValue('logoURL', company?.logoUrl ?? '');
          }}
          error={companyError}
          disabled={!isOwner}
          initiallySelectedCompanyIds={
            job?.companyV2Id ? [job.companyV2Id] : []
          }
          displayName={companyName ?? undefined}
          onlySearchVerified
          canAddCompany
        />
      </div>
    </div>
  );
};

export default observer(CompanyName);
