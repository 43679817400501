import { Breakpoints, Icon, IconType } from '@ateams/components';
import cx from 'classnames';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { ModalPages } from '.';
import { Button } from '@a_team/ui-components';

interface FooterProps {
  className?: string;
  percentageScrolledToBottom: number;
  page: ModalPages;
  handleAgreeChecklist: () => void;
  handleAgreePlatformCoC: () => void;
  handleAgreeMissionCoC: () => void;
  handleBackButtonClick: () => void;
  loading: boolean;
  showOnly?: 'mission' | 'platform';
  onClose: () => void;
  hideExit?: boolean;
}

const useStyles = createUseStyles<{
  percentageScrolledToBottom?: number;
  showOnly?: 'mission' | 'platform';
}>({
  footerActions: {
    height: '64px',
    backgroundColor: '#F7F7F7',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 15px',
  },
  button: {
    width: 'fit-content',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backButton: {
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    color: '#62646A',
  },
  percentageScrolledToBottom: ({ percentageScrolledToBottom }) => ({
    // add a small delay to the animation width to make it look smoother
    transition: 'width 0.1s ease-in-out',
    width: `${percentageScrolledToBottom}%`,
    height: '4px',
    background: 'linear-gradient(90deg, #FF4CC1 0%, #A036FA 100%)',
  }),
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    footerActions: {
      height: '72px',
      padding: '0 40px',
    },
    button: {
      height: '40px',
    },
  },
});

const Footer = ({
  className,
  percentageScrolledToBottom,
  page,
  handleAgreeMissionCoC,
  handleAgreeChecklist,
  handleAgreePlatformCoC,
  handleBackButtonClick,
  loading,
  showOnly,
  onClose,
  hideExit,
}: FooterProps): JSX.Element => {
  const styles = useStyles({ percentageScrolledToBottom });

  return (
    <div className={cx(className)}>
      <div className={styles.percentageScrolledToBottom} />
      <div className={cx(styles.footerActions)}>
        <div>
          {!showOnly && page !== ModalPages.PlatformCodeOfConduct && (
            <button
              className={styles.backButton}
              onClick={handleBackButtonClick}
            >
              <Icon
                clickable
                type={IconType.ArrowBack}
                muted
                size={'medium'}
                style={{ opacity: 1, width: 22, marginRight: 8 }}
              />
              <span>Back</span>
            </button>
          )}
          {showOnly && !hideExit && (
            <button className={styles.backButton} onClick={onClose}>
              Exit
            </button>
          )}
        </div>

        {(page === ModalPages.PlatformCodeOfConduct ||
          showOnly === 'platform') && (
          <Button
            loading={loading}
            disabled={!(percentageScrolledToBottom > 95)}
            className={styles.button}
            onClick={handleAgreePlatformCoC}
          >
            I agree with the Code of Conduct
          </Button>
        )}

        {page === ModalPages.QuickOnboardingChecklist && !showOnly && (
          <Button
            loading={loading}
            disabled={loading}
            className={styles.button}
            onClick={handleAgreeChecklist}
          >
            I Completed Onboarding Checklist
          </Button>
        )}

        {(page === ModalPages.MissionCodeOfConduct ||
          showOnly === 'mission') && (
          <Button
            loading={loading}
            disabled={!(percentageScrolledToBottom > 95) || loading}
            className={styles.button}
            onClick={handleAgreeMissionCoC}
          >
            I agree with the Code of Conduct
          </Button>
        )}
      </div>
    </div>
  );
};

export default Footer;
