import React, { useState } from 'react';
import Modal, { ModalBasicProps } from '@src/components/Modal';
import { Button, TextColors } from '@ateams/components';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import { Column, Icon, Row, Spinner, Typography } from '@a_team/ui-components';
import { HubspotDealsURL, IS_PROD } from '@src/config';
import { useDeal } from '@src/rq/hubspot';
import { isEmpty } from 'lodash';
import formatDate from '@src/helpers/formatDate';

interface Props extends ModalBasicProps {
  onConfirm: (hubspotId: string) => void;
}

const DealInfo = ({
  label,
  value,
}: {
  label: string;
  value: string | undefined;
}) => (
  <Row style={{ marginBottom: 2 }}>
    <Column xs={4} sm={3}>
      <Typography variant="textMedium" weight="bold">
        {label}:
      </Typography>
    </Column>
    <Column xs={8} sm={9}>
      <Typography variant="textMedium">{value}</Typography>
    </Column>
  </Row>
);

export const HubspotIdModal = (props: Props) => {
  const [hubspotId, setHubspotId] = useState('');
  const { data: deal, isFetching, isError } = useDeal(hubspotId);
  return (
    <Modal open={props.open} onClose={props.onClose} style={{ width: 680 }}>
      <>
        <h2 style={{ fontSize: 20 }}>
          Assign HubSpot Deal ID to this sample proposal
        </h2>
        <span style={{ color: TextColors.regularLight }}>
          To find the Deal ID, visit the HubSpot{' '}
          <a href={HubspotDealsURL} target={'_blank'} rel="noreferrer">
            link
          </a>{' '}
          for this client, go to Deal, then copy the last 11 digits from the
          URL.
        </span>
        <>
          <OutlinedInput
            margin={'small'}
            placeholder={'12000000000...'}
            value={hubspotId}
            loading={isFetching}
            icon={
              isFetching ? (
                <Spinner
                  size="md"
                  style={{
                    marginRight: 8,
                    maxWidth: 'initial',
                    maxHeight: 'initial',
                  }}
                />
              ) : (
                <Icon name={'tag'} size={'md'} style={{ marginRight: 8 }} />
              )
            }
            onChange={(e) => setHubspotId(e.target.value)}
            error={isError}
            errorMessage={
              isError
                ? 'Incorrect ID. Check the information provided.'
                : undefined
            }
          />
          <small>
            For testing use id:{' '}
            <strong>{IS_PROD ? '17116811145' : '12833648824'}</strong>
          </small>
        </>
        {deal && !isEmpty(deal) && (
          <div style={{ marginTop: 40 }}>
            <DealInfo
              label="Deal name"
              value={(deal.dealname as string) || ''}
            />
            <DealInfo
              label="Create date"
              value={formatDate(new Date(deal.createdate as string))}
            />
            <DealInfo
              label="Owner name"
              value={deal.deal_owner_name as string}
            />
          </div>
        )}
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 40 }}
        >
          <Button
            size={'small'}
            width={'auto'}
            squared
            onClick={() => props.onConfirm(hubspotId)}
            disabled={hubspotId.trim().length < 11 || !deal || isError}
          >
            Assign and Generate
          </Button>
        </div>
      </>
    </Modal>
  );
};
