import { IconType } from '@ateams/components';
import MissionRole, {
  MissionAdminRole,
  MissionRoleSkill,
} from '@a_team/models/dist/MissionRole';
import { UserTalentSkillAssignment } from '@a_team/models/dist/TalentCategories';
import { useStores } from '@src/stores';
import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import Requirement from '../common/Requirement';
import Tag from '../common/Tag';

export enum SkillTypes {
  Required = 'Required',
  Preferred = 'Preferred',
}

interface SkillsPros {
  label: string;
  role: MissionRole | MissionAdminRole;
  skillsType: string;
}

export const useStyles = createUseStyles({
  skills: {
    fontWeight: 400,
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
  },
});

function Skills({ label, role, skillsType }: SkillsPros): JSX.Element | null {
  const styles = useStyles();
  const stores = useStores();
  const { auth } = stores;

  const allUserSkills: UserTalentSkillAssignment[] = useMemo(() => {
    if (!auth.user?.talentProfile) {
      return [];
    }

    return auth.user.talentProfile?.talentSkills.mainTalentSkills.concat(
      auth.user.talentProfile?.talentSkills.additionalTalentSkills,
    );
  }, [
    auth.user?.talentProfile?.talentSkills.mainTalentSkills,
    auth.user?.talentProfile?.talentSkills.additionalTalentSkills,
  ]);

  const skills = useMemo(() => {
    let skillsSource: MissionRoleSkill[] = [];

    if (skillsType === SkillTypes.Required) {
      skillsSource = Array.isArray(role?.requiredSkills)
        ? role.requiredSkills
        : [];
    } else if (skillsType === SkillTypes.Preferred) {
      skillsSource = Array.isArray(role?.preferredSkills)
        ? role.preferredSkills
        : [];
    }

    return skillsSource
      .map(({ talentSkillId, talentSkillName }) => {
        const skillFromUser: UserTalentSkillAssignment | undefined =
          allUserSkills.find(
            (userSkill) => userSkill.talentSkillId === talentSkillId,
          );

        let iconType: IconType | undefined;
        let tooltipMessage: string | undefined;
        let strikethrough = false;

        if (skillFromUser !== undefined) {
          if (skillFromUser?.rating && skillFromUser.rating < 3) {
            iconType = IconType.BlackArrowDown;
            tooltipMessage = `Companies generally require 3 or more stars in required skills. Change your skill ratings on your profile to 3 or more if you want to improve your chances of being selected for this mission`;
          }
        } else if (skillFromUser === undefined) {
          strikethrough = true;
          tooltipMessage = `Proficiency in this skill is required for this mission (we recommend a rating of ≥3 stars to be considered). You can "Request to join", and add this skill as part of the flow.`;
        }

        return {
          id: talentSkillId,
          iconType: iconType,
          name: talentSkillName,
          userRating: skillFromUser?.rating,
          tooltipMessage: tooltipMessage,
          strikethrough,
        };
      })
      .filter(({ name }) => name !== undefined);
  }, [allUserSkills, role.requiredSkills, role.preferredSkills]);

  const totalMissingRequiredSkills = useMemo(() => {
    if (
      skillsType === SkillTypes.Required &&
      Array.isArray(role?.requiredSkills)
    ) {
      return role.requiredSkills.filter(({ talentSkillId }) => {
        return !allUserSkills.find(
          (userSkill) => userSkill.talentSkillId === talentSkillId,
        );
      }).length;
    } else {
      return 0;
    }
  }, [allUserSkills, role.requiredSkills]);

  const totalMissingPreferredSkills = useMemo(() => {
    if (
      skillsType === SkillTypes.Preferred &&
      Array.isArray(role?.preferredSkills)
    ) {
      return role.preferredSkills.filter(({ talentSkillId }) => {
        return !allUserSkills.find(
          (userSkill) => userSkill.talentSkillId === talentSkillId,
        );
      }).length;
    } else {
      return 0;
    }
  }, [allUserSkills, role.preferredSkills]);

  if (skills.length === 0) {
    return null;
  }

  const missingSkillsMessage = (
    totalSkills: number,
    totalMissingSkills: number,
  ) => {
    return (
      <div>
        You are missing {totalMissingSkills} of {totalSkills}{' '}
        {skillsType === SkillTypes.Required ? 'required' : 'preferred'}{' '}
        {totalSkills > 1 ? 'skills' : 'skill'}
      </div>
    );
  };

  const meetsCriteria =
    (skillsType === SkillTypes.Required && totalMissingRequiredSkills === 0) ||
    (skillsType === SkillTypes.Preferred && totalMissingPreferredSkills === 0);

  const getFeedback = (): JSX.Element | undefined => {
    if (skillsType === SkillTypes.Required && totalMissingRequiredSkills > 0) {
      return missingSkillsMessage(
        role?.requiredSkills?.length || 0,
        totalMissingRequiredSkills,
      );
    }

    if (
      skillsType === SkillTypes.Preferred &&
      totalMissingPreferredSkills > 0
    ) {
      return missingSkillsMessage(
        role?.preferredSkills?.length || 0,
        totalMissingPreferredSkills,
      );
    }

    return undefined;
  };

  return (
    <>
      <Requirement
        iconType={IconType.Tag}
        name={label}
        dataTestingId="skills-requirement"
        meetsRequirement={meetsCriteria}
        feedback={getFeedback()}
      >
        <div className={styles.skills}>
          {skills.map(
            ({
              id,
              iconType,
              name,
              userRating,
              tooltipMessage,
              strikethrough,
            }) => {
              return (
                <Tag
                  key={id}
                  iconType={iconType}
                  name={name as string}
                  rating={userRating}
                  tooltipMessage={tooltipMessage}
                  strikethrough={strikethrough}
                />
              );
            },
          )}
        </div>
      </Requirement>
    </>
  );
}

export default Skills;
