import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { AsyncSelectProps, Select, SelectProps } from '@ateams/components';
import { OnVetterSelect, VetterSelector } from '../vetter-selector';
import {
  VettingType,
  vettingTypeToStatusMap,
} from '@a_team/models/dist/vetting-processes/vetting-processes';
import {
  VettingProcessPublicStatus,
  VettingProcessPublicStatusLabels,
} from '@a_team/models/dist/vetting-processes/status';
import {
  TalentSkillId,
  TalentSpecializationId,
} from '@a_team/models/dist/TalentCategories';
import { FetchVettingProcessesFilters } from '@ateams/api/dist/endpoints/vetting-process';
import { OnUserSelect, UserSelector } from '@src/components/UserSelector';
import { UserId, UserStatus } from '@a_team/models/dist/UserObject';
import { VetterId } from '@a_team/models/dist/vetter';
import { CodeSampleProgrammingLanguageSelector } from '../code-sample-programming-language-selector';
import { RoleSelector } from '../role-selector';
import {
  DateRangePicker,
  OnDateRangeChange,
} from '../../../components/OutlinedDateInput/DateRangePicker';

const USER_STATUSES_TO_FILTER = [
  UserStatus.Active,
  UserStatus.Rejected,
  UserStatus.Registered,
];

export type OnVettingProcessesFiltersChange = (
  filters: FetchVettingProcessesFilters,
) => void;

export interface VettingProcessesFiltersProps {
  onChange?: OnVettingProcessesFiltersChange;
  defaultValues?: FetchVettingProcessesFilters;
  availableFilters?: Set<FilterComponents>;
  showAllFilters?: boolean;
}

export enum FilterComponents {
  User = 'user',
  VettingType = 'vettingType',
  VettingStatus = 'vettingStatus',
  MainRole = 'mainRole',
  CodeSampleProgrammingLanguage = 'codeSampleProgrammingLanguage',
  HasSubmittedPreEvaluationForm = 'hasSubmittedPreEvaluationForm',
  Vetter = 'vetter',
  CreatedAt = 'createdAt',
  InterviewDate = 'interviewDate',
  Badge = 'badge',
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
    marginBottom: '10px',
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  inputContainer: {
    display: 'flex',
    alignSelf: 'center',
    width: '280px',
    marginBottom: '10px',
    marginRight: '20px',
    '&:last-child': {
      marginRight: 0,
    },
  },
});

export enum SubmittedPreEvaluationFormOptions {
  HasSubmittedForm = 'HasSubmittedForm',
  DidNotSubmitForm = 'DidNotSubmitForm',
}

export const HasSubmittedPreEvaluationFormLabels: Record<
  SubmittedPreEvaluationFormOptions,
  string
> = {
  [SubmittedPreEvaluationFormOptions.HasSubmittedForm]: 'Yes',
  [SubmittedPreEvaluationFormOptions.DidNotSubmitForm]: 'No',
};

const HAS_SUBMITTED_PRE_EVALUATION_FORM_OPTIONS = Object.entries(
  HasSubmittedPreEvaluationFormLabels,
).map(([key, label]) => ({ value: key, label }));

function getHasSubmittedPreEvaluationFormDefaultValue(
  hasSubmittedPreEvaluationForm?: boolean,
) {
  if (typeof hasSubmittedPreEvaluationForm === 'undefined') {
    return null;
  }

  if (
    typeof hasSubmittedPreEvaluationForm === 'string' &&
    hasSubmittedPreEvaluationForm === 'true'
  ) {
    return {
      value: SubmittedPreEvaluationFormOptions.HasSubmittedForm,
      label: HasSubmittedPreEvaluationFormLabels.HasSubmittedForm,
    };
  } else {
    return {
      value: SubmittedPreEvaluationFormOptions.DidNotSubmitForm,
      label: HasSubmittedPreEvaluationFormLabels.DidNotSubmitForm,
    };
  }
}

const VETTING_TYPE_OPTIONS = Object.entries(vettingTypeToStatusMap).map(
  ([key, label]) => ({ value: key, label }),
);

const VETTING_STATUS_OPTIONS = Object.entries(
  VettingProcessPublicStatusLabels,
).map(([key, label]) => ({ value: key, label }));

// const BADGE_OPTIONS = [
//   UserBadge.UnqualifiedUser,
//   UserBadge.BeenOnMission,
//   UserBadge.ExceptionalATeamer,
//   UserBadge.ATeamerResidence,
//   UserBadge.ATeamer,
// ].map((badge) => ({ value: badge, label: badgesConfig[badge].tooltip }));

export const VettingProcessesFilters: React.FC<VettingProcessesFiltersProps> = (
  props,
) => {
  const { defaultValues, availableFilters, showAllFilters } = props;
  const styles = useStyles();
  const [user, setUser] = useState<UserId | undefined>(
    defaultValues?.user || undefined,
  );
  const [vettingType, setVettingType] = useState<VettingType | undefined>(
    defaultValues?.vettingType || undefined,
  );
  const [vettingStatus, setVettingStatus] = useState<
    VettingProcessPublicStatus | undefined
  >(defaultValues?.status || undefined);
  const [mainRole, setMainRole] = useState<TalentSpecializationId | undefined>(
    defaultValues?.mainRole,
  );
  const [codeSampleProgrammingLanguage, setCodeSampleProgrammingLanguage] =
    useState<TalentSkillId | undefined>(
      defaultValues?.preVetting?.codeSampleProgrammingLanguage || undefined,
    );
  const [hasSubmittedPreEvaluationForm, setHasSubmittedPreEvaluationForm] =
    useState<boolean | undefined>(
      Boolean(defaultValues?.preVetting) || undefined,
    );
  const [vetter, setVetter] = useState<VetterId | undefined>(
    defaultValues?.vetter || undefined,
  );
  const [createdAtStartDate, setCreatedAtStartDate] = useState<
    Date | undefined
  >(
    defaultValues?.createdAt?.startDate
      ? new Date(defaultValues.createdAt.startDate)
      : undefined,
  );
  const [createdAtEndDate, setCreatedAtEndDate] = useState<Date | undefined>(
    defaultValues?.createdAt?.endDate
      ? new Date(defaultValues.createdAt.endDate)
      : undefined,
  );
  const [interviewStartDate, setInterviewStartDate] = useState<
    Date | undefined
  >(
    defaultValues?.interviewDate?.startDate
      ? new Date(defaultValues.interviewDate.startDate)
      : undefined,
  );
  const [interviewEndDate, setInterviewEndDate] = useState<Date | undefined>(
    defaultValues?.interviewDate?.endDate
      ? new Date(defaultValues.interviewDate.endDate)
      : undefined,
  );
  // const [badge, setBadge] = useState<UserBadge | undefined>(
  //   defaultValues?.badge || undefined,
  // );

  const onChange = () => {
    if (props.onChange) {
      props.onChange({
        user,
        vettingType,
        preVetting: {
          codeSampleProgrammingLanguage,
          hasSubmittedForm: hasSubmittedPreEvaluationForm,
        },
        createdAt: {
          startDate: createdAtStartDate,
          endDate: createdAtEndDate,
        },
        interviewDate: {
          startDate: interviewStartDate,
          endDate: interviewEndDate,
        },
        status: vettingStatus,
        vetter,
        mainRole,
        // badge,
      });
    }
  };

  useEffect(onChange, [
    user,
    vettingType,
    vettingStatus,
    mainRole,
    codeSampleProgrammingLanguage,
    hasSubmittedPreEvaluationForm,
    createdAtStartDate,
    createdAtEndDate,
    interviewStartDate,
    interviewEndDate,
    vetter,
    // badge,
  ]);

  const onUserSelect: OnUserSelect = (option) => {
    const selectedUser = option?.user?.uid || undefined;
    setUser(selectedUser);
  };

  const onVettingTypeSelect: SelectProps['onChange'] = (option) => {
    setVettingType((option?.value as VettingType) || undefined);
  };

  const onVettingStatusSelect: SelectProps['onChange'] = (option) => {
    setVettingStatus(
      (option?.value as VettingProcessPublicStatus) || undefined,
    );
  };

  const onMainRoleChange: AsyncSelectProps['onChange'] = (option) => {
    setMainRole((option?.value as TalentSpecializationId) || undefined);
  };

  const onCodeSampleProgrammingLanguageChange: AsyncSelectProps['onChange'] = (
    option,
  ) => {
    setCodeSampleProgrammingLanguage(
      (option?.value as TalentSkillId) || undefined,
    );
  };

  const onHasSubmittedPreEvaluationFormChange: SelectProps['onChange'] = (
    option,
  ) => {
    switch (option?.value) {
      case SubmittedPreEvaluationFormOptions.HasSubmittedForm:
        setHasSubmittedPreEvaluationForm(true);
        break;
      case SubmittedPreEvaluationFormOptions.DidNotSubmitForm:
        setHasSubmittedPreEvaluationForm(false);
        break;
      default:
        setHasSubmittedPreEvaluationForm(undefined);
    }
  };

  const onVetterSelect: OnVetterSelect = (option) => {
    const selectedVetter = option?.vetter?.id || undefined;
    setVetter(selectedVetter);
  };

  const onCreatedAtDateRangeChange: OnDateRangeChange = (
    startDate,
    endDate,
  ) => {
    setCreatedAtStartDate(startDate);
    setCreatedAtEndDate(endDate);
  };

  const onInterviewDateRangeChange: OnDateRangeChange = (
    startDate,
    endDate,
  ) => {
    setInterviewStartDate(startDate);
    setInterviewEndDate(endDate);
  };

  // const onBadgeChange: SelectProps['onChange'] = (option) => {
  //   setBadge((option?.value as UserBadge) || undefined);
  // };

  const shouldShowUserFilter =
    showAllFilters ||
    !availableFilters ||
    availableFilters.has(FilterComponents.User);
  const shouldShowVettingTypeFilter =
    showAllFilters ||
    !availableFilters ||
    availableFilters.has(FilterComponents.VettingType);
  const shouldShowVettingStatusFilter =
    showAllFilters ||
    !availableFilters ||
    availableFilters.has(FilterComponents.VettingStatus);
  const shouldShowMainRoleFilter =
    showAllFilters ||
    !availableFilters ||
    availableFilters.has(FilterComponents.MainRole);
  const shouldShowCodeSampleProgrammingLanguageFilter =
    showAllFilters ||
    !availableFilters ||
    availableFilters.has(FilterComponents.CodeSampleProgrammingLanguage);
  const shouldShowHasSubmittedPreEvaluationFormFilter =
    showAllFilters ||
    !availableFilters ||
    availableFilters.has(FilterComponents.HasSubmittedPreEvaluationForm);
  const shouldShowVetterFilter =
    showAllFilters ||
    !availableFilters ||
    availableFilters.has(FilterComponents.Vetter);
  const shouldShowCreatedAtFilter =
    showAllFilters ||
    !availableFilters ||
    availableFilters.has(FilterComponents.CreatedAt);
  const shouldShowInterviewDateFilter =
    showAllFilters ||
    !availableFilters ||
    availableFilters.has(FilterComponents.InterviewDate);
  // const shouldShowBadgeFilter =
  //   showAllFilters ||
  //   !availableFilters ||
  //   availableFilters.has(FilterComponents.Badge);

  return (
    <div className={styles.container}>
      <div className={styles.title}>Filters</div>

      <div className={styles.inputsContainer}>
        {shouldShowUserFilter && (
          <div className={styles.inputContainer}>
            <UserSelector
              label={'Evaluated User'}
              placeholder={'Search User...'}
              defaultValue={defaultValues?.user}
              onUserSelect={onUserSelect}
              margin={'none'}
              userStatus={USER_STATUSES_TO_FILTER}
              data-testing-id={'selection-team-table-filter-vetted-user'}
            />
          </div>
        )}

        {shouldShowVettingTypeFilter && (
          <div className={styles.inputContainer}>
            <Select
              label={'Evaluation Type'}
              placeholder={'Select vetting type...'}
              onChange={onVettingTypeSelect}
              options={VETTING_TYPE_OPTIONS}
              defaultValue={VETTING_TYPE_OPTIONS.find(
                ({ value }) => value === defaultValues?.vettingType,
              )}
              margin={'none'}
              isClearable
              data-testing-id={'selection-team-table-filter-vetting-type'}
            />
          </div>
        )}

        {shouldShowVettingStatusFilter && (
          <div className={styles.inputContainer}>
            <Select
              label={'Evaluation Status'}
              placeholder={'Select vetting status...'}
              onChange={onVettingStatusSelect}
              options={VETTING_STATUS_OPTIONS}
              defaultValue={VETTING_STATUS_OPTIONS.find(
                ({ value }) => value === defaultValues?.status,
              )}
              margin={'none'}
              isClearable
              data-testing-id={'selection-team-table-filter-vetting-status'}
            />
          </div>
        )}

        {shouldShowMainRoleFilter && (
          <div className={styles.inputContainer}>
            <RoleSelector
              label={'Main Role'}
              onChange={onMainRoleChange}
              defaultValue={defaultValues?.mainRole}
              margin={'none'}
              data-testing-id={'selection-team-table-filter-main-role'}
            />
          </div>
        )}

        {shouldShowCodeSampleProgrammingLanguageFilter && (
          <div className={styles.inputContainer}>
            <CodeSampleProgrammingLanguageSelector
              label={'Additional Materials Language'}
              onChange={onCodeSampleProgrammingLanguageChange}
              defaultValue={
                defaultValues?.preVetting?.codeSampleProgrammingLanguage
              }
              margin={'none'}
              data-testing-id={
                'selection-team-table-filter-code-sample-programming-language'
              }
            />
          </div>
        )}

        {shouldShowHasSubmittedPreEvaluationFormFilter && (
          <div className={styles.inputContainer}>
            <Select
              label={'Has submitted pre-evaluation form'}
              onChange={onHasSubmittedPreEvaluationFormChange}
              options={HAS_SUBMITTED_PRE_EVALUATION_FORM_OPTIONS}
              defaultValue={getHasSubmittedPreEvaluationFormDefaultValue(
                hasSubmittedPreEvaluationForm,
              )}
              margin={'none'}
              isClearable
              data-testing-id={
                'selection-team-table-filter-has-submitted-pre-evaluation-form'
              }
            />
          </div>
        )}

        {shouldShowVetterFilter && (
          <div className={styles.inputContainer}>
            <VetterSelector
              label={'Interviewer'}
              placeholder={'Search Vetter...'}
              defaultValue={defaultValues?.vetter}
              onVetterSelect={onVetterSelect}
              margin={'none'}
              data-testing-id={'selection-team-table-filter-interviewer'}
            />
          </div>
        )}

        {shouldShowCreatedAtFilter && (
          <div className={styles.inputContainer}>
            <DateRangePicker
              startDate={createdAtStartDate}
              endDate={createdAtEndDate}
              placeholder={'Select date range...'}
              onChange={onCreatedAtDateRangeChange}
              tooltipProps={{ position: 'bottom-end' }}
              inputProps={{ label: 'Evaluation Started Date' }}
              data-testing-id={'selection-team-table-filter-vetting-started-at'}
            />
          </div>
        )}

        {shouldShowInterviewDateFilter && (
          <div className={styles.inputContainer}>
            <DateRangePicker
              startDate={interviewStartDate}
              endDate={interviewEndDate}
              placeholder={'Select date range...'}
              onChange={onInterviewDateRangeChange}
              tooltipProps={{ position: 'bottom-end' }}
              inputProps={{ label: 'Interview Date' }}
              data-testing-id={'selection-team-table-filter-interview-date'}
            />
          </div>
        )}

        {/* {shouldShowBadgeFilter && (
          <div className={styles.inputContainer}>
            <Select
              label={'User Badge'}
              placeholder={'Select badge...'}
              onChange={onBadgeChange}
              options={BADGE_OPTIONS}
              defaultValue={BADGE_OPTIONS.find(
                ({ value }) => value === defaultValues?.badge,
              )}
              margin={'none'}
              isClearable
              data-testing-id={'selection-team-table-filter-user-badge'}
            />
          </div>
        )} */}
      </div>
    </div>
  );
};
