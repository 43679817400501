import { Checkbox } from '@a_team/ui-components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { Spacing, FontSizes, Colors, FontWeights } from '@ateams/components';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: Spacing.xsmall,
  },
  label: {
    fontSize: FontSizes.small,
    color: Colors.regularDark,
    fontWeight: FontWeights.regular,
  },
});

export interface FieldCheckboxProps {
  text: string;
  value: boolean | undefined;
  onChange: (value: boolean) => void;
  className?: string;
  textClassName?: string;
}

export const FieldCheckbox = ({
  text,
  value,
  onChange,
  className,
  textClassName,
}: FieldCheckboxProps) => {
  const styles = useStyles();

  return (
    <div className={cn(styles.container, className)}>
      <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />
      <div className={cn(styles.label, textClassName)}>{text}</div>
    </div>
  );
};
