import _ from 'lodash';

// eslint-disable-next-line @typescript-eslint/ban-types
export function partialEqual<T extends P, P extends {}>(
  full: T,
  partial: P,
): boolean {
  for (const key in partial) {
    if (partial[key] !== full[key]) {
      if (
        typeof full[key] === 'object' &&
        typeof partial[key] === 'object' &&
        JSON.stringify(full[key]) === JSON.stringify(partial[key])
      ) {
        continue;
      }

      return false;
    }
  }

  return true;
}

export const delayedPromise = (delay: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, delay));

export const toggleArrayItem = <T>(
  array: T[] | undefined,
  item: T,
): T[] | undefined => {
  if (array) {
    return _.xor(array, [item]);
  }

  return undefined;
};

export const toOrdinalNumber = (num: number): string => {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const remainder = num % 100;

  return (
    num +
    (suffixes[(remainder - 20) % 10] || suffixes[remainder] || suffixes[0])
  );
};

export const joinWithCommasAnd = (
  arr: string[],
  lastConnector = 'and',
): string => {
  if (!Array.isArray(arr) || arr.length === 0) return '';
  if (arr.length === 1) return arr[0];
  return `${arr.slice(0, -1).join(', ')} ${lastConnector} ${arr.slice(-1)}`;
};

const htmlEntities: {
  [key: string]: string;
} = {
  '&amp;': '&',
  '&lt;': '<',
  '&gt;': '>',
  '&quot;': '"',
  '&apos;': "'",
};

export const decodeHtmlEntities = (text: string): string => {
  return text.replace(
    /&amp;|&lt;|&gt;|&quot;|&apos;/g,
    (match) => htmlEntities[match],
  );
};

export const scrollToElementById = (id: string): void => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

export const normalizeQueryParams = (
  url: string,
  shouldEncode?: boolean,
): string => {
  const firstQuestionMark = url.indexOf('?');

  // no query params appended
  if (firstQuestionMark === -1) {
    return url;
  }

  // grab  the first part including `?`
  const pathnameWithQuestionMark = url.slice(0, firstQuestionMark + 1);
  // grab the query params
  const queryParams = url.slice(firstQuestionMark + 1);

  // this basically is just replace all `?` with `&`
  const normalizedUrl = `${pathnameWithQuestionMark}${queryParams.replace(
    /\?/g,
    '&',
  )}`;

  return shouldEncode ? encodeURIComponent(normalizedUrl) : normalizedUrl;
};
