import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { AdminVettingProcess } from '@a_team/models/dist/vetting-processes/vetting-processes';
import {
  publicStatusToInternalStatusMap,
  VettingProcessPublicStatus,
  VettingProcessPublicStatusLabels,
  VettingProcessStatus,
} from '@a_team/models/dist/vetting-processes/status';
import { AdminVettingProcessColumnProps } from '..';
import { Select, SelectProps } from '@ateams/components';
import { useStores } from '@src/stores';
import useLoadingState from '@src/hooks/useLoadingState';
import { VettingProcessFeedbackScores } from '@a_team/models/dist/vetting-processes/feedback';

export function getStatusFromVettingProcess(
  vettingProcess: AdminVettingProcess,
): VettingProcessPublicStatus {
  if (vettingProcess.status === VettingProcessStatus.OnHold) {
    return VettingProcessPublicStatus.OnHold;
  }

  if (vettingProcess.status === VettingProcessStatus.PendingPreVettingForm) {
    return VettingProcessPublicStatus.SentPreVettingForm;
  }

  if (vettingProcess.status === VettingProcessStatus.PendingVetterAssignment) {
    return VettingProcessPublicStatus.PendingVetterAssignment;
  }

  if (vettingProcess.status === VettingProcessStatus.PendingVetterApproval) {
    return VettingProcessPublicStatus.PendingVetterApproval;
  }

  if (
    [
      VettingProcessStatus.PendingFeedback,
      VettingProcessStatus.PendingBuilderInterviewSchedule,
    ].includes(vettingProcess.status)
  ) {
    return getPendingFeedbackStatus(vettingProcess);
  }

  if (vettingProcess.status === VettingProcessStatus.Completed) {
    return getCompletedStatus(vettingProcess);
  }

  throw new Error('Received unknown vetting status ' + vettingProcess.status);
}

function getPendingFeedbackStatus(vettingProcess: AdminVettingProcess) {
  if (!vettingProcess.interviewDate) {
    return VettingProcessPublicStatus.PendingCandidateScheduling;
  }

  if (new Date(vettingProcess.interviewDate) < new Date()) {
    return VettingProcessPublicStatus.PendingFeedback;
  }

  return VettingProcessPublicStatus.InterviewScheduled;
}

function getCompletedStatus(vettingProcess: AdminVettingProcess) {
  if (
    typeof vettingProcess.feedback?.feedbackAccepted === 'undefined' &&
    vettingProcess.feedback &&
    vettingProcess.user.scores &&
    Object.entries(vettingProcess.user.scores).some(
      ([key, score]) =>
        score !==
        vettingProcess.feedback?.answers.scores[
          key as keyof VettingProcessFeedbackScores
        ],
    )
  ) {
    return VettingProcessPublicStatus.RatingConflict;
  }

  return VettingProcessPublicStatus.Completed;
}

const options = Object.entries(VettingProcessPublicStatusLabels).map(
  ([key, value]) => ({
    value: key,
    label: value,
  }),
);

function getInternalStatusFromStatus(status: VettingProcessPublicStatus) {
  return publicStatusToInternalStatusMap[status];
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const StatusField: React.FC<AdminVettingProcessColumnProps> = ({
  vettingProcess,
}) => {
  const { vettingProcesses } = useStores();
  const [loading, setLoading] = useLoadingState();
  const styles = useStyles();

  const defaultValue = useMemo(() => {
    try {
      const status = getStatusFromVettingProcess(vettingProcess);

      return options.find((option) => option.value === status);
    } catch (_) {
      return undefined;
    }
  }, []);

  const isOptionDisabled: SelectProps['isOptionDisabled'] = (option) => {
    const internalStatus = getInternalStatusFromStatus(
      option.value as VettingProcessPublicStatus,
    );

    if (internalStatus !== VettingProcessStatus.PendingFeedback) {
      return false;
    }

    const allowedStatus = getPendingFeedbackStatus(vettingProcess);

    return allowedStatus !== option.value;
  };

  const onSelect: SelectProps['onChange'] = (option) => {
    if (option) {
      const internalStatus = getInternalStatusFromStatus(
        option.value as VettingProcessPublicStatus,
      );

      setLoading(
        vettingProcesses.patchVettingProcess({
          id: vettingProcess.id,
          status: internalStatus,
        }),
      );
    }
  };

  return (
    <div className={styles.container}>
      <Select
        menuPortalTarget={document.body}
        defaultValue={defaultValue}
        onChange={onSelect}
        hideSelectedOptions={false}
        isOptionDisabled={isOptionDisabled}
        placeholder={'Select status'}
        options={options}
        width="fixed"
        isDisabled={Boolean(loading)}
        useRegularSelectInput
      />
    </div>
  );
};
