import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    marginBottom: '24px',
  },
  title: {
    fontWeight: 500,
    marginBottom: 8,
  },
  text: {
    whiteSpace: 'pre-wrap',
  },
});

interface DescriptionProps {
  text: string;
  title?: string;
}

function Description({ text, title }: DescriptionProps): JSX.Element {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      {title && <div className={styles.title}>{title}</div>}
      <div className={styles.text}>{text}</div>
    </div>
  );
}

export default Description;
