import React from 'react';
import { useCommonStyles } from '../commonStyles';
import { createUseStyles } from 'react-jss';
import {
  BorderColors,
  BorderRadius,
  Colors,
  SelectOption,
  Spacing,
} from '@ateams/components';
import { FieldDropdown } from '../general/FieldDropdown';
import { theme } from '@a_team/ui-components';
import {
  LocationAutocompleteV2,
  LocationSelectOption,
  OnLocationChange,
} from '@src/components/LocationAutocompleteV2';
import {
  formatLocation,
  parseLocation,
} from '@src/components/LocationAutocompleteV2/utils';
import LocationObject from '@a_team/models/dist/LocationObject';
import { FieldTextarea } from '../general/FieldTextarea';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.small,
    backgroundColor: Colors.backgroundLight,
    borderRadius: BorderRadius.xLarge,
  },
  locationAndAvailabilityContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.medium,
    border: `1px solid ${BorderColors.light}`,
    borderRadius: BorderRadius.xLarge,
    backgroundColor: Colors.backgroundWhite,
    padding: Spacing.medium,
  },
  locationContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.small,
  },
  locationAutocomplete: {
    width: 400,
    margin: 0,
  },
  impressionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
    padding: Spacing.medium,
    paddingTop: 0,
  },
});

export interface LocationAndAvailabilitySectionProps {
  location: LocationObject | null;
  onLocationChange: (location: LocationObject | null) => void;
  availabilityOptions: SelectOption[];
  availability: SelectOption | undefined;
  onAvailabilityChange: (availability: SelectOption | undefined) => void;
  impressions: string;
  onImpressionsChange: (impressions: string) => void;
}

export const LocationAndAvailabilitySection: React.FC<
  LocationAndAvailabilitySectionProps
> = ({
  location,
  onLocationChange,
  availabilityOptions,
  availability,
  onAvailabilityChange,
  impressions,
  onImpressionsChange,
}) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();

  const onLocationChangeInternal: OnLocationChange = (options) => {
    if (!options) return;
    const option = Array.isArray(options) ? options[0] : options;
    const { prediction, countryShortName } = option;

    if (prediction?.structured_formatting) {
      const location = parseLocation(
        prediction.structured_formatting,
        countryShortName,
      );

      onLocationChange(location);
    }
  };

  return (
    <div className={commonStyles.section}>
      <div className={commonStyles.sectionTitle}>Location & Availability</div>
      <div className={styles.container}>
        <div className={styles.locationAndAvailabilityContainer}>
          <div className={styles.locationContainer}>
            <div className={commonStyles.title}>Other location</div>
            <LocationAutocompleteV2
              openMenuOnClick={false}
              defaultInputValue={
                location ? formatLocation(location) : undefined
              }
              autocompleteOptions={{ types: ['(cities)'] }}
              onChange={(option) =>
                onLocationChangeInternal(option as LocationSelectOption | null)
              }
              className={styles.locationAutocomplete}
              color={location ? theme.colors.Grey[200] : undefined}
              placeholder="Enter location"
              overrideStyles={{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                control: (provided: any) => ({
                  ...provided,
                  border: 0,
                  boxShadow: 'none !important',
                  '&:hover': {
                    border: `1px solid ${Colors.regularDark}`,
                  },
                }),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                valueContainer: (provided: any) => ({
                  ...provided,
                  border: `1px solid ${BorderColors.light}`,
                  borderRadius: 4,
                  height: 38,
                  fontSize: 12,
                  fontWeight: 400,
                }),
              }}
            />
          </div>
          <FieldDropdown
            label="Does this builder work elsewhere?"
            options={availabilityOptions}
            value={availability}
            placeholder="Select"
          />
        </div>

        <div className={styles.impressionsContainer}>
          <FieldTextarea
            label="Note about location & availability *"
            placeholder="Note any inconsistencies or relevant details about availability/location"
            value={impressions}
            onChange={(value) => onImpressionsChange(value)}
            minLength={40}
          />
        </div>
      </div>
    </div>
  );
};
