import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import {
  Breakpoints,
  Colors,
  FontSizes,
  FontWeights,
  Spacing,
  Tag,
} from '@ateams/components';
import { ApplicantFlags } from '@a_team/models/dist/ApplicantFlags';
import UserObject from '@a_team/models/dist/UserObject';

const MISSION_DISPUTE_TAG_NAME = 'mission_dispute';
const MISSION_WATCHLIST_TAG_NAME = 'mission_watchlist';

interface Props {
  flags: ApplicantFlags;
  customTags: UserObject['customTags'];
}

const useStyles = createUseStyles({
  '@global': {
    '.tippy-popper': {
      zIndex: '999999 !important',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: Colors.backgroundLight,
    borderRadius: 5,
    padding: Spacing.large,
    paddingTop: 0,
  },
  title: {
    fontSize: FontSizes.large,
    fontWeight: FontWeights.medium,
    color: Colors.dark,
  },
  row: {
    display: 'flex',
  },
  col: {
    display: 'flex',
    alignItems: 'center',
    marginRight: Spacing.medium,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    wrapper: {
      textAlign: 'left',
      alignItems: 'flex-start',
      margin: 0,
    },
  },
});

export const BuilderFlagsBanner = ({
  flags,
  customTags,
}: Props): ReactElement => {
  const styles = useStyles();
  const displayFlags = getDisplayFlagsFromApplicantFlagsAndCustomTags(
    flags,
    customTags,
  );

  if (!displayFlags.length) return <></>;

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleRow}>
        <h4 className={styles.title}>Builder flags</h4>
        <div className={styles.row}>
          {displayFlags.map((displayFlag) => (
            <div key={displayFlag} className={styles.col}>
              <Tag
                fontSize="medium"
                color={'backgroundWhite'}
                square
                borderColor="regular"
              >
                {displayFlag}
              </Tag>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const getDisplayFlagsFromApplicantFlagsAndCustomTags = (
  flags?: ApplicantFlags,
  customTags?: UserObject['customTags'],
): string[] => {
  if (!flags) return [];
  const displayFlags = [];

  if (flags.hasPerformanceIssue) displayFlags.push('Performance issue');
  if (flags.activeOnMission) displayFlags.push('Active on mission');
  if (flags.currentlyInterviewing?.length)
    displayFlags.push('Currently interviewing');
  if (flags.currentlyProposed?.length) displayFlags.push('Currently proposed');

  if (
    customTags?.some((customTag) => customTag.name === MISSION_DISPUTE_TAG_NAME)
  )
    displayFlags.push('Mission dispute');
  if (
    customTags?.some(
      (customTag) => customTag.name === MISSION_WATCHLIST_TAG_NAME,
    )
  )
    displayFlags.push('Mission watchlist');

  return displayFlags;
};
