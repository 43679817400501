import { observer } from 'mobx-react';
import VettingFeedbackFormV2 from './VettingFeedbackFormV2';
import VettingFeedbackFormView from '../VettingFeedbackForm';
import React from 'react';
import { useStores } from '@src/stores';

const VettingFeedbackFormContainer = () => {
  const { auth } = useStores();

  return (
    auth.user &&
    (auth.withNewEvaluationFeedbackForm ? (
      <VettingFeedbackFormV2 />
    ) : (
      <VettingFeedbackFormView />
    ))
  );
};

export default observer(VettingFeedbackFormContainer);
