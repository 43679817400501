import { InteractionExperienceLabels } from '@a_team/models/dist/AdminNotesObject';
import React from 'react';
import { BasicVettingProcessColumnProps } from '..';

export const InteractionExperienceField: React.FC<
  BasicVettingProcessColumnProps
> = ({ vettingProcess }) => {
  if (
    typeof vettingProcess.feedback?.answers.scores.interactionExperience !==
    'number'
  ) {
    return null;
  }

  return (
    <>
      {
        InteractionExperienceLabels[
          vettingProcess.feedback.answers.scores.interactionExperience
        ]
      }
    </>
  );
};
