import { UserBadge as UserBadgeType } from '@a_team/models/dist/UserObject';
import React, { CSSProperties, ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { Theme } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import TooltipWrapped from '../TooltipWrapped';
import aTeamer from './images/aTeamer.svg';
import aTeamerResidence from './images/aTeamerResidence.svg';
import aTeamerResidenceV2 from './images/aTeamerResidenceV2.svg';
import aTeamerV2 from './images/aTeamerV2.svg';
import beenOnMission from './images/beenOnMission.svg';
import beenOnMissionV2 from './images/beenOnMissionV2.svg';
import exceptionalATeamer from './images/exceptionalATeamer.svg';
import exceptionalATeamerV2 from './images/exceptionalATeamerV2.svg';
import highPotential from './images/highPotential.svg';
import highPotentialV2 from './images/highPotentialV2.svg';
import notScrubbed from './images/notScrubbed.svg';
import notScrubbedV2 from './images/notScrubbedV2.svg';
import selectionTeam from './images/selectionTeam.svg';
import selectionTeamV2 from './images/selectionTeamV2.svg';
import unqualified from './images/unqualified.svg';
import unqualifiedV2 from './images/unqualifiedV2.svg';
import vettingScheduled from './images/vettingScheduled.svg';
import vettingScheduledV2 from './images/vettingScheduledV2.svg';
import vettingInterviewDate from './images/vettingInterviewDate.svg';
import vettingInterviewDateV2 from './images/vettingInterviewDateV2.svg';
import limitedAccess from './images/limitedAccess.svg';
import { observer } from 'mobx-react';

export const badgesConfig = {
  [UserBadgeType.ATeamer]: {
    img: aTeamer,
    imgV2: aTeamerV2,
    tooltip: `Vetted A.Teamer`,
  },
  [UserBadgeType.ATeamerResidence]: {
    img: aTeamerResidence,
    imgV2: aTeamerResidenceV2,
    tooltip: `A.Teamer in Residence`,
  },
  [UserBadgeType.BeenOnMission]: {
    img: beenOnMission,
    imgV2: beenOnMissionV2,
    tooltip: `Been on Mission`,
  },
  [UserBadgeType.ExceptionalATeamer]: {
    img: exceptionalATeamer,
    imgV2: exceptionalATeamerV2,
    tooltip: `Exceptional A.Teamer`,
  },
  [UserBadgeType.HighPotential]: {
    img: highPotential,
    imgV2: highPotentialV2,
    tooltip: `High Potential User`,
  },
  [UserBadgeType.NotScrubbed]: {
    img: notScrubbed,
    imgV2: notScrubbedV2,
    tooltip: `Not Scrubbed`,
  },
  [UserBadgeType.SelectionTeam]: {
    img: selectionTeam,
    imgV2: selectionTeamV2,
    tooltip: `SelectionTeam`,
  },
  [UserBadgeType.UnqualifiedUser]: {
    img: unqualified,
    imgV2: unqualifiedV2,
    tooltip: `Unqualified`,
  },
  [UserBadgeType.VettingScheduled]: {
    img: vettingScheduled,
    imgV2: vettingScheduledV2,
    tooltip: `Evaluation Call Pending`,
  },
  [UserBadgeType.VettingInterviewDate]: {
    img: vettingInterviewDate,
    imgV2: vettingInterviewDateV2,
    tooltip: `Evaluation Call Booked`,
  },
  [UserBadgeType.Unvetted]: {
    img: notScrubbed,
    imgV2: notScrubbedV2,
    tooltip: `Not fully vetted`,
  },
  [UserBadgeType.LimitedAccess]: {
    img: notScrubbed,
    imgV2: limitedAccess,
    tooltip: `Limited Access`,
  },
};

interface Props {
  style?: CSSProperties;
  className?: string;
  tooltipTheme?: Theme;
  badge: UserBadgeType;
  size?: number;
  tooltipPosition?: 'right' | 'top';
}

const useStyles = createUseStyles({
  badge: (props: Props) => ({
    width: Math.max(props.size || 0, 16),
    height: Math.max(props.size || 0, 16),
  }),
});

const UserBadge = (props: Props): ReactElement => {
  const { badge, tooltipTheme, tooltipPosition, ...rest } = props;
  const styles = useStyles(props);
  const badgeConfig = badgesConfig[badge];
  if (!badgeConfig) {
    return <></>;
  }
  return (
    <span {...rest} className={styles.badge}>
      <TooltipWrapped
        title={badgeConfig.tooltip}
        theme={tooltipTheme || 'dark'}
        position={tooltipPosition || 'right'}
        arrow
        animation="fade"
        style={{ display: 'inline-block' }}
      >
        <img
          src={badgeConfig.imgV2}
          alt={badgeConfig.tooltip}
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            width: '100%',
            height: '100%',
          }}
        />
      </TooltipWrapped>
    </span>
  );
};

export default observer(UserBadge);
