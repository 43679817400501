import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  Colors,
  FontSizes,
  FontWeights,
  Spacing,
  BorderRadius,
} from '@ateams/components';

const useStyles = createUseStyles({
  shareSection: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.medium,
    padding: Spacing.medium,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  shareContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
  },
  shareTitle: {
    fontFamily: 'Inter',
    fontSize: FontSizes.large,
    fontWeight: FontWeights.semiBold,
    lineHeight: '26px',
    textAlign: 'left',
    color: Colors.dark,
  },
  shareDescription: {
    fontSize: FontSizes.medium,
    color: Colors.dark,
    fontWeight: FontWeights.regular,
    lineHeight: '20px',
  },
  shareButton: {
    padding: `${Spacing.small}px ${Spacing.medium}px`,
    backgroundColor: Colors.backgroundDark,
    color: Colors.dark,
    borderRadius: BorderRadius.medium,
    border: 'none',
    cursor: 'pointer',
    height: 40,
  },
});
export const ShareSection = () => {
  const styles = useStyles();

  return (
    <div className={styles.shareSection}>
      <div className={styles.shareContent}>
        <div className={styles.shareTitle}>Evaluation feedback on builder</div>
        <div className={styles.shareDescription}>
          Use this form to document your builder evaluation call. <br />
          Your feedback helps us maintain a high bar for A.Team’s network.
        </div>
      </div>
      <button className={styles.shareButton}>Share form</button>
    </div>
  );
};
