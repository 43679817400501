import { Breakpoints } from '@ateams/components';
import React from 'react';
import { useScreenClass } from 'react-grid-system';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { VideoPlayer } from '@src/components/VideoPlayer';
import { UPLOADCARE_ASSETS_BASE_URL } from '@src/config';

export enum StepStatus {
  INCOMPLETE = 'INCOMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  INFO = 'INFO',
}

export interface SetupProps {
  stepIcon: React.ReactElement;
  title: string;
  description: React.ReactElement | string;
  completionTime?: string;
  cta?: React.ReactElement;
  videoUrl?: string;
  stepStatus?: StepStatus;
  isFinalStep?: boolean;
  className?: string;
  hideBorder?: boolean;
}

export const useStyles = createUseStyles<SetupProps>({
  container: {
    padding: 0,
    marginBottom: 24,
    border: 'none',
  },
  finalStepContainer: {
    backgroundColor: '#FCFAFF',
    width: '100vw',
    marginLeft: -24,
    padding: 24,
    borderRadius: '24px 24px 0 0',
  },
  containerDesktop: {
    padding: 24,
    marginBottom: 24,
    marginLeft: '0 !important',
    width: '100% !important',
  },
  currentStepMobile: {
    marginBottom: 40,
  },
  currentStepDesktop: {
    padding: '40px 40px !important',
    marginBottom: '40px !important',
    backgroundImage: `url("${UPLOADCARE_ASSETS_BASE_URL}/4f10db53-b56a-4e43-95cc-22e657c2f66e/")`,
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  topContent: {
    display: 'flex',
    gap: 16,
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  topLeftContent: {
    display: 'flex',
    gap: 24,
  },
  mobileHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  topMiddleContent: {
    maxWidth: 400,
  },
  stepNumber: {
    width: 40,
    height: 40,
    backgroundColor: '#F5F5F5',
    borderRadius: 11,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
  },
  title: {
    fontSize: 15,
    fontWeight: 500,
    color: ({ stepStatus }) =>
      stepStatus === StepStatus.COMPLETED ? '#818388' : '#222222',
  },
  description: {
    maxWidth: 400,
    marginTop: 4,
    fontSize: 14,
    color: ({ stepStatus }) =>
      stepStatus === StepStatus.COMPLETED ? '#818388' : '#62646A',
    '& a': {
      color: '#62646A',
      textDecoration: 'underline',
    },
  },
  completionTime: {
    marginTop: 4,
    fontSize: 14,
  },
  cta: {
    display: 'flex',
    gap: 8,
    whiteSpace: 'nowrap',
    '& button': {
      borderRadius: 8,
      height: 40,
    },
    '& div': {
      color: '#818388',
      fontSize: 14,
      fontWeight: 500,
    },
  },
  media: {
    position: 'relative',
    width: '100%',
    paddingTop: '56.25%', // 16:9 aspect ratio (9 / 16 = 0.5625)
    '& > div': {
      marginTop: 40,
      borderRadius: 8,
    },
  },
  player: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  placeholderImage: {
    borderRadius: 8,
    maxWidth: 680,
    width: '100%',
  },
  titleWrapper: {
    flexGrowth: 1,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    topContent: {
      flexDirection: 'row',
      gap: 24,
    },
    topLeftContent: {
      flexGrow: 1,
    },
    finalStepContainer: {
      borderRadius: 24,
    },
    titleWrapper: {
      flexGrow: 1,
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
});

function Step({
  stepIcon,
  title,
  description,
  completionTime,
  cta,
  videoUrl,
  stepStatus,
  isFinalStep,
  className,
  hideBorder,
}: SetupProps) {
  const styles = useStyles({ stepStatus, isFinalStep, hideBorder });
  const screenClass = useScreenClass();

  const isMobile = screenClass === 'xs' || screenClass === 'sm';

  return (
    <div
      className={cx(
        styles.container,
        !isMobile ? styles.containerDesktop : undefined,
        !isMobile && stepStatus === StepStatus.IN_PROGRESS && !hideBorder
          ? styles.currentStepDesktop
          : styles.currentStepMobile,
        isFinalStep ? styles.finalStepContainer : {},
        className,
      )}
    >
      <div className={styles.topContent}>
        {isMobile ? (
          <>
            <div className={styles.mobileHeader}>
              <div>{stepIcon}</div>
              <div className={styles.cta}>
                {(stepStatus === StepStatus.IN_PROGRESS ||
                  stepStatus === StepStatus.COMPLETED ||
                  stepStatus === StepStatus.INFO) &&
                  cta}
              </div>
            </div>
            <div className={styles.topMiddleContent}>
              <div className={styles.title}>{title}</div>
              <div className={styles.description}>{description}</div>
              {completionTime && (
                <div className={styles.completionTime}>{completionTime}</div>
              )}
            </div>
          </>
        ) : (
          <>
            <div className={styles.topLeftContent}>
              <div>{stepIcon}</div>
              <div className={styles.titleWrapper}>
                <div className={styles.title}>
                  <div>{title}</div>
                  <div className={styles.cta}>
                    {(stepStatus === StepStatus.IN_PROGRESS ||
                      stepStatus === StepStatus.COMPLETED ||
                      stepStatus === StepStatus.INFO) &&
                      cta}
                  </div>
                </div>
                <div className={styles.description}>{description}</div>
                {completionTime && (
                  <div className={styles.completionTime}>{completionTime}</div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {stepStatus === StepStatus.IN_PROGRESS && videoUrl && (
        <div className={styles.media}>
          <VideoPlayer
            height={339}
            className={styles.player}
            url={videoUrl}
            hideLoomFields
          />
        </div>
      )}
    </div>
  );
}

export default observer(Step);
