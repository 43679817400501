import React, {
  ReactElement,
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { QueryNextToken } from '@a_team/models/dist/misc';
import TargeterSearchObject, {
  TargeterSearchId,
} from '@a_team/models/dist/TargeterSearchObject';
import { TargeterSearchPreview } from '@ateams/api/dist/endpoints/TeamGraph';
import {
  AsyncSelect,
  AsyncSelectProps,
  Button,
  Colors,
  Icon,
  IconType,
  SelectOption,
  TextColors,
} from '@ateams/components';
import { useStores } from '@src/stores';
import { apiTeamGraph } from '@src/logic/services/endpoints';
import useToggle from '@src/hooks/useToggle';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import Dropdown, { DropdownItem } from '@src/components/Dropdown';
import Modal from '@src/components/Modal';
import TextButton from '@src/components/TextButton';
import { setTimeout } from 'timers';
import { format } from 'date-fns';
import ConfirmModal from '@src/components/Modal/ConfirmModal';
import { Spacing } from '@ateams/components';
import copy from 'copy-to-clipboard';
import { Tooltip } from 'react-tippy';
import {
  TargeterSearchCache,
  TargeterTabCache,
} from '@src/stores/TeamGraph/TargeterTabManager';
import { fetchDefaultTabData } from './utils';
import { TargeterLocation } from '@src/locations';
import DownloadModal from './Downloader';
import { AudienceData } from '../TargeterEmail/Sidebar';
import {
  mapTargeterApiRequest,
  queryParametersToSearchRequestBody,
} from '../SearchView/utils';

const MS_BETWEEN_CHECKING_DOWNLOAD = 500;

const toKey = (searchName: string): string =>
  searchName.trim().toLowerCase().replaceAll(/\s/g, '-').replaceAll(/'/g, '');

function isValidSearchName(searchName: string): boolean {
  const key = toKey(searchName);
  const invalidMatches = [...key.matchAll(/[^a-z\d-]/g)];
  const hasInvalidCharacters = invalidMatches && invalidMatches.length > 0;

  return !hasInvalidCharacters;
}

const useStyles = createUseStyles({
  '@global': {
    '.tippy-popper': {
      zIndex: '999999 !important',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  searchNameContainer: {
    display: 'flex',
    whiteSpace: 'nowrap',
    color: TextColors.regularLight,
    fontWeight: 'bold',
    maxWidth: 300,
    marginRight: 8,
  },
  searchName: {
    whiteSpace: 'nowrap',
    maxWidth: 260,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    marginLeft: 8,
    cursor: 'pointer',
  },
  searchNameInput: {
    border: 'none',
    borderBottom: `1px dashed ${TextColors.regularLight}`,
    fontWeight: 'normal',
    width: 320,
    marginRight: 30,
  },
  noSearch: {
    fontWeight: 'normal',
  },
  searchNotSynced: {
    fontWeight: 'normal',
    fontStyle: 'italic',
  },
  optionsButton: {
    position: 'relative',
    whiteSpace: 'nowrap',
  },
  dropdown: {
    position: 'absolute',
    zIndex: 1000,
    right: 0,
  },
  modalHeader: {
    fontSize: 24,
    fontStyle: 'bold',
    paddingBottom: 12,
  },
  sectionTitle: {
    color: TextColors.regularLight,
  },
  unbulletedList: {
    listStyleType: 'none',
    paddingInlineStart: 0,
  },
  recentSearch: {
    padding: 6,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      background: Colors.primaryVeryLight,
    },
  },
  recentSearchButton: {
    color: TextColors.regularLight,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 430,
    overflow: 'hidden',
  },
  loadMoreText: {
    margin: 6,
    marginTop: 12,
    width: '100%',
    textAlign: 'center',
    color: TextColors.primaryLight,
  },
  optionsIcon: {
    marginLeft: Spacing.small,
  },
});

export interface SearchOptionsProps {
  requiresSave: boolean;
  audiences: AudienceData[];
  currentSearch?: Pick<TargeterSearchObject, 'name' | 'creator' | 'tsid'>;
  onSearchLoaded: (search: TargeterSearchObject) => void;
  onSave: (
    name: string,
    key: string,
    tsid?: TargeterSearchId,
    autosave?: boolean,
  ) => Promise<void>;
  unloadSearch: () => void;
  setActiveTab: (label: string) => void;
  updateTabs: (data: TargeterTabCache) => void;
  toggleTeamView: () => void;
}

export default function SearchOptions(props: SearchOptionsProps): ReactElement {
  const styles = useStyles();
  const { auth, missionControl } = useStores();
  const {
    currentSearch,
    requiresSave,
    onSearchLoaded,
    onSave,
    unloadSearch,
    setActiveTab,
    updateTabs,
    toggleTeamView,
    audiences,
  } = props;

  const [downloadProgress, setDownloadProgress] = useState(0.0);
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
  const [downloadModalOpen, toggleDownloadModalOpen] = useToggle();
  const [searchName, setSearchName] = useState(currentSearch?.name ?? '');
  const [editingSearchName, setEditingSearchName] = useState(false);
  const [saveAlertModalMessage, setSaveAlertModalMessage] = useState('');
  const [loading, setLoading] = useLoadingState();
  const [menuOpen, toggleMenuOpen] = useToggle();
  const [searchModalOpen, toggleSearchModal] = useToggle();
  const [nextRecentSearches, setNextRecentSearches] =
    useState<QueryNextToken | null>(null);
  const [recentSearches, setRecentSearches] = useState<TargeterSearchPreview[]>(
    [],
  );
  const [searchToDelete, setSearchToDelete] = useState<
    TargeterSearchPreview | undefined
  >(undefined);
  const [defaultTabs, setDefaultTabs] = useState<TargeterSearchCache[]>([]);

  const inputRef = useRef<HTMLInputElement>(null);
  const autosaveTimeout = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    fetchDefaultTabData(auth).then(setDefaultTabs);
  }, []);

  const getAutosaveKey = () => {
    return `autosave-${auth.user?.uid || ''}`;
  };

  const recentSearchesFiltered = useMemo(() => {
    return recentSearches.filter(
      (item) => !item.searchKey.startsWith('autosave-'),
    );
  }, [recentSearches]);

  const copySavedSearchUrlToClipboard = (tsid: string, e?: SyntheticEvent) => {
    const url = `${window.location.origin}${TargeterLocation}?tsid=${tsid}`;
    copy(url);
    toggleMenuOpen(false);

    missionControl.error = false;
    missionControl.message = 'Link to saved search copied to clipboard';

    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    const autosaveHandler = async () => {
      if (requiresSave && !currentSearch?.name) {
        let newRecentSearches: TargeterSearchPreview[] = [];

        if (recentSearches.length === 0) {
          newRecentSearches = await fetchRecentSearches();
        } else {
          newRecentSearches = recentSearches;
        }

        const autoSaveSearch = newRecentSearches.find(
          (search) => search.searchKey === getAutosaveKey(),
        );
        await onSave('Autosave', getAutosaveKey(), autoSaveSearch?.tsid, true);

        if (!autoSaveSearch) {
          await fetchRecentSearches();
        }
      }
    };

    if (autosaveTimeout.current) {
      clearTimeout(autosaveTimeout.current);
    }
    autosaveTimeout.current = setTimeout(autosaveHandler, 1000);
    return () => {
      if (autosaveTimeout.current) {
        clearTimeout(autosaveTimeout.current);
      }
    };
  }, [requiresSave]);

  useEffect(() => {
    fetchRecentSearches();
  }, []);

  useEffect(() => {
    setSearchName(currentSearch?.name ?? '');
  }, [currentSearch?.name]);

  useEffect(() => {
    setSearchName(currentSearch?.name ?? '');

    if (editingSearchName) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
    }
  }, [editingSearchName]);

  const fetchRecentSearches = async (nextToken?: QueryNextToken | null) => {
    setNextRecentSearches(null);
    const queryResult = await apiTeamGraph.getUserTargeterSearches(
      auth,
      nextToken ?? undefined,
    );

    let newRecentSearches: TargeterSearchPreview[];

    if (nextToken) {
      newRecentSearches = [...recentSearches, ...queryResult.items];
    } else {
      newRecentSearches = queryResult.items;
    }
    setRecentSearches(newRecentSearches);
    setNextRecentSearches(queryResult.next);
    return newRecentSearches;
  };

  const loadOptions: AsyncSelectProps['loadOptions'] = async (searchText) => {
    if (!(searchText && isValidSearchName(searchText))) return [];

    const key = toKey(searchText);
    if (!key) return [];

    const queryResult = await apiTeamGraph.queryTargeterSearches(auth, key);
    return queryResult.items.map(
      (searchObject: TargeterSearchPreview): SelectOption => ({
        label: searchObject.name,
        value: searchObject.searchKey ?? key,
        searchObject,
      }),
    );
  };

  const handleSearchSelection = (option: SelectOption | null) => {
    option && loadSearchSelection(option.searchObject);
  };

  const loadSearchSelection = async ({ tsid }: TargeterSearchPreview) => {
    setLoading(
      apiTeamGraph.getTargeterSearchById(auth, tsid).then((searchObject) => {
        onSearchLoaded(searchObject);
        toggleSearchModal(false);
      }),
      'Loaded',
    );
  };

  const handleSave = async (name: string) => {
    setEditingSearchName(false);
    if (!name) {
      return setSaveAlertModalMessage(`Please name this search before saving.`);
    }

    if (!isValidSearchName(searchName)) {
      return setSaveAlertModalMessage(
        `Invalid name: Please use only numbers, letters, spaces, or dashes.`,
      );
    }

    const searchKey = toKey(name);
    const nameMatchesCurrentSearch =
      currentSearch?.name && searchName === currentSearch.name;

    if (currentSearch && nameMatchesCurrentSearch) {
      const cannotOverwrite =
        currentSearch.creator && currentSearch.creator.uid !== auth.uid;

      if (cannotOverwrite) {
        return setSaveAlertModalMessage(
          `This is not your search. Please change the name and try again.`,
        );
      }

      setLoading(
        onSave(name, searchKey, currentSearch.tsid).then(() => {
          toggleMenuOpen(false);
          fetchRecentSearches(null);
        }),
        'Saved',
      );
    } else {
      setLoading(
        onSave(name, searchKey).then(() => {
          toggleMenuOpen(false);
          fetchRecentSearches(null);
        }),
        'Saved',
      );
    }
  };

  const onUnloadSearch = () => {
    unloadSearch();
    toggleMenuOpen(false);
  };

  const handleDeleteSearch = async () => {
    if (searchToDelete?.tsid) {
      const tsid = searchToDelete.tsid;
      await apiTeamGraph.deleteTargeterSearch(auth, searchToDelete.tsid);
      setSearchToDelete(undefined);
      setRecentSearches((prevRecentSearches) =>
        prevRecentSearches.filter((search) => search.tsid !== tsid),
      );

      if (currentSearch?.tsid === tsid) {
        unloadSearch();
      }
    }
  };

  const handleTabUpdate = (tabs: TargeterSearchCache[], active?: string) => {
    const cache = tabs.reduce(
      (allTabs: TargeterTabCache, tabCache, position) => ({
        ...allTabs,
        [tabCache.label]: { ...tabCache, position },
      }),
      {},
    );

    updateTabs(cache);
    active && setActiveTab(active);
    toggleMenuOpen(false);
  };

  const createTeamProposal = () => {
    toggleTeamView();
    toggleMenuOpen(false);
  };

  const initiateDownload = async () => {
    if (typeof window === 'undefined') return;

    setDownloadUrl(null);
    setDownloadProgress(0.0);
    toggleDownloadModalOpen();
    const data = await audiences.reduce(
      async (accumulatorPromise, { label, filters, selectedBuilders }) => {
        const accumulator = await accumulatorPromise;
        const criteria = mapTargeterApiRequest(filters);
        const { filter: searchQuery } =
          await queryParametersToSearchRequestBody({
            auth,
            criteria,
          });

        return {
          ...accumulator,
          [label]: {
            ...searchQuery,
            selectedBuilders: selectedBuilders.map(({ uid }) => uid),
          },
        };
      },
      Promise.resolve({}),
    );

    const { jobId } = await apiTeamGraph.createTargeterSearchDownload(
      auth,
      data,
    );

    const awaitingDownload = async (): Promise<boolean> => {
      const inTargeter = window.location.pathname === TargeterLocation;

      if (!inTargeter) return false;

      const { fileUrl, progress, error } =
        await apiTeamGraph.getTargeterSearchDownloadUrl(auth, jobId);
      setDownloadProgress(progress);

      if (error) {
        window.alert(
          `Failed to create downloadable results.  Please report download ${jobId} to Team Engine`,
        );
        setDownloadUrl(null);
        setDownloadProgress(0.0);
        return false;
      }

      if (fileUrl) {
        setDownloadProgress(1.0);
        setDownloadUrl(fileUrl);
        return false;
      }

      return true;
    };

    while (await awaitingDownload()) {
      await new Promise((res) => setTimeout(res, MS_BETWEEN_CHECKING_DOWNLOAD));
    }
  };

  return (
    <>
      <div className={styles.container}>
        {editingSearchName ? (
          <input
            ref={inputRef}
            className={cx(styles.searchName, styles.searchNameInput)}
            placeholder="Set search name..."
            onBlur={() => setEditingSearchName(false)}
            onChange={(e) => setSearchName(e.target.value.trim())}
            onKeyDown={(e) => {
              if (['Tab', 'Escape'].includes(e.key)) {
                setSearchName(currentSearch?.name ?? '');
                setEditingSearchName(false);
              } else if (e.key === 'Enter') {
                handleSave(searchName);
              }
            }}
          />
        ) : (
          <>
            <div
              onClick={() => setEditingSearchName(true)}
              className={cx(
                styles.searchNameContainer,
                !searchName && styles.noSearch,
                searchName && requiresSave && styles.searchNotSynced,
              )}
            >
              {currentSearch ? (
                <>
                  <Tooltip
                    title={currentSearch.name}
                    theme={'dark'}
                    position={'right'}
                    arrow
                    animation="fade"
                    style={{ display: 'inline-block' }}
                  >
                    <div className={cx(styles.searchName)}>
                      {currentSearch.name + (requiresSave ? '*' : '')}
                    </div>
                  </Tooltip>

                  <Tooltip
                    title="Copy saved search url to clipboard"
                    theme={'dark'}
                    position={'right'}
                    arrow
                    animation="fade"
                    style={{ display: 'inline-block' }}
                  >
                    <Icon
                      type={IconType.Link}
                      className={styles.icon}
                      title=""
                      onClick={(e) =>
                        copySavedSearchUrlToClipboard(currentSearch.tsid, e)
                      }
                    />
                  </Tooltip>
                </>
              ) : (
                'No search loaded'
              )}
            </div>
          </>
        )}

        <div className={styles.optionsButton}>
          <Button
            outlined
            squared
            size="small"
            width="auto"
            color="regularDark"
            onClick={() => toggleMenuOpen()}
          >
            Options
            <Icon
              className={styles.optionsIcon}
              size="xsmall"
              type={IconType.ArrowDownBlack}
              muted
              flip={menuOpen ? 'vertical' : undefined}
            ></Icon>
          </Button>
          <Dropdown
            open={menuOpen}
            onClose={() => toggleMenuOpen(false)}
            className={styles.dropdown}
          >
            <DropdownItem
              danger
              onClick={() => handleTabUpdate(defaultTabs, defaultTabs[0].label)}
            >
              Reset Tabs
            </DropdownItem>
            <DropdownItem danger onClick={() => createTeamProposal()}>
              Create team proposal
            </DropdownItem>
            <DropdownItem
              danger
              onClick={() => handleSave(currentSearch?.name ?? '')}
            >
              Save Search
            </DropdownItem>
            <DropdownItem danger onClick={() => toggleSearchModal(true)}>
              Load Search
            </DropdownItem>
            <DropdownItem danger onClick={() => onUnloadSearch()}>
              Unload Search
            </DropdownItem>
            <DropdownItem danger onClick={() => initiateDownload()}>
              Export to .csv
            </DropdownItem>
          </Dropdown>
        </div>
      </div>

      <ConfirmModal
        open={searchToDelete !== undefined}
        onClose={() => setSearchToDelete(undefined)}
        title={'Delete Search'}
        description={`Are you sure you want to delete '${searchToDelete?.name}'?`}
        actionColor="danger"
        actionLabel="Remove"
        onConfirm={handleDeleteSearch}
      />
      <Modal
        open={searchModalOpen}
        onClose={() => toggleSearchModal(false)}
        style={{ overflow: 'visible', width: '700px' }}
      >
        <>
          <div className={styles.modalHeader}>Load a previous search</div>
          <AsyncSelect
            placeholder="Load a previous search..."
            onChange={handleSearchSelection}
            loadOptions={loadOptions}
            useDebounce
          />
          {!!recentSearchesFiltered.length && (
            <>
              <div className={styles.sectionTitle}>My recent searches</div>
              <ul className={styles.unbulletedList}>
                {recentSearchesFiltered.map((search: TargeterSearchPreview) => (
                  <li key={search.tsid}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <TextButton
                        className={styles.recentSearch}
                        onClick={() => loadSearchSelection(search)}
                      >
                        <Tooltip
                          title={search.name}
                          theme={'dark'}
                          position={'right'}
                          arrow
                          animation="fade"
                          style={{ display: 'inline-block' }}
                        >
                          <div className={styles.recentSearchButton}>
                            {search.name}
                          </div>
                        </Tooltip>
                        <span style={{ color: TextColors.regularLight }}>
                          {`(${format(new Date(search.updatedAt), 'P p')})`}
                        </span>
                      </TextButton>
                      <TextButton
                        color="danger"
                        style={{
                          paddingLeft: '8px',
                        }}
                        onClick={() => setSearchToDelete(search)}
                      >
                        Delete
                      </TextButton>
                    </div>
                  </li>
                ))}
                {nextRecentSearches && (
                  <li>
                    <TextButton
                      className={styles.loadMoreText}
                      onClick={() => fetchRecentSearches(nextRecentSearches)}
                    >
                      Load more
                    </TextButton>
                  </li>
                )}
              </ul>
            </>
          )}
        </>
      </Modal>
      <Modal
        open={!!saveAlertModalMessage}
        onClose={() => setSaveAlertModalMessage('')}
        style={{ overflow: 'visible' }}
      >
        <>
          <div className={styles.modalHeader}>Cannot save search</div>
          <br />
          <div>{saveAlertModalMessage}</div>
        </>
      </Modal>
      <DownloadModal
        open={downloadModalOpen}
        onClose={toggleDownloadModalOpen}
        downloadUrl={downloadUrl}
        downloadProgress={downloadProgress}
      />
      <LoadingIndicator loading={loading} />
    </>
  );
}
