import React from 'react';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { Button } from '@ateams/components';
import { useStores } from '@src/stores';
import { VettingDashboardLocation } from '@src/locations';
import { BasicVettingProcessColumnProps } from '..';
import { canSubmitOrEditVettingFeedbackForm } from '@src/helpers/vetting';

const useStyles = createUseStyles({
  button: {
    width: '180px',
  },
});

export const SubmitFeedbackField: React.FC<BasicVettingProcessColumnProps> = ({
  isAdminView,
  vettingProcess,
}) => {
  const styles = useStyles();
  const { auth } = useStores();

  const formPath = `${VettingDashboardLocation}/feedback-form/${vettingProcess.id}`;

  const isDisabled = Boolean(
    canSubmitOrEditVettingFeedbackForm(
      isAdminView,
      vettingProcess.vetter?.user.id === auth.user?.uid,
      vettingProcess.feedback?.submitDate,
    ),
  );

  return (
    <>
      <Link to={{ pathname: formPath }}>
        <Button size="small" className={styles.button} disabled={isDisabled}>
          {vettingProcess.feedback ? 'Edit Feedback' : 'Submit Feedback'}
        </Button>
      </Link>
    </>
  );
};
