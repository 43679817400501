import { ConnectionObjectV2 } from '@a_team/models/dist/ConnectionObject';
import {
  BasicNotificationObject,
  CompanyLikedYouNotificationObject,
  EvaluationInterviewScheduledNotificationObject,
  NotificationType,
} from '@a_team/models/dist/NotificationObject';
import UserObject, { BasicUserObject } from '@a_team/models/dist/UserObject';
import {
  ConnectionsModalSources,
  useAnalytics,
} from '@ateams/analytics/dist/platform';
import ConnectModalV2 from '@src/components/Modal/ConnectModalV2';
import CollaboratorRequestModal from '@src/components/Modal/CollaboratorRequestModal';
import { getUpdatedConnectionsForUserToInteract } from '@src/helpers/connections';
import useToggle from '@src/hooks/useToggle';
import {
  useGetAllNotifications,
  useGetInterviewRequestNotificationByCalComBookingId,
  useMarkMultipleNotificationsAsRead,
} from '@src/rq/notifications';
import { useStores } from '@src/stores';
import React, { useEffect, useState } from 'react';
import Empty from './Empty';
import Header from './Header';
import CompanyLikedYouNotification from './Notifications/CompanyLikedYouNotification';
import ConnectionApprovedNotification from './Notifications/ConnectionApprovedNotification';
import ConnectionRequestNotification from './Notifications/ConnectionRequestNotification';
import CollaboratorRequestNotification from './Notifications/CollaboratorRequestNotification';
import NotificationSkeleton from './Notifications/NotificationSkeleton';
import CompanyLikedYouModal from '../Modal/CompanyLikedYouModal';
import { useQueryClientSignalsByIds } from '@src/rq/signals';
import { isNonNullable } from '@src/helpers/sort';
import { ClientSignalObject } from '@a_team/models/src/ClientSignals';
import { ExistingProject } from '@src/stores/Profile/models';
import EditSharedExperienceV2 from '../Modal/ConnectModalV2/screens/EditSharedExperienceV2';
import { EditingExperienceObject } from '../Modal/ConnectModalV2/types';
import {
  useApproveCollaboratorRequest,
  useRejectCollaboratorRequest,
} from '@src/rq/experiences';
import {
  ExperienceData,
  ExperienceObject,
} from '@a_team/models/dist/ExperienceObject';
import { useHistory, useLocation } from 'react-router-dom';
import { getProjectId } from '@src/views/Profile/helpers/experience';
import ApplicationStatusNotification from './Notifications/ApplicationStatusNotification';
import TeamUpRequestNotification from './Notifications/TeamUpRequestNotification';
import TeamUpRequestAcceptedNotification from './Notifications/TeamUpRequestAcceptedNotification';
import CompanyWantsToInterviewYou from './Notifications/CompanyWantsToInterviewYouNotification';
import InterviewRequestModal from '../Modal/InterviewRequest';
import InterviewScheduledNotification from './Notifications/InterviewScheduledNotification';
import TeamUpRequestNoLongerAvailable from '../Modal/TeamUpRequestNoLongerAvailable';
import EvaluationInterviewScheduledNotification from './Notifications/EvaluationInterviewScheduledNotification';
import EvaluationInterviewInviteModal from '../Modal/EvaluationInterviewInviteModal';
import BuilderFeedbackNotification from './Notifications/BuilderFeedbackNotification';
import EvaluationInterviewWaitingScheduleNotification from './Notifications/EvaluationInterviewWaitingScheduleNotification';

interface BodyProps {
  toggle: () => void;
}

const Body = ({ toggle }: BodyProps) => {
  const [onlyUnread, setOnlyUnread] = useState(true);
  const { mutate } = useMarkMultipleNotificationsAsRead();
  const { isLoading, data, isFetching } = useGetAllNotifications({
    onlyUnread: onlyUnread || undefined,
  });

  const { auth, users } = useStores();
  const [
    companyWantsToInterviewYouModalOpen,
    setCompanyWantsToInterviewYouModalOpen,
  ] = useToggle();
  const [connectModalOpen, setConnectModalOpen] = useToggle();
  const [
    teamUpRequestNoLongerAvailableModalOpen,
    setTeamUpRequestNoLongerAvailableModalOpen,
  ] = useToggle();
  const [editProjectModalOpen, setEditProjectModalOpen] = useToggle();
  const [collaboratorRequestModalOpen, setCollaboratorRequestModalOpen] =
    useToggle();
  const [companyLikedYouModalOpen, setCompanyLikedYouModalOpen] = useToggle();
  const [
    evaluationInterviewInviteModalOpen,
    setEvaluationInterviewInviteModalOpen,
  ] = useToggle();
  const [clientInterviewId, setClientInterviewId] = useState<string>();
  const [userToInteract, setUserToInteract] = useState<UserObject>();
  const [selectedProject, setSelectedProject] = useState<ExistingProject>();
  const analytics = useAnalytics();

  const [selectedSignal, setSelectedSignal] = useState<ClientSignalObject>();
  const [selectedNotification, setSelectedNotification] =
    useState<BasicNotificationObject>();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const history = useHistory();

  const selectedEvaluationInterviewNotification =
    selectedNotification as EvaluationInterviewScheduledNotificationObject;

  useEffect(() => {
    if (data && data?.length > 0) {
      if (queryParams.has('notification')) {
        const notificationId = queryParams.get('notification');
        if (notificationId) {
          const notification = data.find(
            (notification) => notification.nid === notificationId,
          );
          if (
            notification &&
            notification.type === NotificationType.CollaboratorRequest
          ) {
            onSeeCollaboratorRequest(notification.experience);
            queryParams.delete('notification');
            history.replace({ search: queryParams.toString() });
          }
        }
      } else if (
        queryParams.has('cancel') &&
        queryParams.has('calComBookingUid')
      ) {
        const calComBookingUid = queryParams.get('calComBookingUid');
        const notification = data.find(
          (notification) =>
            notification.type ===
              NotificationType.EvaluationInterviewScheduled &&
            (notification as EvaluationInterviewScheduledNotificationObject)
              .calComBookingUid === calComBookingUid,
        );

        if (notification) {
          setSelectedNotification(notification);
          setEvaluationInterviewInviteModalOpen(true);
        }

        queryParams.delete('cancel');
        queryParams.delete('calComBookingUid');
        history.replace({ search: queryParams.toString() });
      }
    }
  }, [data?.length, queryParams && queryParams.has('notification')]);

  const clientSignalIds = data
    ?.map((notification) =>
      notification.type === NotificationType.CompanyLikedYou
        ? notification.signalId
        : null,
    )
    .filter(isNonNullable);

  const { data: signalsData } = useQueryClientSignalsByIds({
    clientSignalIds: clientSignalIds || [],
    enabled:
      auth.basicAccess &&
      !!auth.withBuilderLikes &&
      Array.isArray(clientSignalIds) &&
      clientSignalIds.length > 0,
  });

  const calComBookingId = queryParams.get('bid') ?? '';

  useGetInterviewRequestNotificationByCalComBookingId({
    calComBookingId,
    enabled: auth.basicAccess && !!auth.user && !!calComBookingId,
    onSuccess: (notification) => {
      if (
        !notification ||
        notification.type !== NotificationType.CompanyWantsToInterviewYou
      ) {
        return;
      }

      setCompanyWantsToInterviewYouModalOpen(true);
      setClientInterviewId(notification.ciid);
      queryParams.delete('bid');
      history.replace({ search: queryParams.toString() });
    },
  });

  const onClientLikedYou = (
    signal: ClientSignalObject,
    notification: CompanyLikedYouNotificationObject,
  ): void => {
    setSelectedSignal(signal);
    setSelectedNotification(notification);
    setCompanyLikedYouModalOpen();
    toggle();
  };

  const onConnect = async (initiator: BasicUserObject) => {
    try {
      const fullUser = await users.getFullUser(initiator.username, auth);
      setUserToInteract(fullUser);
      setConnectModalOpen();
      toggle();
      if (auth.user?.uid) {
        analytics.trackConnectionModalOpened(
          auth.user?.uid,
          ConnectionsModalSources.NotificationBubble,
        );
      }
    } catch (error) {
      console.error('Failed to get full user when opening a notification', {
        error,
      });
    }
  };

  const approveCollaboratorRequestOnCompleted = (
    updatedProject: ExperienceObject,
  ) => {
    const projects = [...(users.profile?.displayedProjects ?? [])];
    const projectIndex = projects.findIndex(
      (project) => updatedProject.eid === getProjectId(project),
    );

    projects[projectIndex] = updatedProject as ExistingProject;
    users.profile?.setProjects(projects);
  };

  const {
    mutateAsync: approveCollaboratorRequest,
    isLoading: isLoadingSubmit,
  } = useApproveCollaboratorRequest(approveCollaboratorRequestOnCompleted);

  const { mutateAsync: rejectCollaboratorRequest } =
    useRejectCollaboratorRequest();

  const onSeeCollaboratorRequest = (selectedProject: ExistingProject) => {
    setSelectedProject(selectedProject);
    setCollaboratorRequestModalOpen();
  };

  const onModalClose = (): void => {
    setConnectModalOpen(false);
  };

  const onCollaboratorRequestModalClose = (): void => {
    setCollaboratorRequestModalOpen(false);
    setEditProjectModalOpen(false);
  };

  const connectActionOnCompleted = async (
    newConnection: ConnectionObjectV2,
  ): Promise<void> => {
    setUserToInteract((prevUserToInteract) => {
      if (!prevUserToInteract) {
        return prevUserToInteract;
      }

      return getUpdatedConnectionsForUserToInteract(
        prevUserToInteract,
        newConnection,
      );
    });
  };

  if (isLoading && isFetching) {
    return (
      <div data-testing-id="loading-indicator">
        <Header
          onlyUnread={onlyUnread}
          setOnlyUnread={setOnlyUnread}
          toggle={toggle}
        />
        <div>
          {Array.from({ length: 10 }).map((_, index) => (
            <NotificationSkeleton key={index} />
          ))}
        </div>
      </div>
    );
  }

  const onMarkAllAsRead = () => {
    // We don't want to mark the evaluation interview waiting schedule notifications as read
    const allUnread = data?.filter(
      (notification) =>
        notification.unread &&
        notification.type !==
          NotificationType.EvaluationInterviewWaitingSchedule,
    );
    if (!allUnread) {
      return;
    }
    mutate({
      notificationIds: allUnread.map((notification) => notification.nid),
    });
  };

  const onMarkAsRead = (nid: string) => {
    mutate({ notificationIds: [nid] });
  };

  const handleUpdatedExperience = async (
    experience: EditingExperienceObject,
  ) => {
    if (!experience.eid) {
      return;
    }

    await approveCollaboratorRequest({
      experienceId: experience.eid,
      data: experience as ExperienceData,
    });

    onCollaboratorRequestModalClose();
  };

  const handleRejectCollaboratorRequest = async () => {
    if (selectedProject?.eid) {
      await rejectCollaboratorRequest({
        experienceId: selectedProject.eid,
      });
    }

    onCollaboratorRequestModalClose();
  };

  return (
    <div data-testing-id="notification-slider-body">
      <Header
        onlyUnread={onlyUnread}
        setOnlyUnread={setOnlyUnread}
        markAllAsRead={
          data?.some((notification) => notification.unread)
            ? onMarkAllAsRead
            : undefined
        }
        toggle={toggle}
      />
      <div>
        {!data || (data.length === 0 && <Empty />)}
        {data?.map((notification) => {
          if (notification.type === NotificationType.CompanyLikedYou) {
            const signal = signalsData?.find(
              (signal) => signal.id === notification.signalId,
            );

            if (!signal) {
              return null;
            }

            return (
              <CompanyLikedYouNotification
                notification={notification}
                data={signal}
                onClientLikedYou={() => {
                  onClientLikedYou(signal, notification);
                  onMarkAsRead(notification.nid);
                }}
                key={notification.nid}
                onMarkAsRead={() => onMarkAsRead(notification.nid)}
                isRead={!notification.unread}
              />
            );
          }

          if (
            notification.type === NotificationType.CompanyWantsToInterviewYou
          ) {
            return (
              <CompanyWantsToInterviewYou
                key={notification.nid}
                notification={notification}
                onMarkAsRead={() => onMarkAsRead(notification.nid)}
                isRead={!notification.unread}
                onViewRequest={() => {
                  setCompanyWantsToInterviewYouModalOpen(true);
                  setClientInterviewId(notification.ciid);
                  onMarkAsRead(notification.nid);
                }}
              />
            );
          }

          if (notification.type === NotificationType.InterviewScheduled) {
            return (
              <InterviewScheduledNotification
                key={notification.nid}
                notification={notification}
                onMarkAsRead={() => onMarkAsRead(notification.nid)}
                isRead={!notification.unread}
                onViewRequest={() => {
                  onMarkAsRead(notification.nid);
                }}
              />
            );
          }

          if (
            notification.type === NotificationType.EvaluationInterviewScheduled
          ) {
            return (
              <EvaluationInterviewScheduledNotification
                key={notification.nid}
                notification={notification}
                onMarkAsRead={() => onMarkAsRead(notification.nid)}
                isRead={!notification.unread}
                onViewRequest={() => {
                  setSelectedNotification(notification);
                  setEvaluationInterviewInviteModalOpen(true);
                }}
              />
            );
          }

          if (
            notification.type ===
            NotificationType.EvaluationInterviewWaitingSchedule
          ) {
            return (
              <EvaluationInterviewWaitingScheduleNotification
                key={notification.nid}
                notification={notification}
                onViewRequest={() => {
                  window.open(notification.preVettingFormUrl, '_blank');
                }}
              />
            );
          }

          if (notification.type === NotificationType.ApplicationStatus) {
            return (
              <ApplicationStatusNotification
                key={notification.nid}
                notification={notification}
                toggle={toggle}
                onMarkAsRead={() => {
                  onMarkAsRead(notification.nid);
                }}
                isRead={!notification.unread}
              />
            );
          }

          if (notification.type === NotificationType.TeamUpRequest) {
            return (
              <TeamUpRequestNotification
                key={notification.nid}
                notification={notification}
                toggle={toggle}
                onMarkAsRead={() => {
                  onMarkAsRead(notification.nid);
                }}
                openTeamUpRequestNoLongerAvailableModal={() => {
                  setTeamUpRequestNoLongerAvailableModalOpen();
                }}
                isRead={!notification.unread}
              />
            );
          }

          if (notification.type === NotificationType.TeamUpRequestAccepted) {
            return (
              <TeamUpRequestAcceptedNotification
                key={notification.nid}
                notification={notification}
                toggle={toggle}
                onMarkAsRead={() => {
                  onMarkAsRead(notification.nid);
                }}
                isRead={!notification.unread}
              />
            );
          }

          if (notification.type === NotificationType.ConnectionApproved) {
            return (
              <ConnectionApprovedNotification
                key={notification.nid}
                notification={notification}
                onMarkAsRead={() => {
                  onMarkAsRead(notification.nid);
                  toggle();
                }}
                isRead={!notification.unread}
              />
            );
          }

          if (notification.type === NotificationType.ConnectionRequest) {
            return (
              <ConnectionRequestNotification
                dataTestingId={`${notification.connection.initiator.username}-view-request`}
                key={notification.nid}
                onConnect={onConnect}
                notification={notification}
                onMarkAsRead={() => onMarkAsRead(notification.nid)}
                isRead={!notification.unread}
              />
            );
          }

          if (notification.type === NotificationType.CollaboratorRequest) {
            return (
              <CollaboratorRequestNotification
                key={notification.nid}
                notification={notification}
                onMarkAsRead={() => onMarkAsRead(notification.nid)}
                onSeeCollaboratorRequest={onSeeCollaboratorRequest}
                isRead={!notification.unread}
              />
            );
          }

          if (notification.type === NotificationType.BuilderRatings) {
            return (
              <BuilderFeedbackNotification
                key={notification.nid}
                toggle={toggle}
                notification={notification}
                onMarkAsRead={() => onMarkAsRead(notification.nid)}
                isRead={!notification.unread}
                username={auth.user?.username || ''}
              />
            );
          }

          return null;
        })}
      </div>
      {selectedNotification?.type ===
        NotificationType.EvaluationInterviewScheduled && (
        <EvaluationInterviewInviteModal
          open={evaluationInterviewInviteModalOpen}
          onClose={() => {
            setEvaluationInterviewInviteModalOpen(false);
          }}
          onMarkAsRead={() => {
            onMarkAsRead(selectedNotification.nid);
            setSelectedNotification(undefined);
          }}
          preVettingFormNonce={
            selectedEvaluationInterviewNotification?.preVettingFormNonce
          }
          interviewStartDate={
            selectedEvaluationInterviewNotification?.interviewStartDate
          }
          builderTimezone={
            selectedEvaluationInterviewNotification?.builderTimezone
          }
          calComBookingUrl={
            selectedEvaluationInterviewNotification?.calComBookingUrl
          }
        />
      )}
      {userToInteract && auth.user && connectModalOpen && (
        <ConnectModalV2
          source={ConnectionsModalSources.NotificationBubble}
          currentUser={auth.user}
          userToConnect={userToInteract}
          open={connectModalOpen}
          onClose={onModalClose}
          connectActionOnCompleted={connectActionOnCompleted}
        />
      )}

      <TeamUpRequestNoLongerAvailable
        open={teamUpRequestNoLongerAvailableModalOpen}
        handleModalClose={setTeamUpRequestNoLongerAvailableModalOpen}
      />

      {collaboratorRequestModalOpen && selectedProject && (
        <>
          <CollaboratorRequestModal
            selectedProject={selectedProject}
            open={collaboratorRequestModalOpen}
            onClose={onCollaboratorRequestModalClose}
            onEditProject={setEditProjectModalOpen}
            onReject={handleRejectCollaboratorRequest}
          />
          <EditSharedExperienceV2
            editingExperience={selectedProject}
            onSave={handleUpdatedExperience}
            open={editProjectModalOpen}
            onClose={setEditProjectModalOpen}
            loading={isLoadingSubmit}
          />
        </>
      )}

      {clientInterviewId && (
        <InterviewRequestModal
          open={companyWantsToInterviewYouModalOpen}
          clientInterviewId={clientInterviewId}
          onClose={() => {
            setCompanyWantsToInterviewYouModalOpen(false);
            setClientInterviewId(undefined);
          }}
        />
      )}

      {selectedSignal &&
        selectedNotification?.type === NotificationType.CompanyLikedYou && (
          <CompanyLikedYouModal
            open={companyLikedYouModalOpen}
            signal={selectedSignal}
            isJustPublished={
              (selectedNotification as CompanyLikedYouNotificationObject)
                ?.justPublished
            }
            onClose={() => {
              setCompanyLikedYouModalOpen(false);
              setSelectedSignal(undefined);
              setSelectedNotification(undefined);
            }}
          />
        )}
    </div>
  );
};

export default Body;
