import React, { useMemo } from 'react';
import {
  CompanySizeLabels,
  DidBuildProductsFromScratchLabels,
  LinkType,
  ManagementExperienceLabels,
  VettingProcessSoftwareEngineeringPreVettingForm,
} from '@a_team/models/dist/vetting-processes/pre-vetting';
import { InputLabel, MultipleValuesField, useCommonStyles } from '../common';
import { CodeSample } from '@src/views/VettingDashboard/vetting-table/columns/columns/code-sample';
import { ValueTag } from '@src/views/VettingFeedbackForm/components/value-tag';
import { LinkIcon } from '@src/views/VettingFeedbackForm/components/icons/link';
import { TextInput } from '@src/views/VettingFeedbackForm/components/text-input';

export interface PreVettingSoftwareEngineeringAnswersProps {
  answers: VettingProcessSoftwareEngineeringPreVettingForm;
}

export const PreVettingSoftwareEngineeringAnswers: React.FC<
  PreVettingSoftwareEngineeringAnswersProps
> = ({ answers }) => {
  const commonStyles = useCommonStyles();

  const codeSampleProgrammingLanguages = useMemo(() => {
    let result: string[] = [];

    if (answers.codeSampleProgrammingLanguages?.length) {
      result = answers.codeSampleProgrammingLanguages.map(({ name }) => name);
    }

    if (answers.otherCodeSampleProgrammingLanguage) {
      result.push(answers.otherCodeSampleProgrammingLanguage);
    }

    return result;
  }, [answers]);

  return (
    <>
      <div className={commonStyles.row}>
        <InputLabel>Company sizes</InputLabel>
        <div>
          <MultipleValuesField
            answers={answers.companySize.map(
              (cs) => CompanySizeLabels[cs] as string,
            )}
          />
        </div>
      </div>

      <div className={commonStyles.row}>
        <InputLabel>Built products from scratch</InputLabel>
        <div>
          <ValueTag>
            {
              DidBuildProductsFromScratchLabels[
                answers.didBuildProductsFromScratch
              ]
            }
          </ValueTag>
        </div>
      </div>

      <div className={commonStyles.row}>
        <InputLabel>Management experience</InputLabel>
        <div>
          <ValueTag>
            {ManagementExperienceLabels[answers.managementExperience]}
          </ValueTag>
        </div>
      </div>

      <div className={commonStyles.row}>
        <InputLabel>Code sample programming languages</InputLabel>
        <div>
          <MultipleValuesField answers={codeSampleProgrammingLanguages} />
        </div>
      </div>

      <div className={commonStyles.row}>
        <InputLabel>Code sample</InputLabel>
        {answers.codeSampleLinkType === LinkType.Download ? (
          <CodeSample
            codeSampleURL={answers.codeSample}
            codeSampleLinkType={answers.codeSampleLinkType}
          />
        ) : (
          <a href={answers.codeSample} target={'_blank'} rel="noreferrer">
            <ValueTag
              className={commonStyles.purpleTag}
              startAdornment={<LinkIcon />}
            >
              {answers.codeSample}
            </ValueTag>
          </a>
        )}
      </div>

      {answers.codeSampleNotes && (
        <>
          <div className={commonStyles.row}>
            <InputLabel>Code sample notes</InputLabel>
            <TextInput value={answers.codeSampleNotes} isReadOnly />
          </div>
        </>
      )}
    </>
  );
};
