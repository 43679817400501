import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { AdminVettingProcessColumnProps } from '..';
import { VettingDashboardRecordingLocation } from '@src/locations';

const useStyles = createUseStyles({
  list: {
    margin: 0,
    padding: 0,
    '& li': {
      listStyle: 'none',
    },
  },
});

export const CallRecordingUrls: React.FC<AdminVettingProcessColumnProps> = ({
  isAdminView,
  vettingProcess,
}) => {
  const styles = useStyles();

  const recordingPageUrls = useMemo(() => {
    if (!vettingProcess.recordingDownloadLinks) return [];
    const urls = vettingProcess.recordingDownloadLinks.filter((url) => url);
    const recordingPageUrls = urls.map(
      (url) =>
        `${VettingDashboardRecordingLocation}?recordingUrl=${encodeURIComponent(
          url,
        )}`,
    );
    return recordingPageUrls;
  }, []);

  if (!isAdminView) {
    return null;
  }

  return (
    <>
      {recordingPageUrls?.length ? (
        <ul className={styles.list}>
          {recordingPageUrls?.map((link, idx) => {
            const recordingPageUrl = `${VettingDashboardRecordingLocation}?recordingUrl=${encodeURIComponent(
              link,
            )}`;
            return (
              <li key={link}>
                <Link
                  to={{ pathname: recordingPageUrl }}
                  target="_blank"
                  rel="noreferrer"
                >
                  Recording link {idx + 1}
                </Link>
              </li>
            );
          })}
        </ul>
      ) : vettingProcess.feedback?.answers.callRecording ? (
        <Link
          to={{ pathname: vettingProcess.feedback?.answers.callRecording }}
          target="_blank"
        >
          Recording link
        </Link>
      ) : (
        ''
      )}
    </>
  );
};
