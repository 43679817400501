import React from 'react';
import { useCommonStyles } from '../commonStyles';
import { createUseStyles } from 'react-jss';
import {
  BorderColors,
  Colors,
  BorderRadius,
  Spacing,
} from '@ateams/components';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
    border: `1px solid ${BorderColors.light}`,
    borderRadius: BorderRadius.xLarge,
    backgroundColor: Colors.backgroundWhite,
    padding: Spacing.medium,
  },
});

export interface RoleSpecialisationsSectionProps {
  specialisations: string[];
  onSpecialisationsChange: (specialisations: string[]) => void;
}

export const RoleSpecialisationsSection = ({
  specialisations,
  onSpecialisationsChange,
}: RoleSpecialisationsSectionProps) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();

  return (
    <div className={commonStyles.section}>
      <div className={commonStyles.sectionTitle}>Role specialisation</div>
      <div className={styles.container}>
        {specialisations.map((specialisation) => (
          <div key={specialisation}>{specialisation}</div>
        ))}
      </div>
    </div>
  );
};
