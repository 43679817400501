import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import {
  BorderRadius,
  Colors,
  FontSizes,
  Icon,
  IconType,
  Spacing,
} from '@ateams/components';
import { useCommonStyles } from './commonStyles';
import { useTalentSkills } from '@src/rq/useTalentSkills';
import { useStores } from '@src/stores';
import { GuidanceMessage } from '@src/views/Profile/Main/partials/Section/GuidanceMessage';
import { Projects } from '@src/views/Profile/Main/Projects';
import {
  ExistingProject,
  NewProject,
  ProfileViewMode,
} from '@src/stores/Profile/models';
import { observer } from 'mobx-react-lite';
import Profile from '@src/stores/Profile/Profile';

const useStyles = createUseStyles({
  addProject: {
    color: Colors.dark,
    fontSize: FontSizes.small,
    lineHeight: '16px',
    borderRadius: BorderRadius.default,
    border: `1px solid ${Colors.regularLight}`,
    padding: Spacing.small,
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    gap: Spacing.xsmall,
    cursor: 'pointer',
  },
  addIcon: {
    width: 12,
    height: 12,
    cursor: 'pointer',
    stroke: Colors.dark,
  },
  projectsGuidance: {
    lineHeight: '19px',
    marginTop: 24,
  },
});

export interface ProjectsSectionProps {
  profile: Profile | undefined;
  projects: (ExistingProject | NewProject)[];
  selectedProjectIds: string[];
  setSelectedProjectIds: (projects: string[]) => void;
  requiredSkillIds: string[];
  showProjectsError: boolean;
}

const ProjectsSection = observer(
  ({
    profile,
    projects,
    requiredSkillIds,
    showProjectsError,
    selectedProjectIds,
    setSelectedProjectIds,
  }: ProjectsSectionProps) => {
    const { auth } = useStores();
    const styles = useStyles();
    const commonStyles = useCommonStyles();
    const skillsQueryResult = useTalentSkills({});

    const allSkills = useMemo(() => {
      return skillsQueryResult?.data || [];
    }, [skillsQueryResult]);

    const requiredSkills = allSkills.filter((skill) =>
      requiredSkillIds.includes(skill.id),
    );

    const onCreateProject = async (projectData: NewProject) => {
      await profile?.createProject(projectData);
      setSelectedProjectIds([...selectedProjectIds]);
    };

    const onUpdateProject = async (projectData: ExistingProject) => {
      await profile?.updateProject(projectData);
      setSelectedProjectIds([...selectedProjectIds]);
    };

    return (
      <>
        {profile && (
          <Projects
            type={'large'}
            withIcon={false}
            customTitle={<div className={commonStyles.title}>Projects</div>}
            customDescription={
              <div className={commonStyles.description}>
                Please select projects that best represent your skills and
                experience.
                <br />
                Be prepared to discuss these in more detail during the call.{' '}
                <br />
                {requiredSkills.length > 0 && (
                  <>
                    <br />
                    You applied to missions where{' '}
                    {requiredSkills.map((skill) => skill.name).join(', ')} are
                    required skills, if possible please add these skills to
                    projects where you used them.
                  </>
                )}
                {showProjectsError && (
                  <div className={commonStyles.errorText}>
                    Please make sure at least two projects are selected, and at
                    least one of them includes the skills shown above.
                  </div>
                )}
              </div>
            }
            createProjectTemplate={
              <div className={styles.addProject}>
                Add Project
                <Icon
                  type={IconType.PlusBlackFlat}
                  className={styles.addIcon}
                />
              </div>
            }
            profileMode={ProfileViewMode.Edit}
            username={profile?.data.username}
            updateProject={onUpdateProject}
            createProject={onCreateProject}
            allProfileSkills={profile.allSkills}
            hiddenProjects={profile.localHiddenAndSelectedProjects.hidden}
            selectedProjects={selectedProjectIds}
            projects={projects}
            recommendedProjects={
              auth.withApplicationBuilderScoring
                ? profile.application?.data.recommendedProjects
                : undefined
            }
            onChange={profile?.setProjects}
            onSaveProject={profile?.updateProject}
            onVisibilityChange={(projectId, isHidden) => {
              if (isHidden) {
                setSelectedProjectIds(
                  selectedProjectIds.filter((id) => id !== projectId),
                );
              } else {
                setSelectedProjectIds([...selectedProjectIds, projectId]);
              }
            }}
            onAdd={profile?.addEmptyProject}
            onDelete={profile.deleteProject}
            onQueryRoles={() => profile.queryRoles('')}
            onQueryIndustries={() => profile?.queryIndustries('')}
            getEmptyProject={profile.getEmptyProject}
            canHide={true}
            readonly={profile?.readonly}
            error={profile?.showErrors && profile.hasProjectsError}
            currentUserId={profile?.data.uid}
            guidanceMessage={
              profile.isCurrentUser &&
              profile.application?.showProjectsGuidanceMessage ? (
                <GuidanceMessage
                  className={styles.projectsGuidance}
                  type={'secondary'}
                  iconType={IconType.StarsPurple}
                  text={
                    profile.application.guidanceMessages.projectsNotRelevant
                  }
                />
              ) : undefined
            }
            isApplicationView={!!profile?.application}
          />
        )}
      </>
    );
  },
);

export default ProjectsSection;
