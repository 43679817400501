import React, { useRef, useEffect } from 'react';
import { useCommonStyles } from '../commonStyles';
import { createUseStyles } from 'react-jss';
import {
  BorderColors,
  Colors,
  FontSizes,
  FontWeights,
  Spacing,
} from '@ateams/components';
import clsx from 'clsx';

const useStyles = createUseStyles({
  textarea: {
    width: '100%',
    minHeight: '20px',
    backgroundColor: Colors.transparent,
    borderTop: '0 !important',
    borderBottom: '0 !important',
    borderRight: '0 !important',
    borderLeft: `2px solid ${BorderColors.light} !important`,
    borderRadius: '0 !important',
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.regular,
    padding: `0 ${Spacing.small}px`,
    display: 'flex',
    alignItems: 'center',
    resize: 'none',
    overflow: 'hidden',
    lineHeight: '20px',
  },
  noLeftBorder: {
    borderLeft: '0 !important',
  },
  noPadding: {
    padding: 0,
  },
  minLength: {
    fontSize: FontSizes.xsmall,
    fontWeight: FontWeights.regular,
    color: Colors.regularDark,
    paddingLeft: Spacing.small,
  },
});

export interface FieldTextareaProps {
  label?: string;
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  minLength?: number;
  showLeftBorder?: boolean;
}

export const FieldTextarea = ({
  label,
  value,
  onChange,
  placeholder,
  minLength = 0,
  showLeftBorder = true,
}: FieldTextareaProps) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '20px';
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + 'px';
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };

  return (
    <div
      className={clsx(
        commonStyles.field,
        showLeftBorder && styles.noLeftBorder,
      )}
    >
      {label && <div className={commonStyles.title}>{label}</div>}
      <textarea
        ref={textareaRef}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        className={clsx(
          styles.textarea,
          !showLeftBorder && [styles.noLeftBorder, styles.noPadding],
        )}
      />
      {value.length > 0 && value.length < minLength && (
        <div className={styles.minLength}>
          {`Enter at least ${minLength} characters.`}
        </div>
      )}
    </div>
  );
};
