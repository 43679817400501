import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import MainLayout from '@src/layouts/Main';
import { ShareSection } from './components/ShareSection';
import { BuilderSection, BuilderData } from './components/BuilderSection';
import { RoleSpecialisationsSection } from './components/RoleSpecialisationsSection';
import { Project, ProjectsSection } from './components/ProjectsSection';
import { MostProudOfSection } from './components/MostProudOfSection';
import { Skill, SkillsSection } from './components/SkillsSection';
import { BuilderScores } from './components/BuilderScores';
import { GuildsRecommendationSection } from './components/GuildsRecommendationSection';
import {
  BorderRadius,
  Colors,
  FontSizes,
  FontWeights,
  SelectOption,
  Spacing,
} from '@ateams/components';

import LocationObject from '@a_team/models/dist/LocationObject';
import { LocationAndAvailabilitySection } from './components/LocationAndAvailabilitySection';
import { OverallImpressionSection } from './components/OverallImpressionSection';

export const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: Spacing.large,
  },
  content: {
    width: 800,
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.large,
    justifyContent: 'center',
  },
  builderSection: {
    padding: Spacing.small,
    border: `1px solid ${Colors.regularLight}`,
    borderRadius: BorderRadius.default,
    backgroundColor: Colors.backgroundLight,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  saveDraftButton: {
    padding: `${Spacing.xsmall}px ${Spacing.small}px`,
    backgroundColor: Colors.backgroundDark,
    color: Colors.dark,
    borderRadius: BorderRadius.medium,
    border: 'none',
    cursor: 'pointer',
    height: 40,
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.semiBold,
  },
  submitButton: {
    padding: `${Spacing.xsmall}px ${Spacing.small}px`,
    backgroundColor: Colors.secondaryDark,
    color: Colors.light,
    borderRadius: BorderRadius.medium,
    border: 'none',
    cursor: 'pointer',
    height: 40,
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.semiBold,
  },
});

const VettingFeedbackFormV2 = () => {
  const styles = useStyles();
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [interviewDate, setInterviewDate] = useState<Date | undefined>(
    new Date(),
  );
  const [askedNotToBeRecorded, setAskedNotToBeRecorded] = useState(false);
  const [expertiseReason, setExpertiseReason] = useState('');
  const [interactionExperienceReason, setInteractionExperienceReason] =
    useState('');

  const [specialisations, setSpecialisations] = useState<string[]>([
    'React',
    'Node.js',
    'MongoDB',
  ]);
  const [projects, setProjects] = useState<Project[]>([
    {
      id: '1',
      name: 'Motion Array: In-browser video collaboration',
      jobRole: 'Software Engineer',
      company: 'Motion Array',
      companyLogoUrl: 'https://picsum.photos/24?random=1',
      projectUrl: 'https://www.google.com',
      skills: [
        { id: '1', name: 'Typescript' },
        { id: '2', name: 'React' },
        { id: '3', name: 'PostgreSQL' },
      ],
      impressions:
        'Developed a robust in-browser video collaboration tool. Implemented real-time editing features and optimized performance for large video files. Contributed to the architecture design and led the frontend development team.',
    },
    {
      id: '2',
      name: 'Manulife Bank user portal',
      jobRole: 'Software Engineer',
      company: 'Manulife Bank',
      companyLogoUrl: 'https://picsum.photos/24?random=2',
      projectUrl: 'https://www.google.com',
      skills: [
        { id: '1', name: 'Typescript' },
        { id: '2', name: 'React' },
        { id: '3', name: 'PostgreSQL' },
      ],
      impressions:
        'Redesigned and implemented a new user portal for Manulife Bank. Focused on improving user experience and implementing secure authentication methods. Collaborated closely with UX designers and backend developers.',
    },
    {
      id: '3',
      name: 'Spotify Playlist Analyzer',
      jobRole: 'Full Stack Developer',
      company: 'Spotify',
      companyLogoUrl: 'https://picsum.photos/24?random=3',
      projectUrl: 'https://www.spotify.com',
      skills: [
        { id: '4', name: 'Python' },
        { id: '5', name: 'Django' },
        { id: '6', name: 'Machine Learning' },
      ],
      impressions:
        "Built a machine learning-powered tool to analyze user playlists and provide personalized music recommendations. Implemented the backend using Django and integrated with Spotify's API. Developed a responsive frontend using React.",
    },
    {
      id: '4',
      name: 'E-commerce Platform Overhaul',
      jobRole: 'Backend Developer',
      company: 'Amazon',
      companyLogoUrl: 'https://picsum.photos/24?random=4',
      projectUrl: 'https://www.amazon.com',
      skills: [
        { id: '7', name: 'Java' },
        { id: '8', name: 'Spring Boot' },
        { id: '9', name: 'Microservices' },
      ],
      impressions:
        "Participated in a major overhaul of Amazon's e-commerce platform. Focused on developing scalable microservices using Java and Spring Boot. Implemented efficient caching mechanisms and optimized database queries to improve performance.",
    },
    {
      id: '5',
      name: 'AI-powered Chatbot',
      jobRole: 'Machine Learning Engineer',
      company: 'OpenAI',
      companyLogoUrl: 'https://picsum.photos/24?random=5',
      projectUrl: 'https://www.openai.com',
      skills: [
        { id: '10', name: 'Python' },
        { id: '11', name: 'TensorFlow' },
        { id: '12', name: 'Natural Language Processing' },
      ],
      impressions:
        'Developed an advanced AI-powered chatbot using state-of-the-art natural language processing techniques. Trained the model on a large corpus of conversational data and fine-tuned it for specific use cases. Implemented the chatbot interface using React and integrated it with the backend API.',
    },
    {
      id: '6',
      name: 'Blockchain-based Supply Chain Management',
      jobRole: 'Blockchain Developer',
      company: 'IBM',
      companyLogoUrl: 'https://picsum.photos/24?random=6',
      projectUrl: 'https://www.ibm.com',
      skills: [
        { id: '13', name: 'Solidity' },
        { id: '14', name: 'Ethereum' },
        { id: '15', name: 'Smart Contracts' },
      ],
      impressions:
        'Designed and implemented a blockchain-based supply chain management system for IBM. Developed smart contracts using Solidity and deployed them on the Ethereum network. Created a web interface for interacting with the blockchain using React and Web3.js.',
    },
    {
      id: '7',
      name: 'Virtual Reality Fitness App',
      jobRole: 'VR Developer',
      company: 'Oculus',
      companyLogoUrl: 'https://picsum.photos/24?random=7',
      projectUrl: 'https://www.oculus.com',
      skills: [
        { id: '16', name: 'C#' },
        { id: '17', name: 'Unity' },
        { id: '18', name: 'VR Development' },
      ],
      impressions:
        'Developed an immersive virtual reality fitness application for Oculus devices. Implemented realistic physics simulations and gesture recognition using Unity and C#. Optimized performance to maintain high frame rates and reduce motion sickness.',
    },
    {
      id: '8',
      name: 'Autonomous Drone Navigation System',
      jobRole: 'Robotics Engineer',
      company: 'DJI',
      companyLogoUrl: 'https://picsum.photos/24?random=8',
      projectUrl: 'https://www.dji.com',
      skills: [
        { id: '19', name: 'C++' },
        { id: '20', name: 'ROS' },
        { id: '21', name: 'Computer Vision' },
      ],
      impressions:
        'Developed an advanced autonomous navigation system for DJI drones. Implemented SLAM (Simultaneous Localization and Mapping) algorithms and integrated various sensors for obstacle avoidance. Used ROS (Robot Operating System) for system integration and developed computer vision algorithms for object recognition.',
    },
  ]);
  const [mostProudOf, setMostProudOf] = useState('');
  const [workOnProductThatScaled, setWorkOnProductThatScaled] = useState(false);
  const [scaleNotes, setScaleNotes] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [guildsValues, setGuildsValues] = useState<string[]>([
    'guild_fintech',
    'guild_healthcare',
    'guild_ai',
    'guild_frontend',
    'guild_cto',
    'guild_product',
    'guild_backend',
  ]);
  const [guildsRecommendation, setGuildsRecommendation] = useState<
    SelectOption[]
  >([]);
  const [location, setLocation] = useState<LocationObject | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [availabilityOptions, setAvailabilityOptions] = useState<
    SelectOption[]
  >([
    { label: 'Yes, part-time', value: 'yes-part-time' },
    { label: 'Yes, full-time', value: 'yes-full-time' },
    { label: 'No', value: 'no' },
  ]);
  const [availability, setAvailability] = useState<SelectOption | undefined>(
    undefined,
  );
  const [locationImpressions, setLocationImpressions] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [talentCategoryIds, setTalentCategoryIds] = useState<string[]>([
    '60c22477e9c821eacfb855e3',
    '60c22477e9c821eacfb855e3',
  ]);
  const [skills, setSkills] = useState<Skill[]>([
    {
      id: '60c22477e9c821eacfb85642',
      name: 'Typescript',
      score: 4,
      required: true,
      notes: 'Good experience, recent, simple projects',
    },
    {
      id: '60c22477e9c821eacfb85631',
      name: 'React',
      score: 4,
      required: false,
      notes: 'Good experience, recent, simple projects',
    },
    {
      id: '60c22477e9c821eacfb8562d',
      name: 'PostgreSQL',
      score: 2,
      required: false,
      notes: 'Basic experience, recent, simple projects',
    },
  ]);
  const [skillsImpressions, setSkillsImpressions] = useState('');
  const [callSummary, setCallSummary] = useState('');
  const [yourNotes, setYourNotes] = useState('');

  const builderData: BuilderData = {
    name: 'John Doe',
    email: 'john.doe@example.com',
    location: 'New York, NY, USA',
    avatarUrl: 'https://picsum.photos/200',
    linkedinUrl: 'https://www.linkedin.com/in/john-doe',
    resumeUrl: 'https://www.example.com/resume.pdf',
    portfolioUrl: 'https://www.example.com/portfolio',
    portfolioPassword: 'userpassword864',
    evaluationCallLinks: [
      {
        href: 'https://www.example.com/call-1',
        label: 'Call transcript (Mon DD, YYYY)',
      },
      {
        href: 'https://www.example.com/call-2',
        label: 'Call transcript (Mon DD, YYYY)',
      },
      {
        href: 'https://www.example.com/call-3',
        label: 'Call transcript (Mon DD, YYYY)',
      },
    ],
    uploadedMaterials: [
      {
        href: 'https://www.example.com/file-1',
        label: 'File_5678787_new.pdf',
      },
      {
        href: 'https://www.example.com/file-2',
        label: 'New instructions (Nov 2024)_(Mon DD, YYYY)',
      },
      {
        href: 'https://www.example.com/file-3',
        label: 'File_5678787_new1.pdf',
      },
    ],
    missionsBuilderAppliedTo: [
      {
        href: 'https://www.example.com/mission-1',
        label: 'Driver app build, Android — Lyft (Sourced)',
      },
      {
        href: 'https://www.example.com/mission-2',
        label: 'Grocery deliver app — Instacart (Sourced)',
      },
      {
        href: 'https://www.example.com/mission-3',
        label: 'Driver app build, Android — Lyft (Sourced)',
      },
      {
        href: 'https://www.example.com/mission-4',
        label: 'AI-powered chatbot — Anyscale (Sourced)',
      },
      {
        href: 'https://www.example.com/mission-5',
        label: 'AI-powered chatbot — Anyscale (Sourced)',
      },
      {
        href: 'https://www.example.com/mission-6',
        label: 'AI-powered chatbot — Anyscale (Sourced)',
      },
    ],
  };

  return (
    <MainLayout
      title="Vetting Feedback Form"
      contentClassName={styles.container}
    >
      <div className={styles.content}>
        <ShareSection />
        <BuilderSection
          builderData={builderData}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
        />
        <BuilderScores
          interviewDate={interviewDate}
          onInterviewDateChange={setInterviewDate}
          scores={{
            expertise: 3,
            interactionExperience: 4,
            english: 5,
            accent: 2,
          }}
          askedNotToBeRecorded={askedNotToBeRecorded}
          onAskedNotToBeRecordedChange={setAskedNotToBeRecorded}
          expertiseReason={expertiseReason}
          onExpertiseReasonChange={setExpertiseReason}
          interactionExperienceReason={interactionExperienceReason}
          onInteractionExperienceReasonChange={setInteractionExperienceReason}
        />
        <RoleSpecialisationsSection
          specialisations={specialisations}
          onSpecialisationsChange={setSpecialisations}
        />
        <ProjectsSection
          projects={projects}
          setProjects={setProjects}
          maxRows={2}
        />
        <MostProudOfSection
          mostProudOf={mostProudOf}
          onMostProudOfChange={setMostProudOf}
          workOnProductThatScaled={workOnProductThatScaled}
          onWorkOnProductThatScaledChange={setWorkOnProductThatScaled}
          scaleNotes={scaleNotes}
          onScaleNotesChange={setScaleNotes}
        />
        <SkillsSection
          talentCategoryIds={talentCategoryIds}
          skills={skills}
          onSkillsChange={setSkills}
          impressions={skillsImpressions}
          onImpressionsChange={setSkillsImpressions}
        />
        <GuildsRecommendationSection
          guildsValues={guildsValues}
          guildsRecommendation={guildsRecommendation}
          onGuildsRecommendationChange={setGuildsRecommendation}
        />
        <LocationAndAvailabilitySection
          location={location}
          onLocationChange={setLocation}
          availabilityOptions={availabilityOptions}
          availability={availability}
          onAvailabilityChange={setAvailability}
          impressions={locationImpressions}
          onImpressionsChange={setLocationImpressions}
        />
        <OverallImpressionSection
          callSummary={callSummary}
          onCallSummaryChange={setCallSummary}
          yourNotes={yourNotes}
          onYourNotesChange={setYourNotes}
        />
        <div className={styles.buttonsContainer}>
          <button className={styles.saveDraftButton}>Save draft</button>
          <button className={styles.submitButton}>Submit form</button>
        </div>
      </div>
    </MainLayout>
  );
};

export default observer(VettingFeedbackFormV2);
