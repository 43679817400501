import {
  BorderColors,
  BorderRadius,
  Colors,
  FontSizes,
  FontWeights,
  SelectOption,
  Spacing,
} from '@ateams/components';
import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { useCommonStyles } from '../commonStyles';
import { FieldDropdown } from '../general/FieldDropdown';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
  },
  internalContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
  },
  skillsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
    border: `1px solid ${BorderColors.light}`,
    borderRadius: BorderRadius.xLarge,
    backgroundColor: Colors.backgroundWhite,
    padding: Spacing.medium,
  },
  label: {
    fontSize: FontSizes.small,
    fontWeight: FontWeights.medium,
    whiteSpace: 'nowrap',
  },
  dropdown: {
    width: 278,
  },
});

const guildsMapping: Record<string, string> = {
  guild_fintech: 'Fintech guild',
  guild_healthcare: 'Healthcare guild',
  guild_ai: 'A.I. guild',
  guild_frontend: 'Front-end guild',
  guild_cto: 'CTO guild',
  guild_product: 'Product guild',
};

export interface GuildsRecommendationSectionProps {
  guildsValues: string[];
  guildsRecommendation: SelectOption[];
  onGuildsRecommendationChange: (guildsRecommendation: SelectOption[]) => void;
}

export const GuildsRecommendationSection = ({
  guildsValues,
  guildsRecommendation,
  onGuildsRecommendationChange,
}: GuildsRecommendationSectionProps) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();

  const convertGuildValueToLabel = (value: string) => {
    const label = value.replace('guild_', '').replace('_', ' ') + ' guild';
    return label.charAt(0).toUpperCase() + label.slice(1);
  };

  const guildsOptions = useMemo(() => {
    return guildsValues.map((value) => ({
      label: guildsMapping[value] || convertGuildValueToLabel(value),
      value,
    }));
  }, [guildsValues]);

  return (
    <div className={styles.container}>
      <div className={styles.internalContainer}>
        <div className={commonStyles.sectionTitle}>Guilds recommendation</div>
      </div>
      <div className={styles.skillsContainer}>
        <FieldDropdown
          label="Could this builder potentially fit any of the guilds?"
          options={guildsOptions}
          value={guildsRecommendation}
          placeholder="Select guids"
          className={styles.dropdown}
          labelClassName={styles.label}
          isMulti
        />
      </div>
    </div>
  );
};
