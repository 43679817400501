import { createUseStyles } from 'react-jss';
import { Colors, FontSizes, FontWeights, Spacing } from '@ateams/components';

export const useCommonStyles = createUseStyles({
  field: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.small,
  },
  title: {
    fontSize: FontSizes.small,
    fontWeight: FontWeights.medium,
    color: Colors.dark,
  },
  description: {
    fontSize: FontSizes.small,
    color: Colors.dark,
    lineHeight: '16px',
  },
  errorText: {
    color: Colors.danger,
    fontSize: FontSizes.small,
    lineHeight: '16px',
    marginTop: Spacing.small,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
  },
  sectionTitle: {
    fontSize: FontSizes.large,
    fontWeight: FontWeights.semiBold,
    color: Colors.dark,
  },
  sectionDescription: {
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.regular,
    color: Colors.dark,
  },
  skill: {
    height: 32,
    display: 'grid',
    gridTemplateColumns: '130px 200px 1fr',
    gap: Spacing.large,
    alignItems: 'center',
  },
});
