import React from 'react';
import { Skill } from '.';
import { createUseStyles } from 'react-jss';
import {
  BorderRadius,
  Colors,
  FontSizes,
  Icon,
  IconType,
  Spacing,
  Tag,
} from '@ateams/components';
import clsx from 'clsx';
import { FieldTextarea } from '../general/FieldTextarea';
import { ScoreSelection } from '../general/ScoreSelection';
import { useCommonStyles } from '../commonStyles';

const useStyles = createUseStyles({
  skillTag: {
    borderRadius: BorderRadius.medium,
    padding: Spacing.small,
  },
  requiredSkillTag: {
    backgroundColor: Colors.infoLight,
  },
  skillText: {
    fontSize: FontSizes.small,
    color: Colors.dark,
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    gap: Spacing.small,
    paddingLeft: Spacing.xsmall,
    paddingRight: Spacing.xsmall,
  },
  closeIcon: {
    cursor: 'pointer',
    fontSize: FontSizes.xsmall,
  },
});

export interface SkillItemProps {
  skill: Skill;
  onChange: (skill: Skill) => void;
  onDelete: () => void;
}

export const SkillItem = ({ skill, onChange, onDelete }: SkillItemProps) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();

  return (
    <div className={commonStyles.skill}>
      <div>
        <Tag
          className={clsx(
            styles.skillTag,
            skill.required && styles.requiredSkillTag,
          )}
          key={skill.name}
        >
          <div className={styles.skillText}>
            <div>{skill.name}</div>
            {!skill.required && (
              <Icon
                type={IconType.Close}
                className={styles.closeIcon}
                onClick={onDelete}
              />
            )}
          </div>
        </Tag>
      </div>
      <ScoreSelection
        score={skill.score}
        onChange={(score) => onChange({ ...skill, score })}
      />
      <FieldTextarea
        value={skill.notes}
        onChange={(notes) => onChange({ ...skill, notes })}
        placeholder="Add a comment..."
        showLeftBorder={false}
      />
    </div>
  );
};
