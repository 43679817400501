import fetchPonyfill from 'fetch-ponyfill';
import qs from 'qs';
import ServiceError from '../models/ServiceError';
import ApiToken from '@a_team/models/dist/ApiToken';

export const { fetch } = fetchPonyfill();

export interface ServiceAuth {
  bearerToken: ApiToken;
  invalidate(): unknown;
}

export type ServiceFetchMethod = 'get' | 'post' | 'put' | 'delete' | 'PATCH';

// exported functions

export const removeUndefinedValues = <T>(o: T): T => {
  if (typeof o !== 'object' || o === null) {
    return o;
  }
  return Object.fromEntries(
    Object.entries(o).filter(([key, value]) => typeof value !== 'undefined'),
  ) as T;
};

export type ServiceFetchParams = Parameters<typeof serviceFetchParameters>;

export function serviceFetchParameters(
  auth: ServiceAuth | null,
  path: string,
  query: unknown | string | null = null,
  method: ServiceFetchMethod = 'get',
  body?: unknown,
  abortController?: AbortController,
): [string, RequestInit] {
  const fetchOpts: RequestInit & { headers: Record<string, string> } = {
    method,
    headers: {},
  };

  if (auth) {
    fetchOpts.headers['Authorization'] = `Bearer ${auth.bearerToken}`;
  }

  if (body) {
    fetchOpts.headers['Content-Type'] = 'application/json';
    fetchOpts.body = JSON.stringify(body);
  }

  if (abortController) {
    fetchOpts.signal = abortController.signal;
  }

  const url = query
    ? `${path}?${qs.stringify(query, { arrayFormat: 'repeat' })}`
    : path;

  return [url, fetchOpts];
}

export function parseServiceFetchError(
  res: Response,
  params: ServiceFetchParams,
): Promise<never> {
  const [auth, path] = params;

  if (auth && res.status === 401) {
    auth.invalidate();
  }

  return (
    res
      .json()
      .catch((): never => {
        throw new Error(`Invalid response status ${res.status} at: "${path}"`);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((body: any): never => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const err: ServiceError = new Error(
          body.error
            ? String(body.error.message) || String(body.error)
            : String(body.message) || JSON.stringify(body),
        );

        err.code = body.error.code;
        err.payload = body.payload;

        throw err;
      })
  );
}

export default function serviceFetch<T>(
  ...params: ServiceFetchParams
): Promise<T> {
  return fetch(...serviceFetchParameters(...params)).then(
    (res: Response): Promise<T> => {
      if (res.ok) {
        return res.json();
      }

      return parseServiceFetchError(res, params);
    },
  );
}
