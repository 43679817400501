import { AdminNotesScore } from '@a_team/models/dist/AdminNotesObject';
import React, { useMemo } from 'react';
import { FieldDatePicker } from '../general/FieldDatePicker';
import { FieldCheckbox } from '../general/FieldCheckbox';
import { FieldDropdown } from '../general/FieldDropdown';
import { FieldTextarea } from '../general/FieldTextarea';

import { createUseStyles } from 'react-jss';
import { useCommonStyles } from '../commonStyles';
import {
  BorderColors,
  BorderRadius,
  Colors,
  SelectOption,
  Spacing,
} from '@ateams/components';

import {
  AccentScoreOptions,
  EnglishLevelOptions,
  ExpertiseScoreOptions,
  InteractionExperienceOptions,
} from '@src/components/ScoreInput/Scores';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.backgroundLight,
    borderRadius: BorderRadius.xLarge,
  },
  reasonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
    padding: Spacing.medium,
  },
  dateAndScoresContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
    border: `1px solid ${BorderColors.light}`,
    borderRadius: BorderRadius.xLarge,
    backgroundColor: Colors.backgroundWhite,
    padding: Spacing.medium,
  },
  dateAndFlagsContainer: {
    display: 'grid',
    gridTemplateColumns: '176px 1fr',
    gap: Spacing.medium,
  },
  scoresContainer: {
    display: 'grid',
    gridTemplateColumns: '176px 176px 176px 176px',
    gap: Spacing.medium,
  },
  checkbox: {
    border: `1px solid ${BorderColors.light}`,
    borderRadius: BorderRadius.small,
    paddingTop: Spacing.xsmall,
    paddingBottom: Spacing.xsmall,
    paddingLeft: Spacing.small,
    paddingRight: Spacing.small,
    width: 'fit-content',
  },
});

export interface BuilderScoresProps {
  interviewDate: Date | undefined;
  onInterviewDateChange: (date: Date) => void;
  scores: {
    expertise?: AdminNotesScore;
    interactionExperience?: AdminNotesScore;
    english?: AdminNotesScore;
    accent?: AdminNotesScore;
  };
  expertiseReason?: string;
  onExpertiseReasonChange: (value: string) => void;
  interactionExperienceReason?: string;
  onInteractionExperienceReasonChange: (value: string) => void;
  askedNotToBeRecorded?: boolean;
  onAskedNotToBeRecordedChange: (value: boolean) => void;
}

export const BuilderScores = ({
  interviewDate,
  onInterviewDateChange,
  scores,
  expertiseReason,
  onExpertiseReasonChange,
  interactionExperienceReason,
  onInteractionExperienceReasonChange,
  askedNotToBeRecorded,
  onAskedNotToBeRecordedChange,
}: BuilderScoresProps) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const expertiseScoreOptions: SelectOption[] =
    ExpertiseScoreOptions as unknown as SelectOption[];
  const interactionExperienceScoreOptions: SelectOption[] =
    InteractionExperienceOptions as unknown as SelectOption[];
  const englishScoreOptions: SelectOption[] =
    EnglishLevelOptions as unknown as SelectOption[];
  const accentScoreOptions: SelectOption[] =
    AccentScoreOptions as unknown as SelectOption[];

  const selectedExpertiseScore = useMemo(
    () =>
      expertiseScoreOptions.find(
        (option) => option.value === scores.expertise?.toString(),
      ),
    [expertiseScoreOptions, scores.expertise],
  );

  const selectedInteractionExperienceScore = useMemo(
    () =>
      interactionExperienceScoreOptions.find(
        (option) => option.value === scores.interactionExperience?.toString(),
      ),
    [interactionExperienceScoreOptions, scores.interactionExperience],
  );

  const selectedEnglishScore = useMemo(
    () =>
      englishScoreOptions.find(
        (option) => option.value === scores.english?.toString(),
      ),
    [englishScoreOptions, scores.english],
  );

  const selectedAccentScore = useMemo(
    () =>
      accentScoreOptions.find(
        (option) => option.value === scores.accent?.toString(),
      ),
    [accentScoreOptions, scores.accent],
  );

  return (
    <div className={commonStyles.section}>
      <div className={commonStyles.sectionTitle}>Interview observations</div>
      <div className={styles.container}>
        <div className={styles.dateAndScoresContainer}>
          <div className={styles.dateAndFlagsContainer}>
            <FieldDatePicker
              label="Date"
              value={interviewDate}
              onChange={onInterviewDateChange}
            />
            <div className={commonStyles.field}>
              <div className={commonStyles.title}>Flags</div>
              <FieldCheckbox
                text="Asked not to be recorded"
                value={askedNotToBeRecorded}
                onChange={onAskedNotToBeRecordedChange}
                className={styles.checkbox}
              />
            </div>
          </div>
          <div className={styles.scoresContainer}>
            <FieldDropdown
              label="Expertise level *"
              options={expertiseScoreOptions}
              value={selectedExpertiseScore}
              placeholder="Select"
            />
            <FieldDropdown
              label="Interpersonal skills *"
              options={interactionExperienceScoreOptions}
              value={selectedInteractionExperienceScore}
              placeholder="Select"
            />
            <FieldDropdown
              label="English proficiency *"
              options={englishScoreOptions}
              value={selectedEnglishScore}
              placeholder="Select"
            />
            <FieldDropdown
              label="English pronunciation *"
              options={accentScoreOptions}
              value={selectedAccentScore}
              placeholder="Select"
            />
          </div>
        </div>
        <div className={styles.reasonsContainer}>
          <FieldTextarea
            label="Provide reasons for expertise *"
            placeholder="Add your reasons"
            value={expertiseReason ?? ''}
            onChange={onExpertiseReasonChange}
            minLength={40}
          />
          <FieldTextarea
            label="Provide reasons for interaction experience score *"
            placeholder="Add your reasons"
            value={interactionExperienceReason ?? ''}
            onChange={onInteractionExperienceReasonChange}
            minLength={40}
          />
        </div>
      </div>
    </div>
  );
};
