import { ServiceAuth, ServiceEndpoint } from './utils';
import {
  NotificationId,
  NotificationObject,
} from '@a_team/models/dist/NotificationObject';

export const BasePath = '/notifications';

// exported functions

export default class NotificationsEndpoint extends ServiceEndpoint {
  public markMultipleAsRead(
    auth: ServiceAuth,
    nids: NotificationId[],
  ): Promise<NotificationObject[]> {
    return this.fetch(auth, BasePath + '/mark-read', null, 'put', {
      nids,
    });
  }

  public getAllNotifications(
    auth: ServiceAuth,
    onlyUnread?: boolean,
  ): Promise<NotificationObject[]> {
    return this.fetch(
      auth,
      BasePath + '/',
      {
        onlyUnread,
      },
      'get',
    );
  }

  public getInterviewRequestNotificationByCalComBookingId(
    auth: ServiceAuth,
    calComBookingId: string,
  ): Promise<NotificationObject | null> {
    return this.fetch(
      auth,
      BasePath + '/by-cal-com-booking-id',
      {
        calComBookingId,
      },
      'get',
    );
  }

  public createCompanyLikedYou(
    auth: ServiceAuth,
    signalId: string,
    userId: string,
  ): Promise<{ success: boolean }> {
    return this.fetch(
      auth,
      BasePath + '/company-liked-you',
      {
        signalId,
        userId,
      },
      'post',
    );
  }
}
