import { useAnalytics } from '@ateams/analytics/dist/platform';
import {
  MissionApplicationManuallyProposedData,
  MissionApplicationStatusUpdate,
} from '@ateams/api/dist/endpoints/Missions';
import {
  Breakpoints,
  Button as CallToActionButton,
  CollapsibleContainer,
  IconType,
  TextColors,
} from '@ateams/components';
import { AvailabilityData } from '@a_team/models/dist/AvailabilityObject';
import LocationObject from '@a_team/models/dist/LocationObject';
import MissionApplicationObject, {
  MissionApplicationId,
} from '@a_team/models/dist/MissionApplicationObject';
import { MissionStatus } from '@a_team/models/dist/MissionObject';
import MissionRole, {
  BasicMissionRole,
  MissionRoleStatus,
} from '@a_team/models/dist/MissionRole';
import { BasicUserObject, UserStatus } from '@a_team/models/dist/UserObject';
import Alert from '@src/components/Alert';
import LoadingIndicator from '@src/components/LoadingIndicator';
import AllLocationsModal, {
  CountryObject,
} from '@src/components/Modal/AllLocationsModal';
import ApplicationModal from '@src/components/Modal/ApplicationModal';
import ApplyExpertiseModal from '@src/components/Modal/ApplyExpertiseModal';
import ApplyExpertiseModalDescription from '@src/components/Modal/ApplyExpertiseModal/Description';
import ApplyLowProficiencyModal from '@src/components/Modal/ApplyLowProficiencyModal';
import { Description as ApplyLowProficiencyModalDescription } from '@src/components/Modal/ApplyLowProficiencyModal/Description';
import CodeOfConductModal from '@src/components/Modal/CodeOfConductModal';
import ConfirmModal from '@src/components/Modal/ConfirmModal';
import { ExpertiseLegend } from '@src/components/Modal/ExpertiseModal/ExpertiseLegend';
import InviteModal from '@src/components/Modal/InviteModal';
import SetAvailabilityModal from '@src/components/Modal/SetAvailabilityModal';
import SetTimezoneModal from '@src/components/Modal/SetTimezoneModal';
import SetLocationModal from '@src/components/Modal/SetLocationModal';
import LowCompetitivenessModal from '@src/components/Modal/LowCompetitivenessModal';
import RequestInviteCard from '@src/components/RequestInviteCard';
import SectionHeading from '@src/components/SectionHeading';
import { VideoPlayer } from '@src/components/VideoPlayer';
import {
  countryListOptionMap,
  rawCountryList,
} from '@src/helpers/rawCountryList';
import useLoadingState from '@src/hooks/useLoadingState';
import useToggle from '@src/hooks/useToggle';
import MissionLayout from '@src/layouts/Mission';
import {
  MissionApplyEditLocation,
  MissionApplyLocation,
  MissionControlBase,
  MissionPageLocation,
  MissionProposalLocation,
} from '@src/locations';
import { useStores } from '@src/stores';
import { Expertise, ProfileViewMode } from '@src/stores/Profile/models';
import { loadMission, MissionMatch } from '@src/url-loaders/loadMission';
import { observer } from 'mobx-react';
import {
  default as React,
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useScreenClass } from 'react-grid-system';
import ReactHtmlParser from 'react-html-parser';
import { createUseStyles } from 'react-jss';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import LinksList from './LinksList';
import NewMissionRoleRow from './NewMissionRoleRow';
import Tag from './NewMissionRoleRow/common/Tag';
import TimezoneObject from '@a_team/models/dist/TimezoneObject';
import { WorkingHoursSchema } from '@a_team/models/src/WorkingHoursObject';
import { useQuery } from '@tanstack/react-query';
import { apiTeamGraph } from '@ateams/api';
import TeamUpBanner from './TeamUpBanner';
import { queryKeys } from '@src/rq/keys';
import { PotentialExtensions } from '../Proposal/PotentialExtensions';
import { PotentialTeams } from '../Proposal/PotentialTeams';
import { getSuggestedTeamsWithApplications } from '../Proposal/utils';
import { RequestOptimizationModal } from './RequestOptimizationModal';
import { useMutationCreateRoleOptimizationRequest } from '@src/rq/roleOptimizationRequest';
import TeamUpRequestNoLongerAvailable from '@src/components/Modal/TeamUpRequestNoLongerAvailable';
import { useQueryUserRecommendationById } from '@src/rq/teamUp';
import { RecommendationStatus } from '@a_team/models/dist/RecommendationObject';

interface Props {
  match: MissionMatch;
  adminView?: boolean;
}

const useStyles = createUseStyles({
  heroContainer: {
    maxWidth: '640px',
    boxSizing: 'content-box',
  },
  subTitle: {
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '26px',
    letterSpacing: '0.001em',
    color: TextColors.regular,
  },
  description: {
    marginTop: '32px',
  },
  container: {
    maxWidth: '640px',
    paddingBottom: '56px',
  },
  alert: {
    marginBottom: '24px',
  },
  alertTitle: {
    marginTop: 0,
    marginBottom: 0,
  },
  alertContent: {
    marginTop: 0,
    marginBottom: 0,
  },
  rolesTitle: {
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '26px',
    letterSpacing: '0.001em',
    color: TextColors.regular,
  },
  link: {
    color: TextColors.primaryLight,
    cursor: 'pointer',
  },
  rolesContainer: {
    marginTop: '24px',
  },
  showMoreApplicantsCTA: {
    width: '123px',
    height: '35px',
    borderRadius: '104px',
    fontSize: '15px',
    lineHeight: '24px',
    marginBottom: '8px',
    color: TextColors.primary,
    padding: 0,
    marginLeft: '16px',
    textAlign: 'center',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    heroContainer: {
      maxWidth: '1200px',
      boxSizing: 'content-box',
    },
    container: {
      maxWidth: '912px',
    },
  },
});

export const aboutMissionViewLoader = loadMission;

const AboutMissionView = (props: Props): ReactElement => {
  const styles = useStyles();
  const stores = useStores();
  const analytics = useAnalytics();
  const screenClass = useScreenClass();
  const { missions, auth } = stores;

  const { currentUser } = auth;

  const { currentMission } = missions;
  const { match } = props;
  const { mid } = match.params;
  const [loading, setLoading] = useLoadingState();
  const [isolatedUpdate, setIsolatedUpdate] = useState<boolean>(false);
  const [currentRole, setCurrentRole] = useState<MissionRole>();
  const [successMsg, setSuccessMsg] = useState('');
  const [inviteRole, setInviteRole] = useState<BasicMissionRole | null>(null);
  const [missingRole, setMissingRole] = useState<Expertise[] | []>([]);
  const [optimizationRequestRoleId, setOptimizationRequestRoleId] =
    useState<string>();
  const [missingRequiredSkills, setMissingRequiredSkills] = useState<
    Expertise[] | []
  >([]);
  const [lowProficiencyRequiredSkills, setLowProficiencyRequiredSkills] =
    useState<Expertise[] | []>([]);
  const [missingPreferredSkills, setMissingPreferredSkills] = useState<
    Expertise[] | []
  >([]);
  const [applicationModalOpen, toggleApplicationModalOpen] = useToggle();
  const [
    teamUpRequestNoLongerAvailableModalOpen,
    toggleTeamUpRequestNoLongerAvailableModalOpen,
  ] = useToggle();

  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  const rid = params.get('rid');

  const onCloseTeamUpRequestNoLongerAvailableModal = () => {
    toggleTeamUpRequestNoLongerAvailableModalOpen();
    if (rid) {
      params.delete('rid');
      history.replace({ search: params.toString() });
    }
  };

  useQueryUserRecommendationById({
    recommendationId: rid || '',
    enabled: !!rid,
    onSuccess: (data) => {
      if (data?.status === RecommendationStatus.Unavailable) {
        toggleTeamUpRequestNoLongerAvailableModalOpen();
      }
    },
  });

  const [isCodeOfConductModalOpen, setIsCodeOfConductModalOpen] =
    useState(false);

  // using ref to avoid re-rendering the component
  const onCloseCodeOfConductModalRef = useRef<{
    onClose: (() => void) | undefined;
  }>({
    onClose: undefined,
  });

  // open the modal on url change
  let aid: string | undefined = '';
  if (typeof window !== 'undefined' && window.location) {
    const parsedUrl = new URL(window?.location?.href ?? 'https://a.team');
    aid = parsedUrl.searchParams.get('aid')?.toString() ?? '';
  }
  useEffect(() => {
    if (!auth.isAdmin) return;
    if (aid && currentMission?.applications) {
      const activeApplication = currentMission?.applications?.find((app) => {
        return app.aid === aid;
      });
      if (activeApplication?.aid) {
        viewApplicantSubmission(activeApplication).then(() =>
          toggleApplicationModalOpen(true),
        );
      }
    }
  }, [currentMission?.applications, aid]);

  const [requiredSkillsModalOpen, toggleRequiredSkillsModalOpen] = useToggle();
  const [lowProficiencySkillsModalOpen, toggleLowProficiencySkillsModalOpen] =
    useToggle();
  const [setLocationModalOpen, toggleSetLocationModalOpen] = useToggle();
  const [setAvailabilityModalOpen, toggleSetAvailabilityModalOpen] =
    useToggle();
  const [allLocationsModalOpen, toggleAllLocationsModalOpen] = useToggle();
  const [setTimezoneModalOpen, toggleSetTimezoneModalOpen] = useToggle();
  const [lowCompetitivenessModalOpen, toggleLowCompetitivenessModalOpen] =
    useToggle();
  const [notEnoughOverlap, setNotEnoughOverlap] = useState(false);

  const applicantReviewTeamsResponse = useQuery({
    queryKey: queryKeys.teamNarratives.getApplicantReviewTeams(mid || ''),
    queryFn: async () => {
      if (!mid) return;
      return apiTeamGraph.getApplicantReviewTeams(auth, mid);
    },
    enabled: auth.isAdmin,
  });

  const {
    mutate: createRoleOptimization,
    isLoading: isCreatingRoleOptimization,
  } = useMutationCreateRoleOptimizationRequest();

  const filteredPrecomputedTeams = useMemo(
    () =>
      getSuggestedTeamsWithApplications(
        applicantReviewTeamsResponse.data?.newTeams,
        currentMission?.applications || [],
      ),
    [applicantReviewTeamsResponse.data?.newTeams, currentMission?.applications],
  );

  const filteredExtensions = useMemo(() => {
    return getSuggestedTeamsWithApplications(
      applicantReviewTeamsResponse.data?.extensionTeams,
      currentMission?.applications || [],
    );
  }, [
    applicantReviewTeamsResponse.data?.extensionTeams,
    currentMission?.applications,
  ]);

  const canApply = !!auth.uid;
  const canInvite = !!auth.user;

  const isRecruiting =
    currentMission?.data.status === MissionStatus.Created ||
    currentMission?.data.status === MissionStatus.Published;

  const onCloseApplicationModal = (): void => {
    const parsedUrl = new URL(window?.location?.href);
    parsedUrl.searchParams.delete('aid');
    const url = parsedUrl.toString();
    window.history.pushState(null, '', url);
    missions.currentApplication?.setProfileMode(ProfileViewMode.View);
  };

  const openAndActiveRoles = useMemo(() => {
    return (
      currentMission?.rolesSorted.filter(
        (role) =>
          role.status === MissionRoleStatus.Active ||
          role.status === MissionRoleStatus.ScheduledToEnd ||
          role.status === MissionRoleStatus.Open,
      ) || []
    );
  }, [currentMission]);

  const canceledRoles = useMemo(() => {
    return (
      currentMission?.rolesSorted.filter(
        (role) => role.status === MissionRoleStatus.Canceled,
      ) || []
    );
  }, [currentMission]);

  const nonActiveBuilders = useMemo(() => {
    return (
      currentMission?.rolesSorted.filter((role) => {
        const user = role.user as BasicUserObject;
        return (
          role.status === MissionRoleStatus.Ended ||
          (user && user.status === UserStatus.Deleted)
        );
      }) || []
    );
  }, [currentMission]);

  useEffect(() => {
    if (
      currentMission &&
      currentMission.data.status !== MissionStatus.Ended &&
      (currentMission.applications?.length ?? 0) === 0 &&
      mid &&
      auth.isAdmin
    ) {
      setLoading(currentMission.getMissionApplications(), null);
    }
  }, [currentMission, mid]);

  const handleVerifySkillsAndRoles = (role: MissionRole) => {
    setCurrentRole(role);
    const missingRole = currentMission?.getMissingRole(role.rid);
    const missingSkills = currentMission?.getMissingSkills(role.rid);
    const lowProficiencySkills = currentMission?.getLowProficiencySkills(
      role.rid,
    );
    const required = missingSkills?.required;
    const preferred = missingSkills?.preferred;
    const lowProficiencyRequired = lowProficiencySkills?.required;
    const [, userCountryInRoleLocations] = getUserCountry(role);

    if (missingRole) {
      setMissingRole([missingRole]);
    }
    if (required && required.length > 0) {
      setMissingRequiredSkills([...required]);
    }
    if (preferred && preferred.length > 0) {
      setMissingPreferredSkills([...preferred]);
    }
    if (lowProficiencyRequired && lowProficiencyRequired.length > 0) {
      setLowProficiencyRequiredSkills([...lowProficiencyRequired]);
    }

    // Open modal and save data
    if (missingRole || (required && required.length > 0)) {
      toggleRequiredSkillsModalOpen();
      return false;
    }

    if (lowProficiencyRequired && lowProficiencyRequired.length > 0) {
      toggleLowProficiencySkillsModalOpen();
      return false;
    }

    if (
      role?.locations &&
      role.locations?.length > 0 &&
      !userCountryInRoleLocations
    ) {
      toggleLowCompetitivenessModalOpen();
      return false;
    }

    if (notEnoughOverlap) {
      toggleLowCompetitivenessModalOpen();
      return false;
    }

    if (
      role?.availability?.weeklyHoursAvailable &&
      auth?.user?.availability?.weeklyHoursAvailable &&
      role.availability.weeklyHoursAvailable >
        auth.user.availability.weeklyHoursAvailable
    ) {
      toggleLowCompetitivenessModalOpen();
      return false;
    }

    return true;
  };

  const handleApply = (role: MissionRole) => {
    setIsolatedUpdate(false);
    if (!currentMission || !mid || !role.rid) return;

    if (auth.uid) {
      analytics.trackApplicationStarted({
        missionId: currentMission?.data.mid,
        roleId: role.rid,
        userId: auth.uid,
      });
    }

    history.push(MissionApplyLocation(mid, role.rid));
  };

  const handleEdit = (role: MissionRole) => {
    if (!currentMission || !mid || !role.rid || !role.application?.aid) return;
    history.push(MissionApplyEditLocation(mid, role.rid, role.application.aid));
  };

  const handleRequestInvite = (): void => {
    window.open('https://a.team/', '_blank');
  };

  const handleReferFriend = (role: BasicMissionRole): void => {
    if (!currentMission) return;

    analytics.trackReferFriendClicked({
      ...currentMission.data,
      role,
    });

    setInviteRole(role);
  };

  const handleApplicationStatusChange = async (
    aid: MissionApplicationId,
    data: MissionApplicationStatusUpdate,
  ): Promise<void> => {
    if (!currentMission) return;

    const res = await currentMission.updateMissionApplicationStatus(aid, data);

    missions.currentApplication?.application?.setApplicationStatus(res);
  };

  const handleApplicationManuallyProposedChange = async (
    aid: MissionApplicationId,
    data: MissionApplicationManuallyProposedData,
  ): Promise<void> => {
    if (!currentMission) return;

    const res = await currentMission.updateManuallyProposedDetails(aid, data);

    missions.currentApplication?.application?.setApplicationStatus(res);
  };

  const viewApplicantSubmission = async (
    application: MissionApplicationObject,
  ): Promise<void> => {
    if (application.aid !== missions.currentApplication?.application?.aid) {
      await missions.getApplicationProfile(
        application.user.username,
        application,
        application.rid,
      );
      toggleApplicationModalOpen();
    } else {
      toggleApplicationModalOpen();
    }
  };

  const onVideoPlay = () => {
    currentMission &&
      analytics.trackMissionVideoPlayed(currentMission.data, 'mission');
  };

  const removePrefSkill = (skill: Expertise): void => {
    setMissingPreferredSkills(
      missingPreferredSkills.filter((item) => item.id !== skill.id),
    );
  };
  const resetSkills = (): void => {
    setMissingRequiredSkills([]);
    setMissingPreferredSkills([]);
    setLowProficiencyRequiredSkills([]);
    setMissingRole([]);
  };

  const handleUpdateSkillsSubmit = (
    role: Expertise[] | undefined,
    reqSkills: Expertise[],
    prefSkills: Expertise[],
  ): void => {
    setSuccessMsg('Skills Added');
    if (!currentMission) return;
    setLoading(
      currentMission
        ?.updateUserSkillsAndRoles(role, reqSkills, prefSkills)
        .then(() => {
          if (!mid || !currentRole?.rid) return;

          const isQualified = handleVerifySkillsAndRoles(currentRole);

          if (isQualified) {
            history.push(MissionApplyLocation(mid, currentRole?.rid));
          }
        }),
    );

    resetSkills();
    toggleRequiredSkillsModalOpen();
  };

  const handleSetLocationSubmit = (location: LocationObject): void => {
    setSuccessMsg('Location Set');
    if (!currentMission) return;
    setLoading(
      auth.updateLocation(location).then(() => {
        if (!mid || !currentRole?.rid || isolatedUpdate) return;

        const isQualified = handleVerifySkillsAndRoles(currentRole);

        if (isQualified) {
          history.push(MissionApplyLocation(mid, currentRole?.rid));
        }
      }),
    );

    toggleSetLocationModalOpen();
  };

  const handleSetAvailabilitySubmit = (
    availability: AvailabilityData,
  ): void => {
    setSuccessMsg('Availability Set');
    if (!currentMission) return;
    setLoading(
      auth?.updateAvailability(availability).then(() => {
        if (!mid || !currentRole?.rid || isolatedUpdate) return;

        const isQualified = handleVerifySkillsAndRoles(currentRole);

        if (isQualified) {
          history.push(MissionApplyLocation(mid, currentRole?.rid));
        }
      }),
    );

    toggleSetAvailabilityModalOpen();
  };

  const handleSetTimezoneSubmit = (
    timezone: TimezoneObject,
    workingHours: WorkingHoursSchema,
  ): void => {
    setSuccessMsg('Timezone Set');
    if (!currentMission) return;
    setLoading(
      auth?.updateTimezoneAndWorkingHours(timezone, workingHours).then(() => {
        if (!mid || !currentRole?.rid || isolatedUpdate) return;

        const isQualified = handleVerifySkillsAndRoles(currentRole);

        if (isQualified) {
          history.push(MissionApplyLocation(mid, currentRole?.rid));
        }
      }),
    );

    toggleSetTimezoneModalOpen();
  };

  const getUserCountry = useCallback(
    (role: MissionRole): [CountryObject | undefined, boolean] => {
      const roleLocations =
        role?.locations?.filter(
          (code: string) => !!countryListOptionMap[code],
        ) ?? [];

      const currentUserCountryCode = auth.user?.location?.countryShortName;
      const currentUserCountry = rawCountryList.find(
        (country) => country.code === currentUserCountryCode,
      );

      if (!currentUserCountry) {
        return [undefined, false];
      }

      const userCountryInRoleLocations =
        roleLocations.indexOf(currentUserCountry.code) > -1;

      return [currentUserCountry, userCountryInRoleLocations];
    },
    [auth.user?.location?.countryShortName],
  );

  const handleSubmitWithoutUpdating = (): void => {
    resetSkills();
    toggleRequiredSkillsModalOpen();

    if (!mid || !currentRole?.rid) return;
    history.push(MissionApplyLocation(mid, currentRole?.rid));
  };
  const handleUpdateAvailability = () => {
    setLoading(
      (async () => {
        await auth.updateAvailabilityHours(
          auth.promptAvailUpdate.applicationHours,
          undefined,
        );
      })(),
      null,
    );
  };
  const handleDismissUpdateAvailability = () => {
    setLoading(
      (async () => {
        await auth.updateAvailabilityHours(undefined, true);
      })(),
      null,
    );
  };

  const currentApplicationPrecomputedTeams = useMemo(() => {
    return filteredPrecomputedTeams.filter((team) => {
      return Object.values(team.members).some(
        (member) =>
          member.application.aid ===
          missions.currentApplication?.application?.aid,
      );
    });
  }, [filteredPrecomputedTeams, missions.currentApplication]);

  const currentApplicationExtensions = useMemo(() => {
    return filteredExtensions.filter((team) => {
      return Object.values(team.members).some(
        (member) =>
          member.application.aid ===
          missions.currentApplication?.application?.aid,
      );
    });
  }, [filteredExtensions, missions.currentApplication]);

  if (props.adminView && !auth.isAdmin) {
    return (
      <Redirect
        to={
          match.params.mid
            ? MissionPageLocation(match.params.mid)
            : MissionControlBase
        }
      />
    );
  }

  const onSubmitLowCompetitivenessModal = () => {
    toggleLowCompetitivenessModalOpen();
    if (mid && currentRole) {
      history.push(MissionApplyLocation(mid, currentRole.rid));
    }
  };

  const openRoles = currentMission?.data.roles.filter(
    (e) => e.status === MissionRoleStatus.Open,
  );

  return (
    <MissionLayout
      match={props.match}
      title={`${
        currentMission ? currentMission.data.title : 'Loading...'
      } | About`}
    >
      {applicationModalOpen && (
        <ApplicationModal
          open={applicationModalOpen}
          onClose={() => {
            toggleApplicationModalOpen && toggleApplicationModalOpen();
            onCloseApplicationModal && onCloseApplicationModal();
          }}
          profile={missions.currentApplication}
          onStatusChanged={
            auth.isAdmin ? handleApplicationStatusChange : undefined
          }
          onManuallyProposedDetailsChange={
            handleApplicationManuallyProposedChange
          }
          widthConnectionsPanel
          currentMission={currentMission}
          precomputedTeams={currentApplicationPrecomputedTeams}
          extensions={currentApplicationExtensions}
        />
      )}

      {isCodeOfConductModalOpen && (
        <CodeOfConductModal
          showOnly="mission"
          isOpen={isCodeOfConductModalOpen}
          toggleModal={() => {
            setIsCodeOfConductModalOpen(false);
            if (!auth.user?.needsAcceptMissionCodeOfConduct) {
              onCloseCodeOfConductModalRef?.current?.onClose &&
                onCloseCodeOfConductModalRef?.current?.onClose();
            }
          }}
        />
      )}

      {currentUser && currentMission && (
        <>
          {auth.promptAvailUpdate && auth.promptAvailUpdate.shouldUpdate && (
            <ConfirmModal
              title="Do You Want to Update Your Profile Availability?"
              description={`We see you entered ${auth.promptAvailUpdate.applicationHours} hours but your profile is set to  ${auth.promptAvailUpdate.profileHours}. Updating your availability will help us match you to missions.`}
              onConfirm={handleUpdateAvailability}
              open={auth.promptAvailUpdate.shouldUpdate}
              onClose={handleDismissUpdateAvailability}
              actionLabel={'Update'}
            />
          )}
          {(missingRequiredSkills?.length > 0 || missingRole?.length > 0) && (
            <ApplyExpertiseModal
              title={'You Are Missing a Role and/or Skills'}
              onSubmit={(role, reqSkills, prefSkills) =>
                handleUpdateSkillsSubmit(role, reqSkills, prefSkills)
              }
              onSearch={(query) => currentMission.querySkills(query, false)}
              selectedRequiredSkills={missingRequiredSkills}
              selectedPrefSkills={missingPreferredSkills}
              selectedRole={missingRole}
              open={requiredSkillsModalOpen}
              onClose={() => {
                resetSkills();
                toggleRequiredSkillsModalOpen();
              }}
              description={
                <ApplyExpertiseModalDescription
                  missingRequiredSkills={missingRequiredSkills}
                  missingPreferredSkills={missingPreferredSkills}
                  missingRole={missingRole}
                  removePrefSkill={removePrefSkill}
                />
              }
              sideDescription={
                <ExpertiseLegend type={'Skills'}>
                  <p>
                    <strong>
                      These skills will be added to your profile, and you can
                      continue the application process by clicking "Request to
                      Join".
                    </strong>
                  </p>
                </ExpertiseLegend>
              }
              userHasLowProficiencySkills={
                lowProficiencyRequiredSkills?.length > 0
              }
            />
          )}
          {lowProficiencyRequiredSkills?.length > 0 && (
            <ApplyLowProficiencyModal
              title={
                'You lack the required proficiency level in some of the Required Skills'
              }
              onSubmit={(reqSkills) => {
                handleUpdateSkillsSubmit(undefined, reqSkills, []);
              }}
              onSubmitWithoutUpdating={handleSubmitWithoutUpdating}
              onSearch={(query) => currentMission.querySkills(query, false)}
              selectedRequiredSkills={lowProficiencyRequiredSkills}
              open={lowProficiencySkillsModalOpen}
              onClose={() => {
                resetSkills();
                toggleLowProficiencySkillsModalOpen();
              }}
              description={
                <ApplyLowProficiencyModalDescription title="Low Proficiency Skills:">
                  {lowProficiencyRequiredSkills.map((skill) => {
                    return (
                      <Tag
                        key={skill.id}
                        iconType={IconType.BlackArrowDown}
                        name={skill.name}
                        rating={skill.rating}
                      />
                    );
                  })}
                </ApplyLowProficiencyModalDescription>
              }
              sideDescription={<ExpertiseLegend type={'Skills'} />}
              userMissingLocation={
                currentRole ? getUserCountry(currentRole) === undefined : false
              }
            />
          )}
        </>
      )}
      <SetLocationModal
        open={setLocationModalOpen}
        onClose={() => {
          toggleSetLocationModalOpen();
        }}
        onSubmit={(location: LocationObject) => {
          handleSetLocationSubmit(location);
        }}
      />
      <SetAvailabilityModal
        open={setAvailabilityModalOpen}
        onClose={() => {
          toggleSetAvailabilityModalOpen();
        }}
        onSubmit={(availability: AvailabilityData) => {
          handleSetAvailabilitySubmit(availability);
        }}
      />
      <SetTimezoneModal
        open={setTimezoneModalOpen}
        currentUserTimezone={currentUser?.timezone}
        workingHours={auth.user?.workingHours}
        onClose={() => {
          toggleSetTimezoneModalOpen();
        }}
        onSubmit={(
          timezone: TimezoneObject,
          workingHours: WorkingHoursSchema,
        ) => {
          handleSetTimezoneSubmit(timezone, workingHours);
        }}
      />
      <AllLocationsModal
        open={allLocationsModalOpen}
        role={currentRole}
        userCountry={
          currentRole ? getUserCountry(currentRole) : [undefined, false]
        }
        onClose={() => {
          toggleAllLocationsModalOpen();
        }}
      />
      <LowCompetitivenessModal
        open={lowCompetitivenessModalOpen}
        onClose={toggleLowCompetitivenessModalOpen}
        onSubmit={onSubmitLowCompetitivenessModal}
      />

      {auth.user && inviteRole && (
        <InviteModal
          currentUser={auth.user}
          open
          openMissionRole={inviteRole}
          onClose={() => setInviteRole(null)}
        />
      )}
      {currentMission && (
        <>
          <div className={styles.heroContainer}>
            {!auth.username && (
              <RequestInviteCard
                onRequestInvite={handleRequestInvite}
                style={screenClass === 'xs' ? { marginTop: 0 } : undefined}
              />
            )}

            <div className={styles.container}>
              {!auth.isAdmin && (
                <Alert className={styles.alert}>
                  <h4 className={styles.alertTitle}>
                    Earn tokens for inviting a new A.Teamer to this mission
                  </h4>
                  <p className={styles.alertContent}>
                    Do you know the perfect builder for one or more of these
                    open roles? Refer a new A.Teamer and earn tokens for every
                    hour they work on the mission.
                  </p>
                </Alert>
              )}
              {auth.isAdmin && (
                <>
                  {filteredPrecomputedTeams.length ? (
                    <PotentialTeams
                      showHeader
                      missionStatus={currentMission.data.status}
                      proposeTeamButtonText="Open in proposal"
                      onClickOnProposeTeam={() =>
                        history.push(MissionProposalLocation(mid || ''))
                      }
                      teams={filteredPrecomputedTeams || []}
                      openRoles={openRoles || []}
                      applications={currentMission.applications || []}
                    />
                  ) : null}
                  {filteredExtensions.length ? (
                    <PotentialExtensions
                      showHeader
                      proposeTeamButtonText="Open in proposal"
                      missionStatus={currentMission.data.status}
                      onClickOnProposeTeam={() =>
                        history.push(MissionProposalLocation(mid || ''))
                      }
                      teams={filteredExtensions || []}
                      openRoles={openRoles || []}
                      applications={currentMission.applications}
                    />
                  ) : null}
                </>
              )}
              <div>
                <div className={styles.rolesTitle}>
                  {isRecruiting ? 'Apply for a Mission Role' : 'Team Members'}
                </div>
                {auth.withTeamUp && <TeamUpBanner />}
                {!auth.username && (
                  <p>
                    If you're not already an A·Team member and you want to join
                    this mission,{' '}
                    <span className={styles.link} onClick={handleRequestInvite}>
                      Request an Invite to Join A·Team
                    </span>
                    . You'll get to work with world-class tech talent and land
                    ambitious work with high-end clients.
                  </p>
                )}
                <div className={styles.rolesContainer}>
                  {openAndActiveRoles &&
                    openAndActiveRoles.map((role) => (
                      <Fragment key={role.rid}>
                        <NewMissionRoleRow
                          key={role.rid}
                          currentUserHasRole={currentMission.currentUserRole}
                          userCountry={getUserCountry(role)}
                          currentUserTimezone={auth.user?.timezone}
                          currentUserWorkingHours={auth.user?.workingHours}
                          applications={
                            auth.isAdmin
                              ? currentMission?.applications &&
                                currentMission.applications.filter(
                                  (application) =>
                                    application.rid === role.rid &&
                                    !application.withdrawn,
                                )
                              : undefined
                          }
                          onApplicationClick={viewApplicantSubmission}
                          hideMember={!auth.username}
                          role={role}
                          onApply={() => {
                            if (auth.user?.needsAcceptMissionCodeOfConduct) {
                              onCloseCodeOfConductModalRef.current.onClose =
                                canApply ? () => handleApply(role) : undefined;

                              return setIsCodeOfConductModalOpen(true);
                            }

                            return canApply ? handleApply(role) : undefined;
                          }}
                          onEdit={() => handleEdit(role)}
                          onInvite={
                            canInvite
                              ? () => handleReferFriend(role)
                              : undefined
                          }
                          showLookingForApplicationsToggle={auth.isAdmin}
                          onLookingForMoreApplicationsToggle={(e) =>
                            currentMission.toggleRoleLookingForApplications(
                              role.rid,
                              e.target.checked,
                            )
                          }
                          onNicheToggle={(isNiche) =>
                            currentMission.toggleRoleIsNiche(role.rid, isNiche)
                          }
                          showMoreButton={
                            <CallToActionButton
                              onClick={() =>
                                currentMission.getMissionApplications()
                              }
                              className={styles.showMoreApplicantsCTA}
                              color="regular"
                              disabled={!currentMission.applicationsAvailable}
                            >
                              Show More
                            </CallToActionButton>
                          }
                          toggleSetLocationModalOpen={() => {
                            setIsolatedUpdate(true);
                            toggleSetLocationModalOpen();
                          }}
                          toggleSetAvailabilityModalOpen={() => {
                            setIsolatedUpdate(true);
                            toggleSetAvailabilityModalOpen();
                          }}
                          toggleAllLocationsModalOpen={() => {
                            setCurrentRole(role);
                            toggleAllLocationsModalOpen();
                          }}
                          toggleSetTimezoneModalOpen={() => {
                            setIsolatedUpdate(true);
                            toggleSetTimezoneModalOpen();
                          }}
                          toggleOptimizationRequestModal={() => {
                            setOptimizationRequestRoleId(role.rid);
                          }}
                          setNotEnoughOverlap={setNotEnoughOverlap}
                        />
                      </Fragment>
                    ))}
                  {auth.isAdmin && (
                    <>
                      <CollapsibleContainer
                        title={`Non Active Builders (${nonActiveBuilders.length})`}
                      >
                        <div>
                          {!!nonActiveBuilders.length &&
                            nonActiveBuilders?.map((role) => (
                              <NewMissionRoleRow
                                key={role.rid}
                                role={role}
                                showLookingForApplicationsToggle={false}
                                onApplicationClick={viewApplicantSubmission}
                                applications={currentMission.applications?.filter(
                                  (application) => application.rid === role.rid,
                                )}
                              />
                            ))}
                        </div>
                      </CollapsibleContainer>
                      <CollapsibleContainer
                        title={`Canceled Positions (${canceledRoles.length})`}
                      >
                        <div>
                          {!!canceledRoles.length &&
                            canceledRoles?.map((role) => (
                              <NewMissionRoleRow
                                key={role.rid}
                                role={role}
                                showLookingForApplicationsToggle={false}
                                onApplicationClick={viewApplicantSubmission}
                                applications={currentMission.applications?.filter(
                                  (application) => application.rid === role.rid,
                                )}
                              />
                            ))}
                        </div>
                      </CollapsibleContainer>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className={styles.subTitle} style={{ marginTop: 0 }}>
              What We're Building
            </div>
            {currentMission?.data.companyStory && (
              <div className={styles.description}>
                {ReactHtmlParser(currentMission.data.companyStory)}
              </div>
            )}
            <div className={styles.description}>
              {ReactHtmlParser(currentMission?.data.description)}
            </div>

            {currentMission.data.attachedLinks && (
              <LinksList items={currentMission.data.attachedLinks} />
            )}

            {currentMission?.data.videoURL && (
              <div style={{ marginTop: 64 }} id="video">
                <SectionHeading style={{ marginTop: 0, marginBottom: 32 }}>
                  Video description
                </SectionHeading>
                <VideoPlayer
                  url={currentMission?.data.videoURL}
                  onPlay={onVideoPlay}
                />
              </div>
            )}
          </div>
        </>
      )}

      <TeamUpRequestNoLongerAvailable
        open={teamUpRequestNoLongerAvailableModalOpen}
        handleModalClose={onCloseTeamUpRequestNoLongerAvailableModal}
      />

      <LoadingIndicator loading={loading} successMsg={successMsg} />
      <RequestOptimizationModal
        isLoading={isCreatingRoleOptimization}
        isOpen={!!optimizationRequestRoleId}
        onClose={() => {
          setOptimizationRequestRoleId(undefined);
        }}
        onConfirm={(data) => {
          if (!optimizationRequestRoleId) return;
          createRoleOptimization({
            roleId: optimizationRequestRoleId,
            ...data,
          });
        }}
      ></RequestOptimizationModal>
    </MissionLayout>
  );
};

export default observer(AboutMissionView);
