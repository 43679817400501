import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import AdminInvoicesDashboardLayout from '@src/layouts/InvoicesDashboard';
import { Stores, useStores } from '@src/stores';
import Table, { TableRow } from '@src/components/Table';
import TextButton from '@src/components/TextButton';
import Loader from '@src/components/Loader';
import { numberWithCommas } from '@src/helpers/numbers';
import { stringifyDateRange, stringifyMinutes } from '@src/helpers/time';
import UserAvatar from '@src/components/UserAvatar';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import PaymentStatus from './PaymentStatus';
import GlobalPaymentCycleNav, {
  CycleCardsState,
} from './GlobalPaymentCycleNav';
import { GlobalPaymentCycle, GlobalPaymentCycleRole } from '@ateams/api';
import ConfirmModal from '@src/components/Modal/ConfirmModal';
import { ServerSideLoader, ViewProps } from '@src/routes';
import { MissionAdminTimeTrackingLocation } from '@src/locations';
import { Checkbox, LoadMoreLink } from '@ateams/components';
import InlineInput from '@src/components/Inputs/InlineInput';
import useDebounceState from '@src/hooks/useDebounceState';

export const adminInvoicesPrepayPaymentsLoader: ServerSideLoader = async (
  stores: Stores,
  match,
  location,
) => {
  const { invoices } = stores;

  if (!invoices.globalPaymentCycles) {
    await invoices.loadGlobalPaymentCycles();
  }

  const currentCycleId =
    getCurrentCycleId(location.search) ||
    invoices.globalPaymentCycles?.items[0]?.id;

  if (!currentCycleId) {
    return;
  }

  await invoices.loadGlobalPaymentCycleRoles(currentCycleId);
};

function getCurrentCycleId(search: string): string | undefined {
  return search.match(/[?&]id=([^&]+)/)?.[1];
}

const AdminInvoicesPrepayPaymentsView = (props: ViewProps) => {
  const { location, history } = props;
  const { invoices } = useStores();
  const [loading, setLoading] = useLoadingState();
  const [prepayOne, setPrepayOne] = useState<GlobalPaymentCycleRole | null>(
    null,
  );
  const [minBilledHours, setMinBilledHours] = useDebounceState<
    number | undefined
  >(undefined, (value) => invoices.setMinBilledMinutes((value || 0) * 60), 600);
  const [prepaySelected, setPrepaySelected] =
    useState<GlobalPaymentCycle | null>(null);

  const [selection, setSelection] = useState<Record<string, 1>>({});

  const [currentCycleId, currentCycleState, setCurrentCycleId] = useMemo(
    () => [
      getCurrentCycleId(location.search),
      (location.state as Record<'currCycle', CycleCardsState>)?.currCycle,
      (id: string, state: CycleCardsState) =>
        history.replace(`?id=${id}`, { currCycle: state }),
    ],
    [location, history],
  );

  const currentCycle = useMemo(
    () =>
      invoices.globalPaymentCycles?.items.find(
        (item) => item.id === currentCycleId,
      ) || invoices.globalPaymentCycles?.items[0],
    [currentCycleId, invoices.globalPaymentCycles?.items],
  );

  useEffect(() => {
    const id = currentCycle?.id;
    if (!id) return;

    setSelection({});
    setLoading(invoices.loadGlobalPaymentCycleRoles(id), null);
  }, [invoices, currentCycle?.id, invoices.minBilledMinutes]);

  useEffect(() => {
    setMinBilledHours(
      invoices.minBilledMinutes
        ? Math.round(invoices.minBilledMinutes / 60)
        : undefined,
    );
  }, [invoices.minBilledMinutes]);

  const items = useMemo(
    () => invoices.globalPaymentCycles?.items.slice().reverse(),
    [invoices.globalPaymentCycles],
  );

  const headers = useMemo(() => {
    return [
      'Team Member',
      'Mission',
      'Total Hours',
      'Total Invoice',
      'Payment Status',
      <TextButton
        highlight
        onClick={() => setPrepaySelected(currentCycle || null)}
        disabled={
          !currentCycle ||
          loading === true ||
          currentCycle.summary.totalAmount <= currentCycle.summary.paidAmount ||
          !Object.keys(selection).length
        }
      >
        Prepay
        <br />
        Selected
      </TextButton>,
      'Actions',
    ];
  }, [currentCycle, loading, selection]);

  const updateSelection = (sid: string, checked: boolean) =>
    setSelection((selection) => {
      const res = { ...selection };

      if (checked) {
        res[sid] = 1;
      } else {
        delete res[sid];
      }

      return res;
    });

  return (
    <AdminInvoicesDashboardLayout title={'Prepay Payments'}>
      <ConfirmModal
        open={!!prepayOne}
        onClose={() => setPrepayOne(null)}
        onConfirm={() => {
          if (!currentCycleId || !prepayOne) return;

          setPrepayOne(null);
          setLoading(
            invoices.prepayGlobalPaymentCycleRole(
              currentCycleId,
              prepayOne.sid,
            ),
          );
        }}
        title="Prepay Timesheet?"
        description={
          prepayOne ? (
            <>
              Prepay <strong>{prepayOne.user.fullName}</strong> a payment of{' '}
              <strong>
                ${numberWithCommas(prepayOne.summary.totalPayments || 0)}
              </strong>
              ?
            </>
          ) : (
            ''
          )
        }
      />

      <ConfirmModal
        open={!!prepaySelected}
        onClose={() => setPrepaySelected(null)}
        onConfirm={() => {
          if (!prepaySelected) return;

          setPrepaySelected(null);
          setLoading(
            invoices
              .prepayGlobalPaymentCycleRoles(
                prepaySelected.id,
                Object.keys(selection),
              )
              .then(() => setSelection({})),
          );
        }}
        title="Prepay Selected?"
        description={
          prepaySelected ? (
            <>
              Are you sure you want to prepay {Object.keys(selection).length}{' '}
              selected timesheets on period{' '}
              <strong>
                {stringifyDateRange(
                  prepaySelected.startDate,
                  prepaySelected.endDate,
                )}
              </strong>
              ?
            </>
          ) : (
            ''
          )
        }
      />

      {items ? (
        <>
          <GlobalPaymentCycleNav
            items={items}
            currentId={currentCycle?.id}
            state={currentCycleState}
            setCurrent={setCurrentCycleId}
          />

          <br />
          <p>
            Show builders with at least{' '}
            <InlineInput
              value={minBilledHours || ''}
              onChange={(e) =>
                setMinBilledHours(
                  e.target.value
                    ? Number(e.target.value) || minBilledHours
                    : undefined,
                )
              }
              placeholder="400"
              style={{
                display: 'inline-block',
                verticalAlign: 'top',
                margin: '0 4px',
              }}
            />{' '}
            hours
          </p>
          <br />

          {currentCycle?.roles && (
            <>
              <Table headers={headers} noDataText="No builders">
                {currentCycle.roles.items.length > 0 &&
                  currentCycle.roles.items.map((item) => (
                    <TableRow
                      key={item.sid}
                      cells={[
                        <UserAvatar
                          src={item.user.profilePictureURL}
                          size={28}
                          label={item.user.fullName}
                        />,
                        <a
                          href={MissionAdminTimeTrackingLocation(
                            item.mission.mid,
                          )}
                          rel="noopener noreferrer"
                          // eslint-disable-next-line react/jsx-no-target-blank
                          target="_blank"
                        >
                          {item.mission.title}
                        </a>,
                        stringifyMinutes(item.summary.totalMinutes),
                        `$${numberWithCommas(item.summary.totalPayments)}`,
                        <PaymentStatus
                          invoiceStatus={item.invoice?.status}
                          submitted={item.summary.submitted}
                          totalPayments={item.summary.totalPayments}
                          prepayEligible={item.prepayEligible}
                        />,
                        item.summary.submitted &&
                          item.summary.totalPayments > 0 && (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <Checkbox
                                checked={!!selection[item.sid]}
                                onChange={(e) =>
                                  updateSelection(item.sid, e.target.checked)
                                }
                                disabled={
                                  item.prepaid != null || loading === true
                                }
                              />
                            </div>
                          ),
                        item.summary.submitted &&
                          item.summary.totalPayments > 0 && (
                            <TextButton
                              highlight={item.prepayEligible}
                              color="secondary"
                              onClick={() => setPrepayOne(item)}
                              disabled={
                                item.prepaid != null || loading === true
                              }
                            >
                              {item.prepaid
                                ? 'Prepaid ✔'
                                : item.prepayEligible
                                ? 'Prepay'
                                : 'Force Payment'}
                            </TextButton>
                          ),
                      ]}
                    />
                  ))}
              </Table>

              {currentCycle.roles.next && (
                <LoadMoreLink
                  onLoadMore={() =>
                    setLoading(
                      invoices.loadGlobalPaymentCycleRoles(
                        currentCycle.id,
                        true,
                      ),
                      null,
                    )
                  }
                />
              )}
            </>
          )}
        </>
      ) : (
        <Loader />
      )}

      <LoadingIndicator loading={loading} />
    </AdminInvoicesDashboardLayout>
  );
};

export default observer(AdminInvoicesPrepayPaymentsView);
