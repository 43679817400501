import { teamEngineSearchServiceApi } from '@src/logic/services/endpoints';
import { v1CityListFilterApi } from '@a_team/team-engine-search-service-sdk-js/dist/schema';
import { getAuthToken } from '@src/helpers/auth';
import { queryKeys } from '@src/rq/keys';
import { useQuery } from '@tanstack/react-query';
import { SelectOption } from '@ateams/components';

export const useQueryUserCitiesByCountry = (params?: {
  filter?: v1CityListFilterApi;
  applicationName?: string;
}) => {
  const { filter, applicationName } = params ?? {};
  const countries = filter?.countryCode?.filter((c): c is string => !!c) ?? [];

  return useQuery(
    queryKeys.cities.byCountries(countries),
    async (): Promise<ReturnType<typeof teamEngineSearchServiceApi.query>> => {
      return teamEngineSearchServiceApi.query(
        {
          v1ListCities: [{ applicationName, filter }, { cities: 1 }],
        },
        { headers: { Authorization: getAuthToken() } },
      );
    },
    {
      select: ({ data }) => {
        return (
          data?.v1ListCities?.cities?.sort().map(
            (c: string) =>
              ({
                value: c as string,
                label: c as string,
              } as SelectOption),
          ) ?? []
        );
      },
    },
  );
};
