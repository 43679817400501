import { VettingProcessStatus } from '@a_team/models/dist/vetting-processes/status';
import {
  VettingAdminColumns,
  VettingBasicColumns,
} from '../vetting-table/columns';
import {
  VETTING_PROCESSES_TABLE_TABS,
  getProcessesTablePage,
} from '../vetting-processes-table-page';
import { FilterComponents } from '../vetting-processes-query-bar/filters';
import { VettingType } from '@a_team/models/dist/vetting-processes/vetting-processes';
import { FiltersConfig } from '../vetting-processes-table-page/filters';

const COLUMNS = {
  ADMIN: {
    DEFAULT: [
      VettingAdminColumns.NameWithOptOutIndication,
      VettingAdminColumns.InterviewType,
      VettingAdminColumns.ContactOwner,
      VettingAdminColumns.Interviewer,
      VettingBasicColumns.InterviewDate,
      VettingAdminColumns.InterviewerNoShow,
      VettingBasicColumns.FeedbackSubmitDate,
      VettingBasicColumns.Expertise,
      VettingBasicColumns.InteractionExperience,
      VettingBasicColumns.EnglishLevel,
      VettingBasicColumns.Accent,
      VettingAdminColumns.FeedbackScoresActions,
      VettingBasicColumns.SubmitFeedback,
      VettingAdminColumns.RemoveApplication,
      VettingBasicColumns.CancelInterview,
    ],
    ALL: [
      VettingAdminColumns.NameWithOptOutIndication,
      VettingAdminColumns.InterviewType,
      VettingBasicColumns.BuilderInfo,
      VettingBasicColumns.MainRole,
      VettingBasicColumns.SkillForVetting,
      VettingAdminColumns.Status,
      VettingAdminColumns.ContactOwner,
      VettingBasicColumns.PrevettingAnswers,
      VettingBasicColumns.CodeSample,
      VettingAdminColumns.Interviewer,
      VettingAdminColumns.InterviewerSuggestions,
      VettingAdminColumns.CreatedAt,
      VettingAdminColumns.PreVettingFormSentDate,
      VettingAdminColumns.EvaluationInvite,
      VettingAdminColumns.BookingLink,
      VettingBasicColumns.PhoneNumber,
      VettingBasicColumns.InterviewDate,
      VettingAdminColumns.InterviewerNoShow,
      VettingBasicColumns.FeedbackSubmitDate,
      VettingBasicColumns.Expertise,
      VettingBasicColumns.InteractionExperience,
      VettingBasicColumns.EnglishLevel,
      VettingBasicColumns.Accent,
      VettingAdminColumns.FeedbackScoresActions,
      VettingAdminColumns.CallRecording,
      VettingAdminColumns.Transcript,
      VettingBasicColumns.SubmitFeedback,
      VettingAdminColumns.RemoveApplication,
      VettingBasicColumns.CancelInterview,
    ],
  },
  DEFAULT: [
    VettingBasicColumns.Name,
    VettingBasicColumns.InterviewDate,
    VettingBasicColumns.Expertise,
    VettingBasicColumns.InteractionExperience,
    VettingBasicColumns.EnglishLevel,
    VettingBasicColumns.Accent,
    VettingBasicColumns.SubmitFeedback,
    VettingBasicColumns.CancelInterview,
  ],
  [VettingType.InternalTechnical]: [
    VettingBasicColumns.Name,
    VettingBasicColumns.InterviewerReadOnly,
    VettingBasicColumns.InterviewDate,
    VettingBasicColumns.Expertise,
    VettingBasicColumns.InteractionExperience,
    VettingBasicColumns.EnglishLevel,
    VettingBasicColumns.Accent,
    VettingBasicColumns.SubmitFeedback,
    VettingBasicColumns.CancelInterview,
  ],
};

const FILTERS: FiltersConfig = {
  ADMIN: new Set([
    FilterComponents.VettingType,
    FilterComponents.VettingStatus,
    FilterComponents.Vetter,
    FilterComponents.InterviewDate,
    FilterComponents.Badge,
  ]),
  [VettingType.InternalTechnical]: new Set([
    FilterComponents.Vetter,
    FilterComponents.User,
    FilterComponents.MainRole,
    FilterComponents.CreatedAt,
  ]),
};

export default getProcessesTablePage({
  tabName: VETTING_PROCESSES_TABLE_TABS.completedReviews,
  columnsConfig: COLUMNS,
  filtersConfig: FILTERS,
  title: 'Completed Reviews',
  noProcessesMessage: 'No Completed Reviews',
  statuses: [VettingProcessStatus.Completed],
});
