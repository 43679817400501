import React from 'react';
import clsx from 'clsx';
import { createUseStyles } from 'react-jss';
import {
  Spacing,
  FontSizes,
  FontWeights,
  BorderRadius,
  Colors,
} from '@ateams/components';

const useStyles = createUseStyles({
  scoreSelection: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.xsmall,
  },
  score: {
    fontSize: FontSizes.xsmall,
    fontWeight: FontWeights.regular,
    cursor: 'pointer',
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: BorderRadius.default,
    border: `1px solid ${Colors.regularLight}`,
  },
  selected: {
    backgroundColor: Colors.secondaryDark,
    color: Colors.light,
  },
});

export interface ScoreSelectionProps {
  score: number;
  onChange: (score: number) => void;
}

export const ScoreSelection = ({ score, onChange }: ScoreSelectionProps) => {
  const styles = useStyles();

  return (
    <div className={styles.scoreSelection}>
      {[1, 2, 3, 4, 5].map((currentScore) => (
        <div
          key={currentScore}
          onClick={() => onChange(currentScore)}
          className={clsx(
            styles.score,
            currentScore === score && styles.selected,
          )}
        >
          {currentScore}
        </div>
      ))}
    </div>
  );
};
