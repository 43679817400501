import React, { useEffect, useMemo, useState } from 'react';
import useLoadingState from '@src/hooks/useLoadingState';
import {
  AsyncSelect,
  AsyncSelectProps,
  SelectOption,
} from '@ateams/components';
import {
  TalentSpecialization,
  TalentSpecializationId,
} from '@a_team/models/dist/TalentCategories';
import { apiTalentSpecializations } from '@ateams/api';
import { useStores } from '@src/stores';

export interface RoleSelectorProps<IsMulti extends boolean = false>
  extends Omit<AsyncSelectProps<IsMulti>, 'loadOptions'> {
  isMulti?: boolean;
  defaultValue?: IsMulti extends true
    ? TalentSpecializationId[] | SelectOption[]
    : TalentSpecializationId | SelectOption;
  onChange?: OnRoleSelectorChange<IsMulti>;
}

export type OnRoleSelectorChange<IsMulti extends boolean = false> = (
  option:
    | (IsMulti extends true ? RoleCategoryOptionType[] : RoleCategoryOptionType)
    | null,
) => void;

export interface RoleCategoryOptionType {
  value: string;
  label: string;
  talentSpecialization: TalentSpecialization;
}

export function talentSpecializationToOption(
  talentSpecialization: TalentSpecialization,
): RoleCategoryOptionType {
  return {
    value: talentSpecialization.id,
    label: talentSpecialization.name,
    talentSpecialization,
  };
}

export const RoleSelector = <
  T extends RoleSelectorProps<T['isMulti'] extends true ? true : false>,
>(
  props: T,
) => {
  const {
    defaultValue,
    isDisabled,
    onChange,
    isMulti = false,
    ...rest
  } = props;
  const { auth } = useStores();
  const [loadingDefault, setLoadingDefault] = useLoadingState();
  const [role, setRole] = useState<
    RoleCategoryOptionType[] | RoleCategoryOptionType | null
  >();

  const setDefault = async () => {
    if (defaultValue) {
      try {
        setLoadingDefault(
          (async () => {
            if (Array.isArray(defaultValue)) {
              if (typeof defaultValue?.[0] === 'string') {
                const roles =
                  await apiTalentSpecializations.queryTalentSpecializations(
                    auth,
                    { ids: defaultValue as string[] },
                  );

                setRole(roles.items.map(talentSpecializationToOption));
              } else {
                setRole(defaultValue as RoleCategoryOptionType[]);
              }
            } else {
              if (typeof defaultValue === 'string') {
                const role =
                  await apiTalentSpecializations.getTalentSpecializationsById(
                    auth,
                    defaultValue as string,
                  );

                setRole(talentSpecializationToOption(role));
              } else {
                setRole(defaultValue as RoleCategoryOptionType);
              }
            }
          })(),
          false,
        );
      } catch (err) {
        console.error(err, 'Failed to find talent specialization by id');
      }
    }
  };

  useEffect(() => {
    setDefault();
  }, []);

  const value = useMemo(() => props.value || role, [props.value, role]);

  const onRoleChange: AsyncSelectProps<
    T['isMulti'] extends true ? true : false,
    RoleCategoryOptionType
  >['onChange'] = async (option) => {
    setRole(option);
    onChange && onChange(option);
  };

  const loadOptions = async (searchTerm: string) => {
    const skills = await apiTalentSpecializations.queryTalentSpecializations(
      auth,
      {
        searchTerm,
      },
    );

    return skills.items.map(talentSpecializationToOption);
  };

  const onRemoveOption =
    props.onRemoveOption || isMulti
      ? (option: SelectOption) => {
          if (!value) {
            return;
          }

          const newRoles = (value as RoleCategoryOptionType[]).filter(
            ({ value }) => value !== option.value,
          );

          setRole(newRoles);
          onChange &&
            onChange(
              newRoles as (
                T['isMulti'] extends true ? true : false
              ) extends true
                ? RoleCategoryOptionType[]
                : RoleCategoryOptionType,
            );
        }
      : undefined;

  return (
    <AsyncSelect<
      T['isMulti'] extends true ? true : false,
      RoleCategoryOptionType
    >
      placeholder={'Select Role...'}
      loadOptions={loadOptions}
      onChange={onRoleChange}
      value={value}
      useDebounce
      isClearable
      isDisabled={isDisabled || loadingDefault}
      isLoading={loadingDefault}
      onRemoveOption={onRemoveOption}
      isMulti={isMulti}
      {...rest}
    />
  );
};
