import React from 'react';
import { useCommonStyles } from '../commonStyles';
import { createUseStyles } from 'react-jss';
import { FieldTextarea } from '../general/FieldTextarea';
import { Spacing, Colors, BorderRadius } from '@ateams/components';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.small,
    backgroundColor: Colors.backgroundLight,
    borderRadius: BorderRadius.xLarge,
  },
  impressionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
    padding: Spacing.medium,
  },
  divider: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: Spacing.small,
    marginBottom: Spacing.small,
    borderTop: `1px solid ${Colors.regularLight}`,
  },
});

export interface OverallImpressionSectionProps {
  callSummary: string;
  onCallSummaryChange: (callSummary: string) => void;
  yourNotes: string;
  onYourNotesChange: (yourNotes: string) => void;
}

export const OverallImpressionSection: React.FC<
  OverallImpressionSectionProps
> = ({ callSummary, onCallSummaryChange, yourNotes, onYourNotesChange }) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();

  return (
    <div className={commonStyles.section}>
      <div className={commonStyles.sectionTitle}>Overall impression</div>
      <div className={styles.container}>
        <div className={styles.impressionsContainer}>
          <FieldTextarea
            label="Call summary *"
            placeholder="Enter your thoughts after the call"
            value={callSummary}
            onChange={(value) => onCallSummaryChange(value)}
            minLength={280}
          />
          <hr className={styles.divider} />
          <FieldTextarea
            label="Your notes *"
            placeholder="Enter overall impression"
            value={yourNotes}
            onChange={(value) => onYourNotesChange(value)}
            minLength={280}
          />
        </div>
      </div>
    </div>
  );
};
