import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from '../general/Link';
import { Spacing, FontSizes, Colors } from '@ateams/components';

const useStyles = createUseStyles({
  builderSectionLinks: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.small,
    alignItems: 'center',
  },
  builderSectionLinkContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.small,
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  builderSectionLinkPassword: {
    fontSize: FontSizes.xsmall,
    color: Colors.dark,
  },
});

export interface BuilderSectionHorizontalLinksProps {
  linkedinUrl: string;
  resumeUrl: string;
  portfolioUrl: string;
  portfolioPassword?: string;
}

export const BuilderSectionHorizontalLinks = ({
  linkedinUrl,
  resumeUrl,
  portfolioUrl,
  portfolioPassword,
}: BuilderSectionHorizontalLinksProps) => {
  const styles = useStyles();

  return (
    <div className={styles.builderSectionLinks}>
      <Link href={linkedinUrl} text="LinkedIn" />
      <Link href={resumeUrl} text="Resume" />
      <div className={styles.builderSectionLinkContainer}>
        <Link href={portfolioUrl} text="Portfolio" />
        {portfolioPassword && (
          <div className={styles.builderSectionLinkPassword}>
            ({portfolioPassword})
          </div>
        )}
      </div>
    </div>
  );
};
