import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { createUseStyles } from 'react-jss';
import MainLayout from '@src/layouts/Main';
import { Colors, Spacing } from '@ateams/components';
import LoadingIndicator from '@src/components/LoadingIndicator';
import useLoadingState from '@src/hooks/useLoadingState';
import { useParams } from 'react-router-dom';
import { apiTranscript } from '@ateams/api';
import { useStores } from '@src/stores';
import { TranscriptData } from '@ateams/api/dist/endpoints/Transcripts';
import TranscriptLines from './TranscriptLines';
import TranscriptHeader from './TranscriptHeader';
import TranscriptTabs, { TranscriptTabType } from './TranscriptTabs';
import TranscriptSummary from './TranscriptSummary';

export const useStyles = createUseStyles({
  content: {
    background: Colors.backgroundWhite,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100%',
    padding: 0,
    paddingTop: Spacing.xLarge,
    paddingBottom: Spacing.xLarge,
  },
  transcriptArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: Spacing.large,
    padding: Spacing.xLarge,
  },
});

const TranscriptView = () => {
  const styles = useStyles();
  const { auth } = useStores();
  const [, setLoading] = useLoadingState();
  const { jobId } = useParams<{
    jobId: string;
  }>();
  const [transcript, setTranscript] = useState<TranscriptData>();
  const [activeTab, setActiveTab] = useState(TranscriptTabType.Transcript);

  const getTranscript = async (jobId: string) => {
    const newTranscript = await apiTranscript.getTranscript(auth, jobId);
    setTranscript(newTranscript);
  };

  useEffect(() => {
    if (!jobId) {
      return;
    }
    setLoading(getTranscript(jobId), 'Transcript loaded');
  }, []);

  const onTabChange = (tab: number) => {
    setActiveTab(tab);
  };

  return (
    <MainLayout title="Transcript" contentClassName={styles.content}>
      <LoadingIndicator loading={!transcript} />
      {transcript && (
        <>
          <TranscriptHeader transcript={transcript} />
          <TranscriptTabs activeTab={activeTab} onTabChange={onTabChange} />
          {activeTab === TranscriptTabType.Transcript && (
            <TranscriptLines
              recordingUrl={transcript.recordingUrl}
              lines={transcript.lines}
            />
          )}
          {activeTab === TranscriptTabType.Summary && (
            <TranscriptSummary
              summary={transcript.summary}
              duration={transcript.duration}
            />
          )}
        </>
      )}
    </MainLayout>
  );
};

export default observer(TranscriptView);
