import { ServiceAuth, ServiceEndpoint } from './utils';
import { removeUndefinedValues } from '@ateams/service-utils';
import {
  DateISOString,
  QueryNextToken,
  QueryRequestPayload,
  QueryResult,
} from '@a_team/models/dist/misc';
import { RoleCategoryId } from '@a_team/models/dist/RoleCategory';
import {
  EnrichedTeamNarrativesForExportResponse,
  EnrichedTeamNarrativesResponse,
  GetEnrichedTeamNarrativesQueryOptions,
  SuggestedTeamsResultsWithNarrative,
} from '@a_team/models/dist/TeamGraphObject';
import { UserId } from '@a_team/models/dist/UserObject';
import { AdminNotesScore } from '@a_team/models/dist/AdminNotesObject';
import {
  TalentSkillId,
  TalentSkillRating,
  TalentSpecializationId,
} from '@a_team/models/dist/TalentCategories';
import { TalentIndustryId } from '@a_team/models/dist/TalentIndustry';
import TargeterSearchObject, {
  TargeterSearchId,
} from '@a_team/models/dist/TargeterSearchObject';
import { MissionId } from '@a_team/models/dist/MissionObject';
import {
  ExperienceGraphAugmentBuilderResponse,
  ExperienceGraphAugmentMissionResponse,
} from '@a_team/models/dist/ExperieneceGraph';
import { CompanyId } from '@a_team/models/dist/Company';
import { SuggestedTeam } from '@a_team/models/dist/TeamGraphObject';
import { AccountId } from '@a_team/models/src/Account';

export const BasePath = '/team-graph';

export enum BuilderMissionStatus {
  NotActive = 'notActive',
  Active = 'active',
}

export const ProfileCompletenessVariants = [
  'mvp',
  'hqp',
  'mvp-v1',
  'hqp-v1',
] as const;
export type ProfileCompletenessVariant =
  typeof ProfileCompletenessVariants[number];

export interface ScrubQueryParameters {
  includeExceptional?: boolean;
  includeVerified?: boolean;
  includeInsufficient?: boolean;
  includeLegacyUnknown?: boolean;
  includeUnknown?: boolean;
  includeUnscrubbed?: boolean;
}

export interface AdminNotesScoreQueryParameters {
  onlyExactScores?: boolean;
  expertiseScore?: AdminNotesScore;
  interactionScore?: AdminNotesScore;
  englishScore?: AdminNotesScore;
  accentScore?: AdminNotesScore;
  hasScoreLTE?: number;
  minScore?: AdminNotesScore[];
}

export interface BadgeQueryParameters
  extends ScrubQueryParameters,
    AdminNotesScoreQueryParameters {
  selectionTeam?: boolean;
  beenOnMission?: boolean;
  exceptionalATeamer?: boolean;
  residentATeamer?: boolean;
  vettedATeamer?: boolean;
  vettingScheduled?: boolean;
  unvetted?: boolean;
  unqualified?: boolean;
  limitedAccess?: boolean;
  vettingInterviewDate?: boolean;
}

export interface RankingParams {
  scoreStrictnessFactor?: number;
  additionalSpecializationBoostingFactor?: number;
  cityBoostingFactor?: number;
  companyBoostingFactor?: number;
  countryBoostingFactor?: number;
  emailBoostingFactor?: number;
  exactManagementExperienceBoostingFactor?: number;
  hourlyRateDecayRateOutside?: number;
  hourlyRateDefaultBoostingFactorIfMissing?: number;
  hourlyRateMinBoostingFactor?: number;
  hourlyRateWithinRangeBoostingFactor?: number;
  hoursAvailableBoostingFactor?: number;
  immediateAvailabilityBoostingFactor?: number;
  industryExperienceBoostingFactor?: number;
  keywordBoostingFactor?: number;
  mainSpecializationBoostingFactor?: number;
  managementExperienceBoostingFactor?: number;
  midTermAvailabilityBoostingFactor?: number;
  midTermUpdateBoostingFactor?: number;
  preferredIndustriesBoostingFactor?: number;
  preferredSkillsBoostingFactor?: number;
  preferredTagsBoostingFactor?: number;
  profileCompletenessBoostingFactor?: number;
  recentUpdateBoostingFactor?: number;
  requiredIndustriesBoostingFactor?: number;
  requiredSkillsBoostingFactor?: number;
  requiredTagsBoostingFactor?: number;
  shortTermAvailabilityBoostingFactor?: number;
  zeroToOneBoostingFactor?: number;
}

export interface SkillTargeterCriteria extends BadgeQueryParameters {
  keyword?: string;
  useKeywordForSorting?: boolean;
  semanticSearchQuery?: string;
  semanticResults?: number;
  searchType?: 'semanticRaw' | 'hybrid';
  useTrustedTalentProfile?: boolean;
  specializations?: RoleCategoryId[];
  requireMainSpecialization?: boolean;
  requireAssignedSpecialization?: boolean;
  atLeastOneSkillShouldMatch?: boolean;
  excludeBuildersWithPerformanceIssue?: boolean;
  includeSuggestedSkills?: boolean;
  includeSuggestedSpecializations?: boolean;
  requireHasScheduledEndDate?: boolean;
  companies?: CompanyId[];
  requiredIndustries?: TalentIndustryId[];
  preferredIndustries?: TalentIndustryId[];
  industriesExperienceIndustries?: string[];
  industriesExperienceMinYears?: number[];
  requiredSkills?: TalentSkillId[];
  skillRequirements?: TalentSkillRating[];
  preferredSkills?: TalentSkillId[];
  useEstimatedAvailability?: boolean;
  weeklyHoursAvailable?: number;
  availabilityDate?: DateISOString;
  includeUnknownAvailability?: boolean;
  includeNotAvailable?: boolean;
  missionStatus?: BuilderMissionStatus;
  missionStatusAvailabilityDate?: DateISOString;
  requireHourlyRate?: boolean;
  hourlyRateMin?: number;
  hourlyRateMax?: number;
  countries?: string[];
  cities?: string[];
  requireCountry?: boolean;
  requireCity?: boolean;
  requireTimezone?: boolean;
  teammates?: UserId[];
  latestApplicationDate?: DateISOString;
  appliedSinceExclude?: boolean;
  includeApplicationSkills?: boolean;
  includeApplicationIndustries?: boolean;
  includePausedNotifications?: boolean;
  requireAllNotificationCategories?: boolean;
  requiredNotificationCategories?: string[];
  appliedRoles?: string[];
  excludeAppliedRoles?: boolean;
  isAdmin?: boolean;
  userStatus?: string[];
  notifiedForRoles?: string[];
  excludeNotifiedForRoles?: boolean;
  requireVerifiedSkills?: boolean;
  accountId?: AccountId;
  accountUserIds?: UserId[];
  page?: number;
  whFrom?: number;
  whTo?: number;
  whOl?: number;
  whTz?: string;
  customTags?: string[];
  atLeastOneCustomTagShouldMatch?: boolean;
  excludedCustomTags?: string[];
  excludeOnlyIfHasAllTags?: boolean;
  preferredCustomTags?: string[];
  minYearsExperience?: number;
  createdAtFrom?: DateISOString;
  createdAtTo?: DateISOString;
  scrubbedDateFrom?: DateISOString;
  scrubbedDateTo?: DateISOString;
  lastLoginAt?: DateISOString;
  includePlainReachoutEmailType?: boolean;
  includeRichReachoutEmailType?: boolean;
  includeStarredBuilders?: boolean;
  includeHiddenBuilders?: boolean;
  lastLoginAtExclude?: boolean;
  optedOutOfClientDiscovery?: boolean;
  requireCurrentlyInterviewing?: boolean;
  requireCurrentlyProposed?: boolean;
  profileCompleteness?: number;
  profileCompletenessVariant?: ProfileCompletenessVariant;
  declaredIndustryYearsOfExperience?: number;
  requireAnyIndustry?: boolean;
  roleApplicationStatus?: string[];
  excludeAppliedStatus?: boolean;
  excludeOnHoldApplicants?: boolean;
  // vetting data
  mgmtExp?: string;
  mgmtPreferred?: boolean;
  zeroToOne?: boolean;
  zeroToOnePreferred?: boolean;
  requireProposedSkills?: boolean;
  requireProposedSpecializations?: boolean;
  isHourlyRatePreferred?: boolean;
  minimumRequiredSkills?: number;
  excludeIds?: string[];
}

export interface TeammateSkillTargeterCriteria extends SkillTargeterCriteria {
  requiredRole: boolean;
}

export interface TargeterSearchData
  extends Pick<TargeterSearchObject, 'name' | 'searchKey'> {
  tabs: {
    label: string;
    url: string;
    selectedBuilders: { user: UserId; hourlyRate?: number }[];
  }[];
}

export interface TargeterSearchPreview
  extends Omit<TargeterSearchObject, 'tabs'> {
  tabs: { label: string; url: string; selectedBuilderCount: number }[];
}

export interface SuggestedTeamsRoleParameter {
  role: string;
  maxBuilders: number;
  makeCore: boolean;
}

export interface SuggestedTeamsCriteria {
  allowMultipleBuildersPerAudience: boolean;
  roleParameters: SuggestedTeamsRoleParameter[];
  showPartialTeams: boolean;
  requireTimeOverlapOnExperiences: boolean;
}

export interface SkillTargeterGetSuggestedTeamsRequestQuery
  extends QueryRequestPayload {
  jobId: string;
  limit?: number;
}

export interface RoleRateGuidance {
  min: number;
  max: number;
  metadata: {
    count: number;
    specializations: TalentSpecializationId[];
  };
}

export interface SpecializationsPayload {
  specializations: TalentSpecializationId[];
  countries?: string[];
  startDate?: string;
  endDate?: string;
  applicationRate?: boolean;
}

export interface GuidanceRequest extends SpecializationsPayload {
  minDecile?: number;
  maxDecile?: number;
  minRange?: number;
  maxRange?: number;
  minCount?: number;
}

export enum JobStatus {
  PENDING = 'Pending',
  IN_PROGRESS = 'InProgress',
  COMPLETED = 'Completed',
  FAILED = 'Failed',
  CANCELLED = 'Cancelled',
}

export interface ApplicantTeamResponse {
  jobStatus: JobStatus;
  newTeams: SuggestedTeam[];
  extensionTeams: SuggestedTeam[];
}

// exported functions

export default class TeamGraphEndpoint extends ServiceEndpoint {
  public createTargeterSearch(
    auth: ServiceAuth,
    data: TargeterSearchData,
  ): Promise<TargeterSearchObject> {
    return this.fetch(
      auth,
      `${BasePath}/targeter-search`,
      undefined,
      'post',
      data,
    );
  }

  public updateTargeterSearch(
    auth: ServiceAuth,
    tsid: TargeterSearchId,
    data: TargeterSearchData,
  ): Promise<TargeterSearchObject> {
    return this.fetch(
      auth,
      `${BasePath}/targeter-search/${tsid}`,
      undefined,
      'put',
      data,
    );
  }

  public deleteTargeterSearch(
    auth: ServiceAuth,
    tsid: TargeterSearchId,
  ): Promise<TargeterSearchObject> {
    return this.fetch(
      auth,
      `${BasePath}/targeter-search/${tsid}`,
      undefined,
      'delete',
    );
  }

  public getTargeterSearchById(
    auth: ServiceAuth,
    tsid: TargeterSearchId,
  ): Promise<TargeterSearchObject> {
    return this.fetch(auth, `${BasePath}/targeter-search/${tsid}`);
  }

  public getUserTargeterSearches(
    auth: ServiceAuth,
    next?: QueryNextToken,
    limit?: number,
  ): Promise<QueryResult<TargeterSearchPreview>> {
    return this.fetch(
      auth,
      `${BasePath}/targeter-search`,
      removeUndefinedValues({ next, limit }),
    );
  }

  public queryTargeterSearches(
    auth: ServiceAuth,
    query: string,
    next?: QueryNextToken,
    limit?: number,
  ): Promise<QueryResult<TargeterSearchPreview>> {
    return this.fetch(
      auth,
      `${BasePath}/targeter-search/query`,
      removeUndefinedValues({ key: query, next, limit }),
    );
  }

  public getRateGuidance(
    auth: ServiceAuth,
    data: GuidanceRequest,
  ): Promise<Record<TalentSpecializationId, RoleRateGuidance>> {
    return this.fetch(auth, `${BasePath}/rate-guidance`, null, 'post', data);
  }

  public getRoleStatistics(
    auth: ServiceAuth,
    data: SpecializationsPayload,
  ): Promise<
    Record<
      TalentSpecializationId,
      {
        averageRate?: number;
        maxRate?: number;
        medianRate?: number;
        minRate?: number;
        percentiles?: number[];
        specialization?: string;
        stdRate?: number;
        total?: number;
        averageRatePerCountry?: Record<string, number>;
      }
    >
  > {
    return this.fetch(auth, `${BasePath}/role-statistics`, null, 'post', data);
  }

  public getSuggestedTeams(
    auth: ServiceAuth,
    query: SkillTargeterGetSuggestedTeamsRequestQuery,
    next?: QueryNextToken,
    limit?: number,
  ): Promise<Required<SuggestedTeamsResultsWithNarrative>> {
    return this.fetch(
      auth,
      `${BasePath}/targeter/team-formations`,
      removeUndefinedValues({
        ...query,
        next,
        limit,
      }),
    );
  }

  public getExperienceGraphAugmentMission(
    auth: ServiceAuth,
    mid: MissionId,
    query: { minConfidence?: number },
  ): Promise<Required<ExperienceGraphAugmentMissionResponse>> {
    return this.fetch(
      auth,
      `${BasePath}/experience-graph/augment-mission/${mid}`,
      removeUndefinedValues({
        ...query,
      }),
    );
  }

  public getExperienceGraphAugmentBuilder(
    auth: ServiceAuth,
    uid: UserId,
    query: { minConfidence?: number },
  ): Promise<Required<ExperienceGraphAugmentBuilderResponse>> {
    return this.fetch(
      auth,
      `${BasePath}/experience-graph/augment-builder/${uid}`,
      removeUndefinedValues({
        ...query,
      }),
    );
  }

  public createTargeterSearchDownload(
    auth: ServiceAuth,
    searchTabs: Record<string, unknown & { selectedBuilders: UserId[] }>,
  ): Promise<{ jobId: string }> {
    return this.fetch(
      auth,
      `${BasePath}/targeter-search/download`,
      null,
      'post',
      searchTabs,
    );
  }

  public getTargeterSearchDownloadUrl(
    auth: ServiceAuth,
    tsid: TargeterSearchId,
  ): Promise<{ progress: number; fileUrl?: string; error?: string }> {
    return this.fetch(auth, `${BasePath}/targeter-search/download/${tsid}`);
  }

  public getEnrichedTeamNarratives(
    auth: ServiceAuth,
    query: Partial<GetEnrichedTeamNarrativesQueryOptions>,
  ): Promise<EnrichedTeamNarrativesResponse> {
    return this.fetch(
      auth,
      `${BasePath}/narratives/enriched-team-narratives`,
      removeUndefinedValues({
        ...query,
      }),
    );
  }

  public getEnrichedTeamNarrativesForExport(
    auth: ServiceAuth,
    query: Partial<GetEnrichedTeamNarrativesQueryOptions>,
  ): Promise<EnrichedTeamNarrativesForExportResponse> {
    return this.fetch(
      auth,
      `${BasePath}/narratives/enriched-team-narratives-for-export`,
      removeUndefinedValues({
        ...query,
      }),
    );
  }

  public getApplicantReviewTeams(
    auth: ServiceAuth,
    mid: MissionId,
  ): Promise<ApplicantTeamResponse> {
    return this.fetch(auth, `${BasePath}/applicant-review-teams/${mid}`);
  }
}
