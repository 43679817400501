import { ProfileEnhancementSuggestions } from '@a_team/models/dist/ProfileEnhancements';
import {
  BorderColors,
  Colors,
  Icon,
  IconType,
  TextColors,
} from '@ateams/components';
import { Button } from '@a_team/ui-components';
import TextAreaInput from '@src/components/Inputs/TextAreaInput';
import {
  useApplyProfileSuggestion,
  useRejectProfileSuggestion,
  useGetProfileSuggestions,
  useGenerateBioSuggestionOnDemand,
} from '@src/rq/profileSuggestions';
import cx from 'classnames';
import React, {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createUseStyles } from 'react-jss';
import Section from '../partials/Section';
import CommonStyles from '../partials/styles';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import {
  BuilderSuggestionType,
  SuggestionActionType,
} from '@a_team/models/dist/ProfileEnhancements';
import { useIsMutating } from '@tanstack/react-query';
import { profileSuggestions } from '@src/rq/keys/profileSuggestions';
import ConfirmModalV2 from '@src/components/Modal/ConfirmModalV2';
import NavigationPrompt, {
  RenderCustomConfirmModal,
} from '@src/components/NavigationPrompt';
import { useHistory } from 'react-router-dom';
import Assistant from './Assistant';

interface Props {
  userId: string;
  text?: string;
  onChange(text: string): void;
  disabled?: boolean;
  readonly?: boolean;
  error?: boolean;
  textClassName?: string;
  description?: string;
  hideIcon?: boolean;
  type?: 'default' | 'large';
  withGenerateAboutMeSuggestionOnDemand?: boolean;
}

const useStyles = createUseStyles({
  suggestedBioWrapper: {
    marginTop: 8,
    padding: 16,
    border: '1px solid #DADADC',
    borderRadius: '8px',
  },
  suggestionLabel: {
    marginBottom: 16,
    color: Colors.secondaryDark,
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  suggestedBioTextWrapper: {
    marginBottom: 16,
    fontStyle: 'italic',
    lineHeight: 1.5,
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 8,
  },
  saveBioButton: {
    padding: '7px 16px',
    width: 'auto',
    borderRadius: 4,
  },
  cancelBioButton: {
    padding: '7px 16px',
    width: 'auto',
    borderRadius: 4,
    color: TextColors.regular,
    background: Colors.backgroundDark,
  },
  loadingSuggestion: {
    display: 'flex',
    maxWidth: 1360,
    border: `1px solid ${BorderColors.lighter}`,
    padding: 16,
    gap: 16,
    borderRadius: 8,
    color: Colors.secondaryDark,
    marginTop: 16,
  },
  suggestedBioTextAreaWrapper: {
    padding: 16,
  },
  textAreaWrapper: {
    padding: 16,
    border: `1px solid ${BorderColors.lighter}`,
    borderRadius: 8,
  },
  textArea: {
    border: 0,
  },
});

export const AboutMeV2 = React.memo((props: Props): ReactElement | null => {
  const {
    disabled,
    text,
    textClassName,
    onChange,
    readonly,
    error,
    description,
    hideIcon,
    type,
    withGenerateAboutMeSuggestionOnDemand,
  } = props;
  const [bio, setBio] = useState(text);
  const commonStyles = CommonStyles();
  const [suggestedBio, setSuggestedBio] = useState<string | undefined>(
    undefined,
  );
  const [isReviewSuggestionMode, setIsReviewSuggestionMode] = useState(false);
  const styles = useStyles({ isReviewSuggestionMode });
  const analytics = useAnalytics();
  const mutationCount = useIsMutating({
    mutationKey: profileSuggestions.enhanceProfileByUserId(props.userId),
  });
  const history = useHistory();
  const isEnhancingProfile = useMemo(() => mutationCount > 0, [mutationCount]);

  useEffect(() => setBio(text), [text]);

  const handleSuggestedBioTextareaChange = (
    e: ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setSuggestedBio(e.target.value);
  };

  const { data: bioSuggestion } = useGetProfileSuggestions({
    onSuccess: (data: ProfileEnhancementSuggestions) => {
      setSuggestedBio(data?.bio?.bio);
    },
  });

  const { mutateAsync: applySuggestion, isLoading: isApplyingSuggestion } =
    useApplyProfileSuggestion({ shouldRefreshProfile: readonly });
  const { mutateAsync: rejectSuggestion, isLoading: isRejectingSuggestion } =
    useRejectProfileSuggestion();

  const handleApplySuggestedBio = async () => {
    if (!bioSuggestion?.bio?.sid || !suggestedBio) {
      return;
    }
    setIsReviewSuggestionMode(false);
    setBio(suggestedBio);

    await applySuggestion({
      sid: bioSuggestion.bio.sid,
      editedSuggestion: {
        bio: suggestedBio,
      },
    });

    analytics.trackProfileSuggestionInteraction(
      props.userId,
      bioSuggestion.bio.sid,
      SuggestionActionType.ACCEPT,
      BuilderSuggestionType.BIO,
      [suggestedBio],
    );
  };

  const handleRejectSuggestedBio = async () => {
    if (!bioSuggestion?.bio?.sid) {
      return;
    }

    await rejectSuggestion(bioSuggestion.bio.sid);
    analytics.trackProfileSuggestionInteraction(
      props.userId,
      bioSuggestion.bio.sid,
      SuggestionActionType.REJECT,
      BuilderSuggestionType.BIO,
    );
  };

  const tooltipText = useMemo(() => {
    if (readonly) {
      return;
    }
    let tooltip = 'Tell us a little about yourself';
    error && (tooltip = 'Please enter a short description');
    return tooltip;
  }, [error, text, readonly]);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const updatedBio = e.target.value.trimStart();
    onChange(updatedBio);
    setBio(updatedBio);
  };

  const renderSuggestionContent = () => {
    const chunks = suggestedBio?.split('\n') ?? [];

    return (
      <>
        {chunks.map((bioChunk, idx) => (
          <React.Fragment key={idx}>
            {idx === 0 && '"'}
            {bioChunk}
            {idx < chunks.length - 1 && <br />}
            {idx === chunks.length - 1 && '"'}
          </React.Fragment>
        ))}
      </>
    );
  };

  const renderNavigationPromptCustomModal: RenderCustomConfirmModal =
    useCallback(
      (open, closeModal, handleConfirmNavigation) => {
        const onSaveModal = async () => {
          await handleApplySuggestedBio();
          handleConfirmNavigation();
        };

        const onDiscard = () => {
          closeModal();
          handleConfirmNavigation();
        };

        return (
          <ConfirmModalV2
            title="Do you want to save the changes?"
            description="If you exit, your changes on the 'About me' section will be lost."
            open={open}
            onClose={closeModal}
            onCancel={onDiscard}
            onCancelText="Discard"
            onConfirm={onSaveModal}
            onConfirmText="Save"
            onConfirmColor="secondaryDark"
          />
        );
      },
      [handleApplySuggestedBio],
    );

  const {
    mutate: generateBioSuggestionOnDemand,
    isLoading: isLoadingBioSuggestionOnDemand,
  } = useGenerateBioSuggestionOnDemand({
    onSuccess: (data: string) => {
      setSuggestedBio(data);
    },
  });

  const handleGeneration = () => {
    generateBioSuggestionOnDemand();
  };

  const handleInsertSuggestion = (bio: string) => {
    onChange(bio);
    setSuggestedBio('');
    handleApplySuggestedBio();
  };

  const handleDiscardSuggestion = (bio: string) => {
    setSuggestedBio('');
    handleRejectSuggestedBio();
  };

  return (
    <Section
      iconType={hideIcon ? undefined : IconType.PaperAndPencil}
      title="About me"
      type={type}
      description={
        readonly
          ? null
          : description || `What you write here will be shared with companies.`
      }
      tooltipText={tooltipText}
      readonly={readonly}
      error={error}
      dataTestingId={'profile-about-you-text'}
    >
      {readonly ? (
        <>
          <div>
            <div
              className={cx(commonStyles.readingOnlyText, textClassName)}
              data-testing-id={'profile-about-you-text'}
            >
              {bio}
            </div>

            {isEnhancingProfile && (
              <div className={styles.loadingSuggestion}>
                <Icon type={IconType.CircleLoadingViolet} rotating />
                Loading suggested bio
              </div>
            )}
            {suggestedBio !== undefined && (
              <div className={styles.suggestedBioWrapper}>
                <div className={styles.suggestionLabel}>
                  <Icon size="exact" type={IconType.SuggestedTeams} />
                  {isReviewSuggestionMode
                    ? 'Editing suggested'
                    : 'Suggested'}{' '}
                  bio
                </div>
                {isReviewSuggestionMode ? (
                  <TextAreaInput
                    value={suggestedBio}
                    disabled={disabled}
                    onChange={handleSuggestedBioTextareaChange}
                    placeholder="Tell companies about yourself."
                    error={error}
                    data-testing-id={'profile-about-you-text-input'}
                    maxLength={1000}
                    minHeight={50}
                    wrapperClassname={styles.suggestedBioTextAreaWrapper}
                    className={textClassName}
                    autoFocus
                    autoResize
                    showCharsLeft
                  />
                ) : (
                  <div className={styles.suggestedBioTextWrapper}>
                    {renderSuggestionContent()}
                  </div>
                )}
                <div className={styles.buttonsWrapper}>
                  {isReviewSuggestionMode ? (
                    <>
                      <Button
                        className={styles.saveBioButton}
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsReviewSuggestionMode(true);
                          handleApplySuggestedBio();
                        }}
                        size="sm"
                        color="secondaryDark"
                        loading={
                          !suggestedBio ||
                          isApplyingSuggestion ||
                          isRejectingSuggestion
                        }
                      >
                        Save bio
                      </Button>
                      <Button
                        className={styles.cancelBioButton}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSuggestedBio(bioSuggestion?.bio?.bio);
                          setIsReviewSuggestionMode(false);
                        }}
                        size="sm"
                        variant="secondary"
                        disabled={isApplyingSuggestion || isRejectingSuggestion}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        className={styles.saveBioButton}
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsReviewSuggestionMode(true);
                        }}
                        size="sm"
                        color="secondaryDark"
                      >
                        Review & approve
                      </Button>
                      <Button
                        className={styles.cancelBioButton}
                        variant="secondary"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRejectSuggestedBio();
                        }}
                        size="sm"
                      >
                        Discard
                      </Button>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          <NavigationPrompt
            navigate={(path) => history.push(path)}
            shouldBlockNavigation={() =>
              isReviewSuggestionMode && suggestedBio !== bioSuggestion?.bio?.bio
            }
            renderCustomConfirmModal={renderNavigationPromptCustomModal}
          />
        </>
      ) : (
        <>
          <TextAreaInput
            value={bio}
            disabled={disabled}
            onChange={handleChange}
            placeholder="Tell companies about yourself."
            error={error}
            data-testing-id={'profile-about-you-text-input'}
            maxLength={1000}
            wrapperClassname={styles.textAreaWrapper}
            className={cx(styles.textArea, textClassName)}
            autoFocus
            autoResize
            showCharsLeft
          />

          {withGenerateAboutMeSuggestionOnDemand && !disabled && (
            <Assistant
              isLoading={isLoadingBioSuggestionOnDemand}
              suggestion={suggestedBio}
              onGenerateSuggestion={handleGeneration}
              onInsertSuggestion={handleInsertSuggestion}
              onDiscardSuggestion={handleDiscardSuggestion}
            />
          )}
        </>
      )}
    </Section>
  );
});
