import { Breakpoints, TextColors } from '@ateams/components';
import UserAvatar from '@src/components/UserAvatar';
import { CDN_BASE_URL } from '@src/config';
import AuthStore from '@src/stores/Auth';
import Profile from '@src/stores/Profile/Profile';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { createUseStyles } from 'react-jss';
import ProfileStep from './ProfileStep';
import ProgressBar from './ProgressBar';
import Star from './Star';
import {
  checkProfileCompletion,
  getProfileCompletionContent,
  getProfileSteps,
} from './utils';
import { useGetProfilePictureAnalysis } from '@src/rq/profile';
import { useHistory } from 'react-router-dom';
import { SetupLocation } from '@src/locations';
import { OnboardingStage } from '@a_team/models/dist/UserObject';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import useLoadingState from '@src/hooks/useLoadingState';
import { useGetBuilderTypeAndMissionData } from '@src/rq/missions';

interface ProfileCompletenessProps {
  className?: string;
  profile: Profile;
  auth: AuthStore;
  hasCalendarSetup: boolean;
}

export const useStyles = createUseStyles({
  container: {
    background:
      'linear-gradient(white, white) padding-box, linear-gradient(to right, #EAD4FF, #FFE9F8) border-box',
    borderRadius: 16,
    border: '5px solid transparent',
    padding: 12,
    marginBottom: 64,
  },
  allDoneContainer: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    flexDirection: 'column',
  },
  avatarContainer: {
    position: 'relative',
    height: 72,
    width: 72,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  star1: {
    position: 'absolute',
    bottom: 62,
    left: 50,
  },
  star2: {
    position: 'absolute',
    top: 12,
    left: 61,
  },
  star3: {
    position: 'absolute',
    top: 60,
    left: 0,
  },
  discordLogo: {
    height: 25,
    width: 25,
    position: 'absolute',
    top: 42,
    left: 45,
    zIndex: 1,
  },
  lyftLogo: {
    width: 21,
    height: 21,
    position: 'absolute',
    right: 47,
    bottom: 47,
  },
  title: {
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 500,
    marginBottom: 8,
    lineHeight: '24px',
  },
  desc: {
    fontSize: 15,
    color: TextColors.regularLight,
  },
  groupItemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  groupItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  progressBarContainer: {
    margin: '24px 0',
  },
  line: {
    backgroundColor: '#DADADC',
    height: 1,
    width: 20,
    display: 'inline-block',
  },
  link: {
    color: '#62646A',
    textDecoration: 'underline',
  },
  linkBtn: {
    textAlign: 'left',
    padding: 0,
    background: 'none',
    border: 'none',
    color: '#62646A',
    cursor: 'pointer',
    fontSize: 15,
    fontWeight: 500,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  submitLink: {
    color: '#6D00D7',
    fontWeight: 500,
    marginRight: 16,
    // hover underline
    border: 'none',
    background: 'none',
    padding: 0,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  submitLinkDisabled: {
    opacity: 0.5,
    '&:hover': {
      textDecoration: 'none',
      cursor: 'default',
    },
  },
  jobItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  dismissBtn: {
    marginTop: 16,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      padding: 24,
      border: '8px solid transparent',
    },
    allDoneContainer: {
      flexDirection: 'row',
      gap: '24px',
    },
  },
});

const ProfileCompleteness = ({
  className,
  profile,
  auth,
  hasCalendarSetup,
}: ProfileCompletenessProps) => {
  const styles = useStyles();
  const history = useHistory();
  const analytics = useAnalytics();
  const [loading, setLoading] = useLoadingState();

  const { data: builderTypeAndMissionData } = useGetBuilderTypeAndMissionData();

  const isSourcedBuilder = builderTypeAndMissionData?.builderType === 'sourced';

  const {
    data: profilePictureAnalysisData,
    isLoading: isProfilePictureAnalysisLoading,
  } = useGetProfilePictureAnalysis(profile.data.profilePictureURL || '', true);

  const saveProfileChanges = async (callback: () => void) => {
    const cta = profile?.cta;
    cta &&
      setLoading(
        cta?.callback().then(() => callback()),
        cta.confirmation || null,
      );
  };

  const handleConnectCalendar = async () => {
    await saveProfileChanges(() => {
      history.push('/settings?menu=interviews');
    });
  };

  const profileSteps = getProfileSteps(
    profile,
    auth,
    hasCalendarSetup,
    !!profilePictureAnalysisData?.issues.length,
    handleConnectCalendar,
    !!loading,
  );

  const { totalCompleted, allCompleted, totalSteps } =
    checkProfileCompletion(profileSteps);

  React.useEffect(() => {
    if (
      auth.user?.profileCompleteness?.dismissed &&
      allCompleted &&
      auth.uid &&
      !auth.onboardingCompleted
    ) {
      analytics.trackProfileCompleted(auth.uid);
    }
  }, [
    allCompleted,
    auth.uid,
    auth.user?.profileCompleteness?.dismissed,
    totalSteps,
    analytics,
    auth.onboardingCompleted,
  ]);

  if (
    isProfilePictureAnalysisLoading ||
    (auth.user?.profileCompleteness?.dismissed && allCompleted)
  ) {
    return null;
  }

  const handleDismiss = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    auth.handleProfileCompleteness(auth, {
      ...(auth?.user?.profileCompleteness ?? {}),
      dismissed: true,
    });
  };

  const handleSubmitProfile = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();

    await saveProfileChanges(() => {
      history.push(SetupLocation);
    });
  };

  const reviewRequested =
    auth.onboardingStage === OnboardingStage.ReviewRequested ||
    auth.onboardingStage === OnboardingStage.SecondReviewRequested;

  // If the profile is pending of review then we don't need to show any of the messages below
  if (reviewRequested) {
    return null;
  }

  const getCompletedTitle = () => {
    if (isSourcedBuilder) {
      const content = getProfileCompletionContent(auth.onboardingStage);
      if (content) {
        return content.title;
      }
    }

    if (!auth.onboardingCompleted) {
      return 'Nice work! Next up, submit your profile for review.';
    }
    if (auth.fullAccess) {
      return 'Great work! You’ve maximized your company visibility.';
    }
    return 'Nice work! Your profile is now optimized for mission selection.';
  };

  const getCompletedDescription = () => {
    if (isSourcedBuilder) {
      const content = getProfileCompletionContent(auth.onboardingStage);
      if (content) {
        return content.desc;
      }
    }

    if (!auth.onboardingCompleted) {
      return (
        <>
          After submitting, our team will hand review your profile. This process
          usually takes 1-2 weeks.
        </>
      );
    }

    if (auth.fullAccess) {
      return (
        <>
          You’re now eligible to be discovered and starred by companies — learn
          more{' '}
          <a
            className={styles.link}
            href="https://guide.a.team/account/stars"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </>
      );
    }

    return 'Highlighting your skills and experience helps you to stand out on mission applications and company proposals.';
  };

  const getSourcedBuilderCTA = () => {
    switch (auth.onboardingStage) {
      case OnboardingStage.InterviewScheduled:
        return 'View status';
      case OnboardingStage.PendingInterview:
        return 'Schedule call';
      case OnboardingStage.Completed:
        return 'Learn more';
      default:
        return '';
    }
  };

  const sourcedBuilderCTA = getSourcedBuilderCTA();
  const isSourcedBuilderAndHasCTA = isSourcedBuilder && sourcedBuilderCTA;

  if (allCompleted) {
    return (
      <div className={cx(styles.container, className, styles.allDoneContainer)}>
        <div className={styles.avatarContainer}>
          <img
            className={styles.discordLogo}
            src={CDN_BASE_URL + '/website/discord-logo.png'}
            alt="Discord logo"
          />
          <img
            className={styles.lyftLogo}
            src={CDN_BASE_URL + '/website/lyft-logo.png'}
            alt="Lyft logo"
          />
          <Star
            className={cx(styles.star, styles.star1)}
            size={{ width: '8px', height: '8px' }}
          />
          <Star
            className={cx(styles.star, styles.star2)}
            size={{ width: '6px', height: '6px' }}
          />
          <Star
            className={cx(styles.star, styles.star3)}
            size={{ width: '6px', height: '6px' }}
          />
          <UserAvatar
            shouldResizeUploadCareImages={false}
            src={auth.user?.profilePictureURL}
            size={48}
          />
        </div>
        <div>
          <div className={styles.title}>{getCompletedTitle()}</div>
          <div className={styles.desc}>{getCompletedDescription()}</div>
          {(!auth.onboardingCompleted || isSourcedBuilderAndHasCTA) && (
            <button
              onClick={handleSubmitProfile}
              className={cx(
                styles.submitLink,
                loading && styles.submitLinkDisabled,
              )}
              disabled={!!loading}
            >
              {loading
                ? 'Loading...'
                : isSourcedBuilderAndHasCTA
                ? sourcedBuilderCTA
                : 'Submit profile'}
            </button>
          )}

          <button
            className={cx(styles.linkBtn, styles.dismissBtn)}
            onClick={handleDismiss}
          >
            Dismiss
          </button>
        </div>
      </div>
    );
  }

  const getUncompletedTitle = () => {
    if (isSourcedBuilder) {
      const content = getProfileCompletionContent(auth.onboardingStage);
      if (content) {
        return content.title;
      }
    }

    if (!auth.onboardingCompleted) {
      return 'Complete your profile to take the first step in unlocking full A.Team access';
    }
    if (auth.fullAccess) {
      return 'Complete your profile to get the most out of A.Team and get discovered';
    }
    return 'Complete your profile to get the most out of A.Team';
  };

  const getUncompletedDescription = () => {
    if (isSourcedBuilder) {
      const content = getProfileCompletionContent(auth.onboardingStage);
      if (content) {
        return content.desc;
      }
    }

    if (!auth.onboardingCompleted) {
      return 'Once you’ve added the items below, submit your profile for review on the setup page.';
    }
    return 'Over 80% of builders that are selected for missions include these details in their profile.';
  };

  return (
    <div className={cx(styles.container, className)}>
      <div>
        <div className={styles.title}>{getUncompletedTitle()}</div>
        <div className={styles.desc}>{getUncompletedDescription()}</div>
        <div className={styles.progressBarContainer}>
          <ProgressBar totalSteps={totalSteps} currentStep={totalCompleted} />
        </div>
        <div className={styles.groupItemsContainer}>
          {profileSteps.map((step) => (
            <ProfileStep key={step.id} step={step} auth={auth} /> // Smaller components for each step
          ))}
        </div>
      </div>
    </div>
  );
};

export default observer(ProfileCompleteness);
