import React, {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  MissionControlBase,
  MissionControlAllMissionsLocation,
  MissionControlAppliedLocation,
  MissionControlNotInterestedLocation,
  MissionControlRecommendedLocation,
  MissionControlCompletedLocation,
} from '@src/locations';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import { Colors, Icon, IconType } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';
import MainLayout from '@src/layouts/Main';
import SectionHeading from '@src/components/SectionHeading';
import TabButtonsMissionControl from '@src/components/TabButtonsMissionControl';
import { Redirect, Route, Switch } from 'react-router-dom';
import RecommendedMissions from './RecommendedMissions';
import AllMissions from './AllMissions';
import AppliedMissions from './AppliedMissions';
import NotInterestedMissions from './NotInterestedMissions';
import LoadingIndicator from '@src/components/LoadingIndicator';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import useDebounceState from '@src/hooks/useDebounceState';
import CompletedMissions from './CompletedMissions';
import { loadMissionControl } from '@src/url-loaders/loadMissionControl';
import DisplayNotificationBannerFromLocationState from './Notification';

// Styles
const useStyles = createUseStyles({
  content: {
    padding: '1em',
    background: Colors.backgroundWhite,
    minHeight: '80vh',
  },
  header: {
    padding: '38px 1em 0',
  },
  subtitle: {
    lineHeight: '24px',
    marginTop: '-0.5em',
  },
  searchContainer: {
    display: 'flex',
    position: 'relative',
    padding: '0 1em',
  },
  searchInput: {
    '& > div': {
      borderRadius: '4px',
      padding: '0.5em 1em',
      minHeight: '44px',
    },

    '& input': {
      padding: 0,
    },
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    searchContainer: {
      padding: '0 36px',
    },
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    content: {
      padding: '24px 36px',
    },
    header: {
      padding: '40px 36px 0',
    },
    subtitle: {
      marginTop: '1em',
    },
    searchContainer: {
      alignSelf: 'flex-start',
      margin: '1em 0',
    },
    searchInput: {
      width: '500px',
    },
  },
});

export const missionControlLoader = loadMissionControl;

const MissionControlView = (): ReactElement => {
  const styles = useStyles();
  const { auth, missionControl, users } = useStores();
  const { profile } = users;
  const { hasQueryFilter, setQueryFilter, missionsAmount } = missionControl;

  const [searchQuery, setSearchQuery, searchQueryLoading] = useDebounceState(
    '',
    (query) => {
      setQueryFilter(query.trim() || null);
    },
  );

  const handleQueryChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  }, []);

  const tabValues = useMemo(() => {
    return [
      ...(!auth.withLimitedAccess ||
      (auth.withLimitedAccess && !auth.limitedAccess)
        ? [
            {
              label: `Recommended Missions`,
              value: MissionControlRecommendedLocation,
              amount: missionsAmount.recommended,
              total: hasQueryFilter
                ? missionControl.missionsAmount.allRecommended
                : undefined,
              disabled:
                auth.withOnboardingV2 && auth.onboardingCompleted === false,
            },
          ]
        : []),
      {
        label: `All Missions`,
        value: MissionControlAllMissionsLocation,
        amount: missionsAmount.filteredMissions,
        total:
          hasQueryFilter || missionControl.filtersOn
            ? missionsAmount.allMissions
            : undefined,
        skipTotal: auth.withLimitedAccess && auth.limitedAccess,
      },
      {
        label: `Applied Missions`,
        value: MissionControlAppliedLocation,
        amount: missionsAmount.applied,
        total:
          hasQueryFilter || missionControl.selectedLifecycleStage
            ? missionsAmount.allApplied
            : undefined,
      },
      {
        label: `Completed Missions`,
        value: MissionControlCompletedLocation,
        amount: missionsAmount.completed,
        total: hasQueryFilter ? missionsAmount.allCompleted : undefined,
        disabled: auth.withOnboardingV2 && auth.onboardingCompleted === false,
      },
      {
        label: `Not Interested`,
        value: MissionControlNotInterestedLocation,
        amount: missionControl.missionsAmount.notInterested,
        total: hasQueryFilter
          ? missionControl.missionsAmount.allNotInterested
          : undefined,
        iconType: 'EyeClosed',
      },
    ];
  }, [
    missionControl.filtersOn,
    hasQueryFilter,
    missionControl.missionsAmount.recommended,
    missionControl.missionsAmount.allRecommended,
    missionControl.missionsAmount.filteredMissions,
    missionControl.missionsAmount.allMissions,
    missionControl.missionsAmount.applied,
    missionControl.missionsAmount.allApplied,
    missionControl.missionsAmount.notInterested,
    missionControl.missionsAmount.allNotInterested,
    missionControl.missionsAmount.completed,
    auth.limitedAccess,
    auth.withLimitedAccess,
  ]);

  useEffect(() => {
    setSearchQuery(missionControl.queryFilter || '', true);
  }, [missionControl.queryFilter]);

  useEffect(() => {
    if (profile?.profileUpdatedAt && !missionControl.loading) {
      profile?.setProfileUpdatedAt(null);
      missionControl.reloadMissionControl(profile?.data);
    }
    if (missionControl.loading) {
      profile?.setProfileUpdatedAt(null);
    }
  }, [profile?.profileUpdatedAt, missionControl.loading]);

  const getInitialRedirect = () => {
    const isAdmin = auth.currentUser?.isAdmin;
    const hasFullAccess = !auth.withLimitedAccess || !auth.limitedAccess;

    if (!isAdmin && hasFullAccess) {
      const redirectLocation =
        auth.withOnboardingV2 && !auth.onboardingCompleted
          ? MissionControlAllMissionsLocation
          : MissionControlRecommendedLocation;
      return <Redirect to={redirectLocation} />;
    }

    return <Redirect to={MissionControlAllMissionsLocation} />;
  };

  const shouldShowSearchInput = useCallback(() => {
    // Don't show if withOnboardingV2 is undefined (still loading)
    if (auth.withOnboardingV2 === undefined) return false;

    // Show if withOnboardingV2 is false
    if (auth.withOnboardingV2 === false) return true;

    // If withOnboardingV2 is true, show only if onboardingCompleted is true
    return auth.onboardingCompleted === true;
  }, [auth.withOnboardingV2, auth.onboardingCompleted]);

  return (
    <MainLayout
      title="Mission Control"
      style={{
        padding: 0,
        background: Colors.backgroundWhite,
        minHeight: '100%',
      }}
    >
      <div className={styles.header}>
        <SectionHeading isFirst>Mission Control</SectionHeading>
        {auth.withLimitedAccess !== undefined ? (
          auth.limitedAccess && auth.withLimitedAccess ? (
            <p>
              You have access to select missions that align closely with your
              expertise. To streamline the process of connecting you
              <br />
              to high-quality missions that are the best fit for your skillset,
              you will receive invitations to apply via email.
            </p>
          ) : (
            <p className={styles.subtitle}>
              Here you can discover and request to join teams working on
              meaningful missions with high-quality companies.
              <br />
              We recommend you apply to several at once, to maximize your odds
              and speed of placement.
            </p>
          )
        ) : null}
      </div>

      {shouldShowSearchInput() && (
        <div className={styles.searchContainer}>
          <OutlinedInput
            margin="none"
            precursor={<Icon type={IconType.SearchGrey} />}
            placeholder="Find Mission..."
            className={styles.searchInput}
            value={searchQuery}
            onChange={handleQueryChange}
            loading={searchQueryLoading}
            disabled={missionControl.loading}
          />
        </div>
      )}
      <TabButtonsMissionControl sticky tabValues={tabValues} />
      <div className={styles.content}>
        <Switch>
          <Route exact path={MissionControlBase}>
            {getInitialRedirect()}
          </Route>
          <Route path={MissionControlRecommendedLocation}>
            <RecommendedMissions />
          </Route>
          <Route path={MissionControlAllMissionsLocation}>
            <AllMissions />
          </Route>
          <Route path={MissionControlAppliedLocation}>
            <AppliedMissions />
          </Route>
          <Route path={MissionControlNotInterestedLocation}>
            <NotInterestedMissions />
          </Route>
          <Route path={MissionControlCompletedLocation}>
            <CompletedMissions />
          </Route>
        </Switch>
      </div>
      {/* This is to comply with the logic of LoadingIndicator: */}
      {missionControl.loading && <LoadingIndicator loading />}
      {missionControl.message && (
        <LoadingIndicator loading={missionControl.message} />
      )}
      {!missionControl.loading && missionControl.error && (
        <LoadingIndicator
          loading={{
            name: 'Error loading missions',
            message: missionControl.message,
          }}
        />
      )}
      <DisplayNotificationBannerFromLocationState />
    </MainLayout>
  );
};

export default observer(MissionControlView);
