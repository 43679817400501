import React, { ReactElement } from 'react';
import { Checkbox, Select, ToggleSwitch } from '@ateams/components';
import { RoleFilter } from '@src/components/TeamGraphBuilderQuery/index';

export const APPLICATION_STATUSES = [
  { value: 'notReviewed', label: 'Not reviewed' },
  { value: 'exclusive', label: 'Exclusive' },
  { value: 'unavailable', label: 'Unavailable' },
  { value: 'notSelected', label: 'Not selected' },
  { value: 'opportunityToUpdate', label: 'Opportunity to update' },
  { value: 'waitlisted', label: 'Waitlisted' },
  { value: 'proposed', label: 'Proposed' },
  { value: 'interviewing', label: 'Interviewing' },
].map((status) => ({
  label: status.label,
  value: status.value,
}));

interface Props {
  setFilterValue: (filter: Partial<RoleFilter>) => void;
  roleApplicationStatus?: string[];
  excludeAppliedStatus?: boolean;
  excludeOnHoldApplicants?: boolean;
}

const ApplicationStatusSelect = (props: Props): ReactElement => {
  const {
    roleApplicationStatus,
    setFilterValue,
    excludeAppliedStatus,
    excludeOnHoldApplicants,
  } = props;
  const value = roleApplicationStatus
    ? roleApplicationStatus.map((status) => ({
        label: status,
        value: status,
      }))
    : null;

  return (
    <>
      <Checkbox
        margin={'none'}
        onChange={(e) => {
          setFilterValue({
            excludeOnHoldApplicants: e.target.checked ? true : undefined,
          });
        }}
        checked={excludeOnHoldApplicants !== undefined}
        label="Exclude on hold applicants"
      />
      <Select
        isMulti
        hideTags
        showItemCount
        isSearchable
        itemCountPrefix="Application statuses"
        placeholder=""
        value={value}
        onChange={(selectedOptions) =>
          setFilterValue({
            roleApplicationStatus: selectedOptions.map(({ value }) => value),
          })
        }
        options={APPLICATION_STATUSES}
      />
      <ToggleSwitch
        label={excludeAppliedStatus ? 'Excluded' : 'Included'}
        onChange={() =>
          setFilterValue({
            excludeAppliedStatus: !excludeAppliedStatus,
          })
        }
        checked={!excludeAppliedStatus}
        size="small"
        justify="right"
        checkedBackgroundColor="secondary"
      />
    </>
  );
};
export default ApplicationStatusSelect;
