import {
  API_SERVICE_BASE_URL,
  TEAM_ENGINE_SEARCH_SERVICE_BASE_URL,
  DATA_SCIENCE_API_SERVICE_BASE_URL,
  USER_NOTIFICATION_SERVICE_BASE_URL,
} from '@src/config';
import { setApiBasePath } from '@ateams/api/dist/config';
import {
  Configuration,
  PreferencesApi,
  MissionApi,
  NotificationLogApi,
  EmailFromTargeterApi,
  EmailTemplatesApi,
  ReplacementVariablesApi,
  ReachoutAutomationApi,
} from '@a_team/user-notification-service-js-sdk';
import { createClient } from '@a_team/team-engine-search-service-sdk-js';
import { OpenAPI } from '@a_team/data-science-api-client';

const userNotificationsServiceConfig = new Configuration({
  basePath: USER_NOTIFICATION_SERVICE_BASE_URL,
});

setApiBasePath(API_SERVICE_BASE_URL);
OpenAPI.BASE = DATA_SCIENCE_API_SERVICE_BASE_URL;

export const preferencesApi = new PreferencesApi(
  userNotificationsServiceConfig,
);
export const missionNotificationApi = new MissionApi(
  userNotificationsServiceConfig,
);
export const notificationLogApi = new NotificationLogApi(
  userNotificationsServiceConfig,
);
export const emailFromTargeterApi = new EmailFromTargeterApi(
  userNotificationsServiceConfig,
);
export const emailTemplatesApi = new EmailTemplatesApi(
  userNotificationsServiceConfig,
);
export const reachoutAutomationApi = new ReachoutAutomationApi(
  userNotificationsServiceConfig,
);
export const replacementVariablesApi = new ReplacementVariablesApi(
  userNotificationsServiceConfig,
);

export const teamEngineSearchServiceApi = createClient({
  url: TEAM_ENGINE_SEARCH_SERVICE_BASE_URL,
});
// todo check if we can inject jwt token automatically
teamEngineSearchServiceApi?.fetcherInstance?.interceptors.request.use(
  (config) => {
    if (!config.headers || !config.headers['content-type']) {
      config.headers = {
        ...config.headers,
        'content-type': 'application/json',
      };
    }
    return config;
  },
);

export * from '@ateams/api';
