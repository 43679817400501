import React from 'react';
import { createUseStyles } from 'react-jss';

interface ReserveCardProps {
  title: string;
  description: string;
}

const useStyles = createUseStyles({
  container: {
    padding: 24,
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    background: '#F7F7F7',
    maxWidth: 500,
    width: '100%',
  },
  title: {
    fontSize: 15,
    fontWeight: '600',
    margin: 0,
  },
  desc: {
    fontSize: 14,
    color: '#818388',
    fontWeight: '400',
    margin: 0,
  },
});

const ReserveCard = ({ title, description }: ReserveCardProps): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <p className={styles.desc}>{description}</p>
    </div>
  );
};

export default ReserveCard;
