import React, { ReactNode } from 'react';
import SectionHeading from '@src/components/SectionHeading';
import Modal, { ModalBasicProps } from '..';
import { Button as CallToActionButton, ButtonColor } from '@ateams/components';

export interface ConfirmModalProps extends ModalBasicProps {
  title: string;
  description: NonNullable<ReactNode>;
  actionColor?: ButtonColor;
  cancelColor?: ButtonColor;
  actionLabel?: string;
  actionTestingId?: string;
  actionLoading?: boolean;
  cancelLabel?: string;
  cancelDisabled?: boolean;
  actionDisabled?: boolean;
  onConfirm(): void;
  width?: number;
  hideCloseButton?: boolean;
  children?: ReactNode;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {
  const {
    title,
    description,
    onClose,
    onConfirm,
    actionLabel,
    actionColor,
    cancelColor,
    actionLoading,
    cancelLabel,
    cancelDisabled,
    actionTestingId,
    actionDisabled,
    width,
    children,
    hideCloseButton = true,
    ...left
  } = props;

  return (
    <Modal
      onClose={onClose}
      hideCloseButton={hideCloseButton}
      {...left}
      style={{
        zIndex: 10003,
        textAlign: 'center',
        width: width || 496,
      }}
    >
      <div style={{ color: 'black' }}>
        <SectionHeading isFirst>{title}</SectionHeading>
        <p>{description}</p>

        {children && (
          <div style={{ marginTop: '32px', textAlign: 'left' }}>{children}</div>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '32px',
            paddingLeft: '12px',
            paddingRight: '12px',
          }}
        >
          <CallToActionButton
            disabled={cancelDisabled}
            size="small"
            color={cancelColor || 'regular'}
            onClick={onClose}
            style={{
              marginRight: '24px',
              maxWidth: '120px',
            }}
          >
            {cancelLabel || 'Cancel'}
          </CallToActionButton>
          <CallToActionButton
            size="small"
            disabled={actionLoading || actionDisabled}
            color={actionColor}
            onClick={onConfirm}
            style={{
              marginRight: '24px',
              maxWidth: '120px',
            }}
            data-testing-id={actionTestingId}
          >
            {actionLabel || 'Confirm'}
          </CallToActionButton>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
