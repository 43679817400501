import FileUploader from '@src/components/FileUploader';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useScreenClass } from 'react-grid-system';
import { useFormContext } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import useMultipleImages from '../../../MultipleImages/hooks/useMultipleImages';
import ErrorForm from '../common/ErrorForm';

const useStyles = createUseStyles({
  dragAndDropArea: {
    marginTop: 40,
    width: '100%',
    paddingTop: '75%',
    backgroundColor: '#F7F7F7',
    borderRadius: 16,
    position: 'relative',
    alignItems: 'center',
    '& > div': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 0,
      display: 'flex',
      alignItems: 'center',
      '& > div': {
        width: '100%',
      },
    },
    '& [class^="buttonsContainer"]': {
      display: 'none',
    },
    '& input[type="file"] + div': {
      borderRadius: 16,
      height: '100%',
    },
    '& input[type="file"] + div > div': {
      backgroundColor: '#FFFFFF',
      borderRadius: 16,
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
});

const ASPECT_RATIO = 1.33;

const HINTS = [
  'We recommend uploading at least 4 images.',
  '1600x1200px or higher recommended. Max 5MB each.',
];

const Images = (): JSX.Element => {
  const {
    register,
    unregister,
    trigger,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const styles = useStyles();
  const screenClass = useScreenClass();
  const [isCropping, setIsCropping] = useState(false);

  const imagesUrls = watch('imagesUrls') ?? [];

  const {
    currentImage,
    onRemoveImage,
    onAddImage,
    images,
    setImages,
    onSetCoverImage,
    setCurrentImage,
    getIsCoverImage,
  } = useMultipleImages({
    imageUrls: imagesUrls,
  });

  useEffect(() => {
    register('imagesUrls', {
      validate: () => {
        if (isCropping) {
          return 'Complete image cropping';
        }
        return true;
      },
    });

    return () => {
      unregister('imagesUrls');
    };
  }, [isCropping]);

  useEffect(() => {
    setValue('imagesUrls', images);
  }, [JSON.stringify(images)]);

  useEffect(() => {
    trigger('imagesUrls');
  }, [isCropping]);

  const isMobile = screenClass === 'xs' || screenClass === 'sm';

  return (
    <div>
      <div
        className={cx(styles.dragAndDropArea)}
        style={{ marginBottom: images.length > 0 ? 100 : 0 }}
      >
        <div>
          <FileUploader
            onFileUploadSuccess={onAddImage}
            cropAspect={ASPECT_RATIO}
            withOptionBox={false}
            withCrop={true}
            circularCrop={false}
            buttonsPosition={'bottom'}
            type={'cover'}
            currentImage={currentImage}
            setCurrentImage={setCurrentImage}
            onRemoveImage={onRemoveImage}
            onAddImage={onAddImage}
            images={images}
            setImages={setImages}
            hints={isMobile ? [HINTS.join(' ')] : HINTS}
            onSetCoverImage={onSetCoverImage}
            getIsCoverImage={getIsCoverImage}
            inlineCropping
            previewImagesUnderneath
            maxFileSize={5242880}
            setIsCropping={setIsCropping}
          />
        </div>
      </div>
      <ErrorForm field="imagesUrls" errors={errors} />
    </div>
  );
};

export default Images;
