import { Spacing } from '@ateams/components';
import { MissionRoleId } from '@a_team/models/dist/MissionRole';
import { RoleFilter } from '@src/components/TeamGraphBuilderQuery';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { ValidAudienceFormData } from './AudienceForm';
import { SidebarItem } from './SidebarItem';

interface SelectedBuilder {
  email: string;
  firstName: string;
  lastName: string;
  username: string;
  uid: string;
}

export interface AudienceData {
  label: string;
  roleId: MissionRoleId;
  description: string;
  filters: RoleFilter;
  topUsers: number;
  selectedBuilders: SelectedBuilder[];
  automatedReachoutPreset?: string;
}

interface Props {
  audiences: AudienceData[];
  audienceTotalCounts: { label: string; count: number }[];
  audienceSelectedCounts: { label: string; count: number }[];
  audienceFormData: Map<string, ValidAudienceFormData>;
  onAudienceFormDataChange?: (
    audience: AudienceData,
    data: Partial<ValidAudienceFormData>,
  ) => void;
  onClickOnSelectAll?: (audience: AudienceData) => void;
  showMissionFields?: boolean;
  missionRoleOptions?: { label: string; value: MissionRoleId }[];
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${Spacing.large}px ${Spacing.medium}px`,
  },
});

export const Sidebar: React.FC<Props> = (props: Props) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      {props.audiences.map((audience) => {
        return (
          <SidebarItem
            key={audience.label}
            title={audience.label}
            showMissionFields={props.showMissionFields}
            missionRoleOptions={props.missionRoleOptions}
            automatedReachoutPreset={audience.automatedReachoutPreset}
            totalCount={
              props.audienceTotalCounts.find(
                (totalCount) => totalCount.label === audience.label,
              )?.count
            }
            selectedCount={
              props.audienceSelectedCounts.find(
                (totalCount) => totalCount.label === audience.label,
              )?.count
            }
            formData={props.audienceFormData.get(audience.label)}
            onFormDataChange={(data) => {
              if (props.onAudienceFormDataChange) {
                props.onAudienceFormDataChange(audience, data);
              }
            }}
            onClickOnSelectAll={() => {
              if (props.onClickOnSelectAll) {
                props.onClickOnSelectAll(audience);
              }
            }}
          ></SidebarItem>
        );
      })}
    </div>
  );
};
