import React from 'react';
import {
  CompanySizeLabels,
  DidDesignedProductsFromScratchLabels,
  LinkType,
  VettingProcessDesignPreVettingForm,
} from '@a_team/models/dist/vetting-processes/pre-vetting';
import { InputLabel, MultipleValuesField, useCommonStyles } from '../common';
import { CodeSample } from '@src/views/VettingDashboard/vetting-table/columns/columns/code-sample';
import { ValueTag } from '@src/views/VettingFeedbackForm/components/value-tag';
import { LinkIcon } from '@src/views/VettingFeedbackForm/components/icons/link';
import { TextInput } from '@src/views/VettingFeedbackForm/components/text-input';

export interface PreVettingDesignAnswersProps {
  answers: VettingProcessDesignPreVettingForm;
}

export const PreVettingDesignAnswers: React.FC<
  PreVettingDesignAnswersProps
> = ({ answers }) => {
  const commonStyles = useCommonStyles();

  return (
    <>
      <div className={commonStyles.row}>
        <InputLabel>Primary Roles</InputLabel>
        <div>
          <MultipleValuesField answers={answers.primaryRoles} />
        </div>
      </div>

      <div className={commonStyles.row}>
        <InputLabel>Company sizes</InputLabel>
        <div>
          <MultipleValuesField
            answers={answers.companySize.map(
              (cs) => CompanySizeLabels[cs] as string,
            )}
          />
        </div>
      </div>

      {answers.didDesignedProductsFromScratch && (
        <div className={commonStyles.row}>
          <InputLabel>Designed a product from 0 to 1?</InputLabel>
          <div>
            <ValueTag>
              {
                DidDesignedProductsFromScratchLabels[
                  answers.didDesignedProductsFromScratch
                ]
              }
            </ValueTag>
          </div>
        </div>
      )}

      <div className={commonStyles.row}>
        <InputLabel>Case study</InputLabel>
        {answers.caseStudyLinkType === LinkType.Download ? (
          <CodeSample
            codeSampleURL={answers.caseStudy}
            codeSampleLinkType={answers.caseStudyLinkType}
          />
        ) : (
          <a href={answers.caseStudy} target={'_blank'} rel="noreferrer">
            <ValueTag
              className={commonStyles.purpleTag}
              startAdornment={<LinkIcon />}
            >
              {answers.caseStudy}
            </ValueTag>
          </a>
        )}
      </div>

      <div className={commonStyles.row}>
        <InputLabel>Designed case study as team or individual</InputLabel>
        <TextInput
          value={answers.hadDesignedCaseStudyAsTeamOrIndividual}
          isReadOnly
        />
      </div>
    </>
  );
};
