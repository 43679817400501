import React from 'react';
import { createUseStyles } from 'react-jss';
import Lottie from 'lottie-react';
import loadingAnimation from './animated-a-team-logo.json';

const useStyles = createUseStyles({
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage:
      'url(https://ucarecdn.com/cb0f27ab-01bb-4058-96d1-79f5beb303c0/-/preview/1000x625/)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  lottieWrapper: {
    width: '200px',
    height: '200px',
  },
});

const LoadingScreen = () => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div className={styles.lottieWrapper}>
        <Lottie animationData={loadingAnimation} loop={true} />
      </div>
    </div>
  );
};

export default LoadingScreen;
