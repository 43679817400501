import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    marginBottom: '24px',
    paddingBottom: '24px',
    borderBottom: '1px solid #C0C0C0',
  },
});

const Intro = (): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <h4>This is the A.Team Platform Code of Conduct! </h4>

      <p>
        We’re transforming the way companies build and the way people work. As
        with any new model, especially one that emphasizes autonomy, we’re
        iterating on these guidelines to make the A.Team experience a success
        for everyone involved!
      </p>

      <p>
        We’ve worked with a committee of 10 builders from the A.Team community
        to compile these guidelines, incorporating learnings from several years
        and hundreds of missions, with only one thing in mind: Ensuring the
        Greatest Success of the Entire Community of A.Team Builders Over the
        Long Term.
      </p>

      <p>
        Questions? Shoot a note to{' '}
        <a href="mailto:help@a.team ">help@a.team </a> 😎
      </p>
    </div>
  );
};

export default Intro;
