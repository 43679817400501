import { Colors, Tag } from '@ateams/components';
import TooltipWrapped from '@src/components/TooltipWrapped';
import {
  MissionAboutLocation,
  MissionAdminAboutLocation,
  MissionAdminDocumentsLocation,
  MissionAdminFAQLocation,
  MissionAdminTeamPulseLocation,
  MissionAdminTimeTrackingLocation,
  MissionDocumentsLocation,
  MissionFAQLocation,
  MissionTeamPulseLocation,
  MissionTeamTimeTrackingLocation,
  MissionTimeTrackingLocation,
} from '@src/locations';
import Mission from '@src/stores/Missions/Mission';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { Icon } from '@a_team/ui-components';
import Indicator from '@src/components/Indicator';

interface TabsProps {
  currentMission: Mission;
  showTeamTimeTrackingTab: boolean;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    gap: 24,
    marginBottom: 24,
    background: Colors.backgroundWhite,
    position: 'sticky',
    top: 0,
    zIndex: 1,
    padding: '16px 24px 0 24px',
    borderBottom: '1px solid #DADADC',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
  },
  link: {
    paddingBottom: 16,
    fontSize: 12,
    fontWeight: 500,
    textDecoration: 'none',
    color: 'inherit',
    textTransform: 'uppercase',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  iconStyle: {
    cursor: 'pointer',
    display: 'inline-block',
  },
  tooltipMargin: {
    marginLeft: 8,
  },
  disabled: {
    color: '#ccc',
    cursor: 'not-allowed',
  },
  selectedTab: {
    borderBottom: '3px solid #7000E3',
  },
  iconTextContainer: {
    display: 'flex',
    gap: 6,
  },
});

const Tabs = ({ currentMission, showTeamTimeTrackingTab }: TabsProps) => {
  const styles = useStyles();
  const location = useLocation();

  const myOpenIncompleteTeamPulseSurveyCount =
    currentMission?.myOpenIncompleteTeamPulseSurveys?.length;
  const teamPulsesCount = currentMission?.teamPulses?.length;
  const shouldDisplayTeamPulseTab =
    currentMission &&
    (currentMission?.isAdminMode ||
      teamPulsesCount ||
      myOpenIncompleteTeamPulseSurveyCount);
  const teamPulseTabLocation = currentMission?.isAdminMode
    ? MissionAdminTeamPulseLocation(currentMission.mid)
    : MissionTeamPulseLocation(currentMission?.mid);

  return (
    <div className={styles.container}>
      <Link
        to={
          currentMission?.isAdminMode || currentMission?.isMissionManager
            ? MissionAdminTimeTrackingLocation(currentMission.mid)
            : MissionTimeTrackingLocation(currentMission?.mid)
        }
        className={cx(
          styles.link,
          currentMission?.timeTrackingTabDisabled && styles.disabled,
          location.pathname.includes('time-tracking') &&
            !location.pathname.includes('team') &&
            styles.selectedTab,
        )}
      >
        My timesheet
      </Link>

      {showTeamTimeTrackingTab && (
        <Link
          to={MissionTeamTimeTrackingLocation(currentMission?.mid)}
          className={cx(
            styles.link,
            currentMission?.timeTrackingTabDisabled && styles.disabled,
            location.pathname.includes('team-time-tracking') &&
              styles.selectedTab,
          )}
        >
          Team timesheet
        </Link>
      )}

      <Link
        to={
          currentMission?.isAdminMode
            ? MissionAdminAboutLocation(currentMission.mid)
            : MissionAboutLocation(currentMission.mid)
        }
        className={cx(
          styles.link,
          location.pathname.includes('about') && styles.selectedTab,
        )}
      >
        About Mission
      </Link>

      <Link
        to={
          currentMission?.isAdminMode
            ? MissionAdminDocumentsLocation(currentMission.mid)
            : MissionDocumentsLocation(currentMission.mid)
        }
        className={cx(
          styles.link,
          currentMission?.documentsTabDisabled && styles.disabled,
          location.pathname.includes('documents') && styles.selectedTab,
        )}
      >
        <span className={styles.iconTextContainer}>
          {!currentMission?.isAdminMode &&
            !currentMission?.isMissionManager && (
              <TooltipWrapped
                title={'This information is only available to you.'}
                arrow
                animation="fade"
              >
                <Icon size="md" name="lock" className={styles.iconStyle} />
              </TooltipWrapped>
            )}{' '}
          {currentMission?.isAdminMode || currentMission?.isMissionManager
            ? 'Documents'
            : 'My Documents'}
          {currentMission?.pendingContracts &&
            currentMission.pendingContracts.length > 0 && (
              <TooltipWrapped
                title={`You have ${currentMission?.pendingContracts.length} pending contracts`}
                arrow
                animation="fade"
                className={styles.tooltipMargin}
              >
                <Tag color={'primary'} thin style={{ padding: '2px 6px' }}>
                  {currentMission.pendingContracts.length}
                </Tag>
              </TooltipWrapped>
            )}
        </span>
      </Link>

      <Link
        to={
          currentMission?.isAdminMode
            ? MissionAdminFAQLocation(currentMission.mid)
            : MissionFAQLocation(currentMission.mid)
        }
        className={cx(
          styles.link,
          currentMission?.timeTrackingTabDisabled && styles.disabled,
          location.pathname.includes('faq') && styles.selectedTab,
        )}
      >
        Payment Terms
      </Link>

      {!!shouldDisplayTeamPulseTab && (
        <Link
          to={teamPulseTabLocation}
          className={cx(
            styles.link,
            currentMission?.teamPulseTabDisabled && styles.disabled,
            location.pathname.includes('team-pulse') && styles.selectedTab,
          )}
        >
          <span>Team Pulse</span>
          {!!myOpenIncompleteTeamPulseSurveyCount && (
            <Indicator
              fill={Colors['primary']}
              style={{
                top: '-9px',
                left: '2px',
                display: 'inline-block',
                position: 'relative',
              }}
            />
          )}
        </Link>
      )}
    </div>
  );
};

export default Tabs;
