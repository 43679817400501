import React, { ReactElement } from 'react';
import { loadMission, MissionMatch } from '@src/url-loaders/loadMission';
import { useStores } from '@src/stores';
import { Link, Redirect } from 'react-router-dom';
import {
  MissionDocumentsLocation,
  MissionPageLocation,
  MissionControlBase,
} from '@src/locations';
import MissionLayout from '@src/layouts/Mission';
import SectionHeading from '@src/components/SectionHeading';
import { observer } from 'mobx-react';
import faqFees from './faqFees.png';
import faqTaxTips from './faqTaxTips.jpeg';

interface Props {
  match: MissionMatch;
  adminView?: boolean;
}

export const missionFAQViewLoader = loadMission;

function MissionFAQView(props: Props): ReactElement | null {
  const { match } = props;

  const stores = useStores();
  const { missions, auth } = stores;
  const { currentMission } = missions;

  if (!currentMission) {
    return null;
  }

  let notAllowed = currentMission.timeTrackingTabDisabled;
  auth.isAdmin && (notAllowed = false);

  if (notAllowed) {
    return (
      <Redirect
        to={
          match.params.mid
            ? MissionPageLocation(match.params.mid)
            : MissionControlBase
        }
      />
    );
  }
  return (
    <MissionLayout
      title={`${currentMission.data.title} | Time Tracking`}
      match={props.match}
    >
      <div style={{ maxWidth: '912px' }}>
        <SectionHeading isFirst style={{ marginTop: '24px' }}>
          Setting up your payments
        </SectionHeading>
        <p>
          Once you join a mission, you'll see a clear call to action to set up
          payments to your bank account. Please do this as soon as possible. You
          will not be able to submit your timesheets for payment, or receive
          payment until you do.
        </p>

        <SectionHeading>How do I submit my invoice for payment?</SectionHeading>
        <ul>
          <li>
            Clients are invoiced twice a month, from the 1st to 15th, and the
            16th to 30/31st.
          </li>
          <li>
            You should regularly log your time in the ATeam platform with
            details of your work for the client.
          </li>
          <li>
            <b>
              Timesheets automatically lock and submitted four days after the
              end of the period, at 7pm EST (so the 19th and the 4th of each
              month).
            </b>
          </li>
          <li>
            Check over your timesheet to make sure the hours you've worked are
            accurately represented. Once everything looks good, click the
            "Submit Timesheet" button. When you submit your timesheet, you
            attest that the time billed to the client is accurate.
          </li>
          <li>
            If you accidentally submit your timesheet before you are ready,
            please reach out to{' '}
            <a href="mailto:payments@a.team">payments@a.team</a>. If the client
            hasn't been invoiced, we can reopen it and allow you to fix any
            errors — as long as the Team Invoice hasn't been sent to the client.
          </li>
        </ul>

        <SectionHeading>
          When should I expect to receive payment?
        </SectionHeading>
        <p>
          On average, clients pay their invoices in 2-3 weeks. Once a client
          pays the invoice for their team, the funds are routed to each team
          member accordingly. Some clients pay faster or slower depending on a
          variety of factors that are difficult to predict. You can view your
          invoice status by logging into your account on the A.Team platform ,
          selecting your active mission, and clicking on{' '}
          <Link to={MissionDocumentsLocation(currentMission.mid)}>
            My Documents
          </Link>
          . Here, you will see your Invoice Status which will either read
          "pending" or "paid."
        </p>

        <SectionHeading>Fees associated with payments</SectionHeading>
        <p>
          We use Tipalti to process payments to our builders around the globe.
          Depending on your location, you could be subjected to fees when
          receiving your payments. Below is a schedule of fees on builder
          payments:
        </p>
        <img src={faqFees} alt="" width="100%" />

        <SectionHeading>
          If you want your payments in USD($) through a US Bank, or a bank and
          currency in a country different than the country of your residence
          follow these steps:
        </SectionHeading>
        <p>
          You can avoid FX fees, and wire transfer fees by receiving an ACH or
          eCheck deposit through Wise (fka Transferwise), or another bank based
          in the US.
        </p>
        <ul>
          <li>
            In the first screen of the payment setup (Address) there is purple
            highlighted text at the bottom "Select to be paid in a different
            country than the above". Clicking on this link will allow you to set
            up to be paid to a bank account in the country of your choice.
          </li>
        </ul>

        <SectionHeading>Tips on Required Tax Forms</SectionHeading>
        <p>
          <i>
            Disclaimer: We are legally prohibited from providing tax advice. The
            information below is intended to be informative on the account set
            up process for ATeams and not to be construed as tax advice.
          </i>
        </p>
        <p>
          As a US Company, we are required to intake and validate the
          information on one of the three following forms for our builders:
        </p>
        <ul>
          <li>
            W9: this form is required for US-based individuals or entities (if
            you have an LLC or other company structure set up)
          </li>
          <li>
            W8-BEN: this form is required for foreign individuals (non-entities)
          </li>
          <li>
            W8-BEN-E: this form is required for foreign entities (if you have a
            foreign company set up that you want to receive your payments
            through)
          </li>
        </ul>

        <p>
          We use Tipalti to intake and validate this information in the modal
          when you're setting up your bank account. <br />
          <b>
            There is a confusing, pre-set step in this process where it asks if
            you are claiming Treaty Benefits.{' '}
            <u>You can continue without entering anything</u>. Entering your
            country signals that you have documentation to provide to the US and
            your home country tax authorities for treaty benefit treatment. If
            you do qualify, you will know what forms you are required to submit
            to complete this process. If you are unfamiliar, it is likely you
            cannot claim treaty benefits.
          </b>
        </p>
        <img src={faqTaxTips} alt="" width="100%" />

        <SectionHeading>
          What is A.Team's legal address and FEIN, for my accounting?
        </SectionHeading>
        <p>
          In case you need this for your tax purposes, you can use these
          details:
          <br />
          <b>Company Name: </b>ATeams, Inc
          <br />
          <b>Company Address: </b>12 W 10th Street, Unit 2N, New York, NY 10011
          <br />
          <b>EIN: </b>84-2691666
          <br />
        </p>

        <SectionHeading>
          How do I get reimbursed for other expenses (ex. software licenses,
          subscription, equipment, etc.)?
        </SectionHeading>
        <p>
          Make sure you discuss and agree on any expenses associated with your
          mission directly with the client. We recommend that the client makes
          purchases for software, subscriptions, or equipment, directly, with
          their own payment method. If you did incur approved expenses, you can
          submit them using{' '}
          <a
            href="https://airtable.com/shrMfgrUFLX3PcYl5"
            target="_blank"
            rel="noreferrer"
          >
            this form
          </a>
          . Be sure to include your receipts, and the approval communication
          from the client.
        </p>
      </div>
    </MissionLayout>
  );
}

export default observer(MissionFAQView);
